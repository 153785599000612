/**
 * this function changes selected date to string
 * 
 * @param {*} e date from Calendar component
 * @param {*} cb setstate or custom callback
 */
export const onDateChange = (e, cb) => {
    const dds = new Date(e.value);
    const fixedMonth = dds.getMonth() + 1;
    const finalDate = `${dds.getFullYear()}-${fixedMonth < 10 ? "0" + fixedMonth : fixedMonth}-${
      dds.getDate() < 10 ? "0" + dds.getDate() : dds.getDate()
    }`;
    cb(finalDate);
    return finalDate
  };
  