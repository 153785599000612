import { convertTimezone } from "utils/convertTimezone";

const getDrivers = (usernames, driverList) => {
    return driverList.filter(driver => {
        return usernames && usernames.includes(driver.username);
    });
}

const filterHistoryData = (historyData, driverList) => {
    if(!historyData || !driverList) return historyData;
    const usernames = historyData && historyData.map(data => data.username);
    const matchedDrivers = getDrivers(usernames, driverList);

    return historyData && historyData.map(data => {
        const fullName = matchedDrivers.find(driver => driver.username === data.username)?.full_name ?? "";

        return {
            date: convertTimezone(data.date),
            status: data.status,
            username: data.username,
            fullName: fullName,
        };
    });
}

export default filterHistoryData;
