import TYPES from '../types';

const initialState = {
    excel_data: '',
};

export const uploadExcel = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.UPLOAD_EXCEL:
            return {...state, excel_data: action.payload};
        // case TYPES.UPDATE_TABLE_ORDER:
        //     return  
        default:
            return state;
    }
}