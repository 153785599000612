import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
};

export default function SelectField({ label, options, ...props }) {
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth {...props}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...field}
        error={meta.touched && Boolean(meta.error)}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
