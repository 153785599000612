import TYPES from "../types";

const initialState = {
    data: "",
    firmID: null
};

export const customerWarehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_LIST_CUSTOMER_WAREHOUSE:
            //console.log(action.payload)
            return { ...state, data: action.payload.data }
        case TYPES.ADD_CUSTOMER_WAREHOUSE:
            return { ...state, data: [...state.data, action.payload.data[0]] };
        case TYPES.DELETE_CUSTOMER_WAREHOUSE:
            //return { ...state, data: state.data.filter(item => item.id != action.payload.data[0].id) } -> object does not return
            return { ...state, data: state.data.filter(item => item.id != action.payload) }
        case TYPES.UPDATE_CUSTOMER_WAREHOUSE:
            return { ...state, data: state.data.map(content => content.id == action.payload.data[0].id ? action.payload.data[0] : content) }
        case TYPES.SET_CUSTOMER_FIRM_ID:
            //console.log(action.payload)
            return { ...state, firmID: action.payload }
        default:
            return state
    }
};
