import TYPES from "../types";

const initialState = {};

export const vehicleStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.VEHICLE_STATUS:
      return { ...state, data: action.payload }

    default:
      return state
  }
};
