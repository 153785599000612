
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { vehicleStatus } from "../../store/actions/vehicleManagementAction";
import { driverStatus } from "../../store/actions/DriverManagementAction";
import { useSelector, useDispatch } from 'react-redux'

const DialogActive = (props) => {
    const {
        dialogActiveStatus,
        setDialogActiveStatus,
        dialogActivePassiveID,
        activePassive
    } = props;

    const vehicle_status = useSelector( state => state.vehicleStatusReducer);
    const driver_status = useSelector(state => state.driverManagementReducer);

    const [value1, setValue1] = useState('');
    const [selectedStatus, setSelectedStatus] = useState();

    const dispatch = useDispatch();

    var SELECTED_STATUS =  dialogActivePassiveID && dialogActivePassiveID.status;

    useEffect(() => {
        activePassive(vehicle_status)
    }, [vehicle_status]);

    useEffect(() => {
        setSelectedStatus(SELECTED_STATUS);
    }, [dialogActivePassiveID]);

    const saveActivePassive = () => {
       let status = dialogActivePassiveID.status == "ACTIVE" ? "PASSIVE" : "ACTIVE"
       if(dialogActivePassiveID.user_type == "DRIVER"){
        dispatch(
            driverStatus(
                dialogActivePassiveID.id,
                value1,
                status,
                res => { if(res.success){
                    // activePassive(res.data[0]);
                    setDialogActiveStatus(false);
                }}
            )
        )
       } else {
        dispatch(
            vehicleStatus(
                dialogActivePassiveID.id,
                value1,
                status,
                res => { if(res.success){
                    // activePassive(res.data[0]);
                    setDialogActiveStatus(false);
                }}
            )
        )
     }
    }

    const activeDialogFooter = (saveType) => {
        return (
            <React.Fragment>
                <Button
                    label="İptal"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={hideDialog}
                />
                <Button
                    label={ selectedStatus === 'PASSIVE' ? 'Aktif' : 'Pasif'}
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={(e) => saveActivePassive()}
                />
            </React.Fragment>
        );
    };

    const activeHideDialog = () => {
        setDialogActiveStatus(false)
    }

    const hideDialog = () => {
        setDialogActiveStatus(false)
    }



    return (
        <Dialog
            visible={dialogActiveStatus}
            style={{ width: "450px" }}
            header="Aktif/Pasif"
            footer={activeDialogFooter}
            onHide={activeHideDialog}
        >

            <InputTextarea value={value1} rows={5} onChange={(e) => setValue1(e.target.value)} style={{ "width": "100%" }} />

        </Dialog>
    )
}

export default DialogActive;


