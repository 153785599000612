import TYPES from "../types";
import { Services } from "../../api/Services";

const server = new Services();


export const getCustomerWarehouse = (selectedCustomer) => {
  // let mockData = [{
  //     "id": "8a88808877405b8d0177431e543b3436",
  //     "created_at": "2021-01-27T08:03:51",
  //     "lastupdated": "2021-01-27T08:03:51",
  //     "code": "MSA1",
  //     "name": "MSA MUTFAK",
  //     "address": "Sakıp Sabancı Caddesi 42, 34467, Emirgan, Sarıyer, İstanbul, Türkiye",
  //     "country": null,
  //     "state": null,
  //     "city": "istanbul",
  //     "county": "sarıyer",
  //     "latitude": 41.10557,
  //     "longitude": 29.05694,
  //     "district": null,
  //     "street": null,
  //     "address_line": null,
  //     "post_code": null,
  //     "special_1": null,
  //     "special_2": null,
  //     "special_3": null
  // },
  // {

  //     "id": "8a88808877405b8d0177431e543b3436",
  //     "created_at": "2021-01-27T08:03:51",
  //     "lastupdated": "2021-01-27T08:03:51",
  //     "code": "MSA2",
  //     "name": "MSA MUTFAK-2",
  //     "address": "Sakıp Sabancı Caddesi 42, 34467, Emirgan, Sarıyer, İstanbul, Türkiye",
  //     "country": null,
  //     "state": null,
  //     "city": "istanbul",
  //     "county": "sarıyer",
  //     "latitude": 41.10557,
  //     "longitude": 29.05694,
  //     "district": null,
  //     "street": null,
  //     "address_line": null,
  //     "post_code": null,
  //     "special_1": null,
  //     "special_2": null,
  //     "special_3": null
  // },
  // {

  //     "id": "8a88808877405b8d0177431e543b3436",
  //     "created_at": "2021-01-27T08:03:51",
  //     "lastupdated": "2021-01-27T08:03:51",
  //     "code": "MSA3",
  //     "name": "MSA MUTFAK-3",
  //     "address": "Sakıp Sabancı Caddesi 42, 34467, Emirgan, Sarıyer, İstanbul, Türkiye",
  //     "country": null,
  //     "state": null,
  //     "city": "istanbul",
  //     "county": "sarıyer",
  //     "latitude": 41.10557,
  //     "longitude": 29.05694,
  //     "district": null,
  //     "street": null,
  //     "address_line": null,
  //     "post_code": null,
  //     "special_1": null,
  //     "special_2": null,
  //     "special_3": null
  // }]


  // return (dispatch) => {
  //     dispatch({
  //         type: TYPES.GET_LIST_CUSTOMER_WAREHOUSE,
  //         payload: mockData,
  //     });
  // };

  return async (dispatch) => {
    server
      .getListCustomerWarehouse(selectedCustomer.id)
      .then((data) => {
        dispatch({
          type: TYPES.GET_LIST_CUSTOMER_WAREHOUSE,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};



export const addCustomerWarehouse = (data, cb) => {

  let obj = {
    "code": data.product.code,
    "name": data.product.name,
    "address": data.product.address,
    "country": null,
    "state": null,
    "city": data.product.city,
    "county": data.product.county,
    "latitude": data.address.newCoodinate.latitude,
    "longitude": data.address.newCoodinate.longitude,
    "district": null,
    "street": null,
    "address_line": null,
    "post_code": null,
    "special_1": null,
    "special_2": null,
    "special_3": null,
    "firm_id": data.firmID.firmID
  }

  return async (dispatch) => {
    server
      .addCustomerWarehouse(obj)
      .then((data) => {
        dispatch({
          type: TYPES.ADD_CUSTOMER_WAREHOUSE,
          payload: data.entity,
        });
        cb(false);
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};


export const deleteCustomerWarehouse = (id, callback, onError) => {
  let wId = id;
  return async (dispatch) => {
    server
      .deleteCustomerWarehouse(wId)
      .then((data) => {
        dispatch({
          type: TYPES.DELETE_CUSTOMER_WAREHOUSE,
          //payload: data.entity, -> object does not return
          payload: wId,
        });
        callback(data.status);
      })
      .catch(error => onError(error));
  };
};


export const updateCustomerWarehouse = (data, cb) => {

  let obj = {
    "code": data.product.code,
    "name": data.product.name,
    "address": data.product.address,
    "country": null,
    "state": null,
    "city": data.product.city,
    "county": data.product.county,
    "latitude": data.address.newCoodinate.latitude || data.product.latitude,
    "longitude": data.address.newCoodinate.longitude || data.product.longitude,
    "district": null,
    "street": null,
    "address_line": null,
    "post_code": null,
    "special_1": null,
    "special_2": null,
    "special_3": null,
    //"firm_id": data.firmID.firmID
  }

  return async (dispatch) => {
    server
      .updateCustomerWarehouse(obj, data.product.id)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_CUSTOMER_WAREHOUSE,
          payload: data.entity,
        });
        cb(false);
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};



export const getAdminCustomerWarehouse = (selectedCustomer, setDropDownAdminWarehouseList) => {

  return async (dispatch) => {
    server
      .getListCustomerWarehouse(selectedCustomer.id)
      .then((data) => {
        setDropDownAdminWarehouseList({ dropDownData:data.entity.data, labelName: "name", placeholder: "Seciniz" });
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};