import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputBase, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

CustomSelect.propTypes = {
  data: PropTypes.array,
};

CustomSelect.defaultProps = {
  data: [],
};

export default function CustomSelect(props) {
  const { data, value, onChange, required } = props;

  return (
    <FormControl fullWidth required={required}>
      {props.label && (
        <InputLabel shrink sx={{ fontWeight: "600", transform: "scale(.75)" }}>
          {props.label}
        </InputLabel>
      )}
      <Select
        displayEmpty
        multiple={props.multiple}
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
        input={<BootstrapInput />}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography sx={{ opacity: 0.5, fontSize: 14 }}>
                {props.placeholder}
              </Typography>
            );
          }

          return selected.value || selected;
        }}
        {...props}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
