import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { userAdd, getUserList, userEdit } from "../../store/actions/customerUserPopupAction";

const CustomerUserAdd = (props) => {
    const dispatch = useDispatch();
    const userDt = useSelector(state => state.customerUserPopupReducer);
    const { dialogCustomerUser, setDialogCustomerUser, product, toastCallback } = props;
    const [addDialog, setAddDialog] = useState(null);
    const [userData, setUserData] = useState({});



    const hideDialogCustomerUser = () => {
        setDialogCustomerUser(false);
    };

    const input = {
        width: "100%"
    };

    const inputDiv = {
        marginTop: "15px"
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _udata = { ...userData };
        _udata[`${name}`] = val;

        setUserData(_udata);
    }

    const addUserButton = () => {
        // console.log(userData);
        // console.log(product);

        //Form input validation 
        if (!userData.id) { // check when adding a new user
            if (userData.email == undefined || userData.name == undefined || userData.lastName == undefined || userData.password == undefined || userData.phone == undefined || userData.username == undefined) {
                toastCallback("warn", "eksik alanlar var");
                return
            }
        }else {
            if (userData.email == undefined || userData.name == undefined || userData.lastName == undefined || userData.phone == undefined || userData.username == undefined) {
                toastCallback("warn", "eksik alanlar var");
                return
            }
        }


        if (!userData.id) { // add new user
            dispatch(
                userAdd({ userData, product }, toastCallback, setAddDialog)
            )
        } else { // update user
            dispatch(
                userEdit({ userData, product }, toastCallback, setAddDialog)
            )
        }
    }


    const editUserButton = (rowData) => {
        // console.log(userData);
        // console.log(product);
        let obj = {
            "id": rowData.id,
            "name": rowData.first_name,
            "lastName": rowData.last_name,
            "phone": rowData.phone,
            "password": rowData.password,
            "email": rowData.email,
            "username": rowData.username
        }
        setAddDialog(true);
        setUserData(obj);

    }

    useEffect(() => {
        dialogCustomerUser == true && dispatch(
            getUserList(product)
        )
    }, [dialogCustomerUser])



    // useEffect(() => {
    //     console.log(userData)
    // }, [userData])

    const addUserDialogFooter = (
        <React.Fragment>
            <Button
                label="İptal"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => setAddDialog(false)}
            />
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => addUserButton()}
            />
        </React.Fragment>
    );


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUserButton(rowData)} />
            </React.Fragment>
        );
    }

    return (
        <>
            <Dialog
                header="Yeni ekle"
                visible={dialogCustomerUser}
                style={{ width: "650px" }}
                onHide={hideDialogCustomerUser}
            // footer={DialogFooter}
            >
                <Button
                    label="Kullanıcı Ekle"
                    className="p-button-success"
                    style={{ width: "100%" }}
                    onClick={() => {
                        setAddDialog(true);
                        setUserData({});
                    }}
                />

                <div>
                    <div className="card" style={{ "marginTop": "15px" }}>
                        <DataTable value={userDt && userDt.data} responsiveLayout="scroll">
                            <Column field="first_name" header="Adı" style={{ "width": "100px" }}></Column>
                            <Column field="last_name" header="Soyadı" style={{ "width": "100px" }}></Column>
                            <Column field="email" header="Email"></Column>
                            <Column field="phone" header="Telefon"></Column>
                            <Column body={actionBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

            <Dialog
                header="Kullancı Ekle"
                visible={addDialog}
                style={{ width: "450px" }}
                onHide={() => setAddDialog(false)}
                footer={addUserDialogFooter}
            >
                <div style={inputDiv}>
                    <InputText
                        //type="search"
                        //onInput={(e) => setGlobalFilter(e.target.value)}
                        value={userData.name}
                        placeholder="Adı"
                        style={input}
                        onChange={(e) => onInputChange(e, 'name')}
                    />
                </div>
                <div style={inputDiv}>
                    <InputText
                        //type="search"
                        //onInput={(e) => setGlobalFilter(e.target.value)}
                        value={userData.lastName}
                        placeholder="Soyadı"
                        style={input}
                        onChange={(e) => onInputChange(e, 'lastName')}
                    />
                </div>
                <div style={inputDiv}>
                    <InputText
                        //type="phone"
                        //onInput={(e) => setGlobalFilter(e.target.value)}
                        value={userData.phone}
                        placeholder="Telefon"
                        maxLength="11"
                        style={input}
                        onChange={(e) => onInputChange(e, 'phone')}
                    />
                </div>
                <div style={inputDiv}>
                    <InputText
                        type="password"
                        //onInput={(e) => setGlobalFilter(e.target.value)}
                        value={userData.password}
                        placeholder="Şifre"
                        style={input}
                        onChange={(e) => onInputChange(e, 'password')}
                    />
                </div>
                <div style={inputDiv}>
                    <InputText
                        type="email"
                        //onInput={(e) => setGlobalFilter(e.target.value)}
                        value={userData.email}
                        placeholder="Email"
                        style={input}
                        onChange={(e) => onInputChange(e, 'email')}
                    />
                </div>
                <div style={inputDiv}>
                    <InputText
                        //type="search"
                        //onInput={(e) => setGlobalFilter(e.target.value)}
                        value={userData.username}
                        placeholder="Kullanıcı Adı"
                        style={input}
                        onChange={(e) => onInputChange(e, 'username')}
                    />
                </div>



            </Dialog>
        </>
    )
}

export default CustomerUserAdd
