import React from "react";
import PropTypes from "prop-types";
import TurkeyMap from "turkey-map-react";
import { Card, CardHeader, Tooltip, useTheme } from "@mui/material";
import CityList from "../CityList";
import "./style.css";

const RenderCity = (cityComponent, city, cities) => {
  cityComponent.props.key = city.id;
  cityComponent.props["data-is-election-city"] = [
    ...cities.map((i) => i.id),
  ].includes(city.id);

  let cityName = city.name;

  if ([...cities.map((i) => i.id)].includes(city.id)) {
    const getCity = cities.find((i) => i.id === city.id);
    cityName = `${getCity.id} (${getCity.count} Adet)`;
  }

  return (
    <Tooltip key={city.id} title={cityName}>
      {cityComponent}
    </Tooltip>
  );
};

Map.propTypes = {
  data: PropTypes.array,
};

export default function Map({ data }) {
  const { palette } = useTheme();

  const cities = data.map((e) => ({
    id: e.city.toLowerCase(),
    count: e.count,
  }));

  return (
    <Card>
      <CardHeader title="İl Bazlı Gönderiler" sx={{ mb: 3 }} />
      <TurkeyMap
        showTooltip
        cityWrapper={(cityComponent, city) =>
          RenderCity(cityComponent, city, cities)
        }
        customStyle={{
          idleColor: palette.grey[400],
          hoverColor: palette.success.main,
        }}
      />
      <CityList data={data} />
    </Card>
  );
}
