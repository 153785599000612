import TYPES from "../types";

const initialState = {
    data: "",
    drawing: "",
    deleted: "",
    edited: ""
};

export const branchDrawingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_BRANCH_DRAWING:
            return { ...state, data: action.payload.data }
        case TYPES.BRANCH_DRAWING:
            //return { ...state, drawing: [...state.drawing, action.payload] };
            state.deleted = "";
            return { ...state, data: [...state.data, action.payload] };
        case TYPES.REMOVE_BRANCH_DRAWING:
            let delDraw = action.payload.map(x => `POLYGON((${x.toString()}))`);
            let toRemove = delDraw;
            let array = state.data;
            let resultArray = array.filter(function (el) {
                return toRemove.indexOf(el) < 0;
            });
            return { ...state, deleted: resultArray };
        case TYPES.EDIT_BRANCH_DRAWING:
            state.deleted = "";
            let delDraw1 = action.payload.map(x => `POLYGON((${x.toString()}))`);
            let toRemove1 = delDraw1;
            return { ...state, data: toRemove1 };
        default:
            return state
    }
};
