import TYPES from "../types";

const initialState = {};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_ADVERTISEMENT_ALL:
      return { ...state, data: action.payload.data }
    case TYPES.ADVERTISEMENT_ADD:
      return { ...state, data: [...state.data, action.payload] }
    case TYPES.ADVERTISEMENT_BETWEEN_DATE:
      return { data: action.payload.data }
    case TYPES.PUBLISH_PROJECT_UPDATE:
      return {...state, data: state.data.map(content => content.id == action.payload.data[0].id ?{...content, evaluation_status: action.payload.data[0].evaluation_status } : content)}
      case TYPES.PROJECT_UPDATE:
        var projectStoredId = action.payload.id;
        return { data: state.data.map(content => content.id == action.payload.id? action.payload:content)}

    default:
      return state
  }
};
