import { useState } from "react";
import { useHistory } from "react-router-dom";
// @mui
import { Box, Divider, Typography, MenuItem } from "@mui/material";
// components
import MyAvatar from "components/MyAvatar";
import MenuPopover from "components/MenuPopover";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/actions";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((userData) => userData.userReducer);

  const user = userData.user;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    dispatch(logout(() => history.replace("/login")));
  };

  return (
    <>
      <Box onClick={handleOpen} sx={{ cursor: "pointer" }}>
        <MyAvatar />
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.first_name} {user?.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.user_type}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Çıkış Yap
        </MenuItem>
      </MenuPopover>
    </>
  );
}
