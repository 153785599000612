import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  getProjectList,
  getProjectBetweenDate,
} from "../../store/actions/projectAction";
import { customerList } from "../../store/actions/customerAction";
import { Link } from "react-router-dom";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Container } from "@mui/material";
import Topbar from "components/Topbar";

const Project = (props) => {
  const dispatch = useDispatch();
  const projectlist = useSelector((state) => state.projectReducer);
  const customerlist = useSelector((state) => state.customerReducer);

  const [dates2, setDates2] = useState(null);
  const [customerDeleted, setCustomerDeleted] = useState(false);

  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();
  const navBarWidth = 280;
  const isMobile = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  useEffect(() => {
    if (dates2 === null || dates2[1] === null) return;
    let start = new Date(dates2[0].setHours(dates2[0].getHours() + 5));
    let end = new Date(dates2[1].setHours(dates2[1].getHours() + 5));
    dispatch(getProjectBetweenDate(start.toISOString(), end.toISOString()));
  }, [dates2]);

  useEffect(() => {
    dispatch(getProjectList());

    if (Object.keys(customerlist).length <= 2) {
      dispatch(customerList());
    }
  }, []);

  useEffect(() => {
    if (customerDeleted && Object.keys(customerlist).length <= 2) {
      dispatch(customerList());
    }
  }, [customerDeleted]);

  return (
    <>
      {!isMobile && <Topbar marginLeft={`${navBarWidth}px`} />}
      <Container
        maxWidth={false}
        sx={{ display: isMobile ? "block" : "flex", paddingTop: "60px" }}
      >
        <NavBar navBarWidth={navBarWidth} isMobile={isMobile} />
        <Box width={1} mt={2} ml={isMobile ? 0 : `${navBarWidth + 20}px`}>
          <Box sx={{ pb: 10 }}>
            <Link to="/project-application"></Link>
            <Table
              products={projectlist.data}
              type="Project"
              dates2={dates2}
              setDates2={setDates2}
              deleteCustomerCallback={(deleted) => setCustomerDeleted(deleted)}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

Project.whyDidYouRender = false;

export default Project;
