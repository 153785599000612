import TYPES from "../types";

const initialState = {
  general: null,
  delivery: null,
  product: null,
};

export const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_REPORTS:
      return { ...state, general: action.payload };
    case TYPES.GET_REPORTS_DELIVERY:
      return { ...state, delivery: action.payload };
    case TYPES.GET_PRODUCT_REPORTS:
      return { ...state, product: action.payload };
    default:
      return state;
  }
};
