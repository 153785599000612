// Live Tracking Table Head Data
// const tableHead = [
//     {
//         name: "LiveTracking",
//         head: [
//             { key: "delivery_date", title: "Teslimat Tarihi" },
//             { key: "order_code", title: "Sipariş Kodu" },
//             { key: "delivery_date", title: "Teslimat Tarihi" },
//             { key: "order_date", title: "Sipariş Tarihi" },
//             { key: "name", title: "Sipariş Veren" },
//             { key: "firm_name", title: "Firma Adı" },
//             { key: "gsm_number", title: "Telefon" },
//             { key: "filter", title: "Teslim Durumu" },
//             { key: "delivery_date", title: "Teslimat Tarihi" },
//             { key: "arrival_time", title: "Teslim Saati" },
//             { key: "delivery_to", title: "Teslim Alan Kişi" },
//             { key: "driver_name", title: "Kurye Adı Soyadı" },
//             { key: "driver_phone", title: "Kurye Telefon" },
//             { key: "customer_feedback_date", title: "Geri Bildirim Tarihi" },
//             { key: "delivery_description", title: "Teslim Edilememe Nedeni" },
//             { key: "deci", title: "Desi" },
//             { key: "quantity", title: "Paket Sayısı" },
//             { key: "assigned_branch.name", title: "Dağıtım Şubesi" }
//         ]
//     }
// ];
//export default tableHead


export function tableHead(head) {
    switch (head) {
        case "LiveTracking":
            return [{
                name: "LiveTracking",
                head: [
                    { key: "delivery_date", title: "Teslimat Tarihi" },
                    { key: "order_code", title: "Sipariş Kodu" },
                    { key: "delivery_date", title: "Teslimat Tarihi" },
                    { key: "order_date", title: "Sipariş Tarihi" },
                    { key: "name", title: "Sipariş Veren" },
                    { key: "firm_name", title: "Firma Adı" },
                    { key: "gsm_number", title: "Telefon" },
                    { key: "filter", title: "Teslim Durumu" },
                    { key: "delivery_date", title: "Teslimat Tarihi" },
                    { key: "arrival_time", title: "Teslim Saati" },
                    { key: "delivery_to", title: "Teslim Alan Kişi" },
                    { key: "driver_name", title: "Kurye Adı Soyadı" },
                    { key: "driver_phone", title: "Kurye Telefon" },
                    { key: "customer_feedback_date", title: "Geri Bildirim Tarihi" },
                    { key: "delivery_description", title: "Teslim Edilememe Nedeni" },
                    { key: "deci", title: "Desi" },
                    { key: "quantity", title: "Paket Sayısı" },
                    { key: "assigned_branch.name", title: "Dağıtım Şubesi" }
                ]
            }]
            break;
        case "admin-order-management":
            return [{
                name: "admin-order-management",
                head: [
                    { key: "customer.code", title: "Müşteri No" },
                    { key: "order_code", title: "Sipariş No" },
                    { key: "customer.first_name", title: "Alıcı Adı" },
                    { key: "customer.last_name", title: "Alıcı Soyadı" },
                    { key: "address.address", title: "Alıcı Adresi" },
                    { key: "warehouse.name", title: "Depo" },
                    { key: "warehouse.firm_name", title: "Müşteri" },
                    { key: "address.city", title: "il" },
                    { key: "address.county", title: "ilçe" },
                    { key: "delivery_status", title: "Sipariş Durumu" },
                    { key: "created_at", title: "Siparişin Gelme Saati" },
                    { key: "address.latitude", title: "Enlem" },
                    { key: "address.longitude", title: "Boylam" },
                    { key: "customer.gsm_number", title: "Cep Telefonu" },
                    { key: "deci", title: "Desi" },
                    { key: "barcode", title: "Barkod" },
                    { key: "assigned_branch.name", title: "Dağıtım Şubesi" },
                    { key: "barcode_printed", title: "Barkod" },
                ]
            }]
            break;

        default:
            break;
    }
}

