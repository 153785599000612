import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { Box, Collapse, ListItemButton, ListSubheader } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

export default function SwipeableTemporaryDrawer({ open, setOpen, links }) {
  const closeMenu = () => setOpen(false);
  const [openMenuIndex, setOpenMenuIndex] = React.useState(null);
  const history = useHistory();

  const list = () => {
    return (
      <List
        sx={{ width: 280, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Box sx={{ width: 150, my: 2, mx: "auto" }}>
              <Link to="/">
                <img src="/hey-bringo-logo.png" />
              </Link>
            </Box>
          </ListSubheader>
        }
      >
        {links.map((link, index) => {
          const subLinks = link.subLinks && link.subLinks.length > 0;
          const openCollapse = index == openMenuIndex;
          return (
            <React.Fragment key={`link-${index}`}>
              <ListItemButton
                component={ListItemButton}
                onClick={() => {
                  if (subLinks) {
                    setOpenMenuIndex(openCollapse ? null : index);
                  } else {
                    history.push(link.path);
                  }
                }}
              >
                <ListItemText primary={link.title} />
                {subLinks ? (
                  openCollapse ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItemButton>
              {subLinks && (
                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                  <Divider />
                  <List component="div" disablePadding>
                    {link.subLinks.map((subLink, subIndex) => {
                      return (
                        <ListItemButton
                          sx={{ pl: 5 }}
                          key={`sublint-${subIndex}`}
                          onClick={() => history.push(subLink.path)}
                        >
                          <Box
                            component="img"
                            src={subLink.icon}
                            sx={{
                              width: "22px",
                              height: "22px",
                              marginRight: "10px",
                            }}
                          />
                          <ListItemText primary={subLink.title} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={closeMenu}
      onOpen={() => setOpen(true)}
    >
      {list()}
    </SwipeableDrawer>
  );
}
