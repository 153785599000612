import React from "react";
import { Tabs, Tab, Stack, Typography } from "@mui/material/";
import PropTypes from "prop-types";
import Label from "./Label";
import { getLengthByKey } from "../utils";

const CustomTabs = (props) => {
  const { tableData, tabs, tabsActive, tabsOnChange } = props;

  if (!tabs || tabs.length == 0) {
    return null;
  }

  return (
    <Tabs
      allowScrollButtonsMobile
      variant="scrollable"
      scrollButtons="auto"
      value={tabsActive}
      onChange={(event, newValue) => tabsOnChange(newValue)}
      sx={{ px: 2, bgcolor: "#f4f6f8" }}
    >
      {tabs.map((tab) => {
        const getCount = getLengthByKey(tableData, tab.value);
        return (
          <Tab
            disableRipple
            key={tab.value}
            value={tab.value}
            label={
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography textTransform="capitalize">{tab.label}</Typography>
                <Label color={tab.color}>{getCount}</Label>
              </Stack>
            }
          />
        );
      })}
    </Tabs>
  );
};

CustomTabs.propTypes = {
  tableData: PropTypes.array,
  tabs: PropTypes.array,
  tabsActive: PropTypes.string,
  tabsOnChange: PropTypes.func,
};

export default CustomTabs;
