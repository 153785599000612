import { Services } from "../../api/Services";
import TYPES from "../types";

export const getUnplannedOrds = () => {
  return async (dispatch) => {
    const server = new Services();
    server
      .getUnplannedOrders()
      .then(res => {
        dispatch({
          type: TYPES.GET_UNPLANNED_ORDERS,
          payload: res.entity.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getUnplannedOrdsUngeocoded = () => {
  return async (dispatch) => {
    const server = new Services();
    server
      .getUnplannedOrdersUngeocoded()
      .then(res => {
        dispatch({
          type: TYPES.GET_UNPLANNED_ORDERS,
          payload: res.entity.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};


export const getUnplannedSendToPlan = () => {

  return async (dispatch) => {
    const server = new Services();
    server
      .getUnplannedOrdersSendToPlan()
      .then(res => {
        dispatch({
          type: TYPES.GET_UNPLANNED_ORDERS,
          payload: res.entity.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};