import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Divider, Stack } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

MultiStepFormNavigation.propTypes = {
  hasPrevious: PropTypes.bool,
  onBackClick: PropTypes.func,
  isLastStep: PropTypes.bool,
  currentStepName: PropTypes.string,
  loading: PropTypes.bool,
};

export default function MultiStepFormNavigation(props) {
  const { hasPrevious, onBackClick, isLastStep, currentStepName, loading } =
    props;

  return (
    <>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          {hasPrevious ? (
            <Button
              color="inherit"
              type="button"
              onClick={onBackClick}
              startIcon={<ChevronLeft />}
            >
              {currentStepName}'ne Geri Dön
            </Button>
          ) : (
            <Box />
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
          >
            {isLastStep ? "Kaydet" : "İleri"}
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
}
