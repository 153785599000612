import TYPES from "../types";
import { Services } from "../../api/Services";
import { loadingActive } from "helper";


const server = new Services();

export const getDeliveryReport = (data) => {
    // return {
    //   type: TYPES.LISTING,
    //   payload: data,
    // };
    // const getServices = new Services();



    return async (dispatch) => {
        loadingActive(dispatch, true);
        server.
            getDeliveryReport().then((data) => {
                dispatch({
                    type: TYPES.GET_DELIVERY_REPORT,
                    payload: data.entity
                });
                loadingActive(dispatch, false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
};



export const getDeliveryHistory = (id) => {

    return async (dispatch) => {
        server.
            getDeliveryHistory(id).then((data) => {
                dispatch({
                    type: TYPES.GET_DELIVERY_HISTORY,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};


export const getDeliveryReportFilter = (dateRange, deliveryStatus) => {

    let start = dateRange[0];
    let end = dateRange[1];

    var isoDateStartTime = new Date(start.getTime() - (start.getTimezoneOffset() * 60000)).toISOString();
    var isoDateEndTime = new Date(end.getTime() - (end.getTimezoneOffset() * 60000)).toISOString();

    return async (dispatch) => {
        loadingActive(dispatch, true);
        server.
            getDeliveryReportFilter(isoDateStartTime.substring(0, 10), isoDateEndTime.substring(0, 10), deliveryStatus).then((data) => {
                dispatch({
                    type: TYPES.GET_DELIVERY_REPORT_FILTER,
                    payload: data.entity
                });
                loadingActive(dispatch, false);
            })
            .catch((error) => {
                console.log(error);
            });
    }
};




export const delayVisit = (datex, oid, callback, hideDialog) => {
    //console.log(datex);
    let order_oid = oid;
    let date = datex;

    return async (dispatch) => {
        server.delayVisit1(datex, oid)
            .then((data) => {
                callback("success");
                hideDialog(true)
                dispatch({
                    type: TYPES.LIVE_DELAY_VISIT,
                    payload: [order_oid, date]
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // return (dispatch) => {
    //     dispatch({
    //       type: TYPES.LIVE_DELAY_VISIT,
    //       payload: "8a80810b7de24e0d017de6292d2d0014",
    //     });
    //     hideDialog(true);
    // };
};


export const willBeReturn = (oid, callback, onError) => {
    //console.log(datex);
    let order_oid = oid;
    return async (dispatch) => {
        server.willBeReturn([oid])
            .then((data) => {
                dispatch({
                    type: TYPES.LIVE_WILL_BE_RETURN,
                    payload: oid,
                });
                callback(data.status);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // return (dispatch) => {
    //     callback("success");
    //     hideDialog(true);
    //     dispatch({
    //       type: TYPES.LIVE_WILL_BE_RETURN,
    //       payload: oid,
    //     });

    // };
};


export const delayVisitMultiple = (date, oids, callback, hideDialog) => {
    //console.log(datex);
    // let order_oid= oid;
    // let date = datex;

    return async (dispatch) => {
        server.delayVisit2(date, oids)
            .then((data) => {
                callback("success");
                hideDialog(false);
                dispatch({
                    type: TYPES.LIVE_DELAY_VISIT_MULTIPLE,
                    payload: [date, oids],
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // return (dispatch) => {
    //     dispatch({
    //       type: TYPES.LIVE_DELAY_VISIT_MULTIPLE,
    //       payload: [date,oids],
    //     });
    //     //hideDialog(true);
    // };



};



export const liveFilter = (keyword) => {
    return async (dispatch) => {
        server.
            getLiveFilter(keyword).then((data) => {
                //console.log(data);
                dispatch({
                    type: TYPES.LIVE_CUSTOM_FILTER,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};



export const manualDeliverySuccessful = (obj, setProductDialog, toastCallback) => {
    var data1 = obj;
    return async (dispatch) => {
        server.
            manualDeliverySuccessful(obj).then((data) => {
                //console.log(data);
                dispatch({
                    type: TYPES.MANUEL_DELIVERY_SUCCESSFUL,
                    payload: data1
                });
                setProductDialog(false);
                toastCallback("success");
            })
            .catch((error) => {
                console.log(error);
            });
    }
};


export const manualDeliveryFailed = (obj, setProductDialog, toastCallback) => {
    var data1 = obj;
    return async (dispatch) => {
        server.
            manualDeliveryFailed(obj).then((data) => {
                console.log(data);
                dispatch({
                    type: TYPES.MANUEL_DELIVERY_FAILED,
                    payload: data1
                });
                setProductDialog(false);
                toastCallback("success");
            })
            .catch((error) => {
                console.log(error);
            });
    }
};




export const manuallyUpdateOrder = (obj, setProductDialog, toastCallback) => {
    var data1 = obj;
    return async (dispatch) => {
        server.
            manuallyUpdateOrder(obj).then((data) => {
                //console.log(data);
                dispatch({
                    type: TYPES.MANUEL_UPDATE_ORDER,
                    payload: data1
                });
                setProductDialog(false);
                toastCallback("success");
            })
            .catch((error) => {
                console.log(error);
            });
    }
};
