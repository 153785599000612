import React, { useEffect } from "react";
import Table from "../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import { getAdvertisementApplicantsList } from "../../store/actions/projectApplicationDetailAction";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Container } from "@mui/material";
import Topbar from "components/Topbar";

function ProjectDetail(props) {
  const dispatch = useDispatch();
  const projectApplicationDetailList = useSelector(
    (state) => state.projectApplicationDetailReducer
  );
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();
  const navBarWidth = 280;
  const isMobile = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  useEffect(() => {
    dispatch(
      getAdvertisementApplicantsList(props.location.pathname.split("/")[2])
    );
  }, []);

  useEffect(() => {}, [projectApplicationDetailList]);

  return (
    <>
      {!isMobile && <Topbar marginLeft={`${navBarWidth}px`} />}
      <Container
        maxWidth={false}
        sx={{ display: isMobile ? "block" : "flex", paddingTop: "60px" }}
      >
        <NavBar navBarWidth={navBarWidth} isMobile={isMobile} />
        <Box width={1} mt={2} ml={isMobile ? 0 : `${navBarWidth + 20}px`}>
          <Box sx={{ pb: 10 }}>
            <Table
              products={projectApplicationDetailList.data}
              type="ProjectDetail"
              leftToolbarHide={true}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
}

ProjectDetail.whyDidYouRender = false;

export default ProjectDetail;
