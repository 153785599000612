import TYPES from "../types";

const initialState = {
  data: {
      cityId: '',
      city: '',
      vehicle: '',
      driver: '',
  }
};

export const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_TABLE_DATA:
      return { ...state, data: action.payload.data }
    default:
      return state
  }
};
