import moment from "moment";

const currentDate1 = moment()
  .subtract(1, "months")
  .endOf("month")
  .format("YYYY-MM-DD");
const currentDate2 = moment()
  .subtract(0, "months")
  .endOf("month")
  .format("YYYY-MM-DD");

const initialState = {
  start_date: currentDate1,
  end_date: currentDate2,
  branches: [],
  vehicle_types: [],
  cities: [],
  vehicles: [],
  drivers: [],
  counties: [],
  customers: [],
  shipment_number: "",
  max_count: 4,
};

export const dashboardFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_FILTER_SET":
      return { ...state, data: action.payload };
    case "DASHBOARD_FILTER_RESET":
      return {
        data: initialState,
      };
    default:
      return state;
  }
};
