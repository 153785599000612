// @mui
import {
  Box,
  Card,
  Stack,
  Button,
  Avatar,
  Tooltip,
  Typography,
  CardHeader,
  IconButton,
} from "@mui/material";

// components
import Iconify from "components/Iconify";
import Label from "components/Label";
import React from "react";
import { useHistory } from "react-router";
import createAvatar from "utils/createAvatar";
import AddDriver from "../../DriverManagement/Home/Detail";

// ----------------------------------------------------------------------

export default function Courier({ data }) {
  const history = useHistory();
  const [popup, setPopup] = React.useState(false);

  const drivers = data
    .map((i) => ({
      ...i,
      full_name: `${i.first_name || ""} ${i.last_name || ""}`,
      phone: i.username || "",
    }))
    .slice(0, 4);

  return (
    <>
      <Card>
        <CardHeader
          title="Kuryeler"
          subheader={`Toplam kurye sayısı ${data.length}`}
          action={
            <IconButton
              color="primary"
              size="large"
              onClick={() => setPopup(true)}
            >
              <Iconify icon={"eva:plus-fill"} width={20} height={20} />
            </IconButton>
          }
        />

        <Stack spacing={3} sx={{ p: 3 }}>
          {drivers.map((contact, i) => (
            <Stack
              direction="row"
              alignItems="center"
              key={`${contact.id}-${i}`}
            >
              <Avatar
                alt={contact.full_name}
                color={createAvatar(contact.full_name).color}
              >
                {createAvatar(contact.full_name).name}
              </Avatar>
              <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }} noWrap>
                  {contact.full_name}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Iconify icon={"eva:phone-fill"} width={14} height={14} />
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                      noWrap
                    >
                      {contact.phone}
                    </Typography>
                  </Stack>
                  {contact.company && (
                    <Label variant="ghost" color="success">
                      {contact.company?.name}
                    </Label>
                  )}
                </Stack>
              </Box>

              <Tooltip title="Düzenler">
                <IconButton size="small">
                  <Iconify icon={"eva:flash-fill"} width={22} height={22} />
                </IconButton>
              </Tooltip>
            </Stack>
          ))}

          <Button
            variant="outlined"
            size="large"
            color="inherit"
            onClick={() => history.push("/driver-management")}
          >
            Tümünü Gör
          </Button>
        </Stack>
      </Card>
      {popup && (
        <AddDriver
          handleClose={() => setPopup(false)}
          isEditItem={false}
          detail={null}
        />
      )}
    </>
  );
}
