import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import MultiStepFormNavigation from "./Navigation";
import { Box, Card, Step, StepLabel, Stepper, useTheme } from "@mui/material";
import { Debug } from "./Debug";

MultiStepForm.propTypes = {
  loading: PropTypes.bool,
};

export default function MultiStepForm({
  children,
  initialValues,
  onSubmit,
  loading,
}) {
  const [stepNumber, setStepNumber] = React.useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = React.useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, actions);
    }
    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  const beforeStep = steps[stepNumber - 1]
    ? steps[stepNumber - 1]
    : steps[stepNumber];
  const beforeStepName = beforeStep.props.stepName;

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form>
          {/* <Debug /> */}
          <Stepper alternativeLabel activeStep={stepNumber} sx={{ my: 4 }}>
            {steps.map((currentStep) => {
              const label = currentStep.props.stepName;
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {step}
          <MultiStepFormNavigation
            loading={loading}
            currentStepName={beforeStepName}
            isLastStep={isLastStep}
            hasPrevious={stepNumber > 0}
            onBackClick={() => previous(formik.values)}
          />
        </Form>
      )}
    </Formik>
  );
}

export const FormStep = ({ stepName = "", children }) => {
  const { palette } = useTheme();
  return (
    <Card
      sx={{
        py: 4,
        px: 2,
        mt: 6,
        m: 4,
        border: `1px dashed ${palette.grey[500_32]}`,
        boxShadow: "none",
      }}
    >
      {children}
    </Card>
  );
};
