import React from "react";
import PropTypes from "prop-types";
import BookingWidgetSummary from "components/BookingWidgetSummary";
import { Box, Card, Typography } from "@mui/material";
import qs from "query-string";
import { useHistory } from "react-router";

SectionStatics.propTypes = {
  label: PropTypes.string,
};

export default function SectionStatics(props) {
  const { bgcolor, label, data, columnRow = 2 } = props;
  const history = useHistory();

  return (
    <Card>
      <Box bgcolor={bgcolor} sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="primary.darker">
          {label}
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: "grid",
            rowGap: 2,
            columnGap: 2,
            gridTemplateColumns: {
              xs: `repeat(1, 1fr)`,
              sm: `repeat(2, 1fr)`,
              lg: `repeat(${columnRow}, 1fr)`,
            },
          }}
        >
          {data.map((item, index) => {
            const status = item.status;
            return (
              <BookingWidgetSummary
                key={index}
                color="blue"
                title={item.title}
                total={item.count}
                icon={<img src={item.icon} alt={item.title} />}
                sx={{ cursor: status ? "pointer" : "auto" }}
                onClick={() => {
                  if (status) {
                    history.push({
                      pathname: "/shipments-detail",
                      search: qs.stringify({
                        status,
                        pageTitle: `${item.title} Siparişleri`,
                      }),
                    });
                  }
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Card>
  );
}
