import TYPES from '../types';

const initialState = {};

export const warehouseAllReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_WAREHOUSE_ALL:
            return {...state, data: action.payload.data };
        default:
            return state;
    }
}