import TYPES from "../types";

const initialState = {};

export const customerUserPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.USER_DATA_POPUP:
            return { ...state, data: action.payload.data }
        case TYPES.USER_DATA_POPUP_ADD:
            return { ...state, data: [...state.data, action.payload] };
        case TYPES.USER_DATA_POPUP_UPDATE:
            return {
                ...state,
                data: state.data.map(dt => dt.id == action.payload.id ? action.payload : dt)
            }
        default:
            return state
    }
};
