import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown, Radio } from "semantic-ui-react";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import "../DistributionReport/distribution.css";
import data from "./mockdata";
import { Icon } from "semantic-ui-react";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";

const ProgressPayment = (props) => {
  const dropdownlist = [
    {
      key: "1",
      text: "BRINGO",
      value: "BRINGO",
    },
    {
      key: "2",
      text: "ABC TAŞERON",
      value: "ABC TAŞERON",
    },
  ];

  const [products, setProducts] = useState(data);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const table = useRef(null);
  const [date, setDate] = useState(new Date());
  const [customer, setCustomer] = useState(dropdownlist[0].text);
  const tableData = useSelector((state) => state.tableReducer);
  const selectedIndex = data.findIndex((datas) => {
    return datas.İl == tableData.city;
  });

  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  const checkSelectedIndex = () => {
    if (selectedIndex >= 0) {
      return true;
    } else {
      return false;
    }
  };
  const renderTick = (data, type) => {
    if (data.status == type) {
      return <i className={"pi pi-check"}></i>;
    }
  };

  const renderWithIcon = (data, props, warn = false, notif = false) => {
    return (
      <div>
        <Icon className="links" name="file pdf" color="red" size="small" />
        <span>{data[props]}</span>
        {warn && (
          <Icon
            className="rigthIcons"
            name="warning circle"
            color="yellow"
            size="small"
          />
        )}
        {notif && (
          <Icon
            className="rigthIcons"
            name="bell outline"
            color="yellow"
            size="small"
          />
        )}
      </div>
    );
  };

  const renderStatus = (data) => {
    if (!checkSelectedIndex()) {
      return <span>{data.fee}</span>;
    }
    switch (data.vehicleStatus) {
      case "active":
        return <Icon name="check circle" color="green" size="large" />;
      case "maintenance":
        return <Icon name="wrench" color="yellow" size="large" />;
      default:
        return <Icon name="remove circle" color="red" size="large" />;
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={data.orders}>
        <Column field="ilce" header="Araç"></Column>
        <Column
          body={(data) => renderTick(data, "active")}
          field="active"
          header="Aktif Araç"
        ></Column>
        <Column
          body={(data) => renderTick(data, "passive")}
          field="passive"
          header="Pasif Araç"
        ></Column>
        <Column
          body={(data) => renderTick(data, "maintenance")}
          field="maintenance"
          header="Bakımda"
        ></Column>
        <Column
          body={(data) => renderTick(data, "vehicle")}
          field="vehicle"
          header="Dağıtıcı"
        ></Column>
        <Column
          body={(data) => renderTick(data, "completedDoc")}
          field="completedDoc"
          header="Belgesi Tamamlanan"
        ></Column>
        <Column
          body={(data) => renderTick(data, "uncumpletedDoc")}
          field="uncumpletedDoc"
          header="Belgesi Tamamlanmayan"
        ></Column>
        <Column></Column>
      </DataTable>
    );
  };

  const header = <div className="table-header-container"></div>;

  return (
    <>
      <NavBar />
      <div className="datatable-rowexpansion-demo">
        <Toast ref={toast} />
        <div className="filter">
          <span className="customerText">Müşteri </span>
          <Dropdown
            className="dropdownCustomer"
            placeholder={"HEPSİ"}
            search
            selection
            options={dropdownlist}
            onChange={(event, data) => setCustomer(data.value)}
          />
          <Calendar
            id="range"
            style={{ height: "36px", marginRight: "10px" }}
            value={date}
            onChange={(e) => setDate(e.value)}
            selectionMode="range"
            readOnlyInput
          />
          <i style={{ fontSize: "20px" }} className={"pi pi-calendar"}></i>
        </div>
        <div className={"links"}>
          <a href="">Türkiye</a>
          <span>&gt;</span>
          {selectedIndex >= 0 && <a href="">{data[selectedIndex].İl}</a>}
        </div>
        <div className="card">
          <DataTable
            className={"tt"}
            ref={table}
            value={
              selectedIndex >= 0 ? products[selectedIndex].orders : products
            }
            expandedRows={expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
          >
            <Column
              body={(e) => (
                <Radio
                  checked={
                    expandedRows &&
                    expandedRows.hasOwnProperty(e.id) &&
                    expandedRows[e.id] == true
                  }
                  onClick={() =>
                    expandedRows &&
                    expandedRows.hasOwnProperty(e.id) &&
                    expandedRows[e.id] == true
                      ? setExpandedRows({ [e.id]: null })
                      : setExpandedRows({ [e.id]: true })
                  }
                />
              )}
              expander
              style={{ width: "3em" }}
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "plate" : "code"}
              header={checkSelectedIndex() ? "Araç" : "Dağıtıcı Kodu"}
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              body={(data) =>
                checkSelectedIndex() ? (
                  <Icon
                    className="links"
                    name="file pdf"
                    color="red"
                    size="big"
                  />
                ) : (
                  <span>{data.company}</span>
                )
              }
              field={checkSelectedIndex() ? "doc" : "company"}
              header={checkSelectedIndex() ? "Ruhsat" : "Dağıtıcı Adı"}
              sortable
            />
            <Column
              body={(data) =>
                checkSelectedIndex() ? (
                  renderWithIcon(data, "docDate")
                ) : (
                  <span>{data.activeHours}</span>
                )
              }
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "docDate" : "activeHours"}
              header={
                checkSelectedIndex()
                  ? "K Belgesi - Geçerlilik Tarihi"
                  : "Aktif Çalışma Saati"
              }
              sortable
            />
            <Column
              body={(data) =>
                checkSelectedIndex() ? (
                  renderWithIcon(data, "trafficDoc")
                ) : (
                  <span>{data.visited}</span>
                )
              }
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "trafficDoc" : "visited"}
              header={
                checkSelectedIndex()
                  ? "Trafik Sigortası - Geçerlilik Tarihi"
                  : "Ziyaret Edilen Nokta"
              }
              sortable
            />
            <Column
              body={(data) =>
                checkSelectedIndex() ? (
                  renderWithIcon(data, "inspectionDoc", false, true)
                ) : (
                  <span>{data.deliveried}</span>
                )
              }
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "inspectionDoc" : "deliveried"}
              header={
                checkSelectedIndex()
                  ? "Muayene Belgesi - Geçerlilik Tarihi"
                  : "Teslim Edilen Paket Sayısı"
              }
              sortable
            />
            <Column
              body={(data) =>
                checkSelectedIndex() ? (
                  renderWithIcon(data, "insurance", true, false)
                ) : (
                  <span>{data.package}</span>
                )
              }
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "insurance" : "package"}
              header={
                checkSelectedIndex()
                  ? "Kasko Belgesi - Geçerlilik Tarihi"
                  : "Paket Başı Ücret"
              }
              sortable
            />
            <Column
              body={(data) =>
                checkSelectedIndex() ? (
                  renderWithIcon(data, "maintenence", false, true)
                ) : (
                  <span>{data.Performance}</span>
                )
              }
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "maintenence" : "Performance"}
              header={
                checkSelectedIndex() ? "Bakım Durumu" : "Performans Primi"
              }
              sortable
            />
            <Column
              body={(data) => renderStatus(data)}
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field={checkSelectedIndex() ? "vehicleStatus" : "fee"}
              header={checkSelectedIndex() ? "Araç Durumu" : "Ücret"}
              sortable
            />
          </DataTable>
        </div>
      </div>
    </>
  );
};

ProgressPayment.whyDidYouRender = false;

export default ProgressPayment;
