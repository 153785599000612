import TYPES from "../types";
import { Services } from "../../api/Services";
import { loadingActive } from "helper";


const server = new Services();

export const vehicleUsageReportList = (data) => {

    //console.log("XXXX: ", data.split("T")[0])

    return async (dispatch) => {
        loadingActive(dispatch, true);
        server.
            getVehicleUsageReport(data.split("T")[0]).then((data) => {
                dispatch({
                    type: TYPES.VEHICLE_USAGE_REPORT_LIST,
                    payload: data.entity
                });
                loadingActive(dispatch, false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

};


export const vehicleUsageReportListBetween = (start, end, callback) => {

    //  console.log("AAAAA: ", start);
    //  console.log("BBBBB: ", end);

    // var dt = [
    //     { "id": "1907", "vehicle_id": "fenerbahce", "lastupdated": "1907" }
    // ]
    // return  (dispatch) => {
    //     dispatch({
    //         type: TYPES.VEHICLE_USAGE_REPORT_LIST_BETWEEN_DATE,
    //         payload: dt
    //     });
    // }
    
    return async (dispatch) => {
        loadingActive(dispatch, true);
        server.
            getVehicleUsageReportBetween(start,end).then((data) => {
                dispatch({
                    type: TYPES.VEHICLE_USAGE_REPORT_LIST_BETWEEN_DATE,
                    payload: data.entity
                });
                loadingActive(dispatch, false);
                callback("success", "başarılı");
            })
            .catch((error) => {
                console.log(error);
            });
    }

};