import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { Checkbox } from "@material-ui/core";
import TYPES from "../../store/types/index";
import { Button } from "primereact/button";
import { assignWarehouseSave } from "../../store/actions/branchAction";

const BranchAssignment = (props) => {
    const dispatch = useDispatch();
    const { dialogBranchAssignment, setDialogBranchAssignment, product, setProduct } = props;


    const [selectedBranch, setSelectedBranch] = useState([]);
    const [test, setTest] = useState({ data: "" });

    // branch list
    const getBranchAssignmentList = useSelector((state) => state.branchAssignmentReducer);
    //console.log("getBranchAssignmentList :", getBranchAssignmentList)


    useEffect(() => {
        //setSelectedBranch(['0c59c003e8204eed8da463af4b3a5bc3', '8a8081397f7e1807017f83725b51001e', '8a8081397f7e1807017f838b51ec0021'])
        let x = (Object.keys(product).length > 0 && product.warehouses) && product.warehouses.map(wid => wid.id);
        setSelectedBranch(x);
    }, [product])


    useEffect(() => {
        //console.log("AAA :", getBranchAssignmentList);
        let branchs = getBranchAssignmentList.data && getBranchAssignmentList.data.reduce((r, a) => {
            r[a.firm_name] = [...r[a.firm_name] || [], a];
            return r;
        }, {});
        setTest({ data: branchs })
        //console.log("BBB : ", test)
    }, [getBranchAssignmentList])


    // firm ID
    //const firmID = useSelector((state) => state.customerWarehouseReducer);

    const hideDialogBranchAssigment = () => {
        setDialogBranchAssignment(false);
        setSelectedBranch([]);
        setProduct([]);
    };


    const saveBranchAssigment = () => {
        setSelectedBranch([]);

        //remove !!
        // dispatch(
        //     branchAssignmnetSave(getBranchAssignmentList.selectedBranch, selectedBranch, setDialogBranchAssignment)
        // )
        // dispatch({
        //     type: TYPES.EMPTY_SELECTED_BRANCH_OPTIONS_ID,
        //     payload: []
        // });
        //remove ========

        dispatch(
            assignWarehouseSave(product.id, selectedBranch, setDialogBranchAssignment)
        )

        // dispatch({
        //     type: TYPES.EMPTY_SELECTED_BRANCH_OPTIONS_ID,
        //     payload: []
        // });

    };

    // useEffect(() => {
    //     console.log("selectedBranch: ", selectedBranch)
    // }, [selectedBranch])

    const branchAssignmentDialogFooter = (

        <React.Fragment>
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveBranchAssigment()}
            />
        </React.Fragment>

    );


    const selectedBranchAssigment = (e) => {
        //console.log(e.target.id);
        let duplicateData = selectedBranch && selectedBranch.find(d => d == e.target.id);
        if (duplicateData) {
            let currentSelectedBranch = selectedBranch.filter(c => c != duplicateData);
            //console.log("y :", y);
            setSelectedBranch([...currentSelectedBranch])
        } else {
            selectedBranch ? setSelectedBranch([...selectedBranch, e.target.id]) : setSelectedBranch([e.target.id])
        }
    };



    return (
        <Dialog
            header="Şube Atama"
            visible={dialogBranchAssignment}
            style={{ width: "1000px" }}
            onHide={hideDialogBranchAssigment}
            footer={branchAssignmentDialogFooter}
        >

            {/* remove!! */}
            {/* {
                Object.keys(getBranchAssignmentList.data).length > 0 && getBranchAssignmentList.data.map((element) => {
                    return (
                        <table>
                            <tr> */}
            {/* <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchAssigment(e)} /> </td> */}
            {/* <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchAssigment(e)} /> </td>
                                <td>{element.name}</td>
                            </tr>
                        </table>

                    )
                })
            } */}


            {
                Object.keys(test.data).map((d, i) => {
                    return (
                        <>
                            <b>{d.toUpperCase()}</b>
                            {
                                test.data[d].map((element) => {
                                    return (
                                        <table>
                                            <tr>
                                                <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchAssigment(e)} /> </td>
                                                <td>{element.name}</td>
                                            </tr>
                                        </table>

                                    )
                                })
                            }
                        </>
                    )

                })
            }





        </Dialog>
    )
}

export default BranchAssignment
