import React, { useState, useEffect } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from "react-redux";
import { uploadDocumentAction } from '../../store/actions/uploadFilesActions';
import { FilesInput } from './FilesInput';
import './Upload.css';

export const Upload = (props) => {

    const {
        vehicle,
        withButton,
        callbackAlert,
        onDocUploaded,
        product,
        onInputChange,
    } = props;

    const [files, setFiles] = useState({
        files: []
    });
    const [docUploaded, setDocUploaded] = useState(false);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     if(docUploaded) onDocUploaded(vehicle.doc_name)
    // }, [docUploaded]);

    // uploaded file name : Alert_With_File_Name
    const fileName = file_name => {
        return file_name.substring(0, file_name.indexOf('.'));
    };

    // upload file : SERVER_HANDLER
    const uploadFile = file => {
        var doc = file[0];
        dispatch(
            uploadDocumentAction(
                doc,
                res => { 
                    if(res.success) {
                        callbackAlert(
                            'success', 
                            `${fileName(res.data[0].doc_name) + ' uploaded'}`
                            );
                        setDocUploaded(res.success);
                        onDocUploaded(vehicle.doc_name)
                        }
                    },
                error => { 
                        callbackAlert(
                            'error', 
                            error.message
                        )
                    }
            )
        );
    };

    // upload file actions : WITH_WITHOUT_BUTTON
    const uploadFilesActions = () => {
        return (
            <Grid>
                <Grid.Column>
                    <FilesInput
                       product = { product }
                       onInputChange = { onInputChange }
                       vehicle = { vehicle }
                       onFileUploaded = {file => uploadFile(file) }
                       onSuccess = { docUploaded }
                    />
                </Grid.Column>
              {withButton &&
                <Grid.Column style={{marginLeft: "60%"}}>
                    <Button 
                      size = "mini" 
                      onClick = {() => uploadFile}>
                       <Icon name='cloud upload' />
                    </Button>
                </Grid.Column>
              }
            </Grid>
        )
    };

    return (
        <div className = 'upload'>
            <span className = "title" style={{fontWeight: 'bold', paddingTop:"2rem"}}>{vehicle.title}</span>
            <div className = "content">
                
                <div className = "Files">
                    {files.files && files.files.map(file => {
                        return (
                            <div key = {file.name} className = "">
                                <span>{file.name}</span>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div classNames = "actions">{uploadFilesActions()}</div>
            <br/>
        </div>
    )
}
