import React, { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar'
import { onDateChange } from "../../lib/dateconverterForFilter";

/**
 * simple date component for filter check tbody.js -> liveTracking for usage
 * 
 * @param {*} props onchange function when date selected 
 * @returns pass formated date to onchange funtion 
 */
const DateFilter = (props) => {
    const [date, setDate] = useState(null)

    useEffect(() => {
        props.onChange(date)
    },[date])

    return <Calendar value={date} onChange={(e) => onDateChange(e, setDate)} dateFormat="dd-mm-yy" className="p-column-filter" placeholder="Tarih Seçiniz"/>
} 

export default DateFilter