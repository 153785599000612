import React from "react";
import PropTypes from "prop-types";
import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import moment from "moment";
import qs from "query-string";
import { NavLink } from "react-router-dom";
import { LocalPrintshop, LocalShipping, Videocam } from "@mui/icons-material";
import { TextFirstLetter } from "utils";

Item.propTypes = {
  item: PropTypes.object,
};

// {
//   "outlet_count": 4,
//   "route_id": "f2c5f490-51cd-4aff-b6d2-785f5c1930eb",
//   "route_start_time": 1665652985013,
//   "route_status": "ON_THE_WAY",
//   "order_count": 4,
//   "deci": 212,
//   "driver_name": "KURYE BEŞ",
//   "package_count": 6,
//   "delivered_count": 2,
//   "delivered_deci": 44,
//   "branch_name": "ANADOLU-AVRUPA",
//   "not_delivered_count": 2,
//   "route_end_time": null,
//   "route_date": 1662508800000,
//   "driver_username": "5666666666",
//   "vehicle_name": "KURYEBES"
// }

export default function Item({ item }) {
  const DetailProps = {
    pathname: `/shipments-detail`,
    search: qs.stringify({ routeId: item.route_id, pageTitle: "Gönderiler" }),
  };

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.driver_name)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {moment(item.route_date).format("DD.MM.YYYY")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {item.route_start_time
            ? moment(item.route_start_time).format("HH:mm")
            : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.branch_name)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.vehicle_name)}
        </Typography>
      </TableCell>
      <TableCell>
        <Button
          color="primary"
          disabled={item.order_count === 0}
          component={NavLink}
          to={DetailProps}
        >
          <Label
            color="primary"
            sx={{ cursor: item.order_count > 0 ? "pointer" : "auto" }}
          >{`${item.order_count} adet`}</Label>
        </Button>
      </TableCell>
      <TableCell>
        <Button
          color="secondary"
          disabled={item.package_count === 0}
          component={NavLink}
          to={DetailProps}
        >
          <Label
            color="secondary"
            sx={{ cursor: item.package_count > 0 ? "pointer" : "auto" }}
          >{`${item.package_count} adet`}</Label>
        </Button>
      </TableCell>

      <TableCell>
        <Button
          color="success"
          disabled={item.delivered_count === 0}
          component={NavLink}
          to={{
            pathname: "/shipments-detail",
            search: qs.stringify({
              ...DetailProps,
              status: "DELIVERED",
            }),
          }}
        >
          <Label
            color="success"
            sx={{ cursor: item.delivered_count > 0 ? "pointer" : "auto" }}
          >{`${item.delivered_count} adet`}</Label>
        </Button>
      </TableCell>
      <TableCell>
        <Button
          color="warning"
          disabled={item.not_delivered_count === 0}
          component={NavLink}
          to={{ pathname: "/shipments-detail", ...DetailProps }}
        >
          <Label
            color="error"
            sx={{ cursor: item.not_delivered_count > 0 ? "pointer" : "auto" }}
          >
            {`${item.not_delivered_count} adet`}
          </Label>
        </Button>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{`${item.outlet_count} adet`}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.delivered_deci}</Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="caption">
          {item.delivered_percentage.toFixed(2)}%
        </Typography>
        <LinearProgress
          value={item.delivered_percentage}
          variant="determinate"
          color="success"
        />
      </TableCell> */}
      {/* <TableCell>
        <Typography variant="caption">
          {item.quantity_percentage.toFixed(2)}%
        </Typography>
        <LinearProgress
          value={item.quantity_percentage}
          variant="determinate"
          color="success"
        />
      </TableCell> */}
      <TableCell>
        <Stack direction="row" spacing={2}>
          <LocalShipping sx={{ cursor: "pointer" }} />
          <LocalPrintshop sx={{ cursor: "pointer" }} />
          <Videocam sx={{ cursor: "pointer" }} />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
