import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

//import TYPES from "../../store/types";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from "primereact/dialog";
import "./DialogDemo.css";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import GeocodeEditDialog from "./GeocodeEditDialog";
import TYPES from "../../store/types";
import { GeocodeSpinner } from "../../screens/Spinner/GeocodeSpinner";
import { getOrderUnplanned, adminGetOrderUnplanned, getAdminOrderUnplanned } from "../../store/actions/orderAction";







const GeocodeDialog = (props) => {

    const {
        dialogVs,
        geocoderDialogFooter,
        hideGeocodeDialog,
        type,
        geocoderSaveFooter,
        setdialogVs
    } = props;

    const dispatch = useDispatch();
    const ungeocoded = useSelector(state => state.ungeocodedReducer);
    //const [selectedSuggestionAdress, setSelectedSuggestionAdress] = useState(null);
    //const [geocodeCoordinate, setGeocodeCoordinate] = useState({});
    const [selectedProduct1, setSelectedProduct1] = useState(null);
    const [pp, setPP] = useState(false);
    const [rowE, setRowE] = useState();


    const aa = (e) => {
        setSelectedProduct1(e.value);
        setPP(true);

        setRowE([
            ...rowE || [],
            e.originalEvent.currentTarget
        ])
    }

    // request updating unplanned 
    useEffect(() => {
        //(ungeocoded.data && ungeocoded.data.length > 0 && dialogVs === true) && type == "admin-order-management" ? dispatch(adminGetOrderUnplanned()) :  dispatch(getOrderUnplanned())
        if (ungeocoded.data && ungeocoded.data.length > 0 && dialogVs === true) {
            //type == "admin-order-management" ? dispatch(adminGetOrderUnplanned()) : dispatch(getOrderUnplanned())
            type == "admin-order-management" ? dispatch(getAdminOrderUnplanned()) : dispatch(getOrderUnplanned())
        }
        ungeocoded.data == "No geocoded value" && setdialogVs(false);
    }, [ungeocoded.data])



    return (
        <>
            {/* {(ungeocoded.data && ungeocoded.data.length == 0 && dialogVs === true) && <Spinner />} */}
            {(ungeocoded.data && (typeof ungeocoded.data == "string" ? ungeocoded.data != "No geocoded value" : ungeocoded.data.length == 0) && dialogVs === true) && <GeocodeSpinner />}
            <Dialog
                visible={dialogVs}
                style={{ width: "95vw" }}
                header="Koordinatlama Ekranı"
                modal
                footer={geocoderDialogFooter}
                onHide={geocoderSaveFooter}
                blockScroll={true}
            >
                <div className="confirmation-content">
                    <div>
                        <div className="card">
                            <DataTable value={ungeocoded.data && typeof ungeocoded.data == "object" && ungeocoded.data.sort((a, b) => a.address.geocode_score - b.address.geocode_score)} selectionMode="single" onSelectionChange={e => aa(e)} dataKey="id">
                                <Column field="order_code" id="order_code" header="Sipariş Kodu"></Column>
                                {/* <Column field="warehouse.name" header="Depo Adı"></Column> */}
                                <Column field="customer.first_name" header="Müşteri Adı"></Column>
                                <Column field="address.address" header="Müşteri Adresi"></Column>
                                <Column field="address.latitude" header="x"></Column>
                                <Column field="address.longitude" header="y"></Column>
                                <Column field="address.geocode_score" header="Skor"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <GeocodeEditDialog
                pp={pp}
                //geoocoderAddressUpdateDialog={geoocoderAddressUpdateDialog}
                rowE={rowE}
                setPP={setPP}
                //setGeocodeCoordinate={setGeocodeCoordinate}
                //geocodeCoordinate={geocodeCoordinate}
                selectedProduct1={selectedProduct1}
            //setSelectedSuggestionAdress={setSelectedSuggestionAdress}
                 setSelectedProduct1={setSelectedProduct1}
                 setRowE={setRowE}
                 nextButton={true}
            />

        </>
    );
};

export default GeocodeDialog;
