import React from "react";
import Layout from "components/Layout";
import List from "./List";
import { Services } from "api/Services";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import AddWarehouse from "components/AddWarehouse";
import { useSnackbar } from "notistack";

const service = new Services();

export default function WarehouseManagement(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [list, setList] = React.useState([]);
  const [addWarehouseVisible, setAddWarehouseVisible] = React.useState(false);
  const [warehouseData, setUserData] = React.useState(null);

  const getList = async () => {
    try {
      const res = await service.getWarehouse();
      setList(res.entity.data);
      setLoading(false);
    } catch (error) {}
  };

  const handleEditItem = (warehouse) => {
    console.log(warehouse);
    setUserData({ ...warehouse.detailData });
    setAddWarehouseVisible(true);
  };

  const handleDeleteItem = async (warehouseID) => {
    try {
      await service.deleteWarehouse(warehouseID);
      enqueueSnackbar("Başarıyla silindi!", { variant: "success" });
      getList();
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu!", { variant: "error" });
    }
  };

  const handleCloseWarehousePopup = () => {
    setUserData(null);
    setAddWarehouseVisible(false);
  };

  React.useState(() => {
    getList();
  }, []);

  return (
    <Layout
      {...props}
      heading="Depo Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "Depo Yönetimi" }]}
      action={
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setAddWarehouseVisible(true)}
        >
          Ekle
        </Button>
      }
    >
      <List
        data={list}
        loading={loading}
        handleEditItem={handleEditItem}
        handleDeleteItem={handleDeleteItem}
      />
      {addWarehouseVisible && (
        <AddWarehouse
          data={warehouseData}
          label="Depo Ekle"
          getList={getList}
          handleClose={handleCloseWarehousePopup}
        />
      )}
    </Layout>
  );
}
