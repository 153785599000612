import TYPES from "../types";
import { Services } from "../../api/Services";

const server = new Services();
export const getAllListWarehouse = () => {
  return async (dispatch) => {
    server
      .getAllWarehouse()
      .then((data) => {
        dispatch({
          type: TYPES.GET_WAREHOUSE_ALL,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getActiveWarehouse = (id) => {
  return async (dispatch) => {
    server
      .getActiveWarehouse(id)
      .then((data) => {
        dispatch({
          type: TYPES.GET_ACTIVE_WAREHOUSE,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
