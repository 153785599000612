import TYPES from "../types";

const initialState = {
    data: "",
    drivers: ""
};

export const branchReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_LIST_BRANCH:
            //console.log(action.payload)
            return { ...state, data: action.payload.data }
        case TYPES.ADD_BRANCH:
            return { ...state, data: [...state.data, action.payload.data[0]] };
        case TYPES.DELETE_BRANCH:
            return { ...state, data: state.data.filter(item => item.id != action.payload) }
        case TYPES.UPDATE_BRANCH:
            return { ...state, data: state.data.map(content => content.id == action.payload.data[0].id ? action.payload.data[0] : content) }
        case TYPES.WAREHOUSE_ASSIGMENT:
            return { ...state, data: state.data.map(content => content.id == action.payload.data[0].id ? action.payload.data[0] : content) }
        case TYPES.BRANCH_DRIVERS:
            return { ...state, drivers: action.payload.data };
        default:
            return state
    }
};
