import TYPES from "../types";

const initialState = {
    filterData: ""
};

// Get time when changing status manually
// Live tracking table - 3.tab
var dateWithouthSecond = new Date();

const addFilterField = (arr) => {
    // Add filter field to object
    arr.map(d => {
        if (d.delivery_status == "IN_WAREHOUSE" || d.delivery_status == "DELIVERY_PLANNED" || d.delivery_status == "ON_BOARD" || d.delivery_status == "BRANCH_ACCEPTED") {
            d.filter = "DELIVERY_PLANNED"
        } else if (d.delivery_status == "CUSTOMER_INFORMED_1" || d.delivery_status == "CUSTOMER_INFORMED_2" || d.delivery_status == "CUSTOMER_INFORMED_3" || d.delivery_status == "ON_THE_WAY_TO_CUSTOMER" || d.delivery_status == "COURIER_DEBIT") {
            d.filter = "ON_THE_WAY_TO_CUSTOMER"
        } else {
            d.filter = d.delivery_status
        }
    })
    return arr
}

export const liveTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_DELIVERY_REPORT:
            state.filterData = "";
            //console.log("Canlı takip: ", action.payload.data); // order_oid byt2 "8a80810b7de24e0d017de6292d2d0014"
            //return { ...state, data: action.payload.data }
            return { ...state, data:  addFilterField(action.payload.data) }
        case TYPES.LIVE_CUSTOM_FILTER:
            return { ...state, filterData: action.payload.data }
        case TYPES.LIVE_CUSTOM_FILTER_EMPTY:
            return { ...state, filterData: action.payload }
        case TYPES.GET_DELIVERY_REPORT_FILTER:
            // state.filterData = "";
            // return { ...state, data: action.payload.data }
            state.filterData = "";
            // action.payload.data.map(d => {
            //     if(d.delivery_status == "IN_WAREHOUSE" || d.delivery_status == "DELIVERY_PLANNED" || d.delivery_status == "ON_BOARD" || d.delivery_status == "BRANCH_ACCEPTED"){
            //         d.filter = "DELIVERY_PLANNED" 
            //     }else if (d.delivery_status == "CUSTOMER_INFORMED_1" || d.delivery_status == "CUSTOMER_INFORMED_2" || d.delivery_status == "CUSTOMER_INFORMED_3" || d.delivery_status == "ON_THE_WAY_TO_CUSTOMER" || d.delivery_status == "COURIER_DEBIT") {
            //         d.filter = "ON_THE_WAY_TO_CUSTOMER" 
            //     }else {
            //         d.filter = d.delivery_status
            //     }
            // }) 
            return { ...state, data: addFilterField(action.payload.data) }
        case TYPES.LIVE_ASSIGNED_BRANCH_FILTER:
            return {...state, filter: action.payload.filter, total: action.payload.total}
        case TYPES.LIVE_DELAY_VISIT:
            var newDt = new Date(action.payload[1]).setHours(3);
            state.data.map(x => { // update state
                if (x.order_oid == action.payload[0]) {
                    x.delivery_status = "DELIVERY_POSTPONED"
                    x.delivery_date = new Date(newDt).toISOString().substring(0, 19)
                    //x.delivery_date = "11.11.2001"
                }
            })
            return { ...state }
        case TYPES.LIVE_WILL_BE_RETURN:
            state.data.map(x => { // update state
                if (x.order_oid == action.payload) {
                    x.delivery_status = "WILL_BE_RETURN"
                }
            })
            return { ...state }
        case TYPES.LIVE_DELAY_VISIT_MULTIPLE:
            var postponeDatas = action.payload[1];
            var newDt = new Date(action.payload[0]).setHours(3);
            for (var i = 0; i < postponeDatas.length; i++) {
                state.data.map(x => {
                    if (x.order_oid == postponeDatas[i].order_oid) {
                        x.delivery_status = "DELIVERY_POSTPONED"
                        x.delivery_date = new Date(newDt).toISOString().substring(0, 19)
                    }
                })
            }
            return { ...state }
        case TYPES.MANUEL_DELIVERY_SUCCESSFUL:

            state.data.map(x => { // update state
                if (x.order_oid == action.payload.orderId) {
                    x.delivery_status = "DELIVERED";
                    x.delivery_to = action.payload.recipientName;
                    x.arrival_time = new Date().toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });

                }
            })
            return { ...state }
        case TYPES.MANUEL_DELIVERY_FAILED:
            state.data.map(x => { // update state
                if (x.order_oid == action.payload.orderId) {

                    x.delivery_description = action.payload.reasonText;
                    if (action.payload.orderNewDate) { // visit postponed
                        x.delivery_status = "DELIVERY_POSTPONED";
                        x.arrival_time = new Date().toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                        let newDate = action.payload.orderNewDate;
                        let todayAddHours = new Date(newDate.setHours(newDate.getHours() + 5));
                        //let todayFormat = todayAddHours.toISOString().substring(0, 10)
                        x.delivery_date = todayAddHours.toISOString().substring(0, 19)
                    } else {
                        x.delivery_status = "NOT_DELIVERED";
                        x.arrival_time = new Date().toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
                    }

                }
            })
            return { ...state }
        case TYPES.MANUEL_UPDATE_ORDER:
            state.data.map(x => { // update state
                if (x.order_oid == action.payload.order_id) {
                    x.address = action.payload.address;
                    x.city = action.payload.city;
                    x.county = action.payload.county;
                    x.gsm_number = action.payload.gsm_number;
                }
            })
            return { ...state }
        default:
            return state
    }
};
