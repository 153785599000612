import TYPES from "../types";
import { Services } from "../../api/Services"


const server = new Services();

export const getDispatcherPlannerLink = (setDispatcherUrl) => {
    return async (dispatch) => {
        server.
            getDispatcherPlannerLink().then((data) => {
                setDispatcherUrl(data.entity.data);
                // dispatch({
                //   type: TYPES.LISTING,
                //   payload: data.entity
                // });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    // return async (dispatch) => {
    //     setDispatcherUrl("https://visiondispatch-p.geovisiongroup.com/App.html?nomenu=1&pool=1&token=b83a54aa5498466096418cbf370066b8")
    // }
};