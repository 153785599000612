import PropTypes from "prop-types";
// @mui
import { Card, Typography, CardHeader, CardContent, Box, Stack } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
// utils
import { fDateTime } from "utils/formatTime";
import { getStatus } from "helper";

// ----------------------------------------------------------------------

export default function AnalyticsOrderTimeline({ label, data }) {
  window.previousOrderStatus = "";

  return (
    <Card
      sx={{
        p: 3,
        "& .MuiTimelineItem-missingOppositeContent:before": {
          display: "none",
        },
      }}
    >
      {label ?? <CardHeader title="Order Timeline" />}
      <CardContent sx={{ px: 0, pt: 0 }}>
        <Timeline sx={{ px: 0 }}>
          {data.history.map((item, index) => {
            let currentOrderStatus = getStatus(item.status);
            if (window.previousOrderStatus === currentOrderStatus) return
            if (currentOrderStatus === "Depoda") return;
            window.previousOrderStatus = currentOrderStatus;
            return <OrderItem key={index} item={item}/>;
          })}
          {
            (getStatus(data.status) !== window.previousOrderStatus || !window.previousOrderStatus) &&  
             getStatus(data.status) != "Depoda" &&
              <OrderItem
                key="last-item"
                item={{
                  status: data.status,
                  username: data.updated_username,
                  date: data.status_date ?? data.delivery_date,
                  //fullname: data.fullName,
                  fullname: data.updated_username,
                  courierName: data.courier_name,
                  updateduser: data.updated_user,
                  deliveryTo: data.delivery_to
                }}
                isLast
              />
          }
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

const colors = {
  ORDER_RECEIVED: "success",
  ORDER_ACCEPTED: "primary",
  DELIVERY_PLANNED: "info",
  BRANCH_ACCEPTED: "warning",
  COURIER_DEBIT: "secondary",
  ON_THE_WAY_TO_CUSTOMER: "error",
};

function OrderItem({ item, isLast }) {
  const { status, username, date, fullName, courierName, updateduser, deliveryTo } = item;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={colors[status]} />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent >
        {
          status === "DELIVERED" && deliveryTo
          ? <Box>
            <Typography variant="subtitle2">
              <Stack direction="row" spacing={0.5} flexWrap='wrap'>
                <Box>{getStatus(status)}</Box>
                <Box fontStyle="italic">( {deliveryTo} )</Box>
              </Stack>
            </Typography>
          </Box>
          : <Typography variant="subtitle2">{getStatus(status)}</Typography>
        }
        {
          (status === "DELIVERED" || status === "NOT_DELIVERED" || status === "BRANCH_ACCEPTED") // latest statuses
            ?
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {updateduser} ( {username} ) - {fDateTime(date)}
            </Typography>
            :
            <>
            {/* ???????????? */}
              {fullName && <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {fullName} ( {username} ) - {fDateTime(date)}
              </Typography>}
              {(!fullName && courierName) && <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {courierName} ( {username} ) - {fDateTime(date)}
              </Typography>}

              {(!fullName && !courierName) && <Typography variant="caption" sx={{ color: "text.secondary" }}>
                {username} - {fDateTime(date)}
              </Typography>}

            </>
        }
      </TimelineContent>
    </TimelineItem>
  );
}
