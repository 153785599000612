import React from "react";
import PropTypes from "prop-types";
import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";

const style = {
  flex: 1,
  height: 20,
  borderRadius: 1,
  backgroundColor: "#f4f6f8",
};

const TableLoading = ({ colSpan }) => {
  const stackData = [...Array.from({ length: 3 })];
  const skeletonData = [...Array.from({ length: colSpan / 2 })];
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Stack spacing={2} flexDirection="column">
          {stackData.map((el, index) => {
            return (
              <Stack
                key={`stack-${index}`}
                spacing={2}
                flexWrap="wrap"
                direction="row"
                justifyContent="center"
              >
                {skeletonData.map((subEl, i) => (
                  <Skeleton
                    key={`skeleton-${i}`}
                    variant="rectangular"
                    sx={style}
                  />
                ))}
              </Stack>
            );
          })}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

TableLoading.propTypes = {
  colSpan: PropTypes.number,
};

export default TableLoading;
