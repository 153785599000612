import TYPES from "../types";
import { Services } from "../../api/Services"


const server = new Services();

export const getDispatcherLink = (setDispatcherUrl) => {

    return async (dispatch) => {
        server.
            getDispatcherLink().then((data) => {
                setDispatcherUrl(data.entity.data);
                // dispatch({
                //   type: TYPES.LISTING,
                //   payload: data.entity
                // });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};