const data = [
    {
      id: "1000",
      code: "f230fh0g3",
      İl: "ISTANBUL",
      description: "Product Description",
      image: "bamboo-watch.jpg",
      packages: 200,
      category: "Accessories",
      unsuccessful: 25,
      inventoryStatus: "INSTOCK",
      rating: 8.2,
      orders: [
        {
          id: "1000",
          rating: 8.2,
          date: "2020-09-13",
          packages: 50,
          unsuccessful: 10,
          ilce: "Beşiktaş",
          status: null,
        },
        {
          id: "1001",
          rating: 8.2,
          date: "2020-05-14",
          packages: 100,
          unsuccessful: 10,
          ilce: "Maltepe",
          status: null,
        },
        {
          id: "1002",
          rating: 8.2,
          date: "2019-01-04",
          packages: 25,
          unsuccessful: 2,
          ilce: "Ataşehir",
          status: null,
        },
        {
          id: "1003",
          rating: 8.2,
          date: "2020-09-13",
          packages: 25,
          unsuccessful: 3,
          ilce: "Beylikdüzü",
          status: null,
        },
      ],
    },
    {
      id: "1001",
      code: "nvklal433",
      İl: "BURSA",
      description: "Product Description",
      image: "black-watch.jpg",
      packages: 200,
      category: "Accessories",
      unsuccessful: 25,
      inventoryStatus: "INSTOCK",
      rating: 7.3,
      orders: [
        {
          id: "2000",
          rating: 7.3,
          date: "2020-05-14",
          packages: 100,
          unsuccessful: 20,
          ilce: "Osmangazi",
          status: "DELIVERED",
        },
        {
          id: "2001",
          rating: 7.3,
          date: "2020-02-28",
          packages: 100,
          unsuccessful: 5,
          ilce: "Yıldırım",
          status: "PENDING",
        },
      ],
    },
    {
      id: "1002",
      code: "zz21cz3c1",
      İl: "ANKARA",
      description: "Product Description",
      image: "blue-band.jpg",
      packages: 200,
      category: "Fitness",
      unsuccessful: 25,
      inventoryStatus: "LOWSTOCK",
      rating: 5.0,
      orders: [
        {
          id: "3000",
          rating: 5.0,
          date: "2020-07-05",
          packages: 100,
          unsuccessful: 20,
          ilce: "Çankaya",
          status: "DELIVERED",
        },
        {
          id: "3001",
          rating: 5.0,
          date: "2020-02-06",
          packages: 100,
          unsuccessful: 5,
          ilce: "Yenimahalle",
          status: "DELIVERED",
        },
      ],
    },
    {
      id: "1003",
      code: "244wgerg2",
      İl: "IZMIR",
      description: "Product Description",
      image: "blue-t-shirt.jpg",
      packages: 200,
      category: "Clothing",
      unsuccessful: 25,
      inventoryStatus: "INSTOCK",
      rating: 4.3,
      orders: [{
        id: "4000",
        rating: 4.3,
        date: "2020-09-05",
        packages: 100,
        unsuccessful: 20,
        ilce: "Buca",
        status: "PENDING",
      },
      {
        id: "4001",
        rating: 4.3,
        date: "2019-04-16",
        packages: 100,
        unsuccessful: 5,
        ilce: "Bornova",
        status: "DELIVERED",
      },],
    },
    {
      id: "1004",
      code: 4.3,
      İl: "ANTALYA",
      description: "Product Description",
      image: "bracelet.jpg",
      packages: 200,
      category: "Accessories",
      unsuccessful: 25,
      inventoryStatus: "INSTOCK",
      rating: 8.2,
      orders: [
        {
          id: "5000",
          rating: 8.2,
          date: "2020-09-05",
          packages: 100,
          unsuccessful: 20,
          ilce: "Kepez",
          status: "PENDING",
        },
        {
          id: "5001",
          rating: 8.2,
          date: "2019-04-16",
          packages: 100,
          unsuccessful: 5,
          ilce: "Manavgat",
          status: "DELIVERED",
        },
      ],
    },
    
  ];
  export default data