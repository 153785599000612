import merge from "lodash/merge";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Divider,
  CardHeader,
  Typography,
} from "@mui/material";
// hooks
import useResponsive from "hooks/useResponsive";
// components
import ReactApexChart from "react-apexcharts";

import { BaseOptionChart } from "components/chart";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  "& .apexcharts-legend": {
    width: 240,
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "wrap",
      height: 160,
      width: "50%",
    },
  },
  "& .apexcharts-datalabels-group": {
    display: "none",
  },
}));

// ----------------------------------------------------------------------

export default function Projects({ data }) {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "sm");
  const chartOptions = merge(BaseOptionChart(), {
    labels: data.map((item) => item.name),
    colors: [
      theme.palette.primary.main,
      theme.palette.info.darker,
      theme.palette.chart.yellow[0],
      theme.palette.chart.blue[0],
      theme.palette.chart.red[0],
      theme.palette.chart.violet[2],
      theme.palette.chart.violet[0],
      theme.palette.success.darker,
      theme.palette.chart.green[0],
    ],
    stroke: {
      colors: [theme.palette.background.paper],
    },
    fill: { opacity: 0.8 },
    legend: {
      position: "right",
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        options: {
          legend: {
            position: "bottom",
            horizontalAlign: "left",
          },
        },
      },
    ],
  });

  const totalOrderCount = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <RootStyle>
      <CardHeader title="Projeler" />

      <Box sx={{ my: 5 }} dir="ltr">
        <ReactApexChart
          type="polarArea"
          series={data.map((item) => item.value)}
          options={chartOptions}
          height={isDesktop ? 240 : 360}
        />
      </Box>

      <Divider />

      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box sx={{ py: 2, width: 1, textAlign: "center" }}>
          <Typography
            sx={{ mb: 1, typography: "body2", color: "text.secondary" }}
          >
            Toplam Proje
          </Typography>
          <Typography sx={{ typography: "h4" }}>{data.length}</Typography>
        </Box>

        <Box sx={{ py: 2, width: 1, textAlign: "center" }}>
          <Typography
            sx={{ mb: 1, typography: "body2", color: "text.secondary" }}
          >
            Toplam Paket
          </Typography>
          <Typography sx={{ typography: "h4" }}>{totalOrderCount}</Typography>
        </Box>
      </Stack>
    </RootStyle>
  );
}
