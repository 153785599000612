import React, { useEffect } from "react";
import Table from "../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import { jobsListAction } from "../../store/actions/jobsAction";
import { Auth } from "../../api/Auth";
import TYPES from "../../store/types/index";
import Layout from "components/Layout";

const Jobs = (props) => {
  const dispatch = useDispatch();
  const projectApplicationDetailList = useSelector(
    (state) => state.projectApplicationDetailReducer
  );
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  useEffect(() => {
    dispatch(jobsListAction());
    dispatch({ type: TYPES.FILTER_EVALUATION, payload: "new" });
  }, []);

  return (
    <Layout
      {...props}
      heading="İş Başvuru Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "İş Başvuru Yönetimi" }]}
    >
      <Table
        products={
          projectApplicationDetailList.filtered &&
          projectApplicationDetailList.filtered.length > 0
            ? projectApplicationDetailList.filtered
            : projectApplicationDetailList.data
        }
        type="ProjectDetail"
        leftToolbarHide={true}
      />
    </Layout>
  );
};
Jobs.whyDidYouRender = false;
export default Jobs;
