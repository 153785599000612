import { Services } from "../../api/Services";
import TYPES from "../types";

// handle bottleneck perfomance with: REDUX_STATE_HELPER 
export const uploadDocumentAction = (doc, callback, onError) => {
  return async (dispatch) => {
    const server = new Services();
    server
      .uploadVehicleDocument(doc)
      .then(res => {
        dispatch({
          type: TYPES.UPLOAD_VEHICLE_DOCUMNET,
          payload: res.entity.data,
        });
        callback(res.entity)
      })
      .catch(error => {
        onError(error);
      });
  };
};
