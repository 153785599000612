import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
// TODO: => Get url over http or xhr

i18n
   .use(Backend)
   .use(initReactI18next)
   .init({
       debug: true,

       lng: 'tr',
       fallbackLng: 'en',
       whitelist: ['en', 'tr'],

       interpolation: {
           excapeValue: false
       },

       backend: {
        // loadPath: '', : => language url service
        // ajax: (url, options, callback, data) => {
        // } 
       }
   });

export default i18n;