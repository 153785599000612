import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Services } from "api/Services";
import { Form, Formik } from "formik";
import InputField from "components/MultiStepForm/InputField";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import SelectField from "components/MultiStepForm/SelectField";

const service = new Services();

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AddUser.propTypes = {
  firmaId: PropTypes.string,
  label: PropTypes.string,
  handleClose: PropTypes.func,
};

export default function AddUser(props) {
  const { firmaId, userData, label, handleClose, getList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [firmaList, setFirmaList] = React.useState([]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const sendData = {
      ...values,
      status: "ACTIVE",
      user_type: "CUSTOMER_ADMIN",
    };

    delete sendData.password;

    if (userData) {
      sendData.id = userData.id;

      // if new password
      if (values.password) {
        sendData.password = values.password;
      }
    } else {
      sendData.password = values.password;
    }

    try {
      await service.addCustomerPopup(sendData);
      enqueueSnackbar(`Başarıyla ${userData ? "güncellendi" : "kaydedildi"}!`, {
        variant: "success",
      });
      setLoading(false);
      if (getList) {
        getList(); // Get user list
      }
      handleClose();
    } catch (error) {
      setLoading(false);
      const msg = error?.message || "Bir sorun oluştu!";
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const getFirmaList = async () => {
    try {
      const res = await service.getCustomerManagementFilters();
      setFirmaList(
        res.entity.data[0].customers.map((i) => ({
          ...i,
          value: i.key,
          label: i.value,
        }))
      );
    } catch (error) {}
  };

  React.useEffect(() => {
    getFirmaList();
  }, []);

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <BootstrapDialogTitle onClose={handleClose}>{label}</BootstrapDialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            firm: userData?.firmaId || firmaId || "",
            first_name: userData?.first_name || "",
            last_name: userData?.last_name || "",
            email: userData?.email || "",
            phone: userData?.phone || "",
            username: userData?.username || "",
            password: userData?.password || "",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            firm: Yup.string().required("Proje alanı zorunlu"),
            first_name: Yup.string().required("Ad alanı zorunlu"),
            last_name: Yup.string().required("Soyad alanı zorunlu"),
            email: Yup.string().required("E-Posta alanı zorunlu"),
            phone: Yup.string().required("Telefon alanı zorunlu"),
            username: Yup.string().required("Kullanıcı adı alanı zorunlu"),
            password: Yup.string(),
          })}
        >
          {(formik) => (
            <Form>
              <SelectField
                required
                fullWidth
                size="small"
                label="Proje"
                name="firm"
                options={firmaList}
                disabled={firmaId ? true : false}
              />
              <Box
                sx={{
                  mt: 2,
                  display: "grid",
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: {
                    xs: `repeat(2, 1fr)`,
                    lg: `repeat(2, 1fr)`,
                  },
                }}
              >
                <InputField
                  required
                  size="small"
                  label="Ad"
                  name="first_name"
                />
                <InputField
                  required
                  size="small"
                  label="Soyad"
                  name="last_name"
                />
                <InputField
                  required
                  size="small"
                  label="E-Posta"
                  name="email"
                  type="email"
                />
                <InputField
                  required
                  size="small"
                  label="Telefon"
                  name="phone"
                />
                <InputField
                  size="small"
                  label="Kullanıcı Adı"
                  name="username"
                />
                <InputField
                  required={!userData}
                  size="small"
                  label="Parola"
                  name="password"
                  type="password"
                />
              </Box>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2 }}
                >
                  {userData ? "Düzenle" : "Kaydet"}
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
