import React, { useState, useEffect } from "react";
import { getDeliveryHistory } from "../../store/actions/liveTrackingAction";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import { driverList } from "store/actions/DriverManagementAction";
import filterHistoryData from "./filterHistoryData";
import { Stack } from "@mui/material";

// live tracking screen, order history component
const LiveTrackingHistory = (props) => {
  const dispatch = useDispatch();
  const { product } = props;
  const delHistory = useSelector((state) => state.liveTrackingHistoryReducer);
  const getDriver = useSelector((state) => state.driverManagementReducer);
  const [filteredDelHistory, setFilteredDelHistory] = useState([]);

  window.previousOrderStatus = "previousorderstatus";

  useEffect(() => {
    // dispatch(getDeliveryHistory(product.order_oid))
    // dispatch(driverList());
  }, []);

  useEffect(() => {
    if (delHistory.data && getDriver.data) {
      setFilteredDelHistory(
        filterHistoryData(delHistory.data[0]?.history, getDriver.data)
      );
    }
  }, [delHistory, getDriver]);

  const dateFormatForDeliveryHistory = (hr) => {
    //var aa = hr.split("T");
    // return new Date(hr).toLocaleString()
    // let h = hr.split("T")
    // return h;

    var d = new Date(hr);
    d.setHours(d.getHours() + d.getTimezoneOffset() / -60);
    let hh = d.toLocaleTimeString("default", {
      hour: "numeric",
      minute: "numeric",
    });
    let dd = d.toLocaleDateString();
    return [dd, hh];
  };

  const orderStatusSwitch = (status) => {
    switch (status) {
      case "ORDER_RECEIVED":
        //return { "label": "Sipariş sisteme geldi" };
        return { label: ["Sipariş sisteme geldi", "Paket kabul bekliyor"] };
      case "SENT_TO_PLAN":
        return { label: "Planlamaya gönderildi" };
      case "REVERT_FROM_PLAN":
      case "IN_WAREHOUSE":
        return { label: "Depoda" };
      case "DELIVERY_PLANNED":
        return { label: "Planlandı" };
      case "IN_TRANSFER_CENTER":
      case "ON_THE_WAY_FOR_TRANSFER": // hepsi pasif olacak
        //return { "icon": <i class="dolly icon"></i>, "label": "Transfer Merkezinde" };
        return { label: "Transfer merkezinde" };
      case "ON_BOARD": // 1 aktif, 2 3 pasif
        return { label: "Araça yüklendi" };
      case "ON_THE_WAY_TO_CUSTOMER": // 1 aktif, 2 3 pasif
        return { label: "Dağıtıma çıktı" }; // not showing now
      case "CUSTOMER_INFORMED_1": // 1 aktif, 2 3 pasif
      case "CUSTOMER_INFORMED_2": // 1 pasif , 2 aktif, 3 pasif
      case "CUSTOMER_READINESS_RECEIVED": // 1 pasif , 2 aktif, 3 pasif
      case "CUSTOMER_INFORMED_3": // 1 pasif , 2 aktif, 3 pasif
        return { label: "Müşteri bilgilendirildi" };
      case "DELIVERED": //1 ve 2 pasif 3 aktif
      case "PARTIALLY_DELIVERED": // hepsi pasif
        //return { "icon": <i class="check square icon"></i>, "label": "Teslim Edildi" };
        return { label: "Teslim edildi" };
      case "NOT_DELIVERED": // hepsi pasif
        return { label: "Teslim edilemedi" };
      case "DELIVERY_POSTPONED": // hepsi pasif
        return { label: "Ziyaret ertelendi" };
      case "DELIVERY_CANCELLED":
        return { label: "Sipariş müşteriden alınamadı" };
      case "WILL_BE_RETURN":
        return { label: "İade edilecek" };
      case "ORDER_ACCEPTED":
        return { label: "Paket kabul yapıldı" };
      case "BRANCH_ACCEPTED":
        return { label: "Şube Kabul Yapıldı" };
      case "BRANCH_RETURN_ACCEPTED":
        return { label: "Şubeye İade Kabul Yapıldı" };
      case "COURIER_DEBIT":
        return { label: "Kuryeye zimmetlendi" };
      default:
        break;
    }
  };

  return (
    <div>
      <Table striped>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell></Table.HeaderCell> */}
            <Table.HeaderCell>Durum</Table.HeaderCell>
            <Table.HeaderCell>Tarih</Table.HeaderCell>
            <Table.HeaderCell>Saat</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              İşlem Yapan Kullanıcı{" "}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {/**
           * History object
           */}

          {filteredDelHistory &&
            filteredDelHistory.length > 0 &&
            filteredDelHistory.map((dt) => {
              //if (dt.status == "DELIVERED" || dt.status == "PARTIALLY_DELIVERED" || dt.status == "NOT_DELIVERED") return
              if (dt.status != null) {
                let currentOrderStatus = orderStatusSwitch(dt.status).label;

                if (window.previousOrderStatus == currentOrderStatus) return;
                window.previousOrderStatus = currentOrderStatus;

                return dt.status == "ORDER_RECEIVED" ? (
                  <>
                    <Table.Row>
                      {/* <Table.Cell style={{ "fontSize": "18px" }}>{orderStatusSwitch(dt.status).icon}</Table.Cell> */}
                      <Table.Cell>
                        {orderStatusSwitch(dt.status).label[0]}
                      </Table.Cell>
                      <Table.Cell>
                        {dateFormatForDeliveryHistory(dt.date)[0]}
                      </Table.Cell>
                      <Table.Cell>
                        {dateFormatForDeliveryHistory(dt.date)[1]}
                      </Table.Cell>
                      <Table.Cell>
                        <Stack
                          spacing={1}
                          justifyContent="center"
                          alignItems="center"
                          paddingBottom={2}
                        >
                          {dt.fullName && dt.status != "DELIVERY_PLANNED" && (
                            <div>{dt.fullName}</div>
                          )}
                          <div>
                            {dt.status == "DELIVERY_PLANNED"
                              ? "bringo_teslimatik"
                              : dt.username}
                          </div>
                        </Stack>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      {/* <Table.Cell style={{ "fontSize": "18px" }}>{orderStatusSwitch(dt.status).icon}</Table.Cell> */}
                      <Table.Cell>
                        {orderStatusSwitch(dt.status).label[1]}
                      </Table.Cell>
                      <Table.Cell>
                        {dateFormatForDeliveryHistory(dt.date)[0]}
                      </Table.Cell>
                      <Table.Cell>
                        {dateFormatForDeliveryHistory(dt.date)[1]}
                      </Table.Cell>
                      <Stack
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom={2}
                      >
                        {dt.fullName && dt.status != "DELIVERY_PLANNED" && (
                          <div>{dt.fullName}</div>
                        )}
                        <div>
                          {dt.status == "DELIVERY_PLANNED"
                            ? "bringo_teslimatik"
                            : dt.username}
                        </div>
                      </Stack>
                    </Table.Row>
                  </>
                ) : (
                  <Table.Row>
                    {/* <Table.Cell style={{ "fontSize": "18px" }}>{orderStatusSwitch(dt.status).icon}</Table.Cell> */}
                    <Table.Cell>
                      {orderStatusSwitch(dt.status).label}
                    </Table.Cell>
                    <Table.Cell>
                      {dateFormatForDeliveryHistory(dt.date)[0]}
                    </Table.Cell>
                    <Table.Cell>
                      {dateFormatForDeliveryHistory(dt.date)[1]}
                    </Table.Cell>
                    <Stack
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                      paddingBottom={2}
                    >
                      {dt.fullName && dt.status != "DELIVERY_PLANNED" && (
                        <div>{dt.fullName}</div>
                      )}
                      <div>
                        {dt.status == "DELIVERY_PLANNED"
                          ? "bringo_teslimatik"
                          : dt.username}
                      </div>
                    </Stack>
                  </Table.Row>
                );

                // <Table.Row>
                //     {/* <Table.Cell style={{ "fontSize": "18px" }}>{orderStatusSwitch(dt.status).icon}</Table.Cell> */}
                //     <Table.Cell>{orderStatusSwitch(dt.status).label}</Table.Cell>
                //     <Table.Cell>{dateFormatForDeliveryHistory(dt.date)[0]}</Table.Cell>
                //     <Table.Cell>{dateFormatForDeliveryHistory(dt.date)[1]}</Table.Cell>
                //     <Table.Cell> {dt.status == "DELIVERY_PLANNED" ? "bringo_teslimatik" : dt.username} </Table.Cell> {/*   last updated user  */}

                // </Table.Row>
              }
            })}

          {/**
           * status value
           * outside the history object
           */}
          {[
            "DELIVERED",
            "PARTIALLY_DELIVERED",
            "NOT_DELIVERED",
            "DELIVERY_POSTPONED",
            "DELIVERY_CANCELLED",
            "BRANCH_RETURN_ACCEPTED",
            "BRANCH_ACCEPTED",
            "COURIER_DEBIT",
          ].includes(
            delHistory.data.length > 0 && delHistory.data[0].status
          ) && (
            <Table.Row>
              {/* <Table.Cell style={{ "fontSize": "18px" }}> {orderStatusSwitch(delHistory.data[0].status).icon}</Table.Cell> */}
              <Table.Cell>
                {orderStatusSwitch(delHistory.data[0].status).label}
                {delHistory.data[0].status == "DELIVERED" &&
                  `(${product.delivery_to})`}
                {delHistory.data[0].status == "NOT_DELIVERED" &&
                  `(${product.delivery_description})`}
              </Table.Cell>
              {/* <Table.Cell>{dateFormatForDeliveryHistory(delHistory.data[0].delivery_date)[0]}</Table.Cell>
                        <Table.Cell>{dateFormatForDeliveryHistory(delHistory.data[0].delivery_date)[1]}</Table.Cell> */}
              {/* LastUpdate */}
              <Table.Cell>
                {
                  dateFormatForDeliveryHistory(
                    delHistory.data[0].lastupdated
                  )[0]
                }
              </Table.Cell>
              <Table.Cell>
                {
                  dateFormatForDeliveryHistory(
                    delHistory.data[0].lastupdated
                  )[1]
                }
              </Table.Cell>
              {/* LastUpdate */}
              <Table.Cell>
                <Stack
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom={2}
                >
                  {delHistory.data[0].courier_name && (
                    <div>{delHistory.data[0].courier_name}</div>
                  )}
                  <div>{delHistory.data[0].updated_username || "-"}</div>
                </Stack>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default LiveTrackingHistory;
