import TYPES from "../types";
import { Services } from "../../api/Services";
import { loadingActive } from "helper";

const server = new Services();

export const jobsListAction = (data) => {
  return async (dispatch) => {
    loadingActive(dispatch, true);
    server
      .getJobList()
      .then((data) => {
        dispatch({
          type: TYPES.PROJECT_APPLICATION_DETAIL,
          payload: data.entity,
        });
        loadingActive(dispatch, false);
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};

export const JobUpdate = (data, callback, onError) => {
  return async (dispatch) => {
    server
      .updateJob(data)
      .then(data => {
        dispatch({
          type: TYPES.UPDATE_JOB,
          payload: data.entity,
        });
        callback(data.entity);
      })
      .catch(error => onError(error.message));
  };
};
