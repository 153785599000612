import React from "react";
import Loader from "react-loader-spinner";
//import './style.css';
import { useSelector } from "react-redux";

// Loading component
export const Spinner = (props) => {
  const { showText } = props;
  const loadingStatus = useSelector((state) => state.loadingReducer);
  return (
    // <div className="parentDisable">
    loadingStatus.status == true && <div style={{ position: "fixed", top: 0, left: 0, backgroundColor: "#666", opacity: 0.8, zIndex: 99999, height: "100%", width: "100%" }}>
      {/* <div className="overlay-box"> */}
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", backgroundColor: "#666666", opacity: .8, zIndex: 99999 }}>
        {/* <div className="geocode-loading-text">Geocode ediliyor</div> */}
        <div style={{ position: "relative", top: "55px", fontSize: "11px", left: "9px" }}>{showText == true ? "Geocode ediliyor" : ""}</div>
        <Loader
          type="Oval"
          color="tomato"
          height={100}
          width={100}
        //timeout={20000}
        //fullscreen="true"
        />
      </div>
    </div>
  );
};



