import React from "react";
import Layout from "components/Layout";
import { Services } from "api/Services";
import {
  Card,
  Box,
  TextField,
  Grid,
  Skeleton,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import BookingWidgetSummary from "components/BookingWidgetSummary";
import {
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
} from "components/analytics";
import moment from "moment";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import Table from "components/Table";
import { useSelector, useDispatch } from "react-redux";

const services = new Services();

const DATES_ARRAY = [
  {
    label: "Bugün",
    value: [moment(), moment()],
  },
  {
    label: "Bu Hafta",
    value: [moment().subtract(1, "weeks"), moment()],
  },
  {
    label: "Bu Ay",
    value: [moment().subtract(1, "months"), moment()],
  },
  {
    label: "Bu Yıl",
    value: [moment().subtract(1, "years"), moment()],
  },
];

const keys = {
  branch_accepted: {
    title: "Şube kabul yapıldı",
    color: "violet",
    img: "/images/dashboard/branch_accepted",
  },
  delivered: {
    title: "Teslim edildi",
    color: "blue",
    img: "/images/dashboard/delivered",
  },
  not_delivered: {
    title: "Teslim edilmedi",
    color: "green",
    img: "/images/dashboard/not_delivered",
  },
  on_the_way_to_customer: {
    title: "Dağıtımda",
    color: "yellow",
    img: "/images/dashboard/on_the_way_to_customer",
  },
  order_accepted: {
    title: "Paket kabul yapıldı",
    color: "red",
    img: "/images/dashboard/order_accepted",
  },
  order_received: {
    //title: "Sipariş alındı",
    title: "Paket kabul bekliyor",
    color: "purple",
    img: "/images/dashboard/order_received",
  },
  total: {
    title: "Toplam",
    color: "orange",
    img: "/images/dashboard/total",
  },
  will_be_return: {
    //title: "Dönüş olacak",
    title: "İade Edilecek",
    color: "pink",
    img: "/images/dashboard/will_be_return",
  },
};

const CustomerDashboard = (props) => {
  const history = useHistory();
  const [dates, setDates] = React.useState(DATES_ARRAY[0].value);
  const [selectDates, setSelectDates] = React.useState(DATES_ARRAY[0].value);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const ungeocoded = useSelector((state) => state.ungeocodedReducer);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await services.getDashboardCounts(dates);

      if (res.entity.data.length > 0) {
        setData(res.entity.data[0]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    getData();
  }, [dates]);

  React.useEffect(() => {
    ungeocoded.length > 0 && getData();
  }, [ungeocoded]);

  const newData = Object.entries(data || {});
  const newChartData = newData.filter(([k, v]) => k !== "total" && v > 0);
  const chartDataLabels = newChartData.map(([k]) => keys[k].title);
  const chartData = newChartData.map(([k, v]) => v);

  const isChartData = chartData.length > 0;

  return (
    <Layout
      {...props}
      heading="Dashboard"
      action={
        <Table isDashboard type="order-management" unplannedOrderButtonShow />
      }
    >
      <Card sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                disableMaskedInput
                inputFormat="dd/MM/yyyy"
                startText="Başlangıç Tarihi"
                endText="Bitiş Tarihi"
                value={dates}
                onChange={setDates}
                // onChange={(e) => { datarangepicker close...
                //   if(e[0] != null && e[1] != null){
                //     document.querySelectorAll('[role="tooltip"]')[0].style.display='none';
                //     document.querySelectorAll('[role="tooltip"]')[0].blur();
                //     document.querySelector('.yakup').click();
                //   }
                //   setDates(e)
                // }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField fullWidth {...startProps} />
                    <Box sx={{ mx: 2 }}></Box>
                    <TextField fullWidth {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Tarih Aralığı</InputLabel>
              <Select
                fullWidth
                value={selectDates}
                onChange={(e) => {
                  const value = e.target.value;
                  setDates(value);
                  setSelectDates(value);
                }}
                input={<OutlinedInput label="Tarih Aralığı" />}
              >
                {DATES_ARRAY.map((item, index) => {
                  return (
                    <MenuItem key={`calendar-${index}`} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {loading &&
          [...new Array(8)].map((el, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Skeleton
                variant="rectangular"
                height={160}
                sx={{ borderRadius: 1 }}
              />
            </Grid>
          ))}
        {!loading &&
          data &&
          newData.map(([key, value]) => {
            return (
              <Grid item xs={12} md={6} lg={3} key={key}>
                <BookingWidgetSummary
                  sx={{
                    transition: ".4s ease",
                    cursor: "pointer",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                  title={keys[key].title}
                  total={value}
                  icon={
                    <img src={`${keys[key].img}.png`} alt={keys[key].title} />
                  }
                  onClick={() => {
                    if (dates.length === 2) {
                      const params = {
                        pathname: "/bringo-customer/dashboard/list",
                        search: qs.stringify({
                          start: moment(dates[0]).format("YYYY-MM-DD"),
                          end: moment(dates[1]).format("YYYY-MM-DD"),
                        }),
                      };
                      if (key !== "total") {
                        params.search += `&routeKey=${key.toUpperCase()}`;
                      }
                      history.push(params);
                    }
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid
          item
          xs={12}
          md={!isChartData ? 12 : 6}
          lg={!isChartData ? 12 : 8}
        >
          <AnalyticsWebsiteVisits keys={keys} />
        </Grid>
        {isChartData && (
          <Grid item xs={12} md={6} lg={4}>
            <AnalyticsCurrentVisits
              labels={chartDataLabels}
              data={chartData}
              title="Günlük Veri Analizi"
            />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default CustomerDashboard;
