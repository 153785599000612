import {
  Box,
  Stack,
  Card,
  Avatar,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import Label from "components/Label";

export default function VehicleStatics({ data }) {
  const theme = useTheme();
  const dataNew = [
    {
      id: 0,
      name: "Aktif araç sayısı",
      value: data.working_vehicle_count,
      icon: "/images/customerDashboard/panelvan.svg",
    },
    {
      id: 1,
      name: "Ortalama desi",
      value: Math.ceil(data.avg_deci_by_vehicle),
      icon: "/images/customerDashboard/deci.svg",
    },
    {
      id: 2,
      name: "Ortalama paket",
      value: Math.ceil(data.avg_package_by_vehicle),
      icon: "/images/customerDashboard/paket.svg",
    },
    {
      id: 3,
      name: "ortalama sipariş",
      value: Math.ceil(data.avg_order_by_vehicle),
      icon: "/images/customerDashboard/rota.svg",
    },
    {
      id: 4,
      name: "Ortalama rota mesafesi",
      value: Math.ceil(data.avg_route_distance),
      icon: "/images/customerDashboard/siparis.svg",
    },
  ];

  return (
    <Card>
      <CardHeader title="Araç Bazlı İstatistikler" />
      <Stack spacing={3} sx={{ p: 3 }}>
        {dataNew.map((item) => (
          <Stack
            key={item.value}
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Avatar alt={item.name} src={item.icon} />
            <Stack direction={"row"} sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle2" flex={1}>
                {item.name}
              </Typography>
              <Typography variant="caption">
                <Label
                  variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                  color="info"
                >
                  {item.value}
                </Label>
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
