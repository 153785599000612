import React, { useState, useEffect } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import L from "leaflet";
import { useSnackbar } from "notistack";
import "leaflet-control-geocoder";

const zoom = 18;
const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});
const geocoder = L.Control.Geocoder.nominatim();

function Map({ coords, onChange }) {
  const { enqueueSnackbar } = useSnackbar();
  const [map, setMap] = useState(null);
  const markerRef = React.useRef(null);

  function LeafletgeoSearch() {
    useEffect(() => {
      const provider = new OpenStreetMapProvider();

      const searchControl = new GeoSearchControl({
        provider,
        marker: { icon },
        style: "bar",
        showMarker: false, // optional: true|false  - default true
        showPopup: false, // optional: true|false  - default false
        popupFormat: ({ result }) => {
          onChange({
            name: result.label,
            center: {
              lat: result.raw.lat,
              lng: result.raw.lon,
            },
          });
          map?.setView([result.raw.lat, result.raw.lon], zoom);
          enqueueSnackbar(result.label, { variant: "success" });
        },
        resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
        maxMarkers: 1, // optional: number      - default 1
        retainZoomLevel: false, // optional: true|false  - default false
        animateZoom: true, // optional: true|false  - default true
        autoClose: false, // optional: true|false  - default false
        searchLabel: "Adresi giriniz", // optional: string      - default 'Enter address'
        keepResult: false, // optional: true|false  - default false
        updateMap: true, // optional: true|false  - default true
      });

      map.addControl(searchControl);

      return () => map.removeControl(searchControl);
      // eslint-disable-next-line
    }, []);

    return null;
  }

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null
  }


  return coords ? (
    <MapContainer
      dragging
      zoom={zoom}
      center={coords}
      whenCreated={setMap}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ChangeView center={coords || [0, 0]} zoom={zoom} />
      {map && coords && (
        <Marker
          draggable
          icon={icon}
          ref={markerRef}
          position={coords}
          eventHandlers={{
            dragend() {
              const marker = markerRef.current;
              if (marker != null) {
                geocoder.reverse(marker.getLatLng(), 20, (results) => {
                  const result = results[0];
                  onChange(result);
                  map?.setView(
                    [marker.getLatLng().lat, marker.getLatLng().lng],
                    zoom
                  );
                  enqueueSnackbar(result.name, { variant: "success" });
                });
              }
            },
          }}
        />
      )}
      {map && <LeafletgeoSearch />}
    </MapContainer>
  ) : null;
}

export default Map;
