import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";
import Label from "components/CustomTable/TableLabel";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import { TextFirstLetter } from "utils";

Item.propTypes = {
  item: PropTypes.object,
  handleDetailEdit: PropTypes.func,
};

export default function Item({
  item,
  handleDetailEdit,
  handleDelete,
  toggleModal,
}) {
  const [openMenu, setOpenMenuActions] = React.useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = () => {
    handleCloseMenu();
    handleDetailEdit();
  };

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            alt={item.fullName}
            color={createAvatar(item.fullName).color}
            src={item.avatar}
          >
            {createAvatar(item.fullName).name}
          </Avatar>
          <Box flex={1}>
            <Typography variant="subtitle2" noWrap>
              {TextFirstLetter(item.fullName)}
            </Typography>
            <Typography variant="caption">
              {item.userType.slice(0, 15)}
              {item.userType.length > 14 ? "..." : ""}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.plaka}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{TextFirstLetter(item.company)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.address)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.phone}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.createdAt}</Typography>
      </TableCell>
      <TableCell>
        <Label
          variant="ghost"
          color={item.status === "ACTIVE" ? "success" : "error"}
        >
          {item.status === "ACTIVE" ? "Aktif" : "Pasif"}
        </Label>
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => handleDelete(item.id)}
                sx={{ color: "error.main" }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
                Sil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleModal(item);
                  handleCloseMenu();
                }}
              >
                <Iconify icon={"eva:edit-fill"} />
                Düzenle
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
