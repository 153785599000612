import TYPES from "../types";
import { Services } from "../../api/Services"

const getServices = new Services();


export const getAdvertisementApplicantsList = (data) => {

  //const getServices = new Services();

  return async (dispatch) => {


  /*  let data = {
      "data": [
        {
          "id": "402881b77a4240bd017a4242ec730006",
          "created_at": 1624609319954,
          "lastupdated": 1624706672344,
          "company_name": "Özgür Nakliye",
          "user": {
            "id": "402881b77a4240bd017a4242a48d0003",
            "created_at": 1624609301642,
            "lastupdated": 1624609495330,
            "username": "5307827130",
            "status": "PENDING",
            "email": "oonguner@gmail.com",
            "phone": "5307827130",
            "user_type": "COMPANY_OWNER",
            "first_name": null,
            "last_name": null,
            "employement_date": null,
            "birthday": null,
            "gender": null,
            "education_status": null,
            "photo_url": null
          },
          "company_type": "PERSONEL",
          "employement_date": null,
          "advertisement": {
            "id": "402881b77a4240bd017a4242ec730006",
            "created_at": 1623987169309,
            "lastupdated": 1623987169309,
            "code": "ww2",
            "customer": null,
            "application_due_date": 1623986179620,
            "start_date": 1623986179620,
            "end_date": 1623986179620,
            "quota": 3,
            "loading_points": "İstanbu Avrupa",
            "requirements": "sdsadasdasd",
            "status": "ACTIVE",
            "evaluation_status": "EVALUATED_PUBLISHED",
            "advertisement_type": "PROJECT"
          },
          "evaluation_result": "RESERVED"
        },
        {
          "id": "402881b77a4240bd017a4242ec730006",
          "created_at": 1624609319954,
          "lastupdated": 1624706672344,
          "company_name": "Özgür Nakliye",
          "user": {
            "id": "402881b77a4240bd017a4242a48d0003",
            "created_at": 1624609301642,
            "lastupdated": 1624609495330,
            "username": "5307827130",
            "status": "PENDING",
            "email": "oonguner@gmail.com",
            "phone": "5307827130",
            "user_type": "COMPANY_OWNER",
            "first_name": null,
            "last_name": null,
            "employement_date": null,
            "birthday": null,
            "gender": null,
            "education_status": null,
            "photo_url": null
          },
          "company_type": "PERSONEL",
          "employement_date": null,
          "advertisement": {
            "id": "8a8081a67a15b34a017a1d2da81e0017",
            "created_at": 1623987169309,
            "lastupdated": 1623987169309,
            "code": "ww2",
            "customer": null,
            "application_due_date": 1623986179620,
            "start_date": 1623986179620,
            "end_date": 1623986179620,
            "quota": 3,
            "loading_points": "İstanbu Avrupa",
            "requirements": "sdsadasdasd",
            "status": "ACTIVE",
            "evaluation_status": "EVALUATED_PUBLISHED",
            "advertisement_type": "PROJECT"
          },
          "evaluation_result": "APPROVED"
        },
        {
          "id": "402881b77a4240bd017a4242ec730006",
          "created_at": 1624609319954,
          "lastupdated": 1624706672344,
          "company_name": "Özgür Nakliye",
          "user": {
            "id": "402881b77a4240bd017a4242a48d0003",
            "created_at": 1624609301642,
            "lastupdated": 1624609495330,
            "username": "5307827130",
            "status": "PENDING",
            "email": "oonguner@gmail.com",
            "phone": "5307827130",
            "user_type": "COMPANY_OWNER",
            "first_name": null,
            "last_name": null,
            "employement_date": null,
            "birthday": null,
            "gender": null,
            "education_status": null,
            "photo_url": null
          },
          "company_type": "PERSONEL",
          "employement_date": null,
          "advertisement": {
            "id": "8a8081a67a15b34a017a1d2da81e0012",
            "created_at": 1623987169309,
            "lastupdated": 1623987169309,
            "code": "ww2",
            "customer": null,
            "application_due_date": 1623986179620,
            "start_date": 1623986179620,
            "end_date": 1623986179620,
            "quota": 3,
            "loading_points": "İstanbu Avrupa",
            "requirements": "sdsadasdasd",
            "status": "ACTIVE",
            "evaluation_status": "EVALUATED_PUBLISHED",
            "advertisement_type": "PROJECT"
          },
          "evaluation_result": "REJECTED"
        },
        {
          "id": "402881b77a4240bd017a4242ec730006",
          "created_at": 1624609319954,
          "lastupdated": 1624706672344,
          "company_name": "Özgür Nakliye",
          "user": {
            "id": "402881b77a4240bd017a4242a48d0003",
            "created_at": 1624609301642,
            "lastupdated": 1624609495330,
            "username": "5307827130",
            "status": "PENDING",
            "email": "oonguner@gmail.com",
            "phone": "5307827130",
            "user_type": "COMPANY_OWNER",
            "first_name": null,
            "last_name": null,
            "employement_date": null,
            "birthday": null,
            "gender": null,
            "education_status": null,
            "photo_url": null
          },
          "company_type": "PERSONEL",
          "employement_date": null,
          "advertisement": {
            "id": "8a8081a67a15b34a017a1d2da81e0073",
            "created_at": 1623987169309,
            "lastupdated": 1623987169309,
            "code": "ww2",
            "customer": null,
            "application_due_date": 1623986179620,
            "start_date": 1623986179620,
            "end_date": 1623986179620,
            "quota": 3,
            "loading_points": "İstanbu Avrupa",
            "requirements": "sdsadasdasd",
            "status": "ACTIVE",
            "evaluation_status": "EVALUATED_PUBLISHED",
            "advertisement_type": "PROJECT"
          },
          "evaluation_result": "NOT_EVALUATED"
        }
      ]
    }

    dispatch({
      type: TYPES.PROJECT_APPLICATION_DETAIL,
      payload: data
    });*/

     getServices.
     getAdvertisementApplicants(data).then((data) => {
         dispatch({
           type: TYPES.PROJECT_APPLICATION_DETAIL,
           payload: data.entity
         });
       })
       .catch((error) => {
         console.log(error);
       });
  }

};

const evaluationArr = [getServices.advertisementEvaluationApprove, getServices.advertisementEvaluationReject, getServices.advertisementEvaluationReserve];

export const applicationEvaluationUpdate = (data, status, cb) => {

  //const getServices = new Services();

  return async (dispatch) => {

    evaluationArr[status](data.id).then((data) => {
      dispatch({
        type: TYPES.PROJECT_APPLICATION_DETAIL_EVALUATION,
        payload: data.entity
      });
      cb();
    })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  }

};



export const getProjectApplicationReview = (data) => {

  //const getServices = new Services();

  return async (dispatch) => {

    

  /*  let exData = {
      "id": "402881b77a4240bd017a4242ec730006",
      "tckn": 1624609301642,
      "name": "ronaldo",
      "lastname": "zidane",
      "birthday": "02.02.1992",
      "email": "oonguner@gmail.com",
      "phone": "5307827130",
      "country": "France",
      "province": "nice",
      "district": "lyon",
      "address": "france lyon nice",
      "vehicle":[
        {vehicleName: "Araç-1", content: "Araç-1 content"},
        {vehicleName: "Araç-2", content: "Araç-2 content"},
        {vehicleName: "Araç-3", content: "Araç-3 content"},
        {vehicleName: "Araç-4", content: "Araç-4 content"},
        {vehicleName: "Araç-5", content: "Araç-5 content"},
        {vehicleName: "Araç-6", content: "Araç-6 content"},
        {vehicleName: "Araç-7", content: "Araç-7 content"},
        {vehicleName: "Araç-8", content: "Araç-8 content"},
        {vehicleName: "Araç-9", content: "Araç-9 content"},
        {vehicleName: "Araç-10", content: "Araç-10 content"}
      ]
    };

    dispatch({
      type: TYPES.PROJECT_APPLICATION_DETAIL_REVIEW,
      payload: { data: exData }
    });*/
    
    dispatch({
      type: TYPES.PROJECT_APPLICATION_DETAIL_REVIEW,
      payload: { data: data }
    })
    
  }

};
