import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
// components
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from "../../../../components/hook-form";
import { fData } from "utils/formatNumber";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ChevronLeft } from "@mui/icons-material";
import moment from "moment";
import { Services } from "api/Services";
import { BASE_URL } from "api/Env";

// ----------------------------------------------------------------------

const getDocumentID = (url = "") => {
  const arr = url.split("/");
  return arr.at(-1);
};

const server = new Services();

const avatarStyle = {
  width: {
    xs: "100%",
    md: 100,
  },
  height: 40,
  border: "1px solid #f2f2f2",
  borderRadius: 1,
  "& *": {
    borderRadius: 0.5,
  },
  "& svg": {
    display: "none!important",
  },
};

DriverCreateEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  activeStep: PropTypes.number,
  changeStep: PropTypes.func,
  handleClose: PropTypes.func,
  steps: PropTypes.array,
  prevButtonDisable: PropTypes.bool,
  dataList: PropTypes.object.isRequired,
  getDrivers: PropTypes.func,
};

export default function DriverCreateEditForm({
  isEdit,
  activeStep,
  changeStep,
  handleClose,
  prevButtonDisable,
  steps,
  currentData,
  dataList,
  getDrivers,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const NewSchema = Yup.object().shape({
    avatarUrl: Yup.mixed().test(
      "required",
      "Resim alanı zorunlu",
      (value) => value !== ""
    ),
    ad: Yup.string().required("İsim alanı zorunlu"),
    soyad: Yup.string().required("Soyisim alanı zorunlu"),
    tc: Yup.string().required("TC alanı zorunlu"),
    dogumTarih: Yup.string().nullable().required("Doğum Tarihi alanı zorunlu"),
    tel: Yup.string().required("Telefon alanı zorunlu"),
    email: Yup.string().required("E-posta alanı zorunlu"),
    adres: Yup.string().required("Adres alanı zorunlu"),
    // Step 2
    firma: Yup.string().required("Firma alanı zorunlu"),
    arac: Yup.string().required("Araç alanı zorunlu"),
    ehliyetNo: Yup.string().required("Ehliyet No alanı zorunlu"),
    malKabul: Yup.boolean(),
    kuryeZimmet: Yup.boolean(),
    subeyeIade: Yup.boolean(),
    subeKabul: Yup.boolean(),
    // Step 3
    ehliyetTarih: Yup.date().required("Ehliyet Tarih alanı zorunlu"),
    ehliyetFile: Yup.mixed().required("Ehliyet Belgesi alanı zorunlu"),
    srcTarih: Yup.date().required("SRC alanı zorunlu"),
    srcTarihFile: Yup.mixed().required("SRC Belgesi alanı zorunlu"),
    psiTarih: Yup.date().required("Psikoteknik alanı zorunlu"),
    psiTarihFile: Yup.mixed().required("Psikoteknik Belgesi alanı zorunlu"),
    sabikaTarih: Yup.date().required("Sakıba alanı zorunlu"),
    sabikaTarihFile: Yup.mixed().required("Sabıka Belgesi alanı zorunlu"),
  });

  const defaultValues = useMemo(
    () => ({
      avatarUrl: currentData?.photo
        ? `${BASE_URL}/api/public/document/${currentData?.photo.id}`
        : "",
      ad: currentData?.first_name || "",
      soyad: currentData?.last_name || "",
      tc: currentData?.identity_number || "",
      dogumTarih: currentData?.birthday || null,
      tel: currentData?.phone || "",
      email: currentData?.email || "",
      adres: currentData?.address_line || "",
      // Step 2
      firma: currentData?.company?.id || "",
      arac:
        currentData?.vehicles && currentData?.vehicles.length > 0
          ? currentData?.vehicles[0].id || ""
          : "",
      ehliyetNo: currentData?.licence_number || "",
      malKabul: currentData?.malKabul || false,
      kuryeZimmet: currentData?.kuryeZimmet || false,
      subeyeIade: currentData?.subeyeIade || false,
      subeKabul: currentData?.subeKabul || false,
      // Step 3
      ehliyetTarih:
        currentData?.licence_doc?.doc_expiration ||
        moment().format("YYYY-MM-DD"),
      ehliyetFile: currentData?.licence_doc
        ? `${BASE_URL}/api/public/document/${currentData?.licence_doc.id}`
        : "",
      srcTarih:
        currentData?.src_doc?.doc_expiration || moment().format("YYYY-MM-DD"),
      srcTarihFile: currentData?.src_doc
        ? `${BASE_URL}/api/public/document/${currentData?.src_doc.id}`
        : "",
      psiTarih:
        currentData?.psycho_doc?.doc_expiration ||
        moment().format("YYYY-MM-DD"),
      psiTarihFile: currentData?.psycho_doc
        ? `${BASE_URL}/api/public/document/${currentData?.psycho_doc.id}`
        : "",
      sabikaTarih:
        currentData?.criminal_record?.doc_expiration ||
        moment().format("YYYY-MM-DD"),
      sabikaTarihFile: currentData?.criminal_record
        ? `${BASE_URL}/api/public/document/${currentData?.criminal_record.id}`
        : "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentData) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentData]);

  const onSubmit = async () => {
    if (activeStep !== 3) {
      return;
    }

    let res = null;
    let res1 = null;
    let res2 = null;
    let res3 = null;
    let res4 = null;

    try {
      if (isEdit) {
        if (values.avatarUrl && typeof values.avatarUrl !== "string") {
          const x = await server.uploadVehicleDocument(values.avatarUrl);
          res = x.entity.data[0].id;
        } else {
          res = getDocumentID(values.avatarUrl) || "";
        }
        if (values.ehliyetFile && typeof values.ehliyetFile !== "string") {
          const x = await server.uploadVehicleDocument(values.ehliyetFile);
          res1 = x.entity.data[0].id;
        } else {
          res1 = values.ehliyetFile || "";
        }
        if (values.srcTarihFile && typeof values.srcTarihFile !== "string") {
          const x = await server.uploadVehicleDocument(values.srcTarihFile);
          res2 = x.entity.data[0].id;
        } else {
          res2 = values.srcTarihFile || "";
        }
        if (values.psiTarihFile && typeof values.psiTarihFile !== "string") {
          const x = await server.uploadVehicleDocument(values.psiTarihFile);
          res3 = x.entity.data[0].id;
        } else {
          res3 = values.psiTarihFile || "";
        }
        if (
          values.sabikaTarihFile &&
          typeof values.sabikaTarihFile !== "string"
        ) {
          const x = await server.uploadVehicleDocument(values.sabikaTarihFile);
          res4 = x.entity.data[0].id;
        } else {
          res4 = values.sabikaTarihFile || "";
        }
      } else {
        const resX = await server.uploadVehicleDocument(values.avatarUrl);
        res = resX.entity.data[0].id;

        const res1X = await server.uploadVehicleDocument(values.ehliyetFile);
        res1 = res1X.entity.data[0].id;

        const res2X = await server.uploadVehicleDocument(values.srcTarihFile);
        res2 = res2X.entity.data[0].id;

        const res3X = await server.uploadVehicleDocument(values.psiTarihFile);
        res3 = res3X.entity.data[0].id;

        const res4X = await server.uploadVehicleDocument(
          values.sabikaTarihFile
        );
        res4 = res4X.entity.data[0].id;
      }

      const sendData = {
        // Step 1
        photo_url: res,
        first_name: values.ad,
        last_name: values.soyad,
        identity_number: values.tc,
        birthday: values.dogumTarih,
        phone: values.tel,
        email: values.email,
        address_line: values.adres,
        // Step 2
        company: values.firma,
        vehicles: [values.arac],
        roles: [],
        // Step 3
        licence_doc: res1,
        licence_expiration_date: moment(values.ehliyetTarih).format(
          "YYYY-MM-DD"
        ),

        src_doc: res2,
        src_expiration_date: moment(values.srcTarih).format("YYYY-MM-DD"),
        // src_date: "2022-10-13T21:26:03.029Z",

        psycho_doc: res3,
        psycho_expiration_date: moment(values.psiTarih).format("YYYY-MM-DD"),
        // psycho_date: "2022-10-13T21:26:03.029Z",

        licence_number: values.ehliyetNo,
        criminal_record: res4,
        status: "ACTIVE",
        user_type: "DRIVER",

        // education_status: "string",
        employement_date: moment().format("YYYY-MM-DD"),
        // previous_experience: "string",
      };

      if (isEdit) {
        sendData.id = currentData.id;
      }

      await server.addDriver(sendData);
      enqueueSnackbar(
        !isEdit ? "Kurye başarıyla kaydedildi!" : "Kurye başarıyla düzenlendi!",
        {
          variant: "success",
        }
      );
      if (getDrivers) {
        getDrivers();
      }
      handleClose();
    } catch (error) {
      let msg = "Bir sorun oluştu";
      if (error && error.message) {
        msg = error.message;
      }
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles, name) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          name,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const companiesList = dataList.companies;
  const vehiclesList = dataList.vehicles.map((i) => ({
    key: i.id,
    value: i.name,
  }));

  React.useEffect(() => {
    // malKabul
    // kuryeZimmet
    // subeyeIade
    // subeKabul

    if (values.malKabul || values.kuryeZimmet) {
      setValue("subeyeIade", false);
      setValue("subeKabul", false);
    }

    if (values.subeyeIade || values.subeKabul) {
      setValue("malKabul", false);
      setValue("kuryeZimmet", false);
    }
  }, [
    values.malKabul,
    values.kuryeZimmet,
    values.subeyeIade,
    values.subeKabul,
  ]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          py: 4,
          px: 2,
          mt: 6,
          border: "1px dotted #F4F6F8",
          boxShadow: "none",
        }}
      >
        {activeStep === 1 ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <RHFUploadAvatar
                name="avatarUrl"
                accept="image/*"
                maxSize={3145728}
                onDrop={(e) => handleDrop(e, "avatarUrl")}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: "auto",
                      display: "block",
                      textAlign: "center",
                      color: "text.secondary",
                    }}
                  >
                    İzin verilen *.jpeg, *.jpg, *.png
                    <br /> maksimum boyut {fData(3145728)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 2,
                  columnGap: 1,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  },
                }}
              >
                <RHFTextField size="small" name="ad" label="Ad" required />
                <RHFTextField
                  size="small"
                  name="soyad"
                  label="Soyad"
                  required
                />
                <RHFTextField
                  size="small"
                  name="tc"
                  label="T.C. Kimlik No"
                  required
                />
                <Controller
                  name="dogumTarih"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Doğum Tarihi"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <RHFTextField
                  size="small"
                  name="tel"
                  label="Telefon No"
                  required
                />
                <RHFTextField
                  size="small"
                  name="email"
                  label="E-posta Adresi"
                  required
                />
                <RHFTextField
                  size="small"
                  name="adres"
                  label="Adres"
                  required
                />
              </Box>
            </Grid>
          </Grid>
        ) : activeStep === 2 ? (
          <Stack spacing={4}>
            <Box
              sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
              }}
            >
              <RHFSelect
                name="firma"
                label="Firma"
                size="small"
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: false,
                  sx: { textTransform: "capitalize" },
                }}
              >
                <MenuItem
                  value=""
                  sx={{
                    mx: 1,
                    borderRadius: 0.75,
                    typography: "body2",
                    fontStyle: "italic",
                    color: "text.secondary",
                  }}
                >
                  None
                </MenuItem>
                <Divider />
                {companiesList.map((option, i) => (
                  <MenuItem
                    key={`${option.key}-${i}`}
                    value={option.key}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFSelect
                name="arac"
                label="Araç"
                size="small"
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: false,
                  sx: { textTransform: "capitalize" },
                }}
              >
                <MenuItem
                  value=""
                  sx={{
                    mx: 1,
                    borderRadius: 0.75,
                    typography: "body2",
                    fontStyle: "italic",
                    color: "text.secondary",
                  }}
                >
                  None
                </MenuItem>
                <Divider />
                {vehiclesList.map((option, i) => (
                  <MenuItem
                    key={`${option.key}-${i}`}
                    value={option.key}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFTextField
                size="small"
                name="ehliyetNo"
                label="Ehliyet No"
                required
              />
            </Box>
            <Stack>
              <Box mb={1}>
                <Typography variant="subtitle2" sx={{ my: 0.5 }}>
                  Yetkilendirme
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Kurye görev tanımlarını oluşturmaktadır. Özelliklere göre aynı
                  yetkiye sahip olmama durumu olabilmektedir.
                </Typography>
              </Box>
              <RHFSwitch
                name="malKabul"
                labelPlacement="end"
                label={
                  <Typography variant="body2">Paket kabul yapabilir</Typography>
                }
              />
              <RHFSwitch
                name="kuryeZimmet"
                labelPlacement="end"
                label={
                  <Typography variant="body2">
                    Kurye zimmeti yapabilir
                  </Typography>
                }
              />
              <RHFSwitch
                name="subeyeIade"
                labelPlacement="end"
                label={
                  <Typography variant="body2">
                    Şubeye iade kabul yapabilir
                  </Typography>
                }
              />
              <RHFSwitch
                name="subeKabul"
                labelPlacement="end"
                label={
                  <Typography variant="body2">Şube kabul yapabilir</Typography>
                }
              />
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Box>
              <Typography variant="subtitle2" pb={1.5}>
                Ehliyet
              </Typography>
              <Stack direction="row" spacing={1}>
                <Controller
                  name="ehliyetTarih"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Ehliyet Geçerlilik Tarihi"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <RHFUploadAvatar
                  sx={avatarStyle}
                  name="ehliyetFile"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={(e) => handleDrop(e, "ehliyetFile")}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="subtitle2" pb={1.5}>
                SRC Belgesi
              </Typography>
              <Stack direction="row" spacing={1}>
                <Controller
                  name="srcTarih"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="SRC Geçerlilik Tarihi"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <RHFUploadAvatar
                  sx={avatarStyle}
                  name="srcTarihFile"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={(e) => handleDrop(e, "srcTarihFile")}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="subtitle2" pb={1.5}>
                Psikoteknik Belgesi
              </Typography>
              <Stack direction="row" spacing={1}>
                <Controller
                  name="psiTarih"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Psikoteknik Geçerlilik Tarihi"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <RHFUploadAvatar
                  sx={avatarStyle}
                  name="psiTarihFile"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={(e) => handleDrop(e, "psiTarihFile")}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="subtitle2" pb={1.5}>
                Sabıka Kaydı Belgesi
              </Typography>
              <Stack direction="row" spacing={1}>
                <Controller
                  name="sabikaTarih"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Sabıka Geçerlilik Tarihi"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <RHFUploadAvatar
                  sx={avatarStyle}
                  name="sabikaTarihFile"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={(e) => handleDrop(e, "sabikaTarihFile")}
                />
              </Stack>
            </Box>
          </Stack>
        )}
      </Card>
      <Box width={1}>
        <Divider />
        <Stack
          pt={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            startIcon={<ChevronLeft />}
            color="inherit"
            onClick={() => {
              if (prevButtonDisable) {
                handleClose();
              } else {
                changeStep("prev");
              }
            }}
          >
            {activeStep === 1
              ? "Listeye Geri Dön"
              : `${steps[activeStep - 2]}'ye Geri Dön`}
          </Button>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button color="error" variant="outlined" onClick={handleClose}>
              İptal
            </Button>
            {activeStep === 3 ? (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {isEdit ? "Düzenle" : "Kaydet"}
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => changeStep("next")}
              >
                İleri
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}
