import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import Item from "./item";

const Distribution = ({
  loading,
  tableData,
  handleDetailItemEdit,
  handleDetailItemDelete,
}) => {
  const [filterSearch, filterSearchOnChange] = React.useState("");

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "created_at", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadData = [
    { key: "name", title: "Proje Adı", onSort },
    { key: "first_name", title: "Şirket Yetkilisi", onSort },
    { key: "county", title: "İl/İlçe", onSort },
    { key: "address", title: "Adres", onSort },
    { key: "gsm_number", title: "Telefon", onSort },
    { key: "username", title: "Bringo Sorumlusu", onSort },
    { key: "filter", title: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: [
      "name",
      "code",
      "address",
      "county",
      "first_name",
      "gsm_number",
      "username",
    ],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData = tableDataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <CustomTable
      {...{
        loading,
        // Filter
        filterHidden: true,
        filterSearch,
        filterSearchOnChange,
        filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
        // Table
        tableHeadData,
        tableHeadDataOrder: order,
        tableHeadDataOrderBy: orderBy,
        tableData,
        tableDataFiltered,
        // UseTable
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        tableBodyRender: tableBodyRenderData.map((item, index) => (
          <Item
            key={item.id}
            item={item}
            handleDetailEdit={() => handleDetailItemEdit(item.detailData)}
            handleDelete={() => handleDetailItemDelete(item.id)}
          />
        )),
      }}
    />
  );
};

export default Distribution;
