import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, TableCell, TableRow, Typography } from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { TextFirstLetter } from "utils";
import { useDispatch, useSelector } from "react-redux";

Item.propTypes = {
  item: PropTypes.object,
};

// {
//   "density": 4.411764705882353,
//   "city": "Yozgat",
//   "county": "YOZGAT MERKEZ",
//   "count": 3
// }

export default function Item({ item }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = useSelector((s) => s.dashboardFilterReducer.data);

  const newFilter = {
    ...filters,
    cities: [...filters.cities, { value: item.city, key: item.city }],
    counties: [...filters.counties, { value: item.county, key: item.county }],
  };

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer", "& td": { border: 0 } }}
      onClick={() => {
        dispatch({ type: "DASHBOARD_FILTER_SET", payload: newFilter });
        history.push({
          pathname: "/shipments-detail",
          search: qs.stringify({
            pageTitle: `${TextFirstLetter(
              `${item.city} / ${item.county}`
            )} Siparişleri`,
          }),
        });
      }}
    >
      <TableCell>{item.county}</TableCell>
      <TableCell>
        <Label
          color="primary"
          sx={{ cursor: item.count > 0 ? "pointer" : "auto" }}
        >{`${item.count} adet`}</Label>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.density.toFixed(2)}%</Typography>
        <LinearProgress
          value={item.density}
          variant="determinate"
          color="success"
        />
      </TableCell>
    </TableRow>
  );
}
