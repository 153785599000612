import TYPES from "../types";
import { Services } from "../../api/Services";
//import { loadingActive } from "helper";


const server = new Services();

export const driverList = (all) => {

  return async (dispatch) => {
    //loadingActive(dispatch, true);
    server.
      getDrivers(all).then((data) => {
        dispatch({
          type: TYPES.GET_DRIVERS_LIST_ALL,
          payload: data.entity
        });
        //loadingActive(dispatch, false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

};

export const driversVehicleList = (id) => {

  return async (dispatch) => {
    server.
      getDriversVehicle(id).then((data) => {
        dispatch({
          type: TYPES.GET_DRIVERS_VEHICLE_ALL,
          payload: data.entity
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }


};


export const deleteDriverAction = (driver_id, callback, onError) => {
  return async (dispatch) => {
    server.
      deleteDriver(driver_id).then(data => {
        //console.log(data);
        dispatch({
          type: TYPES.DELETE_DRIVER,
          payload: data.entity
        });
        callback(data.entity);
      })
      .catch(error => onError(error));
  }
};





//export const driversAdd = (dt, callback, onError) => {
export const driversAdd = (dt, toast, dialog) => {

  return async (dispatch) => {
    server.
      driverNewAdd(dt).then((data) => {
        dispatch({
          type: TYPES.DRIVERS_ADD,
          payload: data.entity
        });
        toast("success", "başarılı");
        dialog(false)
        //callback(data.entity);
      
      })
      .catch((error) => {
        // if (error.code = "E0001") {
        //   callback(error.code);
        // } else {
        //   onError(error);
        // }
        toast("error", error.message);
      });
  }

};


export const driverUpdate = (dt, callback, onError) => {

  return async (dispatch) => {
    server.
      driverUpdateRecord(dt).then((data) => {
        dispatch({
          type: TYPES.DRIVERS_UPDATE,
          payload: data.entity
        });
        callback(data.entity);
      })
      .catch((error) => {
        if (error.code = "E0000") {
          callback(error.code);
        } else {
          onError(error);
        }


      });
  }
};


export const driverStatus = (id, reason, status, callback) => {

  return async (dispatch) => {
    server.
      changeDriverStatus(id, reason, status).then((data) => {
        dispatch({
          type: TYPES.DRIVER_STATUS,
          payload: data.entity
        });
        callback(data.entity);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const getDriversOnDate = (date, callback) => {
  return async (dispatch) => {
    //loadingActive(dispatch, true);
    server.getDriversOnDate(date)
      .then(res => {
        dispatch({
          type: TYPES.GET_DRIVERS_WITH_DATE,
          payload: res.entity
        })
        callback(res.entity.length);
        //loadingActive(dispatch, false);
      })
      .catch(error => console.log(error));
  }
};


export const getDriversRole = () => {
  return async (dispatch) => {
    server.getDriversRole()
      .then(res => {
        dispatch({
          type: TYPES.GET_DRIVERS_ROLE,
          payload: res.entity
        })
        //callback(res.entity.length);
      })
      .catch(error => console.log(error));
  }
};
