import TYPES from "../types";

const initialState = [];



export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_NOTIFICATION_MESSAGES:
      return { ...state, data: action.payload }
    case TYPES.NOTIFICATION_CHECKED:
      var elementsIndex = state.data.findIndex(element => element.notification_type == action.payload.id);
      // var newMessage = state.data.filter(element => element.notification_type == action.payload.id);

      var newArray = [...state.data];
      (Object.keys(action.payload)[1] == "statusChecked")
        ? newArray[elementsIndex] = { ...newArray[elementsIndex], active: action.payload.statusChecked }
        : newArray[elementsIndex] = { ...newArray[elementsIndex], notification_message: action.payload.sms }
      return { data: newArray };
    default:
      return state
  }
};


