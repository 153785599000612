export const liveTrackingDelivery = [
    {name: 'Depoda', value: 'DELIVERY_PLANNED'},
    {name: 'Dağıtımda', value: 'ON_THE_WAY_TO_CUSTOMER'},
    //{name: 'Dağıtımda', value: 'CUSTOMER_INFORMED_1'},
    {name: 'Teslim Edildi', value: 'DELIVERED'},
    {name: 'Teslim Edilmedi', value: 'NOT_DELIVERED'},
    {name: 'Ertelenen', value: 'DELIVERY_POSTPONED'},
    {name: 'Sipariş müşteriden alınamadı', value: 'DELIVERY_CANCELLED'},
    {name: 'İade edilecek', value: 'WILL_BE_RETURN'},
    // {name: 'Şube Kabul Yapıldı', value: 'BRANCH_ACCEPTED'},
    // {name: 'Şubeye İade Kabul Yapıldı', value: 'BRANCH_RETURN_ACCEPTED'},
    // {name: 'Kuryeye zimmetlendi', value: 'COURIER_DEBIT'},
]