const TOKEN = "TOKEN";
const LISTING = "LISTING";
const USER = "USER_INFO";
const USER_LOGOUT = "USER_LOGOUT";
const CUSTOMER_ADD = "CUSTOMER_ADD";
const GET_USER_CONTACTS = "GET_USER_CONTACTS";
const GET_ADVERTISEMENT_ALL = "GET_ADVERTISEMENT_ALL";
const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
const CUSTOMER_DELETE = "CUSTOMER_DELETE";
const ADVERTISEMENT_ADD = "ADVERTISEMENT_ADD";
const ADVERTISEMENT_BETWEEN_DATE = "ADVERTISEMENT_BETWEEN_DATE";
const PUBLISH_PROJECT_UPDATE = "PUBLISH_PROJECT_UPDATE";
const PROJECT_UPDATE = "PROJECT_UPDATE";
const PROJECT_APPLICATION = "PROJECT_APPLICATION";
const PROJECT_APPLICATION_DETAIL = "PROJECT_APPLICATION_DETAIL";
const PROJECT_APPLICATION_DETAIL_REVIEW = "PROJECT_APPLICATION_DETAIL_REVIEW";
const PROJECT_APPLICATION_DETAIL_EVALUATION = "PROJECT_APPLICATION_DETAIL_EVALUATION";
const SET_TABLE_DATA = "SET_TABLE_DATA";
const GET_JOBS_LIST_ALL = "GET_JOBS_LIST_ALL";
const GET_NOTIFICATION_MESSAGES = "GET_NOTIFICATION_MESSAGES";
const NOTIFICATION_CHECKED = "NOTIFICATION_CHECKED";
const GET_DELIVERY_REPORT = "GET_DELIVERY_REPORT";
const GET_DELIVERY_REPORT_FILTER = "GET_DELIVERY_REPORT_FILTER";
const GET_PRODUCT_REPORTS = "GET_PRODUCT_REPORTS";
const GET_ORDER_UNPLANNED = "GET_ORDER_UNPLANNED";
const GET_WAREHOUSE_ALL = "GET_WAREHOUSE_ALL";
const DELETE_ORDER_UNPLANNED = "DELETE_ORDER_UNPLANNED";
const DELETE_ORDER_MULTIPLE = "DELETE_ORDER_MULTIPLE";
const ADD_ORDER_UNPLANNED = "ADD_ORDER_UNPLANNED";
const GET_WAREHOUSES = "GET_WAREHOUSES";
const GET_UNPLANNED_ORDERS = "GET_UNPLANNED_ORDERS";
const CHECK_UNPLANNED = "CHECK_UNPLANNED";
const EMPTY_CHECK_UNPLANNED = "EMPTY_CHECK_UNPLANNED";
const GET_UNGEOCODED = "GET_UNGEOCODED";
const GET_SUGGESTION = "GET_SUGGESTION";
const UPDATE_UNGEOCODED = "UPDATE_UNGEOCODED";
const UPLOAD_EXCEL = "UPLOAD_EXCEL";
const UPDATE_TABLE_ORDER = "UPDATE_TABLE_ORDER";
const GEOCODE_EDİT = "GEOCODE_EDİT";
const UPDATE_ORDER = "UPDATE_ORDER";
const GET_REPORTS = "GET_REPORTS";
const GET_REPORTS_DELIVERY = "GET_REPORTS_DELIVERY";
const UPDATE_SEND_TO_PLAN = "UPDATE_SEND_TO_PLAN";
const GET_SUBMITTED_PLANS = "GET_SUBMITTED_PLANS";
const UPDATE_REVERT_TO_PLAN = "UPDATE_REVERT_TO_PLAN";
const FILTER_EVALUATION = "FILTER_EVALUATION";
const GET_VEHICLE_LIST_ALL = "GET_VEHICLE_LIST_ALL";
const GET_VEHICLE_ZONE_LIST_ALL = "GET_VEHICLE_ZONE_ALL";
const UPLOAD_VEHICLE_DOCUMNET = "UPLOAD_VEHICLE_DOCUMNET";
const GET_VEHICLE_PROJECT_LIST_ALL = "GET_VEHICLE_PROJECT_ALL";
const DIALOG_CURRENT_DATA = "DIALOG_CURRENT_DATA";
const GET_VEHICLE_MODELS_LIST_ALL = "GET_VEHICLE_MODELS_LIST_ALL";
const GET_VEHICLE_COMPANY_LIST_ALL = "GET_VEHICLE_COMPANY_LIST_ALL";
const GET_VEHICLE_DRIVERS_LIST_ALL = "GET_VEHICLE_DRIVERS_LIST_ALL";
const VEHICLE_STATUS = "VEHICLE_STATUS";
const GET_VEHICLE_ADD = "GET_VEHICLE_ADD";
const GET_DRIVERS_LIST_ALL = "GET_DRIVERS_LIST_ALL";
const INACTIVE_DATE = "INACTIVE_DATE";
const GET_INACTIVE_DATE = "GET_INACTIVE_DATE";
const VEHICLE_UPDATE = "VEHICLE_UPDATE";
const VEHICLE_DELETE = "VEHICLE_DELETE";
const DELETE_INACTIVE_DATE = "DELETE_INACTIVE_DATE";
const GET_DRIVERS_VEHICLE_ALL = "GET_DRIVERS_VEHICLE_ALL";
const DRIVERS_ADD = "DRIVERS_ADD";
const DELETE_VEHICLE = "DELETE_VEHICLE";
const DRIVERS_INACTIVE_DATE = "DRIVERS_INACTIVE_DATE";
const GET_DRIVERS_INACTIVE_DATE = "GET_DRIVERS_INACTIVE_DATE";
const DELETE_DRIVERS_INACTIVE_DATE = "DELETE_DRIVERS_INACTIVE_DATE";
const DELETE_DRIVER = "DELETE_DRIVER";
const DRIVER_STATUS = "DRIVER_STATUS";
const DRIVERS_UPDATE = "DRIVERS_UPDATE";
const GET_DRIVERS_WITH_DATE = "GET_DRIVERS_WITH_DATE";
const GET_VEHICLES_WITH_DATE = "GET_VEHICLES_WITH_DATE";
const USER_DATA_POPUP = "USER_DATA_POPUP";
const USER_DATA_POPUP_ADD = "USER_DATA_POPUP_ADD";
const VEHICLE_USAGE_REPORT_LIST = "VEHICLE_USAGE_REPORT_LIST";
const VEHICLE_USAGE_REPORT_LIST_BETWEEN_DATE = "VEHICLE_USAGE_REPORT_LIST_BETWEEN_DATE";
const UPDATE_BARCODE_PRINTED = "UPDATE_BARCODE_PRINTED";
const PAYMENT_EXTRA_DATE = "PAYMENT_EXTRA_DATE";
const GET_PAYMENT_EXTRA_DATE = "GET_PAYMENT_EXTRA_DATE";
const UPDATE_JOB = "UPDATE_JOB";
const LIVE_DELAY_VISIT = "LIVE_DELAY_VISIT";
const LIVE_DELAY_VISIT_MULTIPLE = "LIVE_DELAY_VISIT_MULTIPLE"
const GET_DELIVERY_HISTORY = "GET_DELIVERY_HISTORY";
const LIVE_CUSTOM_FILTER = "LIVE_CUSTOM_FILTER";
const LIVE_CUSTOM_FILTER_EMPTY = "LIVE_CUSTOM_FILTER_EMPTY";
const EMPTY_UNGEOCODED = "EMPTY_UNGEOCODED";
const GET_CUSTOMER_COUNTY_USER = "GET_CUSTOMER_COUNTY_USER";
const COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER = "COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER";
const COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER_MULTIPLE = "COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER_MULTIPLE";
const LIVE_WILL_BE_RETURN = "LIVE_WILL_BE_RETURN";
const MANUEL_DELIVERY_SUCCESSFUL = "MANUEL_DELIVERY_SUCCESSFUL";
const MANUEL_DELIVERY_FAILED = "MANUEL_DELIVERY_FAILED";
const MANUEL_UPDATE_ORDER = "MANUEL_UPDATE_ORDER";
const CLEAR_UNGEOCODED = "CLEAR_UNGEOCODED";
const USER_DATA_POPUP_UPDATE = "USER_DATA_POPUP_UPDATE";
const GET_LIST_CUSTOMER_WAREHOUSE = "GET_LIST_CUSTOMER_WAREHOUSE";
const SET_CUSTOMER_FIRM_ID = "SET_CUSTOMER_FIRM_ID";
const ADD_CUSTOMER_WAREHOUSE = "ADD_CUSTOMER_WAREHOUSE";
const DELETE_CUSTOMER_WAREHOUSE = "DELETE_CUSTOMER_WAREHOUSE";
const UPDATE_CUSTOMER_WAREHOUSE = "UPDATE_CUSTOMER_WAREHOUSE";
const DELETE_LIST_SUGGESTION = "DELETE_LIST_SUGGESTION";
const GET_LIST_BRANCH = "GET_LIST_BRANCH";
const ADD_BRANCH = "ADD_BRANCH";
const DELETE_BRANCH = "DELETE_BRANCH";
const UPDATE_BRANCH = "UPDATE_BRANCH";
const GET_LIST_BRANCH_ASSIGNMENT = "GET_LIST_BRANCH_ASSIGNMENT";
const SELECTED_BRANCH = "SELECTED_BRANCH";
const SELECTED_BRANCH_OPTIONS_ID = "SELECTED_BRANCH_OPTIONS_ID";
const EMPTY_SELECTED_BRANCH_OPTIONS_ID = "EMPTY_SELECTED_BRANCH_OPTIONS_ID";
const WAREHOUSE_ASSIGMENT = "WAREHOUSE_ASSIGMENT";
const BRANCH_DRIVERS = "BRANCH_DRIVERS";
const GET_BRANCH_DRAWING = "GET_BRANCH_DRAWING";
const BRANCH_DRAWING = "BRANCH_DRAWING";
const GET_DRIVERS_ROLE = "GET_DRIVERS_ROLE";
const GET_VEHICLE_BRANCH = "GET_VEHICLE_BRANCH";
const REMOVE_BRANCH_DRAWING= "REMOVE_BRANCH_DRAWING";
const GET_ACTIVE_WAREHOUSE= "GET_ACTIVE_WAREHOUSE";
const EDIT_BRANCH_DRAWING = "EDIT_BRANCH_DRAWING";
const UPDATE_BARCODE_PRINTED_BULK = "UPDATE_BARCODE_PRINTED_BULK";
const LIVE_ASSIGNED_BRANCH_FILTER = "LIVE_ASSIGNED_BRANCH_FILTER";
const LOADING_CHANGE = "LOADING_CHANGE";

const TYPES = {
  LISTING,
  TOKEN,
  USER,
  USER_LOGOUT,
  CUSTOMER_ADD,
  GET_USER_CONTACTS,
  GET_ADVERTISEMENT_ALL,
  CUSTOMER_UPDATE,
  CUSTOMER_DELETE,
  ADVERTISEMENT_ADD,
  ADVERTISEMENT_BETWEEN_DATE,
  PUBLISH_PROJECT_UPDATE,
  PROJECT_UPDATE,
  PROJECT_APPLICATION,
  PROJECT_APPLICATION_DETAIL,
  PROJECT_APPLICATION_DETAIL_REVIEW,
  PROJECT_APPLICATION_DETAIL_EVALUATION,
  SET_TABLE_DATA,
  GET_JOBS_LIST_ALL,
  GET_NOTIFICATION_MESSAGES,
  NOTIFICATION_CHECKED,
  GET_DELIVERY_REPORT,
  GET_DELIVERY_REPORT_FILTER,
  GET_ORDER_UNPLANNED,
  GET_WAREHOUSE_ALL,
  DELETE_ORDER_UNPLANNED,
  ADD_ORDER_UNPLANNED,
  GET_WAREHOUSES,
  GET_UNPLANNED_ORDERS,
  UPLOAD_EXCEL,
  UPDATE_TABLE_ORDER,
  CHECK_UNPLANNED,
  GET_UNGEOCODED,
  GET_SUGGESTION,
  UPDATE_UNGEOCODED,
  GEOCODE_EDİT,
  UPDATE_ORDER,
  GET_REPORTS,
  GET_REPORTS_DELIVERY,
  GET_PRODUCT_REPORTS,
  UPDATE_SEND_TO_PLAN,
  GET_SUBMITTED_PLANS,
  UPDATE_REVERT_TO_PLAN,
  FILTER_EVALUATION,
  DELETE_ORDER_MULTIPLE,
  GET_VEHICLE_LIST_ALL,
  GET_VEHICLE_ZONE_LIST_ALL,
  UPLOAD_VEHICLE_DOCUMNET,
  GET_VEHICLE_PROJECT_LIST_ALL,
  DIALOG_CURRENT_DATA,
  GET_VEHICLE_MODELS_LIST_ALL,
  GET_VEHICLE_COMPANY_LIST_ALL,
  GET_VEHICLE_DRIVERS_LIST_ALL,
  VEHICLE_STATUS,
  GET_VEHICLE_ADD,
  GET_DRIVERS_LIST_ALL,
  INACTIVE_DATE,
  VEHICLE_UPDATE,
  GET_INACTIVE_DATE,
  VEHICLE_DELETE,
  DELETE_INACTIVE_DATE,
  GET_DRIVERS_VEHICLE_ALL,
  DRIVERS_ADD,
  DELETE_VEHICLE,
  DRIVERS_INACTIVE_DATE,
  GET_DRIVERS_INACTIVE_DATE,
  DELETE_DRIVERS_INACTIVE_DATE,
  DELETE_DRIVER,
  DRIVER_STATUS,
  DRIVERS_UPDATE,
  GET_DRIVERS_WITH_DATE,
  GET_VEHICLES_WITH_DATE,
  USER_DATA_POPUP,
  USER_DATA_POPUP_ADD,
  VEHICLE_USAGE_REPORT_LIST,
  VEHICLE_USAGE_REPORT_LIST_BETWEEN_DATE,
  UPDATE_BARCODE_PRINTED,
  PAYMENT_EXTRA_DATE,
  GET_PAYMENT_EXTRA_DATE,
  GET_DELIVERY_HISTORY,
  LIVE_DELAY_VISIT,
  LIVE_DELAY_VISIT_MULTIPLE,
  UPDATE_JOB,
  LIVE_CUSTOM_FILTER,
  LIVE_CUSTOM_FILTER_EMPTY,
  EMPTY_UNGEOCODED,
  GET_CUSTOMER_COUNTY_USER,
  COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER,
  COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER_MULTIPLE,
  LIVE_WILL_BE_RETURN,
  MANUEL_DELIVERY_SUCCESSFUL,
  MANUEL_DELIVERY_FAILED,
  MANUEL_UPDATE_ORDER,
  CLEAR_UNGEOCODED,
  EMPTY_CHECK_UNPLANNED,
  USER_DATA_POPUP_UPDATE,
  GET_LIST_CUSTOMER_WAREHOUSE,
  SET_CUSTOMER_FIRM_ID,
  ADD_CUSTOMER_WAREHOUSE,
  DELETE_CUSTOMER_WAREHOUSE,
  UPDATE_CUSTOMER_WAREHOUSE,
  DELETE_LIST_SUGGESTION,
  GET_LIST_BRANCH,
  ADD_BRANCH,
  DELETE_BRANCH,
  UPDATE_BRANCH,
  GET_LIST_BRANCH_ASSIGNMENT,
  SELECTED_BRANCH,
  SELECTED_BRANCH_OPTIONS_ID,
  EMPTY_SELECTED_BRANCH_OPTIONS_ID,
  WAREHOUSE_ASSIGMENT,
  BRANCH_DRIVERS,
  GET_BRANCH_DRAWING,
  BRANCH_DRAWING,
  GET_DRIVERS_ROLE,
  GET_VEHICLE_BRANCH,
  REMOVE_BRANCH_DRAWING,
  GET_ACTIVE_WAREHOUSE,
  EDIT_BRANCH_DRAWING,
  UPDATE_BARCODE_PRINTED_BULK,
  LIVE_ASSIGNED_BRANCH_FILTER,
  LOADING_CHANGE
};

export default TYPES;
