import TYPES from "../types";
import { Services } from "../../api/Services"

const getServices = new Services();


export const getProjectList = (data) => {

  //const getServices = new Services();

  return async (dispatch) => {
    getServices.
      getProjectList().then((data) => {
        dispatch({
          type: TYPES.GET_ADVERTISEMENT_ALL,
          payload: data.entity
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

};


export const publishUpdate = (data,evaluation_status,cb) => {

  //const getServices = new Services();

  let ob = {
    "application_due_date": data.application_due_date,
    "end_date": data.end_date,
    "quota": data.quota,
    "start_date": data.start_date,
    "advertisement_type": "PROJECT",
    "code": data.code,
    "customer_id": data.customer.id,
    "evaluation_status": evaluation_status,
    "id": data.id,
    "loading_points": data.loading_points,
    "requirements": data.requirements,
    "status": "ACTIVE"
  }

  return async (dispatch) => {
    getServices.
      publishProjectUpdate(ob).then((data) => {
        dispatch({
          type: TYPES.PUBLISH_PROJECT_UPDATE,
          payload: data.entity
        });
        cb();
      })
      .catch((error) => {
      });
  }

};



export const getProjectBetweenDate = (start, end) => {

  //const getServices = new Services();

  return async (dispatch) => {
    getServices.
      getProjectBetweenDate(start, end).then((data) => {
        dispatch({
          type: TYPES.ADVERTISEMENT_BETWEEN_DATE,
          payload: data.entity
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

};


export const projectAdd = (data, callback, onError) => {

  let ob = {
    //"application_due_date": data.application_due_date.getTime(),
    "application_due_date": data.application_due_date.setHours(6),
    "end_date": data.end_date.setHours(6),
    "quota": data.quota,
    "start_date": data.start_date.setHours(6),
    "advertisement_type": "PROJECT",
    "code": data.code,
    "customer_id": data.customer.id,
    "evaluation_status": "NOT_EVALUATED",
    "id": data.id,
    "loading_points": data.loading_points,
    "requirements": data.requirements,
    "status": "ACTIVE"
  };

  return async (dispatch) => {
    getServices.
      addProject(ob).then((data) => {
        dispatch({
          type: TYPES.ADVERTISEMENT_ADD,
          payload: data.entity.data[0]
        });
        callback(data.entity.success);
      })
      .catch((error) => {
        console.log(error);
        onError(error);
      });
  }

};


export const projectUpdate = (project_data, callback, onError) => {

  let updated_project_data = {
    "application_due_date": new Date(project_data.application_due_date).setHours(6),
    "end_date": new Date(project_data.end_date).setHours(6),
    "quota": project_data.quota,
    "start_date": new Date(project_data.start_date).setHours(6),
    "advertisement_type": "PROJECT",
    //"code": project_data.contact && project_data.contact.code,
    "code": project_data.code,
    //"code":  (!project_data.code) ? project_data.customer.code : project_data.contact.code,
    //"customer_id": project_data.contact && project_data.contact.id ,
    "customer_id": (!project_data.contact) ? project_data.customer.id : project_data.contact.id,
    "evaluation_status": project_data.evaluation_status,
    "id": project_data.id,
    "loading_points": project_data.loading_points,
    "requirements": project_data.requirements,
    "status": "ACTIVE"
  };

  return async dispatch => {
    getServices
             .updateProject(updated_project_data)
             .then(res => {
               dispatch({
                 type: TYPES.PROJECT_UPDATE,
                 payload: res.entity.data[0]
               });
               callback(res.entity.success);
             })
             .catch(error => onError(error))
  }
}




