import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import Item from "./item";
import Iconify from "components/Iconify";
import IstanbulMap from "react-istanbul-map";
import TableNoData from "components/CustomTable/TableNoData";

CountyList.propTypes = {
  data: PropTypes.array,
};

export default function CountyList({ data }) {
  // Table Head Data
  const tableHeadData = [
    { key: "ilce", title: "İlçe Semt" },
    { key: "gonderiSayisi", title: "Sipariş Sayısı" },
    { key: "yogunluk", title: "Yoğunluk" },
  ];
  const { palette } = useTheme();

  const [showAll, setShowAll] = React.useState(false);

  const dataToShow = showAll ? data : data.slice(0, 5);

  return (
    <Card>
      <CardHeader title="İlçe & Semt Dağılımı" sx={{ mb: 3 }} />
      <IstanbulMap
        mapWidth={"100%"}
        mapHeight={"100%"}
        viewBox={"70 30 80 45"}
        mapColor={palette.grey[400]}
        hoverColor={palette.success.main}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadData.map((t) => (
                <TableCell key={t.key}>{t.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.length > 0 ? (
              dataToShow.map((row, index) => <Item key={index} item={row} />)
            ) : (
              <TableNoData isNotFound colSpan={3} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {data.length > 5 && (
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="inherit"
            endIcon={
              showAll ? (
                <Iconify icon="bx:bx-chevron-down" />
              ) : (
                <Iconify icon="bx:bx-chevron-right" />
              )
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Daha az göster" : "Tümünü göster"}
          </Button>
        </Box>
      )}
    </Card>
  );
}
