import TYPES from '../types';

const initialState = {
    vehicle_doc: '',
};

export const uploadFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.UPLOAD_VEHICLE_DOCUMNET:
            return {...state, vehicle_doc: action.payload}; 
        default:
            return state;
    }
}