import React, { useState, useEffect } from 'react'
import { manualDeliverySuccessful, manualDeliveryFailed } from "../../store/actions/liveTrackingAction";
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from 'primereact/checkbox';


const LiveTrackingChangeDeliveryStatus = (props) => {

    const { product, setProductDialog, toastCallback, willBeReturnClick } = props;

    let emptyDeliveryStatus = {
        delivered: '',
        notDelivered: '',
        postponedDate: '',
        smsChecked: ''
    };

    const dispatch = useDispatch();
    const [deliveryInformation, setDeliveryInformation] = useState(emptyDeliveryStatus);
    const [ddLiveTrackingDeliveryStatus, setDdLiveTrackingDeliveryStatus] = useState(null);
    const [checked, setChecked] = useState(false);
    const [smsChecked, setSmsChecked] = useState(false);



    // if checkboxes change, update state

    useEffect(() => {
        checked == false && setDeliveryInformation({ ...deliveryInformation, postponedDate: '' })
    }, [checked])

    useEffect(() => {
        setDeliveryInformation({ ...deliveryInformation, smsChecked: smsChecked })
    }, [smsChecked])



    useEffect(() => {
        setDeliveryInformation(emptyDeliveryStatus)
    }, [ddLiveTrackingDeliveryStatus])

    const dDLiveTrackingItems = [
        { label: 'Teslim Edildi', value: 'delivered' },
        { label: 'Teslim Edilemedi', value: 'notDelivered' },
        { label: 'İade Et', value: 'return' },
    ];

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _deliveryInformation = { ...deliveryInformation };
        _deliveryInformation[`${name}`] = val;

        setDeliveryInformation(_deliveryInformation);
    }


    const saveLiveDeliveryStatus = () => {
        // console.log("a1: ", ddLiveTrackingDeliveryStatus); delivered
        // console.log("a2: ", deliveryInformation);   {delivered: 'sxsxsx', notDelivered: '', postponedDate: '', smsChecked: ''}
        if (!ddLiveTrackingDeliveryStatus) {
            toastCallback("warn", "Teslim durumu değiştirmek için gerekli alanla dolu değil");
            return
        }

        if (ddLiveTrackingDeliveryStatus == "delivered") {

            if (!deliveryInformation.delivered) {
                toastCallback("warn", "Teslim durumu değiştirmek için gerekli alanla dolu değil");
                return
            }

            //console.log("AA: ", product);
            //console.log("BB: ", deliveryInformation);

            let obj = {
                orderId: product.order_oid,
                recipientName: deliveryInformation.delivered,
                deliveryCode: product.order_code,
                notificationEnabled: deliveryInformation.smsChecked == "" ? false : true
            }

            // console.log("CC:", obj);
            // orderId: product.order_oid
            // recipientName: deliveryInformation.delivered
            // deliveryCode : product.order_code
            // notificationEnabled:deliveryInformation.smsChecked

            dispatch(manualDeliverySuccessful(obj, setProductDialog, toastCallback))

        } else if (ddLiveTrackingDeliveryStatus == "return") {
            //alert("iade et");
            willBeReturnClick(product); //taken from the parent component (table/index.js), return the product fucn.

        } else {

            if (!deliveryInformation.notDelivered) {
                toastCallback("warn", "Teslim durumu değiştirmek için gerekli alanla dolu değil");
                return
            }

            let obj = {
                orderId: product.order_oid,
                reasonText: deliveryInformation.notDelivered,
                orderNewDate: deliveryInformation.postponedDate,
                notificationEnabled: deliveryInformation.smsChecked == "" ? false : true
                //deliveryCode: product.order_code,
            }

            dispatch(manualDeliveryFailed(obj, setProductDialog, toastCallback))
        }
    }




    /** Css */
    const notDeliveredContent = {
        "display": "flex",
        "marginTop": "10px"
    }

    const notDeliveredContentCheckbox = {
        "marginRight": "10px"
    }

    const notDeliveredContentCalendar = {
        "width": "300px",
        "marginTop": "10px"
    }

    const deliveryStatusChangeSaveButtonContent = {
        "display": "flex",
        "justifyContent": "space-between"
    }

    const deliveryStatusChangeSaveButton = {
        "width": "250px"
    }

    const deliveryStatusChangeSmsContent = {
        "paddingTop": "6px"
    }

    const deliveryStatusChangeSmsText = {
        "marginRight": "10px"
    }

    return (
        <div>
            <p> Teslimat durumu değiştirme </p>
            <Dropdown value={ddLiveTrackingDeliveryStatus} options={dDLiveTrackingItems} onChange={(e) => setDdLiveTrackingDeliveryStatus(e.value)} style={{ "marginBottom": "15px" }} />

            {ddLiveTrackingDeliveryStatus && <>
                {ddLiveTrackingDeliveryStatus == "delivered" ? <div>
                    <p>Teslim Alan Kişi</p>
                    <InputText value={deliveryInformation.delivered} onChange={(e) => onInputChange(e, 'delivered')} />
                </div>
                    :
                    ddLiveTrackingDeliveryStatus == "notDelivered" ? <div>
                        <p>Teslim Edilememe Sebebi</p>
                        <InputText value={deliveryInformation.notDelivered} onChange={(e) => onInputChange(e, 'notDelivered')} />
                        <div style={notDeliveredContent}>
                            <Checkbox style={notDeliveredContentCheckbox} checked={checked} onChange={e => setChecked(e.checked)} />
                            <p>Ziyareti Ertelemek istiyor musunuz?</p>
                        </div>
                        {checked == true && <div>
                            <Calendar style={notDeliveredContentCalendar} onChange={(e) => onInputChange(e, "postponedDate")} />
                        </div>}
                    </div>
                        :
                        <p style={{ "color": "red", "fontWeight": "400" }}>Siparişin durumu iade statüsüne dönüştürülecek!</p>
                }
            </>}

            <div className="paymentContent" style={deliveryStatusChangeSaveButtonContent}>
                <div>
                    <Button label="Kaydet" onClick={() => saveLiveDeliveryStatus()} style={deliveryStatusChangeSaveButton} />
                </div>
                <div style={deliveryStatusChangeSmsContent}>
                    <span style={deliveryStatusChangeSmsText}>Sms gönder</span>
                    <Checkbox onChange={e => setSmsChecked(e.checked)} checked={smsChecked} />
                </div>
            </div>

        </div>
    )
}

export default LiveTrackingChangeDeliveryStatus
