import TYPES from "../types";

const initialState = {};

export const projectApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PROJECT_APPLICATION:
      return { ...state, data: action.payload.data }
    default:
      return state
  }
};
