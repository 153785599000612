import React from "react";
import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import Projects from "./Projects";
import ProjectRanking from "./ProjectRanking";
import VehicleTypes from "./VehicleTypes";
import VehicleStatics from "./VehicleStatics";
import BookingWidgetSummary from "components/BookingWidgetSummary";
import AppCurrentDownload from "screens/Analytics/StaticsChart";
import qs from "query-string";

const keys = {
  total_package: {
    title: "Toplam Paket Sayısı",
    color: "violet",
    img: "/images/customerDashboard/toplam-paket.svg",
  },
  delivered: {
    title: "Teslim edilen paket",
    color: "blue",
    img: "/images/customerDashboard/teslim-edilen.svg",
  },
  not_delivered: {
    title: "Teslim edilmeyen paket",
    color: "green",
    img: "/images/customerDashboard/teslim-edilmeyen.svg",
  },
  on_the_vehicle: {
    title: "Araçta paket",
    color: "yellow",
    img: "/images/customerDashboard/aracta.svg",
  },
};

const AnalyticalNew = ({ data, statsData }) => {
  const chartData = [
    {
      name: "Teslim edilen",
      value: data?.delivered_count,
    },
    {
      name: "Teslim edilmeyen",
      value: data?.not_delivered_count,
    },
    {
      name: "Araçta",
      value: data?.on_board_count,
    },
  ];
  const statistics = statsData.metrics;
  const vehicleTypes = statsData.vehicle_type_counts;

  const chartDataValues = chartData.map((item) => Number(item.value));
  const chartDataNames = chartData.map((item) => item.name);

  const history = useHistory();

  const projects = data.projects.map((item) => {
    return {
      id: item.project_id,
      name: item.project_name,
      value: item.package_count,
      order_count: item.order_count,
      deci: item.totaldeci,
      customer_logo: item.customer_logo,
    };
  });

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Stack spacing={3}>
            <AppCurrentDownload
              data={chartDataValues}
              labels={chartDataNames}
              title="Paket Durum Grafiği"
            />
            <VehicleTypes data={vehicleTypes} />
            <VehicleStatics data={statistics} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Stack spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <BookingWidgetSummary
                  title={keys.total_package.title}
                  total={data.package_count}
                  color={keys.total_package.color}
                  icon={
                    <img
                      src={keys.total_package.img}
                      alt="total_package"
                      width="100%"
                    />
                  }
                  onClick={() => {
                    history.push({
                      pathname: "/shipments-detail",
                      search: qs.stringify({ pageTitle: "Toplam Sipariş" }),
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <BookingWidgetSummary
                  title={keys.delivered.title}
                  total={data.delivered_count}
                  color={keys.delivered.color}
                  icon={
                    <img
                      src={keys.delivered.img}
                      alt="delivered"
                      width="100%"
                    />
                  }
                  onClick={() => {
                    history.push({
                      pathname: "/shipments-detail",
                      search: qs.stringify({
                        status: "DELIVERED",
                        pageTitle: "Teslim Edilen Siparişler",
                      }),
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <BookingWidgetSummary
                  title={keys.not_delivered.title}
                  total={data.not_delivered_count}
                  color={keys.not_delivered.color}
                  icon={
                    <img
                      src={keys.not_delivered.img}
                      alt="not_delivered"
                      width="100%"
                    />
                  }
                  onClick={() => {
                    history.push({
                      pathname: "/shipments-detail",
                      search: qs.stringify({
                        status: "NOT_DELIVERED",
                        pageTitle: "Teslim Edilmeyen Siparişler",
                      }),
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <BookingWidgetSummary
                  title={keys.on_the_vehicle.title}
                  total={data.on_board_count}
                  color={keys.on_the_vehicle.color}
                  icon={
                    <img
                      src={keys.on_the_vehicle.img}
                      alt="on_the_vehicle"
                      width="100%"
                    />
                  }
                  onClick={() => {
                    history.push({
                      pathname: "/shipments-detail",
                      search: qs.stringify({
                        status: "ON_BOARD",
                        pageTitle: "Araçtaki Siparişler",
                      }),
                    });
                  }}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Projects data={projects} />
            <ProjectRanking data={projects} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticalNew;
