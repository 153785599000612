import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Detail({ handleClose, isEdit, children }) {
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="md">
      <BootstrapDialogTitle onClose={handleClose}>
        Araç {isEdit ? "Düzenle" : "Oluştur"}
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
