import TYPES from "../types";

const initialState = {
  customerUpdated: "",
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LISTING:
      return { ...state, data: action.payload.data };
    case TYPES.CUSTOMER_ADD:
      return { ...state, data: [...state.data, action.payload] };
    case TYPES.GET_USER_CONTACTS:
      return { ...state, user_contant: action.payload };
    case TYPES.CUSTOMER_UPDATE:
      var customerStoredId = action.payload.id;
      return { ...state, [customerStoredId]: {
                                 ...state[customerStoredId],
                                 data: action.payload,
                            },
      };
    case TYPES.CUSTOMER_DELETE:
      // if(typeof action === 'string') console.log(state.data, action.payload, JSON.parse(action), action.payload);
      // console.log(action.toString());
      // console.log(typeof action);
      // var act = JSON.stringify(action);
      // var res = [];
      // res.push(action);
      // console.log(res);
      // res.forEach(item => console.log(item.paylaod))
      // action.map(obj => console.log(obj.toJSON()));
      // if(Object.keys(action).length <= 2) console.log(action.payload);
      let result = JSON.parse(JSON.stringify(action));
      return {...state, data: state.data.filter(customer => customer.id !=  result.paylaod.data[0].id) }
    default:
      return state;
  }
};
