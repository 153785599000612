import React from "react";
import PropTypes from "prop-types";
import sumBy from "lodash/sumBy";
import { Box, Stack, Divider, useTheme } from "@mui/material";
import InvoiceAnalytic from "./InvoiceAnalytic";

const style = {
  mb: 5,
  overflowX: "auto",
  overflowY: "hidden",
  borderRadius: "16px",
  boxShadow: "0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)",
};

const allTotal = (tableData, tabs) => {
  return tableData.reduce((total, item) => {
    if (item.value != "all") {
      return (
        total +
        tabs.reduce((tabTotal, tab) => {
          if (tab.value != "all") return tabTotal + item[tab.value];
          return tabTotal;
        }, 0)
      );
    }
    return total;
  }, 0);
};


const allTotal2 = (tableData) => {
  return tableData.map(a => a.toplam).reduce((partialSum, a) => partialSum + a, 0);
};

const AnalyticTools = (props) => {
  const { tableData, tabs } = props;
  const theme = useTheme();

  const getIconColor = (index, color) => {
    const getItem = {
      0: {
        icon: "ic:round-receipt",
        color: theme.palette[color].main,
      },
      1: {
        icon: "fluent:calendar-cancel-20-filled",
        color: theme.palette[color].main,
      },
      2: {
        icon: "entypo:shop",
        color: theme.palette[color].main,
      },
      3: {
        icon: "fa-solid:truck",
        color: theme.palette[color].main,
      },
    };
    return getItem[index];
  };

  return (
    <Box sx={style}>
      <Stack
        direction="row"
        divider={
          <Divider orientation="vertical" flexItem sx={{ borderStyle: "dashed" }} />
        }
        sx={{ py: 2 }}
      >
        {tabs.map((tab, i) => {
          const all = tab.value == "all";
          //const total = all ? allTotal(tableData, tabs) : sumBy(tableData, tab.value);
          const total = all ? allTotal2(tableData) : sumBy(tableData, tab.value);
          // Get Icon Color
          const { icon, color } = getIconColor(i, tab.color);
          return (
            <InvoiceAnalytic
              key={`analytic-${i}`}
              title={tab.label}
              percent={(total * 100) / allTotal(tableData, tabs)}
              total={total}
              icon={icon}
              color={color}
              routeKey={tab.key}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

AnalyticTools.propTypes = {
  tabs: PropTypes.array,
  tableData: PropTypes.array,
};

export default AnalyticTools;
