import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import React from "react";

const Thumb = (props) => {
  const { file, src, variant = "normal" } = props;

  const matchWidthBigScreen = useMediaQuery("(min-width:900px)");
  if (!file || !src) {
    return null;
  }

  if (src.readyState < 2) {
    return <CircularProgress size={24} />;
    //    <p>Loading ... </p>;
  }

  return (
    <Box
      component="img"
      src={src.result}
      alt={file.name}
      style={{
        width: "100%",
        height: "100%",
        borderRadius: 50,
        objectFit: "cover",
      }}
    />
  );
};

export default Thumb;
