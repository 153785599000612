import TYPES from "../types";

const initialState = {
    data: "",
    selectedBranch: null,
    assigmnetId: null
};

export const branchAssignmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_LIST_BRANCH_ASSIGNMENT:
            //group By
            // let branchs = action.payload.data.reduce((r, a) => {
            //     r[a.firm_id.name] = [...r[a.firm_id.name] || [], a];
            //     return r;
            // }, {});
            //console.log(action.payload)
            return { ...state, data: action.payload.data };
        case TYPES.SELECTED_BRANCH:
            return { ...state, selectedBranch: action.payload };
        case TYPES.SELECTED_BRANCH_OPTIONS_ID:
            let ids = action.payload.data.map(d => d.id)
            return { ...state, assigmnetId: ids };
        case TYPES.EMPTY_SELECTED_BRANCH_OPTIONS_ID:
            return { ...state, assigmnetId: action.payload };
        default:
            return state
    }
};

