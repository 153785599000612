import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField } from "@mui/material";

InputField.propTypes = {
  label: PropTypes.string,
};

export default function InputField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <TextField
      label={label}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}
