import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { getDispatcherLink } from "../../store/actions/dispatcherLiveUrl";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";
import { Box } from "@mui/material";

function DispatcherLive(props) {
  const history = useHistory();
  const [dispatcherUrl, setDispatcherUrl] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDispatcherLink(setDispatcherUrl));
  }, []);

  const iframeContentStyle = {
    // position: "fixed",
    // background: "#000",
    // border: "none",
    // top: 100, right: 0,
    // bottom: 0, left: 0,
    // width: "100%",
    // height: "100%"
    //height: 'calc(100vh - 180px)'
  };

  const iframeContentStyle1 = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    //background: "red",
    paddingTop: "150px",
  };

  const iframeContentHead = {
    position: "absolute",
    top: "95px",
    marginLeft: "27px",
  };

  const iframeContentBackIcon = {
    position: "absolute",
    top: "95px",
    background: "#ea7972",
    borderRadius: "20px",
    color: "white",
    cursor: "Pointer",
    paddingTop: "7px",
    paddingLeft: "7px",
    width: "38px",
    zIndex: "999999999",
  };

  return (
    <Layout
      {...props}
      heading="Harita Canlı takip"
      links={[{ name: "Genel", href: "/" }, { name: "Harita Canlı takip" }]}
    >
      <Box sx={{ height: "100vh", borderRadius: 1, overflow: "hidden" }}>
        {/* <p style={iframeContentHead}> Geri</p> */}
        <iframe
          src={dispatcherUrl}
          title="Dispatcher Live"
          height="100%"
          //height="450px"
          width="100%"
          // style={iframeContentStyle1}
          //style={{ "overflow": "hidden", "overflowX": "hidden", "overflowY": "hidden", "height": "100%", "width": "100%", "position": "absolute", "top": "100px", "left": "0px", "right": "0px", "bottom": "0px" }}
        ></iframe>
      </Box>
    </Layout>
  );
}

export default DispatcherLive;
