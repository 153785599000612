import TYPES from "../types";

const initialState = {};

export const orderReducer = (state = initialState, action) => {
    //console.log(state);
    switch (action.type) {
        case TYPES.GET_ORDER_UNPLANNED:
            return { ...state, data: action.payload.data }
        //return { ...state, data: action.payload.data || action.payload }
        case TYPES.ADD_ORDER_UNPLANNED:
            return { ...state, data: [...state.data, action.payload.data[0]] }
        case TYPES.DELETE_ORDER_UNPLANNED:
            return { ...state, data: state.data.filter(item => item.id !== action.payload.data[0].id) }
        case TYPES.DELETE_ORDER_MULTIPLE:
            //return { ...state, data: state.data.filter(item => item.id !== action.payload.data[0].id) }
            /**
             * AllListData= state.data, action.payload.data = Deleted records
             * Remove deleted records from table
             */
            return {
                ...state, data: state.data.filter(AllListData => {
                    return action.payload.data.every(deleteDt => AllListData.id != deleteDt.id);
                })
            }
        case TYPES.GEOCODE_EDİT:
            console.log(action.payload)
            state.data.filter((d, i) => {
                if (d.address.id === action.payload.data[0].id) {
                    //d.address.address = action.payload.newAddress;
                    d.address.latitude =
                        action.payload.data[0].latitude;
                    d.address.longitude =
                        action.payload.data[0].longitude;
                    d.address.geocode_score =
                        action.payload.data[0].geocode_score
                }
            });
            return state
        case TYPES.UPDATE_ORDER:
            //return { data: state.data.map(order => order.customer.id == action.payload.customer.id && order.order_code == action.payload.order_code ? action.payload : order) }
            return { data: state.data.map(order => order.customer.id == action.payload.customer.id || order.order_code == action.payload.order_code ? action.payload : order) }
        case TYPES.UPDATE_BARCODE_PRINTED:
            return { data: state.data.map(order => order.id == action.payload.data[0].id ? action.payload.data[0] : order) }
        case TYPES.UPDATE_BARCODE_PRINTED_BULK:
            //return { data: state.data.map(order => order.id == action.payload.data[0].id ? action.payload.data[0] : order) }
            for (let i = 0; i < state.data.length; i++) {
                for (let j = 0; j < action.payload.data.length; j++) {
                    if(state.data[i].id == action.payload.data[j].id){ state.data[i].barcode_printed = true}
                } 
             }
             return { data: state.data }
        case TYPES.UPDATE_SEND_TO_PLAN:
            //return { ...state, data: state.data.filter(item => item.id !== action.payload.data[0].id) }
            let sendResult = state.data.filter((o1, i) =>
                action.payload.data.every((o2) => {
                    if (o1.id !== o2.id) { return o2.id }
                })
            );
            return { ...state, data: sendResult }
        case TYPES.GET_SUBMITTED_PLANS:
            return { ...state, data: action.payload.data }
        case TYPES.UPDATE_REVERT_TO_PLAN:
            //return { ...state, data: state.data.filter(item => item.id !== action.payload.data[0].id) }
            let revertResult = state.data.filter((o1, i) =>
                action.payload.data.every((o2) => {
                    if (o1.id !== o2.id) { return o2.id }
                })
            );
            return { ...state, data: revertResult }
        default:
            return state
    }
};

