import TYPES from "../types";
import { Services } from "../../api/Services";

const server = new Services();
export const getWarehouses = () => {
  return async (dispatch) => {
    server
      .getWarehouses()
      .then((data) => {
        dispatch({
          type: TYPES.GET_WAREHOUSES,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
