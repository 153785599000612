import isString from "lodash/isString";
import { useDropzone } from "react-dropzone";
// @mui
import { styled } from "@mui/material/styles";

import UploadIllustration from "./illustration_upload";
import { Box } from "@mui/material";
// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  flex: 1,
  display: "flex",
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      multiple: false,
      ...other,
    });

  return (
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        ...((isDragReject || error) && {
          color: "error.main",
          borderColor: "error.light",
          bgcolor: "error.lighter",
        }),
      }}
    >
      <input {...getInputProps()} />

      <UploadIllustration
        sx={{
          width: 1,
          height: 1,
          m: "auto",
        }}
      />

      {file && (
        <Box
          component="img"
          alt="file preview"
          src={isString(file) ? file : file.preview}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "90%",
            height: "90%",
            borderRadius: 1,
            transform: "translateY(-50%) translateX(-50%)",
            objectFit: "cover",
            zIndex: 2,
          }}
        />
      )}
    </DropZoneStyle>
  );
}
