// hooks
import { useSelector } from "react-redux";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const userData = useSelector((userData) => userData.userReducer);

  const user = userData.user;

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.first_name}
      color={user?.photoURL ? "default" : createAvatar(user?.first_name).color}
      {...other}
    >
      {createAvatar(user?.first_name).name}
    </Avatar>
  );
}
