/* eslint-disable */
const prod = "https://bringo-p.geovisiongroup.com";
//const prod = "https://api.heybringo.com";

//const env = prod;
const env = prod;

export const BASE_URL =`${env}/`;

/* eslint-disable */
