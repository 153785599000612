import TYPES from "../types";
import { Services } from "../../api/Services";

const server = new Services();

export const getCustomerCountyUser = (customer_id) => {
  return async (dispatch) => {
    server
      .getCustomerCountyUserDebit(customer_id)
      .then((data) => {
        dispatch({
          type: TYPES.GET_CUSTOMER_COUNTY_USER,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  };
};



export const postCustomerCountyUser = (data, cb) => {
    let dbtChangeData = data;
    return async (dispatch) => {
      server
        .postCustomerCountyUserDebit(data)
        .then((data) => {
          dispatch({
            type: TYPES.COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER,
            payload: dbtChangeData,
          });
          cb("success")
        })
        .catch((error) => {
          console.log(error);
          //onError();
        });
    };
  };

  export const postCustomerCountyUserMultiple = (data, cb , hideDialog) => {
    let dbtChangeDataMultiple = data;
    return async (dispatch) => {
      server
        .postCustomerCountyUserDebit(data)
        .then((data) => {
          dispatch({
            type: TYPES.COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER_MULTIPLE,
            payload: dbtChangeDataMultiple,
          });
          cb("success");
          hideDialog(false)
        })
        .catch((error) => {
          console.log(error);
          //onError();
        });
    };
  };
  
 