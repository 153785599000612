import TYPES from "../types";
import { Services } from "../../api/Services"

const getServices = new Services();




export const userAdd = (data, callback, hideMdl, onError) => {


    let ob = {
        "email": data.userData.email,
        "firm": data.product.id,
        "first_name": data.userData.name,
        "id": null,
        "last_name": data.userData.lastName,
        "password": data.userData.password,
        "phone": data.userData.phone,
        "username": data.userData.username
    }



    return async (dispatch) => {
        getServices.
            addCustomerPopup(ob).then((data) => {
                console.log(data)
                dispatch({
                    type: TYPES.USER_DATA_POPUP_ADD,
                    payload: data.entity.data[0]
                });

                hideMdl(false);
                callback("success");
            })
            .catch((error) => {
                console.log(error);
                //onError(error);
                error.message == "Oluşturmak istediğiniz kullanıcı zaten içeride tanımlıdır." ? callback("warn", error.message) : callback("error", error.message);
                //callback("error", error.message);
            });
    }

};




export const userEdit = (data, callback, hideMdl, onError) => {


    let ob = {
        "email": data.userData.email,
        "firm": data.product.id,
        "first_name": data.userData.name,
        "id": data.userData.id,
        "last_name": data.userData.lastName,
        //"password": data.userData.password,
        "phone": data.userData.phone,
        "username": data.userData.username
    }



    if (data.userData.password) { 
        ob.password = data.userData.password
    }

    return async (dispatch) => {
        getServices.
            addCustomerPopup(ob).then((data) => {
                //console.log(data)
                dispatch({
                    type: TYPES.USER_DATA_POPUP_UPDATE,
                    payload: data.entity.data[0]
                });

                hideMdl(false);
                callback("success");
            })
            .catch((error) => {
                console.log(error);
                onError(error);
            });
    }

};


export const getUserList = (data) => {

    let firmID = data.id

    return async (dispatch) => {
        getServices.
            getCustomerPopup(firmID).then((data) => {
                dispatch({
                    type: TYPES.USER_DATA_POPUP,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

};