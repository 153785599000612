import TYPES from "../types";
import { Services } from "../../api/Services";
import { getOrderUnplanned as refleshUnplannedTable } from "../../store/actions/orderAction";
import { BASE_URL } from "../../api/Env";
import { loadingActive } from "helper";
const server = new Services();

export const getOrderUnplanned = (data) => {
  //const getServices = new Services();

  return async (dispatch) => {
    server
      .getOrderUnplannedList()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ORDER_UNPLANNED,
          //payload: data.entity
          //payload: { data: data.entity.data.filter(d => d.address.geocode_score >= 100) }
          payload: {
            data: data.entity.data.filter((d) =>
              d.debit_type == null ? (d.debit_type = "-") : d.debit_type
            ),
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const adminGetOrderUnplanned = (data) => {
  //const getServices = new Services();

  return async (dispatch) => {
    loadingActive(dispatch, true);
    server
      .adminGetOrderUnplannedList()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ORDER_UNPLANNED,
          payload: data.entity,
        });
        loadingActive(dispatch, false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const print_barcode = (id) => {
  //const getServices = new Services();

  return async (dispatch) => {
    server
      .post_print_barcode(id)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_BARCODE_PRINTED,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const print_barcode_bulk = (ids, parID) => {
  let barcodeID = parID;
  //const getServices = new Services();

  return async (dispatch) => {
    server
      .post_print_barcode_bulk(ids, parID)
      .then((data) => {
        window.open(`${BASE_URL}qrcode/?bulkId=${barcodeID}`);
        dispatch({
          type: TYPES.UPDATE_BARCODE_PRINTED_BULK,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getUnplannedByDate = (data, cb, closeDialog) => {
  //const getServices = new Services();
  // var cc = new Date(data[0].value)
  // cc.setDate(cc.getDate() + 1);
  // var newDt = new Date(cc).toISOString().replace(/T.*/, '').split('-').join('-');
  var newDt = data.map((x) => x.value.split("T")[0]).toString();
  return async (dispatch) => {
    server
      .getUnplannedByDeliveryDate(newDt)
      .then((data) => {
        dispatch({
          type: TYPES.GET_ORDER_UNPLANNED,
          payload: data.entity,
        });
        cb("success");
        closeDialog(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getUnplannedByDateGeocode = (data) => {
  //const getServices = new Services();
  var newDt;
  if (data.value) {
    var cc = new Date(data.value);
    cc.setDate(cc.getDate() + 1);
    newDt = new Date(cc).toISOString().replace(/T.*/, "").split("-").join("-");
  } else {
    newDt = data.map((x) => x.value.split("T")[0]).toString();
  }
  // var cc = new Date(data.value)
  // cc.setDate(cc.getDate() + 1);
  // var newDt = new Date(cc).toISOString().replace(/T.*/, '').split('-').join('-');
  //var newDt=data.value ? data.value : data.map(x => x.value.split("T")[0]).toString();

  return async (dispatch) => {
    server
      .getUnplannedByDeliveryDateGeocode(newDt)
      .then((data) => {
        dispatch({
          type: TYPES.GET_UNGEOCODED,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getGeocodeSuggestion = (keyword) => {
  return async (dispatch) => {
    server
      .getGeocodeSuggestionList(keyword)
      .then((data) => {
        dispatch({
          type: TYPES.GET_SUGGESTION,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const geocodeCoordinateSave = (data, closeDialog, toastMes, type) => {
  return async (dispatch) => {
    server
      .geocodeCoordinate(data)
      .then((data) => {
        toastMes("success", "koordinatlandı");
        closeDialog(false);
        if (type == "admin-order-management") {
          dispatch(getAdminOrderUnplanned()); // performance issue!!
        } else {
          dispatch(refleshUnplannedTable()); // performance issue!!
        }
      })
      .catch((error) => {
        console.log(error);
        toastMes("error");
      });
  };
};

export const deleteOrderUnplanned = (data, cb) => {
  let ordercode = data.order_code;
  let warehousecode = data.warehouse.code;

  return async (dispatch) => {
    server
      .deleteOrderUnplanned(warehousecode, ordercode)
      .then((data) => {
        dispatch({
          type: TYPES.DELETE_ORDER_UNPLANNED,
          payload: data.entity,
        });
        cb("success", "Silindi");
      })
      .catch((error) => {
        console.log("erasrasrasr", error);
        alert("Hata");
      });
  };
};

export const orderMultipleDelete = (oids, cb, setSelectedProducts) => {
  return async (dispatch) => {
    server
      .orderMultipleDeleteServices(oids)
      .then((data) => {
        setSelectedProducts(null);
        dispatch({
          type: TYPES.DELETE_ORDER_MULTIPLE,
          payload: data.entity,
        });
        cb("success", "Silindi");
      })
      .catch((error) => {
        alert("Hata");
      });
  };
};

export const manuelOrderAdd = (data, callback, onError) => {
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(".");
  }

  let ob = {
    address: data.address || "",
    addressCode: "",
    barcode: data.barcode || "",
    city: data.city || "",
    county: "",
    deci: data.deci || "",
    deliveryDate: convertDate(data.delivery_date) || "",
    description: data.delivery_note || "",
    email: data.email || "",
    latitude: data.latitude || "",
    longitude: data.longitude || "",
    name: data.first_name || "",
    orderCode: data.order_code || "",
    orderDate: convertDate(data.order_date) || "",
    outletCode: data.outlet_code || "",
    phoneNumber: data.gsm_number || "",
    quantity: data.quantity || "",
    sender: data.send_first_name || "",
    smsNotification: data.sms_notification || "",
    warehouseOid: data.customer.id || "",
  };
  return async (dispatch) => {
    server
      .addOrderManuel(ob)
      .then((data) => {
        dispatch({
          type: TYPES.ADD_ORDER_UNPLANNED,
          payload: data.entity,
        });
        callback(data.entity.success);
      })
      .catch((error) => {
        onError(error);
      });
  };
};

export const updateOrder = (data, callback, onError) => {
  function convertDate(par) {
    // function pad(s) { return (s < 10) ? '0' + s : s; }
    // var d = new Date(inputFormat)
    // return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.')
    var d = new Date(par);
    var a = d.setHours(d.getHours() + d.getTimezoneOffset() / -60);
    var b = new Date(a).toISOString();
    return b;
  }

  let new_data = {
    address:
      typeof data.address == "object" ? data.address.address : data.address,
    addressCode: "",
    branchOid: data.assigned_branch
      ? (data.assigned_branch.id && data.assigned_branch.id) ||
        data.assigned_branch
      : null,
    barcode: data.barcode,
    city: data.city ? data.city : data.address.city,
    county: data.county ? data.county : data.address.county,
    deci: data.deci,
    deliveryDate: new Date(convertDate(data.delivery_date)).toISOString(),
    description: data.delivery_note
      ? data.delivery_note
      : data.customer.delivery_note,
    email: data.email ? data.email : data.customer.email,
    latitude: data.latitude ? data.latitude : data.address.latitude,
    longitude: data.longitude ? data.longitude : data.address.longitude,
    name: data.first_name ? data.first_name : data.customer.first_name,
    //lastname:
    orderCode: data.order_code,
    orderDate: new Date(convertDate(data.order_date)).toISOString(),
    outletCode: data.outlet_code ? data.outlet_code : data.customer.code,
    phoneNumber: data.gsm_number ? data.gsm_number : data.address.gsm_number,
    quantity: data.quantity ? data.quantity : data.products[0].quantity,
    sender: data.send_first_name ? data.send_first_name : data.taken_by,
    smsNotification: data.sms_notification,
    created_date: data.created_date,
    created_user: data.created_user,
    warehouseOid: data.warehouse.id,
  };

  return async (dispatch) => {
    server
      .updateOrder(new_data)
      .then((updated_data) => {
        dispatch({
          type: TYPES.UPDATE_ORDER,
          payload: updated_data.entity.data[0],
        });
        callback(updated_data.entity.data[0], updated_data.entity.success);
      })
      .catch((error) => {
        onError(error);
      });
  };
};

export const geocodeEditSave = (data, cb, closePopup, row) => {
  let ob = [
    {
      address_id: data.selected.address.id,
      latitude: data.newCoodinate.latitude,
      longitude: data.newCoodinate.longitude,
    },
  ];

  return async (dispatch) => {
    server
      .geocodeCoordinate(ob)
      .then((data) => {
        dispatch({
          type: TYPES.GEOCODE_EDİT,
          payload: data.entity,
        });
        //row.target.parentElement.parentElement.parentElement.classList.add('upd');

        if (
          row.target.parentElement.parentElement.parentElement.nodeName == "TR"
        ) {
          row.target.parentElement.parentElement.parentElement.classList.add(
            "upd"
          );
        } else {
          row.target.parentElement.parentElement.classList.add("upd");
        }

        cb("success");
        closePopup(false);
      })
      .catch((error) => {
        console.log(error);
        cb("error");
      });
  };
};

export const sendToPlanAction = (data, cb) => {
  var sendDataArr = data;
  return async (dispatch) => {
    server
      .sendToPlan(data)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_SEND_TO_PLAN,
          payload: data.entity,
        });
        //cb("success", "Planlamaya gönderildi");
        if (data.entity.data.length == 0) {
          cb("warn", "Paket kabulu onaylanmayan kayıt var");
        } else if (data.entity.data.length != sendDataArr.length) {
          cb("success", "Planlamaya gönderildi");
          cb("warn", "Paket kabulu onaylanmayan kayıt var");
        } else {
          cb("success", "Planlamaya gönderildi");
        }
      })
      .catch((error) => {
        console.log(error);
        cb("error");
      });
  };
};

export const submittedPlansAction = (data, cb, closeDialog) => {
  // var cc = new Date(data[0].value)
  // cc.setDate(cc.getDate() + 1);
  // var newDt = new Date(cc).toISOString().replace(/T.*/, '').split('-').join('-');
  var newDt = data.map((x) => x.value.split("T")[0]).toString();

  return async (dispatch) => {
    server
      .submittedPlans(newDt)
      .then((data) => {
        dispatch({
          type: TYPES.GET_SUBMITTED_PLANS,
          payload: data.entity,
        });
        cb("success", "Planlanmış Siparişler Listelendi");
        closeDialog(false);
      })
      .catch((error) => {
        console.log(error);
        cb("error");
      });
  };
};

export const revertToPlanAction = (data, cb) => {
  return async (dispatch) => {
    server
      .revertToPlan(data)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_REVERT_TO_PLAN,
          payload: data.entity,
        });
        cb("success", "Planlamadan çıkarıldı");
      })
      .catch((error) => {
        console.log(error);
        cb("error");
      });
  };
};

export const getAdminOrderUnplanned = (data) => {
  //const getServices = new Services();

  return async (dispatch) => {
    loadingActive(dispatch, true);
    server
      .getAdminOrderList()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ORDER_UNPLANNED,
          //payload: data.entity
          payload: {
            data: data.entity.data.filter((d) =>
              d.debit_type == null ? (d.debit_type = "-") : d.debit_type
            ),
          },
        });
        loadingActive(dispatch, false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getAdminOrderDate = (dateRange, setDeliveryDate) => {
  //const getServices = new Services();
  let start = dateRange[0];
  let end = dateRange[1];

  var isoDateStartTime = new Date(
    start.getTime() - start.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
  var isoDateEndTime = new Date(end.getTime() - end.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];

  return async (dispatch) => {
    server
      .getAdminOrderFilter(isoDateStartTime, isoDateEndTime)
      .then((data) => {
        dispatch({
          type: TYPES.GET_ORDER_UNPLANNED,
          payload: data.entity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
