import React from "react";
import { Services } from "api/Services";
import { Box, Button, Grid, Stack } from "@mui/material";
import Layout from "components/Layout";
import Filters from "./Filters";
import Statics from "./Statics";
import SectionStatics from "./SectionStatics";
import { FilterList } from "@mui/icons-material";

const service = new Services();

const filterInitial = {
  customers: [],
  branches: [],
  cities: [],
  counties: [],
};

const Analytics = () => {
  const [openFilter, setOpenFilter] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({
    returned_count: 0,
    will_be_return_count: 0,
    order_received_count: 0,
    will_be_return_on_board_count: 0,
    package_not_found_count: 0,
    will_be_return_branch_count: 0,
    delivered_count: 0,
    order_accepted_count: 0,
    branch_return_accepted_count: 0,
    branch_accepted_count: 0,
    on_board_count: 0,
    not_delivered_count: 0,
    package_cancelled_count: 0,
    // Genel
    branches_and_distributions_total: 0,
    accepts_total: 0,
    returns_total: 0,
  });
  const [filter, setFilter] = React.useState(filterInitial);

  const getDashboardCounts = async (reset) => {
    setLoading(true);
    try {
      const res = await service.getNewDashboardCountsLast(
        reset
          ? filterInitial
          : {
              customers: filter.customers.map((i) => i.key),
              branches: filter.branches.map((i) => i.key),
              cities: filter.cities.map((i) => i.key),
              counties: filter.counties.map((i) => i.key),
            }
      );
      setData(res.entity.data[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getDashboardCounts(false);
  }, [filter]);

  return (
    <Layout
      heading="Analiz"
      links={[{ name: "Genel", href: "/" }, { name: "Analiz" }]}
      action={
        <Button
          variant="contained"
          color={openFilter ? "primary" : "inherit"}
          startIcon={<FilterList />}
          onClick={() => setOpenFilter(!openFilter)}
        >
          Filtrele
        </Button>
      }
    >
      <Stack spacing={3}>
        {openFilter && (
          <Filters
            filterInitial={filterInitial}
            filter={filter}
            setFilter={setFilter}
            loading={loading}
            getDashboardCounts={getDashboardCounts}
          />
        )}
        <Stack spacing={4}>
          <Statics
            label="Genel Durum"
            data={{
              subeDagitimTotal: data.branches_and_distributions_total,
              kabulTotal: data.accepts_total,
              iadeTotal: data.returns_total,
            }}
          />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <SectionStatics
                  bgcolor="primary.lighter"
                  label="Kabul & Transfer Süreci"
                  data={[
                    {
                      title: "Paket Kabul Bekliyor",
                      count: data.order_received_count,
                      icon: "/images/analiz-img/Paket_Kabul_Bekliyor.svg",
                      status: "ORDER_RECEIVED",
                    },
                    {
                      title: "Paket Kabul Yapıldı",
                      count: data.order_accepted_count,
                      icon: "/images/analiz-img/Paket_Kabul_Yapıldı.svg",
                      status: "ORDER_ACCEPTED",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SectionStatics
                  bgcolor="warning.lighter"
                  label="Şube"
                  data={[
                    {
                      title: "Şube Kabul Yapıldı",
                      count: data.branch_accepted_count,
                      icon: "/images/analiz-img/Şube_Kabul_Yapıldı.svg",
                      status: "BRANCH_ACCEPTED",
                    },
                    {
                      title: "Şube Teslimleri",
                      count: data.branch_return_accepted_count,
                      icon: "/images/analiz-img/Şube_Kabul_Yapıldı.svg",
                      status: "BRANCH_RETURN_ACCEPTED",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <SectionStatics
            columnRow={3}
            bgcolor="info.lighter"
            label="Dağıtım Süreci"
            data={[
              {
                title: "Paket Depoda Bulunamadı",
                count: data.package_not_found_count,
                icon: "/images/analiz-img/Paket_Depoda_Bulunamadı.svg",
                status: "PACKAGE_NOT_FOUND",
              },
              {
                title: "Teslim Edilemedi",
                count: data.not_delivered_count,
                icon: "/images/analiz-img/Teslim_Edilemedi.svg",
                status: "NOT_DELIVERED",
              },
              {
                title: "Paket İptal Edildi",
                count: data.package_cancelled_count,
                icon: "/images/analiz-img/paket_iptal.svg",
              },
            ]}
          />
          <SectionStatics
            bgcolor="error.lighter"
            label="İade Süreci"
            data={[
              {
                title: "Şubede",
                count: data.will_be_return_branch_count,
                icon: "/images/analiz-img/Ring.svg",
                status: "WILL_BE_RETURN_BRANCH",
              },
              {
                title: "Araçta",
                count: data.will_be_return_on_board,
                icon: "/images/analiz-img/Şubede.svg",
                status: "WILL_BE_RETURN_ON_BOARD",
              },
            ]}
          />
        </Stack>
      </Stack>
    </Layout>
  );
};

export default Analytics;
