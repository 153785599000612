import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import qs from "query-string";

const DetailLink = ({ pathname, routeID, routeKey, count }) => {
  if (!count) return "0";
  return (
    <Link
      to={{
        pathname,
        search: qs.stringify({ routeID, routeKey }),
      }}
    >
      {count} adet
    </Link>
  );
};

DetailLink.propTypes = {
  pathname: PropTypes.string,
  routeID: PropTypes.string,
  routeKey: PropTypes.string,
  count: PropTypes.number,
};

export default DetailLink;
