import TYPES from "../types";

const initialState = {};

export const vehicleUsageReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.VEHICLE_USAGE_REPORT_LIST:
            return { ...state, data: action.payload.data }
         case TYPES.VEHICLE_USAGE_REPORT_LIST_BETWEEN_DATE:
            return { ...state, data: action.payload.data }
        default:
            return state
    }
};