import TYPES from '../types';

const initialState = {};

export const unplannedCheckReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.CHECK_UNPLANNED:
            //return {...state, data: action.payload};


            //return {...state, data: [action.payload]};
            //return { ...state, data: [...state.data, action.payload] } 

            if (state.data != undefined) {
                let aa = state.data.filter(d => d.id != action.payload.id);
                if (aa.length != state.data.length) {
                    return { data: aa }
                } else {
                    return { ...state, data: [...state.data, action.payload] }
                }

            } else {
                return { ...state, data: [action.payload] };
            }
        case TYPES.EMPTY_CHECK_UNPLANNED:
            return { ...state, data: [] };
        default:
            return state;
    }
}