import TYPES from '../types';

const initialState = {
    token: '',
    data: null,
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.TOKEN:
            return {...state, token: action.payload, data: action.data};
        case TYPES.USER_LOGOUT:
            return {...state};
        default:
            return state;
    }
}