import { Grid } from "@mui/material";
import React from "react";
import AllShipmentAcceptances from "./AllShipmentAcceptances";
import StaticsChart from "./StaticsChart";

const Statics = ({ label, data }) => {
  // subeDagitimTotal
  // kabulTotal
  // iadeTotal

  const allTotal = data.subeDagitimTotal + data.kabulTotal + data.iadeTotal;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={5}>
        <StaticsChart
          data={[data.kabulTotal, data.subeDagitimTotal, data.iadeTotal]}
          labels={["Kabul", "Şube ve Dağıtım", "İade"]}
          title="Paket Durum Grafiği"
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <AllShipmentAcceptances
          label={label}
          data={[
            {
              label: "Kabul",
              amount: data.kabulTotal,
              value: (data.kabulTotal * 100) / allTotal,
              color: "primary",
            },
            {
              label: "Şube ve Dağıtım",
              amount: data.subeDagitimTotal,
              value: (data.subeDagitimTotal * 100) / allTotal,
              color: "warning",
            },
            {
              label: "İade",
              amount: data.iadeTotal,
              value: (data.iadeTotal * 100) / allTotal,
              color: "error",
            },
            {
              label: "Toplam Paket",
              amount: allTotal,
              value: (allTotal * 100) / allTotal,
              color: "secondary",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Statics;
