import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { saveProgressPayment, getProgressPayment, saveProgressPaymentVehicle, getProgressPaymentVehicle } from "../../store/actions/dialogsProgressPaymentAction";

function ProgressPayment(props) {
    

    
    const { toastCallback, id, type } = props;
    const dispatch = useDispatch();


    const [countPricNumber, setCountPricNumber] = useState({
        "min_price": null,
        "fixed_price": null,
        "count_calculators": [
            // {"op":"between", "min":0,"max":10, "price": 5},
            // {"op":"between", "min":10,"max":20, "price": 6.3},
            // {"op":">", "value":20, "price": 3.4}
        ],
        "deci_calculators": [
            // {"op":">", "value":0, "price": 3}
        ]
    });


    const oPItems = [
        { label: 'Arasında', value: 'between' },
        { label: 'Büyüktür', value: '>' },
        //{ label: 'test', value: 'test' }
    ];


    useEffect(() => {
        
       //Progress service will be requested here
       //dispatch(getProgressPayment(id, setCountPricNumber))
       type == "jobApplicationManagement" ?  dispatch(getProgressPayment(id, setCountPricNumber)) :  dispatch(getProgressPaymentVehicle(id, setCountPricNumber))
    }, [])


    return (
        <div style={{ overflow: "overlay" }}>
            <div>
                <div className="paymentContent pc-w-250">
                    <span className="pc-span-fontw">Minumum günlük hakediş</span>
                    <InputText
                        //placeholder="Min. price"
                        value={countPricNumber.min_price}
                        onChange={(e) => setCountPricNumber({ ...countPricNumber, min_price: e.target.value })} />
                </div>
                <div className="paymentContent pc-w-250">
                    <span className="pc-span-fontw">Sabit günlük hakediş</span>
                    <InputText
                        //placeholder="Fixed price"
                        value={countPricNumber.fixed_price}
                        onChange={(e) => setCountPricNumber({ ...countPricNumber, fixed_price: e.target.value })} />
                </div>

                {/* =========================== Count Price ===========================*/}

                <div className="paymentContent">
                    <p className="pc-span-fontw" >Ziyaret başı hakediş</p>
                    {/* <Button icon="pi pi-plus"  onClick={() => setCountPricNumber([...countPricNumber, {name : "CountPrice-" + countPricNumber.length, op: null, min: null, max: null, price: null}])} />

            { countPricNumber.map(d =>  <div className="countPriceContent">
                <div className="paymentContent pc-w-100">
                  <Dropdown placeholder="op" className="opHeight" options={oPItems} />
                </div>  
                <div className="paymentContent pc-w-100 pc-ml-15">
                  <InputText placeholder="min" />
                </div>
                <div className="paymentContent pc-w-100 pc-ml-15">
                  <InputText placeholder="max" />
                </div>
                <div className="paymentContent pc-w-100 pc-ml-15">
                  <InputText placeholder="price" />
                </div>
             </div> )} */}

                    <Button icon="pi pi-plus" onClick={(e) => {
                        let operatorLess = countPricNumber.count_calculators.filter(ch => ch.op == ">");
                        if (operatorLess.length > 0) {
                            toastCallback("warn", "Büyüktür operatörü 1 tane ekleyebilirsiniz");
                            return
                        }
                        setCountPricNumber({ ...countPricNumber, count_calculators: [...countPricNumber.count_calculators, { "op": ">", "value": null, "price": null }] })
                    }
                    }
                    />


                    <div className="pc-header">
                        <span>Silme</span>
                        <span>Oparetör</span>
                        <span>Tutar</span>
                        <span>Minumum değer</span>
                        <span>Maksimum değer</span>
                    </div>

                    {countPricNumber.count_calculators && countPricNumber.count_calculators.map((d, i) => <div className="countPriceContent">

                        <div className="paymentContent pc-mr-10">
                            <Button id={i} icon="pi pi-trash" className="p-button-danger" onClick={(e) => {
                                let temporary = countPricNumber;
                                temporary.count_calculators.splice(i, 1);
                                // temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
                                setCountPricNumber({ ...countPricNumber, temporary })
                            }} />
                        </div>

                        <div className="paymentContent pc-w-110">
                            <Dropdown id={i} placeholder="op" className="opHeight" value={d.op} options={oPItems}
                                onChange={(e) => {
                                    let temporary = [...countPricNumber.count_calculators];
                                    temporary[i] = e.target.value == ">" ? { "op": e.target.value, "value": temporary[i].value, "price": temporary[i].price } : { "op": e.target.value, "price": temporary[i].price, "min": temporary[i].min, "max": temporary[i].max }
                                    setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
                                }}
                            />
                        </div>
                        <div className="paymentContent pc-w-100 pc-ml-15">
                            <InputText
                                id={i}
                                className="pc-w-100"
                                placeholder="Tutar"
                                value={d.price}
                                onChange={(e) => {
                                    let temporary = [...countPricNumber.count_calculators];
                                    temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
                                    setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
                                }}
                            />
                        </div>

                        {d.op == ">" ? <div className="paymentContent pc-w-100 pc-ml-15">
                            <InputText
                                id={i}
                                className="pc-w-100"
                                placeholder="Min. Değer"
                                value={d.value}
                                onChange={(e) => {
                                    let temporary = [...countPricNumber.count_calculators];
                                    temporary[i] = { "op": temporary[i].op, "value": e.target.value, "price": temporary[i].price }
                                    setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
                                }}
                            />
                        </div>
                            :
                            <>
                                <div className="paymentContent pc-w-100 pc-ml-15">
                                    <InputText
                                        placeholder="Min. Değer"
                                        className="pc-w-100"
                                        value={d.min}
                                        onChange={(e) => {
                                            let temporary = [...countPricNumber.count_calculators];

                                            // min > max , value check
                                            // if (parseInt(e.target.value) > temporary[i].max) {
                                            //   toastCallback("warn", "Minumum değer maksimumdan değerden büyük olamaz!")
                                            //   return
                                            // }
                                            //if (minValueCheck(e.target.value, temporary[i].max) == true) return;

                                            temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": e.target.value, "max": temporary[i].max }
                                            setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
                                        }}
                                    />
                                </div>
                                <div className="paymentContent pc-w-100 pc-ml-15">
                                    <InputText
                                        placeholder="Max. Değer"
                                        className="pc-w-100"
                                        value={d.max}
                                        onChange={(e) => {
                                            let temporary = [...countPricNumber.count_calculators];

                                            // max < min , value check
                                            // if (parseInt(e.target.value) < temporary[i].min) {
                                            //   toastCallback("warn", "Maksimum değer minumumdan değerden küçük olamaz!")
                                            //   return
                                            // }
                                            //if (maxValueCheck(e.target.value, temporary[i].min) == true) return;

                                            temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": temporary[i].min, "max": e.target.value }
                                            setCountPricNumber({ ...countPricNumber, count_calculators: temporary })
                                        }}
                                    />
                                </div>
                            </>
                        }
                    </div>)}
                </div>


                {/* =========================== Deci Price ===========================*/}

                <div className="paymentContent" style={{ "marginTop": "50px" }}>
                    <p className="pc-span-fontw">Desi başı hakediş</p>


                    <Button icon="pi pi-plus" onClick={(e) => {
                        let operatorLess = countPricNumber.deci_calculators.filter(ch => ch.op == ">");
                        if (operatorLess.length > 0) {
                            toastCallback("warn", "Büyüktür operatörü 1 tane ekleyebilirsiniz");
                            return
                        }
                        setCountPricNumber({ ...countPricNumber, deci_calculators: [...countPricNumber.deci_calculators, { "op": ">", "value": null, "price": null }] })
                    }
                    }
                    />

                    <div className="pc-header">
                        <span>Silme</span>
                        <span>Oparetör</span>
                        <span>Tutar</span>
                        <span>Minumum değer</span>
                        <span>Maksimum değer</span>
                    </div>

                    {countPricNumber.deci_calculators && countPricNumber.deci_calculators.map((d, i) => <div className="countPriceContent">

                        <div className="paymentContent pc-mr-10">
                            <Button id={i} icon="pi pi-trash" className="p-button-danger" onClick={(e) => {
                                let temporary = countPricNumber;
                                temporary.deci_calculators.splice(i, 1);
                                // temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
                                setCountPricNumber({ ...countPricNumber, temporary })
                            }} />
                        </div>

                        <div className="paymentContent pc-w-110">
                            <Dropdown id={i} placeholder="op" className="opHeight" value={d.op} options={oPItems}
                                onChange={(e) => {
                                    let temporary = [...countPricNumber.deci_calculators];
                                    temporary[i] = e.target.value == ">" ? { "op": e.target.value, "value": temporary[i].value, "price": temporary[i].price } : { "op": e.target.value, "price": temporary[i].price, "min": temporary[i].min, "max": temporary[i].max }
                                    setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
                                }}
                            />
                        </div>
                        <div className="paymentContent pc-w-100 pc-ml-15">
                            <InputText
                                id={i}
                                className="pc-w-100"
                                placeholder="Tutar"
                                value={d.price}
                                onChange={(e) => {
                                    let temporary = [...countPricNumber.deci_calculators];
                                    temporary[i] = { "op": temporary[i].op, "value": temporary[i].value, "price": e.target.value }
                                    setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
                                }}
                            />
                        </div>

                        {d.op == ">" ? <div className="paymentContent pc-w-100 pc-ml-15">
                            <InputText
                                id={i}
                                className="pc-w-100"
                                placeholder="Min. Değer"
                                value={d.value}
                                onChange={(e) => {
                                    let temporary = [...countPricNumber.deci_calculators];
                                    temporary[i] = { "op": temporary[i].op, "value": e.target.value, "price": temporary[i].price }
                                    setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
                                }}
                            />
                        </div>
                            :
                            <>
                                <div className="paymentContent pc-w-100 pc-ml-15">
                                    <InputText
                                        placeholder="Min. Değer"
                                        className="pc-w-100"
                                        value={d.min}
                                        onChange={(e) => {
                                            let temporary = [...countPricNumber.deci_calculators];
                                            //if (minValueCheck(e.target.value, temporary[i].max) == true) return;
                                            temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": e.target.value, "max": temporary[i].max }
                                            setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
                                        }}
                                    />
                                </div>
                                <div className="paymentContent pc-w-100 pc-ml-15">
                                    <InputText
                                        placeholder="Max. Değer"
                                        className="pc-w-100"
                                        value={d.max}
                                        onChange={(e) => {
                                            let temporary = [...countPricNumber.deci_calculators];
                                            //if (maxValueCheck(e.target.value, temporary[i].min) == true) return;
                                            temporary[i] = { "op": temporary[i].op, "price": temporary[i].price, "min": temporary[i].min, "max": e.target.value }
                                            setCountPricNumber({ ...countPricNumber, deci_calculators: temporary })
                                        }}
                                    />
                                </div>
                            </>
                        }
                    </div>)}
                </div>

                <div className="paymentContent pc-w-250">
                    {/* <Button label="Kaydet" onClick={(e) => dispatch(saveProgressPayment(countPricNumber))} /> */}
                    <Button label="Kaydet" onClick={(e) => {
                        // countPricNumber.count_calculators.map(dt => {
                        //   //console.log(dt)
                        //   if (dt.max < dt.min) {
                        //     toastCallback("warn", "Maksimum değer minumumdan değerden küçük olamaz!")
                        //     return 
                        //   } else if (dt.min > dt.max) {
                        //     toastCallback("warn", "Minumum değer maksimumdan değerden büyük olamaz!")
                        //     return 
                        //   }
                        // })

                        let maxMinValueCheckCount = countPricNumber.count_calculators.filter(dt => (parseInt(dt.max) < parseInt(dt.min) || parseInt(dt.min) > parseInt(dt.max)))
                        let maxMinValueCheckDeci = countPricNumber.deci_calculators.filter(dt => (parseInt(dt.max) < parseInt(dt.min) || parseInt(dt.min) > parseInt(dt.max)))

                        //let bb =  countPricNumber.count_calculators.filter(dt => dt.min > dt.max)
                        //if(aa.length > 0 || bb.length > 0 ){
                        if (maxMinValueCheckCount.length > 0 || maxMinValueCheckDeci.length > 0) {
                            toastCallback("warn", "Minumum yada maksimum değerler yanlış")
                        } else {
                           type == "jobApplicationManagement" ? dispatch(saveProgressPayment(id, countPricNumber, toastCallback, type)) : dispatch(saveProgressPaymentVehicle(id, countPricNumber, toastCallback, type))
                        }


                    }
                    } />
                </div>

            </div>
        </div>
    )
}

export default ProgressPayment
