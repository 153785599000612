import React, {Fragment} from "react";
import { Redirect, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";
import NavBar from './Navbar/NavBar';

const AuthRoutes = (props) => {

  var {path, isAuthUser, type, component } = props;
  let Component = component;

return(
<Route {...props} render={(props) => 
      (
        type === "private" && isAuthUser ? (
           <Fragment>
             {/* <Container> */}
               {/* <NavBar/> */}
               {/* <BodyContainer>
                 <Body> */}
                  <Component {...props}/>
                  {/* </Body>
               </BodyContainer> */}
             {/* </Container> */}
           </Fragment>
        ) : <Redirect to={{
                         pathname: "/login",  
                         state: {
                             prevLocation: path,
                             }
                        }}
        />
      )
  } />
  );
};

export default AuthRoutes;