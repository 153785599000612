import TYPES from '../types';

const initialState = {
    warehouses: '',
};

export const warehousesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.USER:
            return {...state, warehouses: action.payload};
        default:
            return state;
    }
}