import * as React from "react";
import "assets/styles/reset.scss";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Profile from "./Profile";
import { custumerLinks, adminLinksNew } from "./links";
import MobileMenu from "./MobileMenu";
import { useSelector } from "react-redux";

import NavbarVertical from "./NavbarVertical";

const Navbar = ({ navBarWidth, isMobile }) => {
  const { user } = useSelector((s) => s.userReducer);
  const customer = user.user_type !== "ADMIN";
  const links = customer ? custumerLinks : adminLinksNew;
  const [mobileMenu, setMobileMenu] = React.useState(false);

  const baseURL = customer ? "/bringo-customer/dashboard" : "/";

  if (isMobile) {
    return (
      <>
        <div className={[styles.wrapper, styles.mobile].join(" ")}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMobileMenu(true)}
          >
            <MenuIcon />
          </IconButton>
          <Link to={baseURL}>
            <figure className={styles.logo}>
              <img src="/hey-bringo-logo.png" />
            </figure>
          </Link>
          <Profile />
        </div>
        <MobileMenu open={mobileMenu} setOpen={setMobileMenu} links={links} />
      </>
    );
  }

  return <NavbarVertical links={links} />;
};
export default Navbar;
