import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown, Radio } from "semantic-ui-react";
import { Calendar } from "primereact/calendar";
import "../DistributionReport/distribution.css";
import data from "./mockdata";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";

const VehicleReport = (props) => {
  const dropdownlist = [
    {
      key: "1",
      text: "BRINGO",
      value: "BRINGO",
    },
    {
      key: "2",
      text: "ABC TAŞERON",
      value: "ABC TAŞERON",
    },
  ];

  const [products, setProducts] = useState(data);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const isMounted = useRef(false);
  const table = useRef(null);
  const [date, setDate] = useState(new Date());
  const [customer, setCustomer] = useState(dropdownlist[0].text);

  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable className={"subtable"} value={data.orders}>
        <Column field="ilce" header="ilce"></Column>
        <Column header="Teslim Edilen Paket Sayısı"></Column>
        <Column header="Teslim Edilemeyen Paket Sayısı"></Column>
        <Column header="Ortalama Müşteri Puanı"></Column>
        <Column></Column>
      </DataTable>
    );
  };

  const header = <div className="table-header-container"></div>;

  return (
    <>
      <NavBar />
      <div className="datatable-rowexpansion-demo">
        <Toast ref={toast} />
        <div className="filter">
          <span className="customerText">Müşteri </span>
          <Dropdown
            className="dropdownCustomer"
            placeholder={"HEPSİ"}
            search
            selection
            options={dropdownlist}
            onChange={(event, data) => setCustomer(data.value)}
          />
          <Calendar
            id="range"
            style={{ height: "36px", marginRight: "10px" }}
            value={date}
            onChange={(e) => setDate(e.value)}
            selectionMode="range"
            readOnlyInput
          />
          <i style={{ fontSize: "20px" }} className={"pi pi-calendar"}></i>
        </div>
        <div className={"links"}>
          <a href="">{customer}</a>
          <span>&gt;</span>
          <a href="">Türkiye</a>
        </div>
        <div className="card">
          <DataTable
            className={"tt"}
            ref={table}
            value={products}
            expandedRows={expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
          >
            <Column
              body={(e) => (
                <Radio
                  checked={
                    expandedRows &&
                    expandedRows.hasOwnProperty(e.id) &&
                    expandedRows[e.id] == true
                  }
                  onClick={() =>
                    expandedRows &&
                    expandedRows.hasOwnProperty(e.id) &&
                    expandedRows[e.id] == true
                      ? setExpandedRows({ [e.id]: null })
                      : setExpandedRows({ [e.id]: true })
                  }
                />
              )}
              expander
              style={{ width: "3em" }}
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="İl"
              header="İl"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="active"
              header="Aktif Araç Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="passive"
              header="Pasif Araç Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="maintenance"
              header="Bakımda Olan Araç Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="vehicle"
              header="Dağıtıcı Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="completedDoc"
              header="Belgesi Tamamlanan Dağıtıcı Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="uncumpletedDoc"
              header="Belgesi Tamamlanmayan Dağıtıcı Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              body={(data) => {
                const ratingColor =
                  data.rating <= 5
                    ? " pi-angle-down iconDown"
                    : " pi-angle-up iconUp";
                return (
                  <div className={"rating"}>
                    <div className={"ratingNumber"}>{data.rating}</div>
                    <i className={`pi ${ratingColor}`}></i>
                  </div>
                );
              }}
              field="rating"
              header="Ortalama Müşteri Puanı"
              sortable
            />
          </DataTable>
        </div>
      </div>
    </>
  );
};

VehicleReport.whyDidYouRender = false;

export default VehicleReport;
