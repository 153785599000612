import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import TYPES from "../../store/types";
//import TYPES from "../../store/types";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { Checkbox } from "@material-ui/core";
import {
    vehicleZoneList,
    vehicleProjectList,
    vehicleModelList,
    vehicleCompanyList,
    vehicleDriversList,
    vehicleBracnhList
} from "../../store/actions/vehicleManagementAction";
import {
    inactiveDateReasonAction,
    getInactiveDateReasonAction,
    deleteInactiveDateReasonAction,
    paymentExtraDateAction,
    getPaymentExtraDateAction
} from '../../store/actions/inactiveDateReasonAction';
import ProgressPayment from "../../components/ProgressPayment/ProgressPayment";
import {
    Table,
    Divider,
    Segment,
    Tab,
    Grid,
    Input,
    GridColumn
} from 'semantic-ui-react';
import { Upload } from '../../components/UploadFiles/Upload';
import './vehicleCss.css';
import VehicleAddBranch from "./VehicleAddBranch";

export const VehicleManagement = (props) => {

    const {
        product,
        onInputChange,
        dialogVehicleManagement,
        setDialogVehicleManagement,
        productDialogFooter,
        zoneAddProduct,
        toastCallback,
        onDocUploaded,
        setProduct,
        vehicleEditStatus,
        onInputChangeCalendar,
        type
    } = props;
    const dispatch = useDispatch();
    const customDialogData = useSelector(state => state.customDialogReducer);
    const modelsList = useSelector(state => state.vehicleModelsReducer);
    const vehicleProjectData = useSelector(state => state.vehicleProjectReducer);
    const companyList = useSelector(state => state.vehicleCompanyReducer);
    const driversList = useSelector(state => state.vehicleDriversReducer);
    const vehicleList = useSelector((state) => state.vehicleManagementReducer);
    const vehiclesInactiveDates = useSelector(state => state.inactiveDateReasonReducer);

    /** Vehicle Add Branch ***/

    const [selectedBranch, setSelectedBranch] = useState([]) // selected branch
    const [vehicleAddBranchDialog, setVehicleAddBranchDialog] = useState(false);


    useEffect(() => {
        dialogVehicleManagement == false && setSelectedBranch([]); // branch user drivers state
    }, [dialogVehicleManagement])

    useEffect(() => {

        if (Object.keys(product).length > 0 && product.branches) {
            let prodBranches = product.branches.map(d => d.id);
            prodBranches.length > 0 && setSelectedBranch(prodBranches);
        }
    }, [product.branches])


    const addBranch = () => {
        setVehicleAddBranchDialog(true);
        dispatch(
            vehicleBracnhList()
        )
    };

    /**  Vehicle Add Branch ===============***/


    // console.log("sürücüler : ", driversList.data.data[0].drivers);

    if (Object.keys(modelsList).length > 0) {
        for (let i = 0; i < modelsList.data.data.length; i++) {

            for (let j = 0; j < modelsList.data.data[i].models.length; j++) {
                delete modelsList.data.data[i].models[j]["created_at"]
                delete modelsList.data.data[i].models[j]["lastupdated"]
            }

        }
    }


    // if (Object.keys(driversList).length > 0) {
    //     for (let i = 0; i < driversList.data.data[0].drivers.length; i++) {
    //         delete driversList.data.data[0].drivers[i]["birthday"]
    //         delete driversList.data.data[0].drivers[i]["company"]
    //         delete driversList.data.data[0].drivers[i]["created_at"]
    //         delete driversList.data.data[0].drivers[i]["criminal_record"]
    //         delete driversList.data.data[0].drivers[i]["education_status"]
    //         delete driversList.data.data[0].drivers[i]["email"]
    //         delete driversList.data.data[0].drivers[i]["employement_date"]
    //         delete driversList.data.data[0].drivers[i]["gender"]
    //         delete driversList.data.data[0].drivers[i]["last_name"]
    //         delete driversList.data.data[0].drivers[i]["lastupdated"]
    //         delete driversList.data.data[0].drivers[i]["licence_doc"]
    //         delete driversList.data.data[0].drivers[i]["licence_number"]
    //         delete driversList.data.data[0].drivers[i]["phone"]
    //         delete driversList.data.data[0].drivers[i]["photo"]
    //         delete driversList.data.data[0].drivers[i]["psycho_doc"]
    //         delete driversList.data.data[0].drivers[i]["src_doc"]
    //         delete driversList.data.data[0].drivers[i]["status"]
    //         delete driversList.data.data[0].drivers[i]["status_reason"]
    //         delete driversList.data.data[0].drivers[i]["user_type"]
    //         //delete driversList.data.data[0].drivers[i]["username"]
    //         //  let name1 = driversList.data.data[0].drivers[i].username
    //         //  delete driversList.data.data[0].drivers[i].username
    //         //  driversList.data.data[0].drivers[i].name= name1

    //         delete driversList.data.data[0].drivers[i]["first_name"]
    //         delete driversList.data.data[0].drivers[i]["vehicles"]
    //     }
    // }


    const [deneme, setDeneme] = useState();

    const [selectedCity1, setSelectedCity1] = useState(null);
    const [driversData, setDriversData] = useState(null);
    const [frigo, setFrigo] = useState(null);
    const [companyDropdown, setCompanyDropdown] = useState(null);

    const [selectedGroupedCity, setSelectedGroupedCity] = useState(null);


    // zone project popup start ==============>
    const vehicleZoneData = useSelector(state => state.vehicleZoneReducer);
    const [vehicleProjectDialog, setVehicleProjectDialog] = useState(false);
    const [zoneSelectedID, setZoneSelectedID] = useState([]);
    const [zoneSelectedHtml, setZoneSelectedHtml] = useState([]);
    const [vehicleZoneDialog, setVehicleZoneDialog] = useState(false);
    const [projectSelectedID, setProjectSelectedID] = useState([]);
    const [projectSelectedHtml, setProjectSelectedHtml] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [dateDescriptionDialog, setDateDescriptionDialog] = useState({
        opened: false,
        tabId: null,
        placeholder: '',
        title: ''
    });
    const [inactiveReason, setInactiveReason] = useState('');
    const [inactiveDates, setInactiveDates] = useState();
    const [inactiveDelete, setInactiveDelete] = useState(false);
    const [deleteInactiveDateDialog, setDeleteInactiveDateDialog] = useState(false);
    const [deleteDate, setDeleteDate] = useState();
    const [paymentExtraDates, setPaymentExtraDates] = useState();
    const [projectAllCheckboxStatus, setProjectAllCheckboxStatus] = useState(false);


    var NOT_ACTIVE_DATE = [];
    var arrIndex = {};

    useEffect(() => {
        console.log("TEST");
        dispatch(
            vehicleZoneList()
        );
        dispatch(
            vehicleProjectList()
        );
        dispatch(
            vehicleModelList()
        );
        dispatch(
            vehicleCompanyList()
        )

    }, []);

    const getVehicleInactiveDates = vehicle => {
        let date = new Date();
        let start_date = new Date(date.getFullYear(), 0, 1);
        let end_date = new Date(date.getFullYear(), 12, 31);
        let vehicle_id = vehicle && vehicle.id;

        if (vehicle_id) {
            // inactive dates
            dispatch(
                getInactiveDateReasonAction(
                    start_date.toISOString(),
                    vehicle_id,
                    end_date.toISOString(),
                    res => {
                        setInactiveDelete(false)
                    }
                )
            );

            // payment extra
            dispatch(
                getPaymentExtraDateAction(
                    start_date.toISOString().slice(0, 10),
                    vehicle_id,
                    end_date.toISOString().slice(0, 10),
                    res => {
                        // console.log(res)
                    }
                )
            );
        }
    };

    // get selected dates
    useEffect(() => {
        // let date = new Date();
        // let start_date = new Date(date.getFullYear(), date.getMonth(), 1);
        // let end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        // let vehicle_id = product && product.id;

        // if(vehicle_id){
        //     dispatch(
        //         getInactiveDateReasonAction(
        //             start_date.toISOString(),
        //             vehicle_id,
        //             end_date.toISOString(),
        //             res => {
        //                 setInactiveDelete(false)
        //             }
        //         )
        //     );
        // }
        let vehicle = product && product;
        getVehicleInactiveDates(vehicle);
    }, [dialogVehicleManagement]);

    useEffect(() => {
        setInactiveDates(vehiclesInactiveDates.inactive && vehiclesInactiveDates.inactive.data);
        setPaymentExtraDates(vehiclesInactiveDates.payment_extra && vehiclesInactiveDates.payment_extra.data);
    }, [vehiclesInactiveDates]);

    useEffect(() => {
        // let date = new Date();
        // let start_date = new Date(date.getFullYear(), date.getMonth(), 1);
        // let end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        // let vehicle_id = product && product.id;

        // if(vehicle_id){
        //     dispatch(
        //         getInactiveDateReasonAction(
        //             start_date.toISOString(),
        //             vehicle_id,
        //             end_date.toISOString(),
        //             res => {
        //                 setInactiveDelete(false)
        //             }
        //         )
        //     );
        // }
        let vehicle = product && product;
        getVehicleInactiveDates(vehicle);
    }, [dateDescriptionDialog]);

    useEffect(() => {
        // let date = new Date();
        // let start_date = new Date(date.getFullYear(), date.getMonth(), 1);
        // let end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        // let vehicle_id = product && product.id;

        // if(vehicle_id){
        //     dispatch(
        //         getInactiveDateReasonAction(
        //             start_date.toISOString(),
        //             vehicle_id,
        //             end_date.toISOString(),
        //             res => {
        //                 setInactiveDelete(false)
        //             }
        //         )
        //     );
        // }
        let vehicle = product && product;
        getVehicleInactiveDates(vehicle);
    }, [inactiveDelete]);

    // useEffect(() => {
    //     let vehicle = product && product;
    //     getVehicleInactiveDates(vehicle);
    // }, [inactiveDates]);

    const vehicleHideDialog = () => {
        setVehicleZoneDialog(false)

    }

    const zoneOpenPopup = () => {
        setVehicleZoneDialog(true)

    }

    const projectOpenPopup = () => {
        setVehicleProjectDialog(true);
    }

    const projectHideDialog = () => {
        setVehicleProjectDialog(false)
    }


    useEffect(() => {
        zoneAddProduct(zoneSelectedHtml, "zones");
    }, [zoneSelectedHtml])


    useEffect(() => {
        zoneAddProduct(projectSelectedHtml, "projects");
    }, [projectSelectedHtml])


    useEffect(() => {
        //zone sorting
        if (!vehicleZoneData || Object.keys(vehicleZoneData).length == 0) return
        for (let i = 0; i < vehicleZoneData.data.data.length; i++) {
            vehicleZoneData.data.data[i].zones.sort(function (a, b) {
                return a.name.localeCompare(b.name)
            });
        }
    }, [vehicleZoneData])


    const editProjectUploadData = (editProject) => {
        // return(rowData.projects.map(zone => <p>{zone.substring(0, 8)+"..."}</p>))

        if (Object.keys(vehicleProjectData).length > 0) {
            for (let p of vehicleProjectData.data.data) {

                if (editProject.projects && editProject.projects.includes(p.project_id) == true) {
                    setProjectSelectedHtml((projectList) => [
                        ...projectList,
                        p,
                    ]);

                    setProjectSelectedID((projectList) => [
                        ...projectList,
                        p.project_id
                    ]);
                }

            }

        }

        return
    };
    projectSelectedHtml.length == 0 && Object.keys(product).length > 0 && product.projects && editProjectUploadData(product);

    const editZoneUploadData = (editZone) => {
        // return(rowData.projects.map(zone => <p>{zone.substring(0, 8)+"..."}</p>))


        if (Object.keys(vehicleZoneData).length > 0) {
            for (let p of vehicleZoneData.data.data) {
                for (let i of p.zones) {
                    if (editZone.zones.includes(i.zone_id) == true) {
                        setZoneSelectedHtml((zoneList) => [
                            ...zoneList,
                            i,
                        ]);
                        setZoneSelectedID((zoneList) => [
                            ...zoneList,
                            i.zone_id,
                        ]);
                    }
                }
            }
        }

        return
    };

    zoneSelectedHtml.length == 0 && Object.keys(product).length > 0 && product.zones && editZoneUploadData(product);


    const saveZone = () => {

        vehicleZoneData.data.data.map((zone, i) => {
            {
                zone.zones.map((zonesDt, i) => {


                    let a = zoneSelectedID.includes(zonesDt.zone_id);
                    let b = zoneSelectedHtml.some(x => x.zone_id == zonesDt.zone_id);
                    if (a == true && b == false) {
                        setZoneSelectedHtml((zoneList) => [
                            ...zoneList,
                            zonesDt,
                        ]);
                    }

                })
            }
        })

        if (zoneSelectedID.length < zoneSelectedHtml.length) {
            var duplicates = zoneSelectedHtml.filter(function (val) {
                return zoneSelectedID.indexOf(val.zone_id) != -1;
            });
            setZoneSelectedHtml(duplicates);
            zoneAddProduct(duplicates, "zone");
        }

        setVehicleZoneDialog(false)
    }


    const saveProject = () => {

        vehicleProjectData.data.data.map((project, i) => {

            {

                let a = projectSelectedID.includes(project.project_id);
                let b = projectSelectedHtml.some(x => x.project_id == project.project_id);
                if (a == true && b == false) {
                    setProjectSelectedHtml((projectList) => [
                        ...projectList,
                        project,
                    ]);
                }

            }
        })


        if (projectSelectedID.length < projectSelectedHtml.length) {
            var duplicates = projectSelectedHtml.filter(function (val) {
                return projectSelectedID.indexOf(val.project_id) != -1;
            });
            setProjectSelectedHtml(duplicates);
            zoneAddProduct(duplicates, "project");
        }

        setVehicleProjectDialog(false)

    };

    // upsert to an existentiel array: UPSERT_ARRAY
    const addDate = object => {
        var index = arrIndex[object.uid];
        if (index === undefined) {
            index = NOT_ACTIVE_DATE.length;
            arrIndex[object.uid] = index;
        }
        NOT_ACTIVE_DATE[index] = object;
    };

    const timeZone = date => {
        var new_date = new Date(date);
        var format = new_date.setHours(new_date.getHours() + (new_date.getTimezoneOffset() / -60));
        var formated_date = new Date(format).toISOString();
        return formated_date
    };

    const saveAndSelectDate = () => {
        var inactive_data = {
            date: timeZone(selectedDate),
            id: null,
            reason: inactiveReason,
            vehicle: product && product.id // selected product
        };

        // addDate(date);
        // localStorage.setItem('date', JSON.stringify(date));
        if (inactiveReason != '' && dateDescriptionDialog.tabId == "inactive-date") {
            dispatch(
                inactiveDateReasonAction(
                    inactive_data,
                    res => {
                        if (res.success) {
                            setDateDescriptionDialog({
                                opened: false,
                                tabId: null,
                                placeholder: '',
                                title: ''
                            });
                            setInactiveDelete(false)
                            setInactiveReason('');
                        }
                    }
                )
            );
        } else
            if (inactiveReason != '' && dateDescriptionDialog.tabId == "payment-progress") {
                var payment_extra = {
                    date: timeZone(selectedDate).slice(0, 10),
                    id: product && product.id,
                    payment_extra: inactiveReason,
                };
                dispatch(
                    paymentExtraDateAction(
                        payment_extra,
                        res => {
                            if (res.success) {
                                setDateDescriptionDialog({
                                    opened: false,
                                    tabId: null,
                                    placeholder: '',
                                    title: ''
                                });
                                setInactiveReason('');
                            }
                        }
                    )
                );
            }
            else {
                toastCallback('warn', "Error");
            }

    };

    const hideVehicleDialog = () => {
        setVehicleProjectDialog(false);
        setVehicleZoneDialog(false);

    }

    const vehicleDialogFooter = (
        <React.Fragment>
            <Button
                label="İptal"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideVehicleDialog}
            />
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveZone}
            />
        </React.Fragment>
    );

    const vehicleProjectDialogFooter = (
        <React.Fragment>
            <Button
                label="İptal"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideVehicleDialog}
            />
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveProject}
            />
        </React.Fragment>
    );

    const DialogFooter = (
        <React.Fragment>
            {
                dateDescriptionDialog.opened ?

                    <Button
                        label="Kaydet"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={e => saveAndSelectDate()}
                    /> :
                    <>
                        <Button
                            label="İptal"
                            icon="pi pi-times"
                            className="p-button-text"
                        //onClick={hideDeleteProductsDialog}
                        />
                        <Button
                            label="Kaydet"
                            icon="pi pi-check"
                            className="p-button-text"
                            onClick={saveProject}
                        />
                    </>
            }
        </React.Fragment>
    );

    const dateDescriptionHideDialog = () => {
        setDateDescriptionDialog({
            opened: false,
            tabId: null,
            placeholder: '',
            title: ''
        });
    };

    const selectedZoneMethod = (e) => {

        for (var i = 0; i < zoneSelectedID.length; i++) {
            if (zoneSelectedID[i] === e.target.id) {
                zoneSelectedID.splice(i, 1);
                return
            }
        }


        setZoneSelectedID((zoneList) => [
            ...zoneList,
            e.target.id,
        ]);

    }


    const selectedProjectMethod = (e) => {
        let checked = e.target.checked;
        if (checked == false) {
            for (var i = 0; i < projectSelectedID.length; i++) {
                if (projectSelectedID[i] === e.target.id) { //  selected projects(state) == unchecked option id (target.id) 
                    let removedOption = projectSelectedID.splice(i, 1);
                    setProjectSelectedID(projectSelectedID.filter(d => d != removedOption));
                    //return
                }
            }
        }

        if (checked == true) {
            setProjectSelectedID((projectList) => [
                ...projectList,
                e.target.id,
            ]);
        }

    }


    const selectedProjectAll = (e) => {
        //get ids of selected projects
        let prID = vehicleProjectData.data.data.map((prj, i) => prj.project_id);
        //console.log(prID);
        if (e.target.checked == true) {
            setProjectSelectedID(prID);
        } else {
            setProjectSelectedID([]);
        }
    }



    // zone project popup end ================>



    const onDropDownChange = (e) => {

        if (e.target.name == "refrigerate") {
            setFrigo(e.value)
        } else if (e.target.name == "drivers") {
            //setDriversData(e.value)
        } else if (e.target.name == "company") {
            setCompanyDropdown(e.value)

            dispatch(
                vehicleDriversList(e.target.value.id)
            )

        } else {
            // delete e.value.created_at
            // delete e.value.lastupdated
            setSelectedGroupedCity(e.value);
        }


        onInputChange(e, e.target.name)
    }

    // useEffect(() => {
    //   console.log("LOP: ", customDialogData)
    // }, [customDialogData])

    // useEffect(() => {
    //    console.log(deneme)
    // }, [deneme])

    const onInputChangeVehicle = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...deneme };
        _product[`${name}`] = val;
        setDeneme(_product);

        // dispatch({
        //     type: TYPES.DIALOG_CURRENT_DATA,
        //     payload: deneme
        // })
    }

    // const cities = [
    //     { name: 'New York', code: 'NY' },
    //     { name: 'Rome', code: 'RM' },
    //     { name: 'London', code: 'LDN' },
    //     { name: 'Istanbul', code: 'IST' },
    //     { name: 'Paris', code: 'PRS' }
    // ];

    const drivers = [
        { name: 'Yakup', code: 'ykp' },
        { name: 'Laurent', code: 'lrn' },
        { name: 'Özgür', code: 'ozg' },
        { name: 'Erdal', code: 'erd' },
        { name: 'Ömer', code: 'omr' }
    ];

    const frigoOptions = [
        { name: 'Var', code: 'true' },
        { name: 'Yok', code: 'false' }
    ];
    // const groupedCities = [
    //     {
    //         label: 'Germany', code: 'DE',
    //         items: [
    //             { label: 'Berlin', value: 'Berlin' },
    //             { label: 'Frankfurt', value: 'Frankfurt' },
    //             { label: 'Hamburg', value: 'Hamburg' },
    //             { label: 'Munich', value: 'Munich' }
    //         ]
    //     },
    //     {
    //         label: 'USA', code: 'US',
    //         items: [
    //             { label: 'Chicago', value: 'Chicago' },
    //             { label: 'Los Angeles', value: 'Los Angeles' },
    //             { label: 'New York', value: 'New York' },
    //             { label: 'San Francisco', value: 'San Francisco' }
    //         ]
    //     },
    //     {
    //         label: 'Japan', code: 'JP',
    //         items: [
    //             { label: 'Kyoto', value: 'Kyoto' },
    //             { label: 'Osaka', value: 'Osaka' },
    //             { label: 'Tokyo', value: 'Tokyo' },
    //             { label: 'Yokohama', value: 'Yokohama' }
    //         ]
    //     }
    // ];


    const hideDialog = () => {
        var emptyProduct = {};
        setDialogVehicleManagement(false);

        setZoneSelectedHtml([])
        setZoneSelectedID([])

        setProjectSelectedHtml([])
        setProjectSelectedID([])

        setProduct(emptyProduct)

    };

    const hideDeleteInactiveDialog = () => {
        setDeleteInactiveDateDialog(false);
    };

    // vehicle documents instances
    const vehilcleDocuments = [
        {
            doc_name: "k_certificateDoc",
            title: "K Belgesi",
            date_title: "K Belgesi (Geçerlilik Tarihi)",
            date_instance: "k_certificate",
            date_calendar_id: "basic1"
        },
        {
            doc_name: "insuranceDoc",
            title: "Kasko",
            date_title: "Kasko Belgesi (Geçerlilik Tarihi)",
            date_instance: "insurance",
            date_calendar_id: "basic2"
        },
        {
            doc_name: "inceptionDoc",
            title: "Muayene",
            date_title: "Muayene(Geçerlilik Tarihi)",
            date_instance: "inception",
            date_calendar_id: "basic3"
        },
        {
            doc_name: "vehicle_LicenseDoc",
            title: "Araç Ruhsat",
            date_title: "Ruhsat (Geçerlilik Tarihi)",
            date_instance: "vehicle_license",
            date_calendar_id: "basic4"
        }
    ];

    const deleteInactiveDate = () => {
        dispatch(
            deleteInactiveDateReasonAction(
                deleteDate.id,
                success => {
                    if (success) {
                        setInactiveDelete(success);
                        setDeleteInactiveDateDialog(false);
                    }
                }
            )
        )
    };

    const handleSelectDate = (date, tabId) => {
        // date.originalEvent.target.parentElement.attr('class','p-datepicker-today');
        if (product.id && tabId == "inactive-date") {
            let date_handler;
            if (inactiveDates && inactiveDates.length > 0) {
                inactiveDates && inactiveDates.forEach(inactive_date => {

                    let server_date = new Date(inactive_date.date).getDate();
                    let tick_date = date.value.getDate();

                    if (tick_date == server_date) date_handler = inactive_date;

                });
            }

            if (date_handler) {
                setDeleteInactiveDateDialog(true);
                setDeleteDate(date_handler);

            } else {
                setDateDescriptionDialog({
                    opened: true,
                    tabId: tabId,
                    placeholder: 'Aktif olmadigini nedeni...',
                    title: 'Aktif Olmadıgı Gün Nedeni'
                });
                setSelectedDate(date.value);
            }
        }

        if (product.id && tabId == "payment-progress") {
            setDateDescriptionDialog({
                opened: true,
                tabId: tabId,
                placeholder: '₺',
                title: 'Extra Payment'
            });
            setSelectedDate(date.value);
        }
        // NOT_ACTIVE_DATE.push({date: date})
    };

    const selectedInactiveDate = (date, tabId) => {

        // select inactive date: AS_IS
        let div;
        //if(inactiveDates.length > 0){
        if (inactiveDates && inactiveDates.length > 0 && tabId == "inactive-date") {
            inactiveDates && inactiveDates.forEach(inactive_date => {
                let date_format = new Date(inactive_date.date);
                let vehicle_id = inactive_date.vehicle;
                let selected = {
                    day: date_format.getDate(),  // get date day: type number
                    month: date_format.getMonth(),   // get date month: type number
                    selectable: true,
                    reason: inactive_date.reason     // get inactive date reason
                };
                // for the selected vehicle
                if (
                    selected.day == date.day &&
                    vehicle_id == product.id &&
                    selected.month == date.month
                ) {
                    div = <div title={selected.reason || "Nedeni bulumamaktadir"} style={{ backgroundColor: 'red', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', width: '2em', height: '2em', lineHeight: '1.6em', padding: 4 }}>{selected.day}</div>
                }
            });
        } else if (paymentExtraDates && paymentExtraDates.length > 0 && tabId == "payment-progress") {
            paymentExtraDates && paymentExtraDates.forEach(payment_extra => {
                let date_format = new Date(payment_extra.date);
                let vehicle_id = payment_extra.vehicle;
                let selected = {
                    day: date_format.getDate(),  // get date day: type number
                    month: date_format.getMonth(),   // get date month: type number
                    selectable: true,
                    reason: payment_extra.payment_extra     // get inactive date reason
                };
                // for the selected vehicle
                if (
                    selected.day == date.day &&
                    vehicle_id == product.id &&
                    selected.month == date.month
                ) {
                    div = <div title={selected.reason || "Nedeni bulumamaktadir"} style={{ backgroundColor: 'red', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', width: '2em', height: '2em', lineHeight: '1.6em', padding: 4 }}>{selected.day}</div>
                }
            });
        }


        if (div) return div;
        else return date.day;
    };

    const deleteInactiveDateDialogFooter = (
        <React.Fragment>
            <Button
                label="Hayır"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteInactiveDialog}
            />
            <Button
                label="Evet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteInactiveDate}
            />
        </React.Fragment>
    );

    const formatDate = iso_date => {
        let date = new Date(iso_date);
        date.setDate(date.getDate());

        let dd = date.getDate();
        let mm = date.getMonth();
        let yy = date.getFullYear();

        let formated_date = dd + "/" + mm + "/" + yy;

        return formated_date;
    };


    const vehicleManagementTabs = [
        {
            menuItem: 'Araç Bilgileri',
            pane: {
                key: 'tab3',
                content: (
                    <div style={{ overflow: "overlay" }}>
                        <React.Fragment>
                            <Segment>
                                <Grid columns={2} relaxed='very'>
                                    <Grid.Column>
                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf">Firma </label>
                                            <br />
                                            <Dropdown
                                                value={product && product.company}
                                                options={Object.keys(companyList).length > 0 ? companyList.data.data : []}
                                                name="company"
                                                onChange={onDropDownChange}
                                                //onChange={e => setSelectedGroupedCity(e.value)}
                                                optionLabel="name" />
                                        </div>

                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf">Plaka </label>
                                            <InputText
                                                id="vehivle_id"
                                                defaultValue={product && product.vehicle_id}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => {
                                                    e.target.value = e.target.value.toUpperCase(); //plate number
                                                    onInputChange(e, "vehicle_id")
                                                }
                                                }
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>
                                        {/* <div className="p-field">
                                            <label htmlFor="sdfdf">Model</label>
                                            <br />
                                            <Dropdown
                                                value={selectedCity1}
                                                name="Model"
                                                options={cities}
                                                onChange={onDropDownChange}
                                                optionLabel="name"
                                                placeholder="Select a City"
                                            onChange={e => onInputChange(e, "model")}
                                            />

                                        </div> */}
                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf">Model</label>
                                            <br />
                                            {/* <Dropdown
                                                value={selectedCity1}
                                                name="Model"
                                                options={cities}
                                                onChange={onDropDownChange}
                                                optionLabel="name"
                                                placeholder="Select a City"
                                                //onChange={e => onInputChange(e, "model")}
                                            /> */}
                                            <Dropdown
                                                value={product && product.vehicle_model}
                                                options={Object.keys(modelsList).length > 0 ? modelsList.data.data : []}
                                                name="vehicle_model"
                                                onChange={onDropDownChange}
                                                //onChange={e => setSelectedGroupedCity(e.value)}
                                                optionLabel="name"
                                                optionGroupLabel="name"
                                                optionGroupChildren="models" />


                                        </div>

                                        {/* <div className="p-field">
                                            <label htmlFor="sdfdf"> Muayene (Geçerlilik Tarihi)</label>
                                            <Calendar id="basic4" dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "car-control")} />
                                        </div> */}
                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf"> Frigo /izolasyon- var?</label>

                                            <Dropdown
                                                value={product && product.refrigerate}
                                                options={frigoOptions}
                                                name="refrigerate"
                                                onChange={onDropDownChange}
                                                optionLabel="name"
                                                placeholder="Var mı? Yok mu?"
                                            //onChange={e => onInputChange(e, "frigo")}
                                            />
                                        </div>
                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf"> Araç Sürücüsü </label>
                                            <br />
                                            <Dropdown
                                                value={product.drivers && product.drivers.id || product.drivers}
                                                options={Object.keys(driversList).length > 0 ? driversList.data.data[0].drivers : []}
                                                name="drivers"
                                                onChange={onDropDownChange}
                                                optionLabel="full_name"
                                                //optionLabel="username"
                                                optionValue="id"
                                                placeholder="Araç Sürücüsü "
                                            //onChange={e => onInputChange(e, "driver")}
                                            />

                                        </div>
                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf"> Son Bakım Tarihi</label>
                                            <Calendar id="basic5" value={new Date(product.maintanence_date && product.maintanence_date)} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "maintanence_date")} />
                                        </div>
                                        <div className="p-field vehicleLeftElementWidth">
                                            <label htmlFor="sdfdf"> Son Bakım KM</label>
                                            <InputText
                                                id="last-maintenance-km"
                                                defaultValue={product && product.maintanence_km}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "maintanence_km")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>
                                    </ Grid.Column>

                                    <GridColumn>
                                        <div className="p-field">
                                            <label htmlFor="sdfdf"> K Belgesi (Geçerlilik Tarihi)</label>
                                            <Calendar id="basic1" value={new Date(product.k_certificate && product.k_certificate.doc_expiration || product.k_certificate)} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "k_certificate")} />
                                            {/* <Calendar id={product.k_certificate && product.k_certificate.id} value={new Date(product.k_certificate && product.k_certificate.doc_expiration || product.k_certificate )} dateFormat="dd.mm.yy" onChange={(e) => onInputChangeCalendar(e, "k_certificate")} />  */}
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="sdfdf"> Kasko Belgesi (Geçerlilik Tarihi)</label>
                                            <Calendar id="basic2" value={new Date(product.insurance && product.insurance.doc_expiration || product.insurance)} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "insurance")} />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf"> Muayene(Geçerlilik Tarihi) </label>
                                            <Calendar id="basic3" value={new Date(product.inception ? product.inception.doc_expiration || product.inception : "-")} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "inception")} />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="sdfdf"> Ruhsat (Geçerlilik Tarihi)</label>
                                            <Calendar id="basic4" value={new Date(product.vehicle_licence && product.vehicle_licence.doc_expiration || product.vehicle_licence)} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "vehicle_licence")} />
                                        </div>

                                        {vehilcleDocuments.map((vehicle, i) => (
                                            <Upload
                                                key={i}
                                                vehicle={vehicle}
                                                withButton={false}
                                                callbackAlert={toastCallback}
                                                onDocUploaded={doc_name => onDocUploaded(doc_name)}
                                                product={product}
                                                onInputChange={onInputChange}
                                            />)
                                        )}

                                    </GridColumn>

                                </Grid>

                                <Divider vertical>-</Divider>
                            </Segment>
                        </React.Fragment>

                    </div>
                ),
            }
        },
        {
            menuItem: 'Bölge',
            pane: {
                key: 'tab2',
                content: (
                    <div style={{ overflow: "overlay" }}>
                        <p onClick={zoneOpenPopup} style={{ "cursor": "pointer", "color": "#302424", "width": "107px", "background": "#ffdb77", "padding": "5px", "text-align": "center", "border-radius": "2px" }}>Bölge Ekle/Çıkar</p>
                        <Table celled striped>
                            <Table.Body>
                                {/* <Table.Row>
                                <Table.Cell>çekmeköy</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                <Table.Cell>çekmeköy</Table.Cell>
                                </Table.Row> */}

                                {zoneSelectedHtml && zoneSelectedHtml.map((zn) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>{zn.name} ({zn.group_name})</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>

                    </div>
                ),
            },
        },
        // {
        //     menuItem: 'Projeler',
        //     pane: {
        //         key: 'tab1', content: (
        //             <div style={{ overflow: "overlay" }}>
        //                 <p onClick={projectOpenPopup} style={{ "cursor": "pointer", "color": "#302424", "width": "107px", "background": "#ffdb77", "padding": "5px", "text-align": "center", "border-radius": "2px" }}>Proje Ekle/Çıkar</p>
        //                 <Table celled striped>
        //                     <Table.Body>
        //                         {projectSelectedHtml && projectSelectedHtml.map((pr) => {
        //                             return (
        //                                 <Table.Row>
        //                                     <Table.Cell>({pr.name})</Table.Cell>
        //                                 </Table.Row>
        //                             )
        //                         })}
        //                     </Table.Body>
        //                 </Table>
        //             </div>
        //         )
        //     },
        // },
        {
            menuItem: 'Aktif Olmadıgı Günler',
            pane: {
                key: 'tab4', content: (
                    <div style={{ overflow: "overlay" }}>
                        <Calendar
                            inline
                            value={new Date()}
                            style={{ width: "100%" }}
                            onSelect={date => handleSelectDate(date, "inactive-date")}
                            dateTemplate={date => selectedInactiveDate(date, "inactive-date")}
                        >
                        </Calendar>
                    </div>
                )
            },
        },
        {
            menuItem: 'Ek Hakediş',
            pane: {
                key: 'tab5',
                content: (
                    <div style={{ overflow: "overlay" }}>
                        <Calendar
                            inline
                            value={new Date()}
                            style={{ width: "100%" }}
                            onSelect={date => handleSelectDate(date, "payment-progress")}
                            dateTemplate={date => selectedInactiveDate(date, "payment-progress")}
                        >
                        </Calendar>
                    </div>
                )
            },
        },
        {
            menuItem: 'Hakediş',
            pane: {
                key: 'tab6',
                content: (
                    <ProgressPayment
                        toastCallback={toastCallback}
                        id={product.id}
                        type="vehicleManagement"
                    />
                )
            },
        },
        {
            menuItem: 'Şubeler',
            pane: {
                key: 'tab7',
                content: (
                    <Button
                        label="Şubeye Bağla"
                        className="p-button-warning"
                        onClick={addBranch}
                    />

                )
            },
        }
    ];


    return (
        <>
            <Dialog
                visible={dialogVehicleManagement}
                style={{ width: "650px" }}
                header={product.id ? "Araç Güncelle" : "Araç Ekle"}
                modal
                footer={productDialogFooter("vehicle-mannagement-dialog")}
                onHide={hideDialog}
                blockScroll={true}
            >

                <Tab panes={vehicleManagementTabs} renderActiveOnly={false} />

            </Dialog>

            <Dialog
                visible={vehicleZoneDialog}
                style={{ width: "450px" }}
                onHide={vehicleHideDialog}
                footer={vehicleDialogFooter}
            >
                <p>Bölgeler</p>
                <dl>

                    <dl>
                        {Object.keys(vehicleZoneData).length > 0 && (
                            vehicleZoneData.data.data.map((zone, i) => {
                                return (
                                    <dt>
                                        {zone.name}
                                        {zone.zones.map((zonesDt, j) => {
                                            // return (
                                            //    <dd><Checkbox id={zonesDt.zone_id} key={j} defaultChecked={zonesDt.zone_id == zoneSelectedID[j] ? true : false} onChange={e => selectedZoneMethod(e)} /> {zonesDt.name}</dd>
                                            // )

                                            return (
                                                <dd><Checkbox id={zonesDt.zone_id} key={j} defaultChecked={zoneSelectedID.includes(zonesDt.zone_id)} onChange={e => selectedZoneMethod(e)} /> {zonesDt.name}</dd>
                                            )
                                        })}
                                    </dt>
                                )
                            })
                        )}
                    </dl>

                </dl>
            </Dialog>

            <Dialog
                visible={vehicleProjectDialog}
                style={{ width: "450px" }}
                onHide={projectHideDialog}
                footer={vehicleProjectDialogFooter}
            >
                <p>Projeler</p>
                {/* checked = all project options (vehicleProjectData) == number of selected projects(projectSelectedID) */}
                <span><Checkbox checked={Object.keys(vehicleProjectData).length > 0 && vehicleProjectData.data.data.length == projectSelectedID.length ? true : false} onChange={e => selectedProjectAll(e)} /> Tümünü Seç </span>
                <dl>

                    {Object.keys(vehicleProjectData).length > 0 && (
                        //data.data
                        vehicleProjectData.data.data.map((prj, i) => {
                            return (
                                <dd><Checkbox id={prj.project_id} key={i} checked={projectSelectedID.includes(prj.project_id)} onChange={e => selectedProjectMethod(e)} /> {prj.name}</dd>
                            )
                        })
                    )}

                </dl>
            </Dialog>

            {/* Date description dialog */}
            <Dialog
                visible={dateDescriptionDialog.opened}
                style={{ width: "450px" }}
                onHide={dateDescriptionHideDialog}
                footer={DialogFooter}
            >
                <p>{dateDescriptionDialog.title}</p>
                <dl>
                    <Input
                        action={{
                            color: 'teal',
                            labelPosition: 'left',
                            icon: 'calendar',
                            content: selectedDate && selectedDate.toLocaleDateString(),
                        }}
                        actionPosition='left'
                        placeholder={`${dateDescriptionDialog.placeholder}`}
                        // defaultValue={ inactive && inactive.reason}
                        type={`${dateDescriptionDialog.tabId == 'payment-progress' ? 'number' : 'text'}`}
                        size="huge"
                        onChange={event => setInactiveReason(event.target.value)}
                    />

                </dl>
            </Dialog>

            {/* confirm date delete */}
            <Dialog
                visible={deleteInactiveDateDialog}
                style={{ width: "250px" }}
                header="Uyarı"
                modal
                footer={deleteInactiveDateDialogFooter}
                onHide={hideDeleteInactiveDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle p-mr-3"
                        style={{ fontSize: "2rem" }}
                    />
                    {deleteDate && (
                        <span>
                            {formatDate(deleteDate.date)} silmek istediğinize emin misiniz <b></b>?
                        </span>
                    )}
                </div>
            </Dialog>

            {/* Vehicle add branch */}
            <VehicleAddBranch
                vehicleAddBranchDialog={vehicleAddBranchDialog}
                setVehicleAddBranchDialog={setVehicleAddBranchDialog}
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
                product={product}
            />
        </>
    );

}

export default VehicleManagement;




