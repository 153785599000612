import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/Table";
import { Auth } from "../../api/Auth";
import { adminGetOrderUnplanned } from "../../store/actions/orderAction";

import Layout from "components/Layout";

const AdminOrderGeocode = (props) => {
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();
  const getOrderList = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminGetOrderUnplanned());
  }, []);

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  return (
    <Layout
      {...props}
      heading="Kordinatlama"
      links={[{ name: "Genel", href: "/" }, { name: "Kordinatlama" }]}
    >
      <Table
        products={getOrderList.data}
        //unplannedOrderButtonShow={true}
        type="admin-order-geocode"
      />
    </Layout>
  );
};
export default AdminOrderGeocode;
