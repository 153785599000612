import TYPES from "../types";

const initialState = {
    data: ""
};

export const driverRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_DRIVERS_ROLE:
            return { ...state, drivers: action.payload.data };
        default:
            return state
    }
};
