import React from "react";
import { Box, FormControl, Grid, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DeleteSweep } from "@mui/icons-material";
import { Services } from "api/Services";
import _ from "lodash";
import MultipleSelect from "components/MultipleSelect";

const server = new Services();

export default function Filters({
  filterInitial,
  filter,
  setFilter,
  loading,
  getDashboardCounts,
}) {
  const [options, setOptions] = React.useState({
    customers: [],
    branches: [],
    cities: [],
    counties: [],
  });
  const getFilters = async () => {
    try {
      const res = await server.getCustomerManagementFilters();
      const data = res.entity.data[0];
      setOptions({
        customers: _.uniqBy(data.customers, (e) => e.key),
        branches: _.uniqBy(data.branches, (e) => e.key),
        cities: _.uniqBy(data.cities, (e) => e.key),
        counties: _.uniqBy(data.counties, (e) => e.key),
      });
    } catch (error) {
      //console.log(error);
    }
  };

  React.useEffect(() => {
    getFilters();
  }, []);

  const onChange = (name, value) => {
    setFilter((s) => ({ ...s, [name]: value }));
  };

  const resetFilter = () => {
    setFilter(filterInitial);
    getDashboardCounts(true);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Projeler</InputLabel>
            <MultipleSelect
              label="Projeler"
              name="customers"
              defaultValue={filter.customers}
              options={options.customers}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Şubeler</InputLabel>
            <MultipleSelect
              label="Şubeler"
              name="branches"
              defaultValue={filter.branches}
              options={options.branches}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>İller</InputLabel>
            <MultipleSelect
              label="İller"
              name="cities"
              defaultValue={filter.cities}
              options={options.cities}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>İlçeler</InputLabel>
            <MultipleSelect
              label="İlçeler"
              name="counties"
              defaultValue={filter.counties}
              options={options.counties}
              handleChange={(val, name) => onChange(name, val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <LoadingButton
            fullWidth
            color="error"
            loading={loading}
            startIcon={<DeleteSweep />}
            onClick={resetFilter}
          >
            Temizle
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
