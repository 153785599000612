import React, { useState, useEffect, useMemo, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import AutoComplete from "./AutoComplete";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import TYPES from "../../store/types";
import { useSelector, useDispatch } from 'react-redux';
import { geocodeEditSave } from "../../store/actions/orderAction";
import { Table } from 'semantic-ui-react';
import './GeocodeEditDialogStyle.css';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";


//marker files not found error
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

const GeocodeEditDialog = (props) => {

    const {
        pp,
        //geoocoderAddressUpdateDialog,
        setPP,
        rowE,
        //setGeocodeCoordinate,
        //geocodeCoordinate,
        selectedProduct1,
        //setSelectedSuggestionAdress
        comp,
        setDialogEdit,
        selectedDialogEdit,
        toastCallback,
        selectedDialogEditTarget,
        orderManagementAddressUpdate,
        product,
        setSelectedProduct1,
        setRowE,
        nextButton
        //onInputChangeProduct
    } = props;

    const [selectedSuggestionAdress, setSelectedSuggestionAdress] = useState(null);
    const [geocodeCoordinate, setGeocodeCoordinate] = useState({});
    const [orderManagementAddress, setOrderManagementAddress] = useState({
        "address": null,
        "city": null,
        "county": null,
        "latitude": null,
        "longitude": null
    });



    const dispatch = useDispatch();
    const markerRef = useRef(null);

    // Ungeocoded list
    const ungeocoded = useSelector(state => state.ungeocodedReducer);

    const ChangeView = ({ center, zoom }) => {
        const map = useMap();
        map.setView(center, zoom);
        return null
    }

    // useEffect(() => {
    //     console.log("geocode edit dialog product: ", product)
    // }, [product])

    useEffect(() => {
        selectedProduct1 && setGeocodeCoordinate({ "latitude": selectedProduct1.address.latitude, "longitude": selectedProduct1.address.longitude })
    }, [pp])


    useEffect(() => {
        selectedProduct1 && setGeocodeCoordinate({ "latitude": selectedProduct1.address.latitude, "longitude": selectedProduct1.address.longitude })
    }, [selectedProduct1])


    useEffect(() => {
        //     (product && product.address != undefined) && setOrderManagementAddress({
        //         address: product.address.address,
        //         city: product.address.city,
        //         county: product.address.county
        //    })
        //     (product && product.address != undefined) && setGeocodeCoordinate({ "latitude": product.address.latitude, "longitude": product.address.longitude })
        if (product && product.address != undefined) {
            setOrderManagementAddress({
                address: product.address.address,
                city: product.address.city,
                county: product.address.county
            })
            setGeocodeCoordinate({ "latitude": product.address.latitude, "longitude": product.address.longitude })
        }
    }, [product])




    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;

                if (marker != null) {
                    var draggedPoint = { "latitude": marker.getLatLng().lat, "longitude": marker.getLatLng().lng }
                    setGeocodeCoordinate(draggedPoint)
                }
            }
        }),
        [],
    )


    const nextGeocodeData = () => {
        let currentIndex = ungeocoded.data.findIndex(i => i.id == selectedProduct1.id);
        let nextIndex = (currentIndex + 1) % ungeocoded.data.length;
        let nextGData = ungeocoded.data[nextIndex];
        setSelectedProduct1(nextGData);
        let classUpdElement = Array.from(rowE[0].parentElement.childNodes).filter((d, i) => d.cells[0].textContent == nextGData.order_code);
        setRowE(classUpdElement);
        geoocoderAddressUpdateDialog("nextButton");
        //console.log("Filtered name: ", nextGData.customer.first_name);
    }


    const geocoderUpdateDialogFooter = (e) => {
        return (
            <React.Fragment>
                {nextButton && <Button
                    label="ileri"
                    className="p-button-success"
                    onClick={nextGeocodeData}
                />}
                <Button
                    label="Tamam"
                    className="p-button-warning"
                    onClick={(e) => geoocoderAddressUpdateDialog("close")}
                />
            </React.Fragment>
        )
    }

    const hideUpdateGeocoderDialog = () => {
        if (comp == "orderManagement") {
            setDialogEdit(false);
            orderManagementAddressUpdate != true && setGeocodeCoordinate(null)
        } else {
            setPP(false)
            rowE.pop()
            setGeocodeCoordinate(null)
        }

        // order clears address
        //setOrderManagementAddress({})
    }

    const geoocoderAddressUpdateDialog = (close) => {

        if (orderManagementAddressUpdate == null) {

            if (comp == "orderManagement") {
                let geocodeAddressEdit = { selected: selectedDialogEdit, newAddress: selectedSuggestionAdress, newCoodinate: geocodeCoordinate }
                //console.log(geocodeAddressEdit)
                dispatch(
                    geocodeEditSave(geocodeAddressEdit, toastCallback, setDialogEdit, selectedDialogEditTarget)
                )
                //selectedDialogEditTarget.target.parentElement.parentElement.parentElement.classList.add('upd');
                setGeocodeCoordinate(null)
            } else {
                let geocodeAddressCooedinate = { selected: selectedProduct1, newAddress: selectedSuggestionAdress, newCoodinate: geocodeCoordinate }

                dispatch({
                    type: TYPES.UPDATE_UNGEOCODED,
                    payload: geocodeAddressCooedinate
                });
                close == "close" && setPP(false)
                //rowE.classList.add('upd');
                rowE.map(e => e.classList.add('upd'))
                setGeocodeCoordinate(null)
            }
        }

        if (orderManagementAddressUpdate == true) {
            if (orderManagementAddress.address) product.address = orderManagementAddress.address;
            orderManagementAddress.address ? product.city = orderManagementAddress.city : product.address.city = orderManagementAddress.city;
            orderManagementAddress.address ? product.county = orderManagementAddress.county : product.address.county = orderManagementAddress.county;
            product.latitude = geocodeCoordinate.latitude;
            product.longitude = geocodeCoordinate.longitude;
            setDialogEdit(false);
        }


    }

    // x coordinate (input)
    // y coordinate (input)
    // updates input values
    const onInputChange = (e, name) => {
        // console.log("e2: ", e);
        // console.log("e3: ", name);
        let val = (e.target && e.target.value.replace(/[^\d.-]/g, '')) || "";
        setGeocodeCoordinate({ "latitude": name == "latitude" ? val : geocodeCoordinate && geocodeCoordinate.latitude || selectedDialogEdit.address.latitude, "longitude": name == "longitude" ? val : geocodeCoordinate && geocodeCoordinate.longitude || selectedDialogEdit.address.longitude })

    }





    return (


        <Dialog
            visible={pp}
            style={{ width: "1100px", height: "600px" }}
            header="Adres Güncelle"
            modal
            footer={geocoderUpdateDialogFooter}
            onHide={hideUpdateGeocoderDialog}
            blockScroll={true}
            resizable={false}
        >
            <div className="confirmation-content" style={{ "display": "flex" }}>
                {/* <p>{selectedProduct1 && selectedProduct1.name}</p> */}
                <div style={{ "width": "30%" }}>
                    {/* <div className="sizes">
                        <b>Müşteri Adı : </b> <span>{selectedProduct1 && selectedProduct1.address.first_name} </span> <br />
                        <b>Müşteri Adresi : {selectedProduct1 && selectedProduct1.address.address || selectedDialogEdit && selectedDialogEdit.address.address}</b> <br /> */}

                    {/* < AutoComplete setGeocodeCoordinate={setGeocodeCoordinate} onChange={(selectedAdress) => setSelectedSuggestionAdress(selectedAdress)} /> */}
                    {/* <InputText type="text" className="p-d-block p-mb-2 w100" placeholder="X" />
                    <InputText type="text" className="p-d-block p-mb-2 w100" placeholder="Y" /> */}

                    {/* </div> */}

                    {/* <div className="geocodeEditDetailData">Detay Bilgileri</div> */}
                    {orderManagementAddressUpdate == null && <Table basic style={{ "width": "95%" }}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >Müşteri Adı </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                {/* <Table.Cell> <b> Müşteri Adı : </b> </Table.Cell> */}
                                <Table.Cell>{selectedProduct1 && selectedProduct1.address.first_name || selectedDialogEdit && selectedDialogEdit.address.first_name + " " + (selectedDialogEdit.address.last_name != null ? selectedDialogEdit.address.last_name : "")}</Table.Cell>

                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >Müşteri Adresi </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                {/* <Table.Cell style={{"width": "126   px"}}><b> Müşteri Adresi : </b></Table.Cell> */}
                                <Table.Cell > {selectedProduct1 && selectedProduct1.address.address || selectedDialogEdit && selectedDialogEdit.address.address}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >İl/İlçe </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                {/* <Table.Cell style={{"width": "126   px"}}><b> Müşteri Adresi : </b></Table.Cell> */}
                                <Table.Cell > {selectedProduct1 && selectedProduct1.address.city + " / " + selectedProduct1.address.county || selectedDialogEdit && selectedDialogEdit.address.city + " / " + selectedDialogEdit.address.county}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > X Koordinatı </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <InputText className="geocodeEditDetailInput" value={geocodeCoordinate && geocodeCoordinate.longitude || selectedDialogEdit && selectedDialogEdit.address.longitude} onChange={(e) => onInputChange(e, 'longitude')} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > Y Koordinatı </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <InputText className="geocodeEditDetailInput" value={geocodeCoordinate && geocodeCoordinate.latitude || selectedDialogEdit && selectedDialogEdit.address.latitude} onChange={(e) => onInputChange(e, 'latitude')} />
                                </Table.Cell>
                            </Table.Row>

                        </Table.Body>
                    </Table>
                    }

                    {/* order-management-update-address + */}
                    {orderManagementAddressUpdate == true && <Table basic style={{ "width": "95%" }}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > Adres </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell >
                                    <InputTextarea className="geocodeEditDetailInput" style={{ "height": "75px" }} value={orderManagementAddress.address ? orderManagementAddress.address : product.address.address} onChange={(e) => setOrderManagementAddress({ ...orderManagementAddress, address: e.target.value })} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >İl </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell >
                                    <InputText className="geocodeEditDetailInput" value={orderManagementAddress.city ? orderManagementAddress.city : product.address.city || product.city} onChange={(e) => setOrderManagementAddress({ ...orderManagementAddress, city: e.target.value })} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} >İlçe </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell >
                                    <InputText className="geocodeEditDetailInput" value={orderManagementAddress.county ? orderManagementAddress.county : product.address.county || product.county} onChange={(e) => setOrderManagementAddress({ ...orderManagementAddress, county: e.target.value })} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > X Koordinatı </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <InputText className="geocodeEditDetailInput" value={geocodeCoordinate && geocodeCoordinate.longitude || selectedDialogEdit && selectedDialogEdit.address.longitude} onChange={(e) => onInputChange(e, 'longitude')} /> {/** onChange={(e) => onInputChange(e, 'longitude')} */}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className="geocodeEditDetailDataTableHeader"> <b style={{ "color": "#7c9fff", "fontWeight": "500" }} > Y Koordinatı </b>  </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <InputText className="geocodeEditDetailInput" value={geocodeCoordinate && geocodeCoordinate.latitude || selectedDialogEdit && selectedDialogEdit.address.latitude} onChange={(e) => onInputChange(e, 'latitude')} /> {/**onChange={(e) => onInputChange(e, 'latitude')} */}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    }
                    {/* order-management-update-address - */}

                </div>
                <div style={{ "width": "70%" }}>
                    < AutoComplete setGeocodeCoordinate={setGeocodeCoordinate} onChange={(selectedAdress) => setSelectedSuggestionAdress(selectedAdress)} />

                    <MapContainer style={{ width: "100%", height: "400px", marginTop: "10px" }} center={[41.015137, 28.979530]} scrollWheelZoom={true} >
                        <ChangeView center={geocodeCoordinate && Object.keys(geocodeCoordinate).length > 0 ? [geocodeCoordinate.latitude, geocodeCoordinate.longitude] : selectedDialogEdit && [selectedDialogEdit.address.latitude, selectedDialogEdit.address.longitude] || [0, 0]} zoom={18} />
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker ref={markerRef} eventHandlers={eventHandlers} position={geocodeCoordinate && Object.keys(geocodeCoordinate).length > 0 ? [geocodeCoordinate.latitude, geocodeCoordinate.longitude] : selectedDialogEdit && [selectedDialogEdit.address.latitude, selectedDialogEdit.address.longitude] || [0, 0]} draggable={true}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>

            </div>
        </Dialog>


    )
}

export default GeocodeEditDialog