// @mui
import React from "react";
import {
  Box,
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  Divider,
  Button,
} from "@mui/material";
// utils
import qs from "query-string";

// components
import Label from "components/Label";
import Scrollbar from "components/Scrollbar";
import Iconify from "components/Iconify";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "api/Env";
import TableNoData from "components/CustomTable/TableNoData";
import { useDispatch, useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function ProjectRanking({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = useSelector((s) => s.dashboardFilterReducer.data);
  const newData = data.map((item, index) => {
    return {
      ...item,
      rank: `Top ${index + 1}`,
    };
  });
  const [showAll, setShowAll] = React.useState(false);

  const dataToShow = showAll ? newData : newData.slice(0, 5);

  return (
    <Card>
      <CardHeader title="Proje Sıralaması" sx={{ mb: 3 }} />
      <Scrollbar
        sx={{
          "& .simplebar-placeholder": {
            height: "0 !important",
          },
        }}
      >
        <TableContainer sx={{ minWidth: 70, height: "min-content !important" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Proje Bilgisi</TableCell>
                <TableCell>Sipariş</TableCell>
                <TableCell>Paket</TableCell>
                <TableCell>Desi</TableCell>
                <TableCell align="right">Sıralama</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataToShow.length > 0 ? (
                dataToShow.map((row) => {
                  const photo = row.customer_logo
                    ? `${BASE_URL}/api/public/document/${row.customer_logo}`
                    : null;

                  const newFilter = {
                    ...filters,
                    customers: [
                      ...filters.customers,
                      { value: row.name, key: row.id },
                    ],
                  };

                  return (
                    <TableRow
                      key={row.name}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch({
                          type: "DASHBOARD_FILTER_SET",
                          payload: newFilter,
                        });
                        history.push({
                          pathname: "/shipments-detail",
                          search: qs.stringify({
                            pageTitle: `${row.name} Proje Siparişleri`,
                          }),
                        });
                      }}
                    >
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar alt={row.name} src={photo || row.avatar} />
                          <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2">
                              {row.name}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Label
                          sx={{ cursor: "pointer" }}
                          variant="ghost"
                          color="primary"
                        >
                          {row.order_count} adet
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Label
                          sx={{ cursor: "pointer" }}
                          variant="ghost"
                          color="secondary"
                        >
                          {row.value} adet
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption">{row.deci}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="caption">{row.rank}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableNoData isNotFound colSpan={5} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />
      {data.length > 5 && (
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="inherit"
            endIcon={
              showAll ? (
                <Iconify icon="bx:bx-chevron-down" />
              ) : (
                <Iconify icon="bx:bx-chevron-right" />
              )
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Daha az göster" : "Tümünü göster"}
          </Button>
        </Box>
      )}
    </Card>
  );
}
