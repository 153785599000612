import TYPES from "../types";
import { Services } from "../../api/Services";
import { loadingActive } from "helper";


const server = new Services();

export const customerList = (data) => {
  // return {
  //   type: TYPES.LISTING,
  //   payload: data,
  // };
  // const getServices = new Services();

  return async (dispatch) => {
    loadingActive(dispatch, true);
    server.
      getList().then((data) => {
        dispatch({
          type: TYPES.LISTING,
          payload: data.entity
        });
        loadingActive(dispatch, false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const getUserContacts = (data) => {
  // const getServices = new Services();

  return async (dispatch) => {
    server.
      getUserContacts().then((data) => {
        dispatch({
          type: TYPES.GET_USER_CONTACTS,
          payload: data.entity
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const customerAdd = (data, callback, onError) => {

  let ob = {
    "address": {
      "address": data.address,
      "address_code_ext": "",
      "address_line": "",
      "city": data.city,
      "code": "",
      "country3": "",
      "county": data.county,
      "district": "",
      "email": "",
      "first_name": data.compony,
      "gsm_number": data.phone_1,
      "last_name": "",
      "latitude": 0,
      "longitude": 0,
      "post_code": "",
      "state": "",
      "street": ""
    },
    "is_follow": data.firm_is_follow,
    "contact_user_oid": data.customer.id,
    "code": data.code,
    "name": data.name,
    "barcode": data.barcode || ""
  };

  // const getServices = new Services();

  return async (dispatch) => {
    server.
      addCustomer(ob).then((data) => {
        dispatch({
          type: TYPES.CUSTOMER_ADD,
          payload: data.entity.data[0]
        });
        callback(data.entity.success);
      })
      .catch(error => {
        onError(error.message);
      });
  }
};

export const customerUpdate = (customer_data, callback, onError) => {

  let customer_address = customer_data.address;
  var address;

  if (typeof customer_address === "object" && customer_address != null) address = customer_address.address;
  else address = customer_address;

  let formatData = {
    "id": customer_data.id,
    "code": customer_data.code,
    "name": customer_data.name,
    "contactUserOid": customer_data.contact.id, 
    //"barcode": customer_data.barcode,
    "address": {
      "address": address,
      "address_code_ext": "",
      "address_line": "",
      "city": "",
      "code": "",
      "country": "",
      "county": customer_data.county,
      "district": "",
      "email": "",
      "first_name": customer_data.compony,
      "gsm_number": customer_data.phone_1,
      "last_name": "",
      "latitude": 0,
      "longitude": 0,
      "post_code": "",
      "state": "",
      "street": ""
    }
  };

  return async dispatch => {
    server.updateCustomer(formatData)
      .then(res => {
        dispatch({
          type: TYPES.CUSTOMER_UPDATE,
          paylaod: res.entity.data[0]
        });
        callback(res.entity.data[0]);
      })
      .catch(error => {
        console.log(error)
      })
  }
}


export const customerDelete = (data, callback, onError) => {

  return async (dispatch) => {
    server
      .delCustomer(data.id)
      .then(res => {
        dispatch({
          type: TYPES.CUSTOMER_DELETE,
          paylaod: res.entity
        });
        callback(res.entity.success);
      })
      .catch(error => { onError(error.message) });
  }

};
