
import { getStatus } from "helper";
import { convertTimezone } from "utils/convertTimezone";
export const tableManipulation = (data) => {
    let dup_array;
    if (data) {
        dup_array = JSON.parse(JSON.stringify(data))
        dup_array.forEach((cell_value) => {
            cell_value.filter = getStatus(cell_value.delivery_status);
        })
    }
    return dup_array
}

export const tableManipulationAdminOrderManagement = (data) => {
    let dup_array;
    if (data) {
        dup_array = JSON.parse(JSON.stringify(data))
        dup_array.forEach((cell_value) => {
            cell_value.created_at = convertTimezone(cell_value.created_at);
        })
    }
    return dup_array
}