import React, { useEffect, useState, useRef } from "react";
import Table from "../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "../../api/Auth";
import { Toast } from "primereact/toast";
import {
  vehicleUsageReportList,
  vehicleUsageReportListBetween,
} from "../../store/actions/vehicleUsageReportAction";
import Layout from "components/Layout";

function VehicleUsageReport(props) {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.vehicleUsageReportReducer);
  const [dates2, setDates2] = useState(null);
  const toast = useRef(null);

  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  const toastCallback = (callback, message) => {
    if (callback === "success") {
      toast.current.show({
        severity: "success",
        //summary: t('successfull'),
        summary: "Başarılı",
        //detail: message ? message : "Updated",
        detail: message ? message : "Güncellendi",
        life: 3000,
      });
    }
    if (callback === "error") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: message ? message : "error",
        life: 3000,
      });
    }
    if (callback === "warn") {
      toast.current.show({
        severity: "warn",
        summary: "Uyarı",
        detail: message ? message : "warn",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    dispatch(vehicleUsageReportList(new Date().toISOString()));
  }, []);

  useEffect(() => {
    if (dates2 === null || dates2[1] === null) return;
    let start = new Date(dates2[0].setHours(dates2[0].getHours() + 5));
    let end = new Date(dates2[1].setHours(dates2[1].getHours() + 5));
    // dispatch(getProjectBetweenDate(start.toISOString(), end.toISOString()));
    //console.log("TTTT: ", dates2)

    dispatch(
      vehicleUsageReportListBetween(
        start.toISOString().split("T")[0],
        end.toISOString().split("T")[0],
        toastCallback
      )
    );
  }, [dates2]);

  return (
    <Layout
      {...props}
      heading="Araç Kullanım"
      links={[{ name: "Genel", href: "/" }, { name: "Araç Kullanım" }]}
    >
      <Toast ref={toast} />
      <Table
        products={(Object.keys(list).length > 0 && list.data.data) || list.data}
        type="vehicle-usage-report"
        dates2={dates2}
        setDates2={setDates2}
      />
    </Layout>
  );
}

export default VehicleUsageReport;
