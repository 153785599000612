import TYPES from "../types";
import { Services } from "../../api/Services";

const server = new Services();

export const getMessages = (id) => {
  return async (dispatch) => {
    server
      .getNotificationMessages(id)
      .then((data) => {
        let tableDataObject = [
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişinizin teslimat adresini düzenleyebilirsiniz.",
            notification_type: "SMS_MODIFY_ADDRESS",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişinizin teslimat adresini onaylayabilirsiniz.",
            notification_type: "SMS_VERIFY_ADDRESS",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz planlamaya hazırdır.",
            notification_type: "SMS_READY_FOR_PLANNING",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz {{QUANTITY}} paket olmak üzere dağıtılmak üzere yola çıkmıştır. {{URL}}",
            notification_type: "SMS_MODIFY_DELIVERY_DETAIL",
            active: false,
          },
          {
            notification_message: "",
            notification_type: "SMS_VERIFY_FOR_NEW_ADDRESS",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz {{POSTPONED_DATE}} tarihinde dağıtıma çıkarılacaktır.",
            notification_type: "SMS_TOMORROW",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz {{QUANTITY}} paket olmak üzere birazdan teslim edilecek. Teslimat seçeneklerini değiştirek için tıklayınız. {{URL}}",
            notification_type: "SMS_MODIFY_DELIVERY_OPTION",
            active: false,
          },
          {
            notification_message:
              "{{URL}} Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz {{QUANTITY}} paket olmak üzere birazdan teslim edilecek. Takip etmek için tıklayınız. {{URL}}",
            notification_type: "SMS_LIVE",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz {{QUANTITY}} {{NOT_VISITED_REASON}} nedeniyle teslim edilememiştir.",
            notification_type: "SMS_NOT_DELIVERED",
            active: false,
          },
          {
            notification_message:
              "Sayın {{NAME}}, {{ORDER_CODE}} numaralı siparişiniz {{QUANTITY}} paket olmak üzere birazdan teslim edilmiştir. Puan vermek için tıklayınız. {{URL}}",
            notification_type: "SMS_DELIVERED",
            active: false,
          },
          {
            notification_message:
              "",
            notification_type: "SMS_ORDER_ACCEPTED",
            active: false,
          }
        ];

        var tableDataClone = [...tableDataObject];
        var result = tableDataObject.filter((o1, i) =>
          data.entity.data.some((o2) => {
            if (o1.notification_type === o2.notification_type) {
              tableDataClone[i] = { ...tableDataClone[i], active: o2.active };
              tableDataClone[i] = {
                ...tableDataClone[i],
                notification_message: o2.notification_message,
              };
            }
          })
        );

        dispatch({
          type: TYPES.GET_NOTIFICATION_MESSAGES,
          payload: tableDataClone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


export const saveMessages = (data, cb , id) => {
  // return {
  //   type: TYPES.LISTING,
  //   payload: data,
  // };
  // const getServices = new Services();

  let _dataMessages = data;
  // _dataMessages.data.map((x) => {
  //     delete x.title;
  //     delete x.sendtime
  // })

  return async (dispatch) => {
    server
      .saveNotificationMessages(_dataMessages.data, id)
      .then((data) => {
        if (data.status == 200) {
          //alert("Kaydedildi");
          cb()
        }
        // dispatch({
        //     type: TYPES.GET_NOTIFICATION_MESSAGES,
        //     payload: array1
        // });
      })
      .catch((error) => {
        alert("hata oluştu");
        console.log(error);
      });
  };
};

export const test = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.NOTIFICATION_CHECKED,
      payload: data,
    });
  };
};
