import TYPES from "../types";
import { Services } from "../../api/Services";
//import { loadingActive } from "helper";


const server = new Services();

export const vehicleList = (data) => {

    return async (dispatch) => {
        //loadingActive(dispatch, true);
        server.
            getVehicle().then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_LIST_ALL,
                    payload: data.entity
                });
                //loadingActive(dispatch, false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // let testVehicleData = [
    //     {
    //         "id": "0000000079126f16017917f596aa3e5f",
    //         "created_at": null,
    //         "lastupdated": "2021-04-28T10:10:41",
    //         "vehicle_id": "araç 8",
    //         "vehicle_brand": {},
    //         "vehicle_model": {
    //             "id": "851da2c296a44de0a4d7167c62e2050a",
    //             "name": "Egea"
    //         },
    //         "company": {
    //             "id": "8a8081af7c0de0ee017c127b63f4004f",
    //             "name": "AybukeX"
    //         },
    //         "name": "araç 8",
    //         "status": "ACTIVE",
    //         "vehicle_licence": {
    //             "id": "8a8081897b500126017b500394f40000",
    //             "created_at": "2021-08-16T17:30:17",
    //             "lastupdated": "2021-08-16T17:34:13",
    //             "doc_type": "VEHICLE_LICENCE",
    //             "doc_date": null,
    //             "doc_expiration": "2021-08-16T17:34:13",
    //             "doc_url": "/2021/08/16/D618790A-7C64-47E9-A8D4-71B742291443_1629135016886.jpg",
    //             "doc_name": "D618790A-7C64-47E9-A8D4-71B742291443.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "k_certificate": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2021-10-21T12:05:15",
    //             "doc_url": null,
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "insurance": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2022-10-21T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "inception": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2024-10-21T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "refrigerate": true,
    //         "capacity": 100,
    //         "maintanence_date": "2022-12-21T12:05:15",
    //         "maintanence_km": 234,
    //         "status_reason": "xxxxxasxasx",
    //         "zones": [
    //             "87ea4955d8ce453693168d2fb3114fa3",
    //             "04a84e4a4030420ea156d2ab7d82ee89"
    //         ],
    //         "drivers": [{ "first_name": "Ali", "id": "8a8081af7c0de0ee017c12a5383d0062" }],
    //         // "drivers": [
    //         //     {
    //         //         "id": "8a8081af7c0de0ee017c12a5383d0062",
    //         //         "created_at": "2021-09-23T12:33:10",
    //         //         "lastupdated": "2021-09-23T12:33:10",
    //         //         "username": "5053845551",
    //         //         "status": "PENDING",
    //         //         "email": "Aliali@gmail.com",
    //         //         "phone": "5053845551",
    //         //         "user_type": "DRIVER",
    //         //         "first_name": "Ali",
    //         //         "last_name": "Ali",
    //         //         "company": {
    //         //             "id": "8a8081af7c0de0ee017c127b63f4004f",
    //         //             "name": "AybukeX"
    //         //         },
    //         //         "employement_date": null,
    //         //         "birthday": "1991-09-21T21:00:00",
    //         //         "gender": "FEMALE",
    //         //         "education_status": null,
    //         //         "photo": null,
    //         //         "criminal_record": {
    //         //             "id": "8a8081af7c0de0ee017c12a4697f005f",
    //         //             "created_at": "2021-09-23T12:32:17",
    //         //             "lastupdated": "2021-09-23T12:33:10",
    //         //             "doc_type": "CRIMINAL_RECORD",
    //         //             "doc_date": null,
    //         //             "doc_expiration": null,
    //         //             "doc_url": "/2021/09/23/0431F12A-8BA2-4013-B51E-04901996A8EA_1632400337272.jpg",
    //         //             "doc_name": "0431F12A-8BA2-4013-B51E-04901996A8EA.jpg",
    //         //             "doc_extension": "jpg"
    //         //         },
    //         //         "licence_doc": {
    //         //             "id": "8a8081af7c0de0ee017c12a487de0060",
    //         //             "created_at": "2021-09-23T12:32:25",
    //         //             "lastupdated": "2021-09-23T12:33:10",
    //         //             "doc_type": "DRIVER_LICENCE",
    //         //             "doc_date": null,
    //         //             "doc_expiration": "2028-09-23T12:26:10",
    //         //             "doc_url": "/2021/09/23/9955B215-9BB0-4EE7-ABC2-4A4CFA6F8F8D_1632400345051.jpg",
    //         //             "doc_name": "9955B215-9BB0-4EE7-ABC2-4A4CFA6F8F8D.jpg",
    //         //             "doc_extension": "jpg"
    //         //         },
    //         //         "src_doc": {
    //         //             "id": "8a8081af7c0de0ee017c12a428b5005e",
    //         //             "created_at": "2021-09-23T12:32:00",
    //         //             "lastupdated": "2021-09-23T12:33:10",
    //         //             "doc_type": "DRIVER_SRC",
    //         //             "doc_date": "2019-09-22T21:00:00",
    //         //             "doc_expiration": "2025-09-23T12:31:44",
    //         //             "doc_url": "/2021/09/23/939AB0E5-0390-46D7-9A7F-9DE7D6ED8C26_1632400320689.jpg",
    //         //             "doc_name": "939AB0E5-0390-46D7-9A7F-9DE7D6ED8C26.jpg",
    //         //             "doc_extension": "jpg"
    //         //         },
    //         //         "psycho_doc": null,
    //         //         "licence_number": "112233",
    //         //         "status_reason": null,
    //         //         "vehicles": []
    //         //     }
    //         // ],
    //         "projects": [
    //             "6676df1c07ff4a1099cb5a3567edeff9",
    //             "ea6725d69d384dfba838564e265345cd"
    //         ]
    //     },
    //     {
    //         "id": "0000000079126f16017917f596aa3e5f",
    //         "created_at": null,
    //         "lastupdated": "2021-04-28T10:10:41",
    //         "vehicle_id": "araç 23",
    //         "vehicle_brand": {},
    //         "vehicle_model": {
    //             "id": "757e37d4147942ffadedb21053eaf103",
    //             "name": "C3"
    //         },
    //         "company": {
    //             "id": "8a8081887b4ff2d4017b4ff5900a0003",
    //             "name": "Badc"
    //         },
    //         "name": "araç 23",
    //         "status": "ACTIVE",
    //         "vehicle_licence": {
    //             "id": "8a8081897b500126017b500394f40000",
    //             "created_at": "2021-08-16T17:30:17",
    //             "lastupdated": "2021-08-16T17:34:13",
    //             "doc_type": "VEHICLE_LICENCE",
    //             "doc_date": null,
    //             "doc_expiration": "2021-08-16T17:34:13",
    //             "doc_url": "/2021/08/16/D618790A-7C64-47E9-A8D4-71B742291443_1629135016886.jpg",
    //             "doc_name": "D618790A-7C64-47E9-A8D4-71B742291443.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "k_certificate": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2033-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2022-11-17T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "insurance": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2022-10-21T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "inception": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2024-10-21T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "refrigerate": true,
    //         "capacity": 100,
    //         "maintanence_date": "2022-12-21T12:05:15",
    //         "maintanence_km": 234,
    //         "status_reason": "xxxxxasxasx",
    //         "zones": [
    //             "583a640da86d48ee926873554b658dd3",
    //             "6f2d975d0be443c5ae144677750cd23f"
    //         ],
    //         "drivers": [{ "id": "8a8081897b500126017b500730900008", "first_name": "Ghost" }],
    //         "projects": [
    //             "ea6725d69d384dfba838564e265345cd"
    //         ]
    //     },
    //     {
    //         "id": "0000000079126f16017917f596aa3e5f",
    //         "created_at": null,
    //         "lastupdated": "2021-04-28T10:10:41",
    //         "vehicle_id": "araç 44",
    //         "vehicle_brand": {},
    //         "vehicle_model": {
    //             "id": "87ec044fb21e4826ba3bf14603a8ff30",
    //             "name": "Doblo"
    //         },
    //         "company": {
    //             "id": "402881b97b4fc075017b4fdbf5e00005",
    //             "name": "Test"
    //         },
    //         "name": "araç 44",
    //         "status": "ACTIVE",
    //         "vehicle_licence": {
    //             "id": "8a8081897b500126017b500394f40000",
    //             "created_at": "2021-08-16T17:30:17",
    //             "lastupdated": "2021-08-16T17:34:13",
    //             "doc_type": "VEHICLE_LICENCE",
    //             "doc_date": null,
    //             "doc_expiration": "2021-08-16T17:34:13",
    //             "doc_url": "/2021/08/16/D618790A-7C64-47E9-A8D4-71B742291443_1629135016886.jpg",
    //             "doc_name": "D618790A-7C64-47E9-A8D4-71B742291443.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "k_certificate": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2022-12-07T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "insurance": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2022-10-21T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "inception": {
    //             "id": "8a8081887b4ff2d4017b4ff785280005",
    //             "created_at": "2021-08-16T17:17:06",
    //             "lastupdated": "2021-10-21T12:05:15",
    //             "doc_type": "VEHICLE_K_CERTIFICATE",
    //             "doc_date": null,
    //             "doc_expiration": "2024-10-21T12:05:15",
    //             "doc_url": "/2021/08/16/93E5373A-EBB2-4839-A90A-0C848186B27D_1629134226372.jpg",
    //             "doc_name": "93E5373A-EBB2-4839-A90A-0C848186B27D.jpg",
    //             "doc_extension": "jpg"
    //         },
    //         "refrigerate": false,
    //         "capacity": 100,
    //         "maintanence_date": "2022-12-21T12:05:15",
    //         "maintanence_km": 234,
    //         "status_reason": "xxxxxasxasx",
    //         "zones": [
    //             "583a640da86d48ee926873554b658dd3",
    //             "04a84e4a4030420ea156d2ab7d82ee89"
    //         ],
    //         "drivers": [{ "id": "402881b97b4fc075017b4fdbd1610002", "first_name": "Özgür" }],
    //         "projects": [
    //             "6676df1c07ff4a1099cb5a3567edeff9"
    //         ]
    //     }
    // ]

    // return {
    //     type: TYPES.GET_VEHICLE_LIST_ALL,
    //     payload: testVehicleData
    // };
};


export const vehicleZoneList = (data) => {

    return async (dispatch) => {
        server.
            getVehicleZone().then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_ZONE_LIST_ALL,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

};



export const vehicleProjectList = (data) => {

    return async (dispatch) => {
        server.
            getVehicleProject().then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_PROJECT_LIST_ALL,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    // let testProjectData = [
    //     { "id": "1", "name": "A Projesi" },
    //     { "id": "2", "name": "B Projesi" },
    //     { "id": "3", "name": "C Projesi" },
    //     { "id": "4", "name": "D Projesi" },
    //     { "id": "5", "name": "E Projesi" },
    //     { "id": "6", "name": "F Projesi" },
    //     { "id": "7", "name": "G Projesi" }
    // ]

    // return {
    //     type: TYPES.GET_VEHICLE_PROJECT_LIST_ALL,
    //     payload: testProjectData
    // };


};


export const vehicleModelList = (data) => {

    return async (dispatch) => {
        server.
            getVehicleModels().then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_MODELS_LIST_ALL,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    // let testProjectData = [
    //     { "id": "1", "name": "A Projesi" },
    //     { "id": "2", "name": "B Projesi" },
    //     { "id": "3", "name": "C Projesi" },
    //     { "id": "4", "name": "D Projesi" },
    //     { "id": "5", "name": "E Projesi" },
    //     { "id": "6", "name": "F Projesi" },
    //     { "id": "7", "name": "G Projesi" }
    // ]

    // return {
    //     type: TYPES.GET_VEHICLE_PROJECT_LIST_ALL,
    //     payload: testProjectData
    // };


};



export const vehicleCompanyList = (data) => {

    return async (dispatch) => {
        server.
            getVehicleCompany().then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_COMPANY_LIST_ALL,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }


};


export const vehicleDriversList = (id) => {

    return async (dispatch) => {
        server.
            getVehicleDrivers(id).then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_DRIVERS_LIST_ALL,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};


export const vehicleStatus = (id, reason, status, callback) => {

    return async (dispatch) => {
        server.
            changeVehicleStatus(id, reason, status).then((data) => {
                dispatch({
                    type: TYPES.VEHICLE_STATUS,
                    payload: data.entity
                });
                callback(data.entity);
            })
            .catch((error) => {
                console.log(error);
            });
    }
};



export const vehicleAdd = (dt, callback, onError) => {

    return async (dispatch) => {
        server.
            vehicleNewAdd(dt).then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_ADD,
                    payload: data.entity
                });
                callback(data.entity);
            })
            .catch(error => {
                onError(error);
            });
    }


    // let add = [
    //     {
    //         "id": "8a8081af7c0de0ee017c127b63f4004f",
    //         "created_at": "2021-09-23T11:47:28",
    //         "lastupdated": "2021-09-23T12:20:51",
    //         "company_name": "AybukeX",
    //         "user": {
    //             "id": "8a8081af7c0de0ee017c127b63d7004c",
    //             "created_at": "2021-09-23T11:47:28",
    //             "lastupdated": "2021-09-23T11:47:38",
    //             "username": "5313147191",
    //             "status": "PENDING",
    //             "email": "aybukecakici98@gmail.com",
    //             "phone": "5313147191",
    //             "user_type": "COMPANY_OWNER",
    //             "first_name": "Aybuke",
    //             "last_name": "Cakici",
    //             "employement_date": null,
    //             "birthday": "1998-12-07T22:00:00",
    //             "gender": "FEMALE",
    //             "education_status": null,
    //             "photo_url": null,
    //             "identity_number": "11111111110"
    //         },
    //         "company_type": "PERSONEL",
    //         "employement_date": null,
    //         "advertisement": null,
    //         "evaluation_result": "APPROVED",
    //         "description": null,
    //         "vehicle_option": "HAS_VEHICLE",
    //         "has_delivery_exprience": true,
    //         "drivers": [
    //             {
    //                 "id": "8a8081af7c0de0ee017c12a5383d0062",
    //                 "created_at": "2021-09-23T12:33:10",
    //                 "lastupdated": "2021-09-23T12:33:10",
    //                 "username": "5053845551",
    //                 "status": "PENDING",
    //                 "email": "Aliali@gmail.com",
    //                 "phone": "5053845551",
    //                 "user_type": "DRIVER",
    //                 "first_name": "Ali",
    //                 "last_name": "Ali",
    //                 "company": {
    //                     "id": "8a8081af7c0de0ee017c127b63f4004f",
    //                     "name": "AybukeX"
    //                 },
    //                 "employement_date": null,
    //                 "birthday": "1991-09-21T21:00:00",
    //                 "gender": "FEMALE",
    //                 "education_status": null,
    //                 "photo": null,
    //                 "criminal_record": {
    //                     "id": "8a8081af7c0de0ee017c12a4697f005f",
    //                     "created_at": "2021-09-23T12:32:17",
    //                     "lastupdated": "2021-09-23T12:33:10",
    //                     "doc_type": "CRIMINAL_RECORD",
    //                     "doc_date": null,
    //                     "doc_expiration": null,
    //                     "doc_url": "/2021/09/23/0431F12A-8BA2-4013-B51E-04901996A8EA_1632400337272.jpg",
    //                     "doc_name": "0431F12A-8BA2-4013-B51E-04901996A8EA.jpg",
    //                     "doc_extension": "jpg"
    //                 },
    //                 "licence_doc": {
    //                     "id": "8a8081af7c0de0ee017c12a487de0060",
    //                     "created_at": "2021-09-23T12:32:25",
    //                     "lastupdated": "2021-09-23T12:33:10",
    //                     "doc_type": "DRIVER_LICENCE",
    //                     "doc_date": null,
    //                     "doc_expiration": "2028-09-23T12:26:10",
    //                     "doc_url": "/2021/09/23/9955B215-9BB0-4EE7-ABC2-4A4CFA6F8F8D_1632400345051.jpg",
    //                     "doc_name": "9955B215-9BB0-4EE7-ABC2-4A4CFA6F8F8D.jpg",
    //                     "doc_extension": "jpg"
    //                 },
    //                 "src_doc": {
    //                     "id": "8a8081af7c0de0ee017c12a428b5005e",
    //                     "created_at": "2021-09-23T12:32:00",
    //                     "lastupdated": "2021-09-23T12:33:10",
    //                     "doc_type": "DRIVER_SRC",
    //                     "doc_date": "2019-09-22T21:00:00",
    //                     "doc_expiration": "2025-09-23T12:31:44",
    //                     "doc_url": "/2021/09/23/939AB0E5-0390-46D7-9A7F-9DE7D6ED8C26_1632400320689.jpg",
    //                     "doc_name": "939AB0E5-0390-46D7-9A7F-9DE7D6ED8C26.jpg",
    //                     "doc_extension": "jpg"
    //                 },
    //                 "psycho_doc": null,
    //                 "licence_number": "112233",
    //                 "status_reason": null,
    //                 "vehicles": [
    //                     "0000000079126f16017917f596aa3e5f"
    //                 ]
    //             },
    //             {
    //                 "id": "8a8081af7c0de0ee017c127b63d7004c",
    //                 "created_at": "2021-09-23T11:47:28",
    //                 "lastupdated": "2021-09-23T11:47:38",
    //                 "username": "5313147191",
    //                 "status": "PENDING",
    //                 "email": "aybukecakici98@gmail.com",
    //                 "phone": "5313147191",
    //                 "user_type": "COMPANY_OWNER",
    //                 "first_name": "Aybuke",
    //                 "last_name": "Cakici",
    //                 "company": {
    //                     "id": "8a8081af7c0de0ee017c127b63f4004f",
    //                     "name": "AybukeX"
    //                 },
    //                 "employement_date": null,
    //                 "birthday": "1998-12-07T22:00:00",
    //                 "gender": "FEMALE",
    //                 "education_status": null,
    //                 "photo": null,
    //                 "criminal_record": null,
    //                 "licence_doc": null,
    //                 "src_doc": null,
    //                 "psycho_doc": null,
    //                 "licence_number": null,
    //                 "status_reason": null,
    //                 "vehicles": []
    //             }
    //         ],
    //         "vehicles": [
    //             {
    //                 "id": "8a8081af7c0de0ee017c12a65f610066",
    //                 "created_at": "2021-09-23T12:34:25",
    //                 "lastupdated": "2021-09-23T12:34:25",
    //                 "vehicle_id": "00 BB 00",
    //                 "vehicle_brand": {
    //                     "id": "6c89a24983ec47e1ac1970deb5e2ed16",
    //                     "name": "Ford"
    //                 },
    //                 "vehicle_model": {
    //                     "id": "d754da0f9e1541018e0be6de6295a6ea",
    //                     "name": "Connect"
    //                 },
    //                 "company": {
    //                     "id": "8a8081af7c0de0ee017c127b63f4004f",
    //                     "name": "AybukeX"
    //                 },
    //                 "name": null,
    //                 "status": "ACTIVE",
    //                 "vehicle_licence": null,
    //                 "k_certificate": {
    //                     "id": "8a8081af7c0de0ee017c12a62b980065",
    //                     "created_at": "2021-09-23T12:34:12",
    //                     "lastupdated": "2021-09-23T12:34:25",
    //                     "doc_type": "VEHICLE_K_CERTIFICATE",
    //                     "doc_date": null,
    //                     "doc_expiration": null,
    //                     "doc_url": "/2021/09/23/EEDD3783-E2A3-484F-A4C6-9B2597C5FAA9_1632400452500.jpg",
    //                     "doc_name": "EEDD3783-E2A3-484F-A4C6-9B2597C5FAA9.jpg",
    //                     "doc_extension": "jpg"
    //                 },
    //                 "insurance": null,
    //                 "inception": null,
    //                 "refrigerate": null,
    //                 "capacity": 100.0,
    //                 "maintanence_date": null,
    //                 "maintanence_km": null,
    //                 "status_reason": null,
    //                 "zones": [],
    //                 "drivers": [],
    //                 "projects": []
    //             },
    //             {
    //                 "id": "0000000079126f16017917f596aa3e5f",
    //                 "created_at": null,
    //                 "lastupdated": "2021-10-24T12:55:39",
    //                 "vehicle_id": "deneme 1",
    //                 "vehicle_brand": {
    //                     "id": null,
    //                     "name": null
    //                 },
    //                 "vehicle_model": {
    //                     "id": "851da2c296a44de0a4d7167c62e2050a",
    //                     "name": "Egea"
    //                 },
    //                 "company": {
    //                     "id": "8a8081af7c0de0ee017c127b63f4004f",
    //                     "name": "AybukeX"
    //                 },
    //                 "name": "deneme 1",
    //                 "status": "ACTIVE",
    //                 "vehicle_licence": {
    //                     "id": "8a8081be7ca8a4bd017cb216b9630004",
    //                     "created_at": "2021-10-24T11:36:46",
    //                     "lastupdated": "2021-10-24T12:55:39",
    //                     "doc_type": "VEHICLE_INCEPTION",
    //                     "doc_date": null,
    //                     "doc_expiration": "2022-10-24T10:38:51",
    //                     "doc_url": "/2021/10/24/EXP_1634225959677_1635075406178.xls",
    //                     "doc_name": "EXP_1634225959677.xls",
    //                     "doc_extension": "xls"
    //                 },
    //                 "k_certificate": {
    //                     "id": "8a8081be7ca8a4bd017cb211da530002",
    //                     "created_at": "2021-10-24T11:31:26",
    //                     "lastupdated": "2021-10-24T12:55:39",
    //                     "doc_type": "VEHICLE_K_CERTIFICATE",
    //                     "doc_date": null,
    //                     "doc_expiration": "2022-08-24T10:38:51",
    //                     "doc_url": "/2021/10/24/EXP_1634226811060_1635075086929.xls",
    //                     "doc_name": "EXP_1634226811060.xls",
    //                     "doc_extension": "xls"
    //                 },
    //                 "insurance": null,
    //                 "inception": null,
    //                 "refrigerate": true,
    //                 "capacity": 110.0,
    //                 "maintanence_date": "2022-03-24T10:38:51",
    //                 "maintanence_km": 340.0,
    //                 "status_reason": null,
    //                 "zones": [
    //                     "87ea4955d8ce453693168d2fb3114fa3"
    //                 ],
    //                 "drivers": [
    //                     "8a8081af7c0de0ee017c12a5383d0062"
    //                 ],
    //                 "projects": [
    //                     "ea6725d69d384dfba838564e265345cd"
    //                 ]
    //             }
    //         ],
    //         "address": {
    //             "id": "8a8081af7c0de0ee017c127b63ed004e",
    //             "created_at": "2021-09-23T11:47:28",
    //             "lastupdated": "2021-09-23T11:47:28",
    //             "code": "A1",
    //             "address_code_ext": "A1",
    //             "first_name": null,
    //             "last_name": null,
    //             "gsm_number": null,
    //             "email": null,
    //             "address": "Muradiye mah. ",
    //             "country": "Türkiye",
    //             "state": null,
    //             "city": "İstanbul",
    //             "county": "Beşiktaş",
    //             "post_code": null,
    //             "latitude": 0.0,
    //             "longitude": 0.0,
    //             "district": null,
    //             "street": null,
    //             "address_line": null,
    //             "geocode_score": null
    //         }
    //     }
    // ]

    // return {
    //     type: TYPES.GET_VEHICLE_ADD,
    //     payload: add
    // };

};



export const vehicleUpdate = (dt, callback, onError) => {

    return async (dispatch) => {
        server.
            vehicleUpdateRecord(dt).then((data) => {
                dispatch({
                    type: TYPES.VEHICLE_UPDATE,
                    payload: data.entity
                });
                callback(data.entity);
            })
            .catch((error) => {
                onError(error);
            });
    }
};

export const deleteVehicleAction = (vehicle_id, callback, onError) => {
    return async (dispatch) => {
        server.
            deleteVehicle(vehicle_id).then(data => {
                console.log(data);
                dispatch({
                    type: TYPES.DELETE_VEHICLE,
                    payload: data.entity
                });
                callback(data.entity);
            })
            .catch(error => onError(error));
    }
};

export const getVehiclesOnDate = (date, callback) => {
    return async (dispatch) => {
        //loadingActive(dispatch, true);
        server.getVehiclesOnDate(date)
            .then(res => {
                dispatch({
                    type: TYPES.GET_VEHICLES_WITH_DATE,
                    payload: res.entity
                })
                callback(res.entity.length);
                //loadingActive(dispatch, false);
            })
            .catch(error => console.log(error));
    }
};



export const vehicleBracnhList = (data) => {

    return async (dispatch) => {
        server.
            vehicleBranchList().then((data) => {
                dispatch({
                    type: TYPES.GET_VEHICLE_BRANCH,
                    payload: data.entity
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};