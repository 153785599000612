import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import List from "./List";
import { Services } from "api/Services";
import Loader from "react-loader-spinner";
import { useSnackbar } from "notistack";
import FormModal from "./Detail";
import Form from "./Detail/Form";

VehicleManagement.propTypes = {
  style: PropTypes.array,
};

const server = new Services();

export default function VehicleManagement(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFilterOptions, setVehicleFilterOptions] = useState();
  const [getFiltersData, setGetFiltersData] = useState(false);
  const [vehicleFilter, setVehicleFilter] = useState({
    branchId: "",
    companyId: "",
    propertyType: "",
    vehicleTypeId: "",
    active: "",
    vehicleName: "",
  });
  const [formIsEdit, setFormIsEdit] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const [visibleForm, setVisibleForm] = React.useState(null);

  const getVehicles = async () => {
    try {
      const res = await server.getVehicleList(vehicleFilter);
      setVehicles(res.entity.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getVehiclesFilter = async () => {
    try {
      const res = await server.getVehiclesFilter();
      setVehicleFilterOptions(res.entity.data[0]);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDeleteListItem = async (id) => {
    try {
      await server.deleteVehicle(id);
      enqueueSnackbar("Araç başarıyla silindi", { variant: "success" });
      getVehicles();
    } catch (error) {
      enqueueSnackbar("Araç silinirken bir hata oluştu", { variant: "error" });
    }
  };

  useEffect(() => {
    getVehiclesFilter();
  }, []);

  useEffect(() => {
    getVehicles();
    setGetFiltersData(false);
  }, [getFiltersData]);

  const openForm = ({ type, item }) => {
    if (type === "new") {
      setFormIsEdit(false);
      setFormData(null);
    } else {
      setFormIsEdit(true);
      setFormData(item);
    }
    setVisibleForm(true);
  };

  const closeForm = () => {
    setFormData(null);
    setFormIsEdit(false);
    setVisibleForm(false);
  };

  return (
    <Layout
      {...props}
      heading="Araç Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "Araç Yönetimi" }]}
      action={
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => openForm({ type: "new" })}
        >
          Araç Ekle
        </Button>
      }
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Oval" color="tomato" height={40} width={40} />
        </Box>
      ) : (
        <>
          <List
            data={vehicles}
            vehicleFilter={vehicleFilter}
            setFilters={setVehicleFilter}
            options={vehicleFilterOptions}
            setGetFiltersData={setGetFiltersData}
            loading={loading}
            handleDelete={handleDeleteListItem}
            toggleModal={openForm}
          />
          {visibleForm && (
            <FormModal handleClose={closeForm} isEdit={formIsEdit}>
              <Form
                handleClose={closeForm}
                isEdit={formIsEdit}
                currentData={formData}
                options={vehicleFilterOptions}
                refreshData={getVehicles}
              />
            </FormModal>
          )}
        </>
      )}
    </Layout>
  );
}
