import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrderUnplanned } from "../../store/actions/orderAction";
import Table from "../../components/Table";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Container } from "@mui/material";
import Topbar from "components/Topbar";

// import TYPES from "../../store/types/";

const OrderManagement = (props) => {
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();
  const navBarWidth = 280;
  const isMobile = useMediaQuery("(max-width:1024px)");

  const dispatch = useDispatch();

  const getOrderList = useSelector((state) => state.orderReducer);
  const new_orders = useSelector((excel_file) => excel_file.uploadExcel);
  // const getUngeocodedReducer = useSelector((state) => state.ungeocodedReducer);
  //const path = props.location.pathname.split("/")[1];  url path

  useEffect(() => {
    dispatch(getOrderUnplanned());
  }, []);

  useEffect(() => {
    if (new_orders && new_orders.excel_data) {
      if (!getOrderList.data) {
        getOrderList.data = [];
      }
      new_orders.excel_data.forEach((order) => {
        getOrderList.data.push(order);
      });
      // dispatch({
      //   type: TYPES.GET_ORDER_UNPLANNED,
      //   payload: new_orders.excel_data
      // })
    }
  }, [new_orders]);

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  return (
    <>
      {!isMobile && <Topbar marginLeft={`${navBarWidth}px`} />}
      <Container
        maxWidth={false}
        sx={{ display: isMobile ? "block" : "flex", paddingTop: "60px" }}
      >
        <NavBar navBarWidth={navBarWidth} isMobile={isMobile} />
        <Box width={1} mt={2} ml={isMobile ? 0 : `${navBarWidth + 20}px`}>
          <Box sx={{ pb: 10 }}>
            <Table
              products={getOrderList.data}
              unplannedOrderButtonShow={true}
              type="order-management"
              //differentPage={path == "geocode" ? "OrderManangementAdminGeocode": null}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default OrderManagement;
