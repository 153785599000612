import filterData from "./filterData";

const initialState = {
  data: null,
};

export const distributionDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISTRIBUTION_DATA_SET":
      return { ...state, data: filterData(action.payload) };
    case "DISTRIBUTION_DATA_RESET":
      return {
        data: null,
      };
    default:
      return state;
  }
};
