import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { Checkbox } from "@material-ui/core";
import { Button } from "primereact/button";


const AddBranchUser = (props) => {
    const dispatch = useDispatch();
    const { dialogAddBranchUser, setDialogAddBranchUser, selectedUser, setSelectedUser, product } = props;

    const branchDriversList = useSelector((state) => state.branchReducer);
    //const [selectedUser, setSelectedUser] = useState([]);

    // useEffect(() => {
    //     console.log("zzzzzzzzzzzzz: ", branchDriversList);
    // }, [branchDriversList])


    // useEffect(() => {
    //     console.log("AAAAX:", selectedUser)
    // }, [selectedUser])




    const hideDialogAddBranchUser = () => {
        setDialogAddBranchUser(false);
    };


    const selectedUserAssigment = (e) => {
        //console.log(e.target.id);
        let duplicateData = selectedUser && selectedUser.find(d => d == e.target.id);
        if (duplicateData) {
            let currentSelectedBranch = selectedUser.filter(c => c != duplicateData);
            //console.log("y :", y);
            setSelectedUser([...currentSelectedBranch])
        } else {
            selectedUser ? setSelectedUser([...selectedUser, e.target.id]) : setSelectedUser([e.target.id])
        }
    };


    const saveBranchAssigment = () => {
        // setSelectedUser([]);
        setDialogAddBranchUser(false)
    };


    const addBranchUserDialogFooter = (

        <React.Fragment>
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveBranchAssigment()}
            />
        </React.Fragment>

    );





    return (
        <Dialog
            header="Kullanıcı Atama"
            visible={dialogAddBranchUser}
            style={{ width: "1000px" }}
            onHide={hideDialogAddBranchUser}
            footer={addBranchUserDialogFooter}
        >


            <>
                {
                    Object.keys(branchDriversList.drivers).length > 0 && branchDriversList.drivers.map((element) => {
                        return (
                            <table>
                                <tr>
                                    {/* <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchAssigment(e)} /> </td> */}
                                    <td><Checkbox id={element.id} checked={selectedUser && selectedUser.includes(element.id)} onChange={e => selectedUserAssigment(e)} /> </td>
                                    <td>{element.username}  ({element.first_name + " " + element.last_name})</td>
                                </tr>
                            </table>

                        )
                    })
                }

                {/* selected drivers */}
                {
                    product.users && product.users.map((element) => {
                        return (
                            <table>
                                <tr>
                                    {/* <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchAssigment(e)} /> </td> */}
                                    <td><Checkbox id={element.id} checked={selectedUser && selectedUser.includes(element.id)} onChange={e => selectedUserAssigment(e)} /> </td>
                                    <td>{element.username} ({element.first_name} {element.last_name})</td>
                                </tr>
                            </table>
                        )
                    })
                }
            </>

        </Dialog>
    )
}

export default AddBranchUser
