import TYPES from "../types";

const initialState = {
  changeCoordinate: [],
};

export const ungeocodedReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_UNGEOCODED:
      return { ...state, data: action.payload.data.length != 0 ? action.payload.data : "No geocoded value" };
    case TYPES.CLEAR_UNGEOCODED:
      return { ...state, data: action.payload };
    case TYPES.UPDATE_UNGEOCODED:
      state.data.filter((d, i) => {
        if (d.id === action.payload.selected.id) {
          d.address.address = action.payload.newAddress.length > 0 ? action.payload.newAddress : action.payload.selected.address.address;
          d.address.latitude = action.payload.newCoodinate.latitude;
          d.address.longitude = action.payload.newCoodinate.longitude;
        }
      });
      state.changeCoordinate.push({
        address_id: action.payload.selected.address.id,
        latitude: action.payload.newCoodinate.latitude,
        longitude: action.payload.newCoodinate.longitude,
      });

      // state.data.filter((d, i) => {
      //   if (d.id === action.payload.selected.id) {
      //     d.address.address = action.payload.newAddress;
      //     d.address.latitude = action.payload.newCoodinate.latitude;
      //     d.address.longitude = action.payload.newCoodinate.longitude;
      //   }
      // });
      // console.log(state.data);

      // state.changeCoordinate.push({
      //   address_id: action.payload.selected.address.id,
      //   latitude: action.payload.newCoodinate.latitude,
      //   longitude: action.payload.newCoodinate.longitude,
      // });

      return state;
    case TYPES.EMPTY_UNGEOCODED:
      return { ...state, changeCoordinate: action.payload };
    default:
      return state;
  }
};
