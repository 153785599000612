import { IconButton, Stack, Typography } from "@mui/material";
import Profile from "components/Navbar/Profile";
import SearchBox from "components/Navbar/SearchBox";
import React from "react";
import Iconify from "components/Iconify";

const Topbar = ({ marginLeft }) => {
  const [searchOpen, setSearchOpen] = React.useState(false);
  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 2,
        backgroundColor: "white",
        position: "fixed",
        width: `calc(100% - ${marginLeft})`,
        height: 60,
        zIndex: 1,
        marginLeft: `${marginLeft}`,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        borderRadius: "0 0 16px 16px",
      }}
    >
      <Stack
        sx={{ cursor: "pointer" }}
        direction="row"
        alignItems="center"
        spacing={0.5}
        onClick={() => setSearchOpen(true)}
      >
        <IconButton>
          <Iconify icon={"eva:search-fill"} width={20} height={20} />
        </IconButton>
        <Typography sx={{ color: "GrayText" }}>
          Hızlı sipariş sorgula
        </Typography>
      </Stack>

      <SearchBox open={searchOpen} onClose={() => setSearchOpen(false)} />

      <Profile />
    </Stack>
  );
};

export default Topbar;
