import React from "react";
import Layout from "components/Layout";
import Filters from "./Filters";
import { Box, Button, Grid, Stack } from "@mui/material";
import List from "./List";
import Map from "./Map";
import CountyList from "./CountyList";
import Branches from "./Branches";
import { Services } from "api/Services";
import Loader from "react-loader-spinner";
import moment from "moment";
import AnalyticalNew from "./AnalyticalNew";
import Couriers from "./Couriers";
import { FilterList } from "@mui/icons-material";
import { useDispatch } from "react-redux";

const server = new Services();
const currentDate1 = moment()
  .subtract(1, "months")
  .endOf("month")
  .format("YYYY-MM-DD");
const currentDate2 = moment()
  .subtract(0, "months")
  .endOf("month")
  .format("YYYY-MM-DD");

const initialStateFilter = {
  start_date: currentDate1,
  end_date: currentDate2,
  branches: [],
  vehicle_types: [],
  cities: [],
  vehicles: [],
  drivers: [],
  counties: [],
  customers: [],
  shipment_number: "",
  max_count: 4,
};

export default function CustomerManagement(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [openFilter, setOpenFilter] = React.useState(true);
  const [filters, setFilters] = React.useState({
    cities: [],
    customers: [],
    branches: [],
    vehicle_types: [],
    vehicles: [],
    drivers: [],
    counties: [],
  });
  const [filter, setFilter] = React.useState(initialStateFilter);
  const [filterLoading, setFilterLoading] = React.useState(false);
  const [statsData, setStatsData] = React.useState({});
  const [drivers, setDrivers] = React.useState([]);
  const [distributions, setDistributions] = React.useState({
    package_count: 0,
    distance: 0,
    delivered_count: 0,
    delivered_deci: 0,
    completed_distance: 0,
    on_board_count: 0,
    not_delivered_count: 0,
    deci: 0,
  });
  const [shipments, setShipments] = React.useState([]);
  const [shipmentsByCity, setShipmentsByCity] = React.useState([]);
  const [shipmentsByCounty, setShipmentsByCounty] = React.useState([]);
  const [shipmentsByBranch, setShipmentsByBranch] = React.useState([]);

  const getFilters = async () => {
    try {
      const res = await server.getCustomerManagementFilters();
      setFilters(res.entity.data[0]);
      handleFilter({ resetl: false });
    } catch (error) {
      //console.log(error);
    }
  };

  const getStats = async () => {
    try {
      const res = await server.getHomeStats(
        initialStateFilter.end_date,
        initialStateFilter.start_date
      );
      setStatsData(res.entity.data[0]);
    } catch (error) {
      // console.log(error);
    }
  };

  React.useEffect(() => {
    getFilters();
    getStats();
    dispatch({ type: "DASHBOARD_FILTER_RESET" });
  }, []);

  const handleChangeFilter = (key, newValue) => {
    if (key === "shipment_number") {
      setFilter((f) => {
        const newFilter = { ...f, shipment_number: newValue.target.value };
        dispatch({ type: "DASHBOARD_FILTER_SET", payload: newFilter });
        return newFilter;
      });
    } else {
      setFilter((f) => {
        let data = null;
        if (key === "end_date") {
          data = {
            ...f,
            start_date: moment(newValue[0]).format("YYYY-MM-DD"),
            end_date: moment(newValue[1]).format("YYYY-MM-DD"),
          };
        } else {
          data = { ...f, [key]: newValue };
        }
        dispatch({ type: "DASHBOARD_FILTER_SET", payload: data });
        return data;
      });
    }
  };

  const handleFilter = async ({ reset }) => {
    let newFilter = null;

    if (reset) {
      setFilter(initialStateFilter);
      dispatch({ type: "DASHBOARD_FILTER_RESET" });
      newFilter = { ...initialStateFilter };
    } else {
      newFilter = {
        ...filter,
        cities: filter.cities.map((i) => i.key),
        customers: filter.customers.map((i) => i.key),
        branches: filter.branches.map((i) => i.key),
        vehicle_types: filter.vehicle_types.map((i) => i.key),
        vehicles: filter.vehicles.map((i) => i.key),
        drivers: filter.drivers.map((i) => i.key),
        counties: filter.counties.map((i) => i.key),
      };
    }

    setFilterLoading(true);

    for (const [key, value] of Object.entries(newFilter)) {
      const newValue = Array.isArray(value) ? value[0] : value;

      if (newValue === "") {
        delete newFilter[key];
      }
    }

    try {
      const getDriverList = await server.getFilterResult("users", newFilter);
      const getDistributions = await server.getFilterResult(
        "distributions",
        newFilter
      );
      const getShipments = await server.getFilterResult("shipments", newFilter);
      const getShipmentsByCity = await server.getFilterResult(
        "shipments-by-city",
        newFilter
      );
      const getShipmentsByCounty = await server.getFilterResult(
        "shipments-by-county",
        newFilter
      );
      const getShipmentsByBranch = await server.getFilterResult(
        "shipments-by-branch",
        newFilter
      );
      setDrivers(getDriverList.entity.data);
      setDistributions(getDistributions.entity.data[0]);
      setShipments(getShipments.entity.data);
      setShipmentsByCity(getShipmentsByCity.entity.data);
      setShipmentsByCounty(getShipmentsByCounty.entity.data);
      setShipmentsByBranch(getShipmentsByBranch.entity.data);
    } catch (error) {
      console.log("ERROR =>", error);
    } finally {
      setFilterLoading(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleFilter({ reset: false });
  }, [filter]);

  return (
    <Layout
      {...props}
      heading="Genel Durum"
      links={[{ name: "Genel", href: "/" }, { name: "Genel Durum" }]}
      action={
        <Button
          variant="contained"
          color={openFilter ? "primary" : "inherit"}
          startIcon={<FilterList />}
          onClick={() => setOpenFilter(!openFilter)}
        >
          Filtrele
        </Button>
      }
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="Oval" color="tomato" height={40} width={40} />
        </Box>
      ) : (
        <Stack spacing={3}>
          {openFilter && (
            <Filters
              data={filters}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
              filterLoading={filterLoading}
              handleFilterReset={() => handleFilter({ reset: true })}
            />
          )}
          <Stack spacing={2}>
            <AnalyticalNew data={distributions} statsData={statsData} />
            <List data={shipments} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Map data={shipmentsByCity} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CountyList data={shipmentsByCounty} />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Branches data={shipmentsByBranch} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Couriers data={drivers} />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Stack>
      )}
    </Layout>
  );
}
