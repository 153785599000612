import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";

const itemsTemplate = (items) => {
  return <p>{items.name}</p>;
};

/**
 * dropdown for filtering
 * 
 * @param {*} props  options is an array check \dropdownFilter\options.js for format 
 * @returns 
 */
export const DropDownFilter = (props) => {
  const [value, setvalue] = useState(null);
  const [name, setName] = useState(null);
  useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <Dropdown
      value={value}
      optionLabel="name"
      optionValue="value"
      options={props.options}
      //style={{ width: '140px', padding: "0px", textAlign: "center", height: "35px", padding: "0px", position: "relative", top: "9px", left: "-7px" }}
      //itemTemplate={itemsTemplate}
      placeholder={props.placeholder ? props.placeholder : "Seçiniz.."}
      className="p-column-filter"
      onChange={(e) => {
        const selected = props.options.filter((el) => el.value == e.value);
        if (selected.length > 0) setName(selected[0].name);

        setvalue(e.value);
      }}
      showClear
    />
  );
};
