export const adminLinks = [
  {
    title: "Müşteri Yönetimi",
    path: "/customer-management",
  },
  // {
  //   title: "Proje Başvuru Yönetimi",
  //   path: "/project",
  // },
  {
    title: "İş Başvuru Yönetimi",
    path: "/jobs",
  },
  {
    title: "Raporlar",
    path: "/report5",
    subLinks: [
      {
        title: "Araç Kullanım Raporu",
        path: "/report5",
      },
      {
        title: "Canlı Takip",
        path: "/report6",
      },
      {
        title: "KPI",
        path: "/kpi",
      },
      {
        title: "Kurye Zimmet",
        path: "/distribution",
      },
    ],
  },
  {
    title: "Araç Yönetimi",
    path: "/vehicle-management",
  },
  {
    title: "Sürücü Yönetimi",
    path: "/driver-management",
  },
  {
    title: "Tanımlamalar",
    path: "/geocode",
    subLinks: [
      {
        title: "Koordinatlama",
        path: "/geocode",
      },
      {
        title: "Şube Tanımlama",
        path: "/branch",
      },
    ],
  },
  {
    title: "Sipariş İşlemleri",
    path: "#",
    subLinks: [
      {
        title: "Sipariş Yönetimi",
        path: "/admin-order-management",
      },
      {
        title: "Planlama",
        path: "/dispatcherplanner",
      },
    ],
  },
];

export const adminLinksNew = [
  {
    title: "Genel",
    path: "#",
    subLinks: [
      {
        title: "Ön İzleme",
        path: "/customer-management",
        icon: "/images/navbar/preview.svg",
      },
      {
        title: "Analiz",
        path: "/analytics",
        icon: "/images/navbar/analytics.svg",
      },
    ],
  },
  {
    title: "Yönetim",
    path: "#",
    subLinks: [
      {
        title: "Proje Yönetimi",
        path: "/project-definition",
        icon: "/images/navbar/customer-management.svg",
      },

      {
        title: "Şube Yönetimi",
        path: "/branch",
        icon: "/images/navbar/branches-management.svg",
      },

      {
        title: "Araç Yönetimi",
        path: "/vehicle-management",
        icon: "/images/navbar/vehicle-management.svg",
      },
      {
        title: "Kurye Yönetimi",
        path: "/driver-management",
        icon: "/images/navbar/driver-management.svg",
      },
      {
        title: "Sipariş Yönetimi",
        path: "/admin-order-management",
        icon: "/images/navbar/order-management.svg",
      },
      {
        title: "İş Başvuru Yönetimi",
        path: "/jobs",
        icon: "/images/navbar/jobs.svg",
      },
      {
        title: "Kullanıcı Yönetimi",
        path: "/users-management",
        icon: "/images/navbar/customer-management.svg",
      },
      {
        title: "Depo Yönetimi",
        path: "/warehouse-management",
        icon: "/images/navbar/warehouse.png",
      },
    ],
  },
  {
    title: "Raporlar",
    path: "#",
    subLinks: [
      {
        title: "Araç Kullanım",
        path: "/report5",
        icon: "/images/navbar/vehicle-usage.svg",
      },
      {
        title: "Dağıtım",
        path: "/report6",
        icon: "/images/navbar/genel.svg",
      },
      {
        title: "KPI",
        path: "/kpi",
        icon: "/images/navbar/kpi.svg",
      },
      {
        title: "Kurye Zimmet",
        path: "/distribution",
        icon: "/images/navbar/zimmet.svg",
      },
    ],
  },
  {
    title: "Uygulamalar",
    path: "#",
    subLinks: [
      {
        title: "Canlı Takip",
        path: "/dispatcherlive",
        icon: "/images/navbar/live-preview.svg",
      },

      {
        title: "Planlama",
        path: "/dispatcherplanner",
        icon: "/images/navbar/planlama.svg",
      },
      {
        title: "Kordinatlama",
        path: "/geocode",
        icon: "/images/navbar/kordinatlama.svg",
      },
    ],
  },
];

export const custumerLinks = [
  // {
  //   title: "Sipariş Yönetimi",
  //   path: "/bringo-customer/order-management",
  // },
  // {
  //   title: "Canlı Takip",
  //   path: "/bringo-customer/live-tracking",
  // },
  // {
  //   title: "Mesaj Yönetimi",
  //   path: "/bringo-customer/messages-management",
  // },
  // {
  //   title: "KPI Raporları",
  //   path: "/bringo-customer/kpi",
  // },
];
