import TYPES from "../types";

const initialState = {
  data: null
};

export const debitReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CUSTOMER_COUNTY_USER:
      return { ...state, data: action.payload.data }
    case TYPES.COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER: //single update
      var ty = state.data;
      ty.map(x => (x.city == action.payload[0].city && x.county == action.payload[0].county) ? x.assigned_user = action.payload[0].user_oid : x.assigned_user = x.assigned_user)
      return { data: ty }
    case TYPES.COMBOBOX_CHANGE_CUSTOMER_COUNTY_USER_MULTIPLE: //multiple updates
      var ty = state.data;
      ty.map(x => {
        action.payload.map(y => {
          (x.city == y.city && x.county == y.county) ? x.assigned_user = y.user_oid : x.assigned_user = x.assigned_user
        })
      }
      )
      return { data: ty }
    default:
      return state
  }
};



