//excel functions in table.js
//return delivery status codes
export const excelDeliveryStatus = (value) => {

    switch (value.delivery_status) {
      case "IN_WAREHOUSE":
      case "DELIVERY_PLANNED":
      case "ON_BOARD":
        return "Depoda";
        break;
      case "ON_THE_WAY_TO_CUSTOMER":
      case "CUSTOMER_INFORMED_1":
      case "CUSTOMER_INFORMED_2":
      case "CUSTOMER_READINESS_RECEIVED":
        return "Dağıtımda";
        break;
      case "CUSTOMER_INFORMED_3":
        return "Dağıtımda";
        break;

      case "DELIVERED":
        return "Teslim edildi";
        break;
      case "PARTIALLY_DELIVERED":
        return "Kısmen teslim edildi";
        break;
      case "NOT_DELIVERED":
        return "Teslim edilmedi";
        break;
      case "DELIVERY_POSTPONED":
        return "Teslim ertelendi";
        break;
      default:
        break;
    }
  
};