import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import Table from "../../components/Table";


const CustomerWarehouse = (props) => {
    const dispatch = useDispatch();
    const { dialogCustomerWarehouse, setDialogCustomerWarehouse } = props;

    //warehouse list
    const warehouseList = useSelector((state) => state.customerWarehouseReducer);

    const hideDialogCustomerWarehouse = () => {
        setDialogCustomerWarehouse(false);
    };

    return (
        <Dialog
            header="Depo Ekle"
            visible={dialogCustomerWarehouse}
            style={{ width: "1300px" }}
            onHide={hideDialogCustomerWarehouse}
        // footer={debitDialogFooter}
        >

            <Table
                products={warehouseList.data}
                //unplannedOrderButtonShow={true}
                type="dialog-table-customer-warehouse"
            />

        </Dialog>
    )
}

export default CustomerWarehouse
