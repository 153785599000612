import TYPES from '../types';

const initialState = {
    user: '',
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.USER:
            return {...state, user: action.payload};
        default:
            return state;
    }
}