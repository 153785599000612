import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

//import TYPES from "../../store/types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import {
    vehicleCompanyList
} from "../../store/actions/vehicleManagementAction";

import {
    driversVehicleList,
    getDriversRole
} from "../../store/actions/DriverManagementAction";

import { Upload } from '../../components/UploadFiles/Upload';
import DriverRole from "./DriverRole";

import {
    Divider,
    Segment,
    Tab,
    Grid,
    Input,
} from 'semantic-ui-react';

import {
    driversInactiveDateReasonAction,
    getDriversInactiveDateReasonAction,
    deleteDriversInactiveDateReasonAction
} from '../../store/actions/inactiveDateReasonAction';

const DriverManagement = (props) => {

    const {
        dialogDriversManagement,
        setDialogDriversManagement,
        product,
        toastCallback,
        onInputChange,
        onDocUploaded,
        productDialogFooter,
        setProduct,
        onInputChangeCalendar,
        type
    } = props;
    const dispatch = useDispatch();
    const companyList = useSelector(state => state.vehicleCompanyReducer);
    const vehicleList = useSelector(state => state.driverVehiclesReducer);
    const driversInactiveDates = useSelector(state => state.inactiveDateReasonReducer);

    const [inactiveDates, setInactiveDates] = useState();
    const [inactiveDelete, setInactiveDelete] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [dateDescriptionDialog, setDateDescriptionDialog] = useState(false);
    const [inactiveReason, setInactiveReason] = useState('');
    const [deleteInactiveDateDialog, setDeleteInactiveDateDialog] = useState(false);
    const [deleteDate, setDeleteDate] = useState();
    const [driverRoleDialog, setDriverRoleDialog] = useState(false);
    const [selectedDriversRole, setSelectedDriversRole] = useState([]); // selected drivers role


    /** Driver Role ***/

    useEffect(() => {
        dialogDriversManagement == false && setSelectedDriversRole([]); // branch user drivers state
    }, [dialogDriversManagement])

    useEffect(() => {

        if (Object.keys(product).length > 0 && product.roles) {
            let prodDriversRole = product.roles.map(d => d);
            prodDriversRole.length > 0 && setSelectedDriversRole(prodDriversRole);
        }
    }, [product.roles])


    /** Driver Role ===============***/

    const criminalOptions = [
        { name: 'Var', code: 'true' },
        { name: 'Yok', code: 'false' }
    ];

    const getDriversInactiveDates = driver => {
        let date = new Date();
        let start_date = new Date(date.getFullYear(), 0, 1);
        let end_date = new Date(date.getFullYear(), 12, 31);
        let driver_id = driver && driver.id;

        if (driver_id) {
            dispatch(
                getDriversInactiveDateReasonAction(
                    start_date.toISOString(),
                    driver_id,
                    end_date.toISOString(),
                    res => {
                        setInactiveDelete(false)
                    }
                )
            );
        }
    };

    useEffect(() => {
        // let date = new Date();
        // let start_date = new Date(date.getFullYear(), 0, 1);
        // let end_date = new Date(date.getFullYear(), 11, 31);
        // let driver_id = product && product.id;

        // if(driver_id){
        //     dispatch(
        //         getDriversInactiveDateReasonAction(
        //             start_date.toISOString(),
        //             driver_id,
        //             end_date.toISOString(),
        //             res => {
        //                 setInactiveDelete(false)
        //             }
        //         )
        //     );
        // }
        let driver = product && product;
        getDriversInactiveDates(driver);
    }, [dialogDriversManagement]);


    useEffect(() => {
        setInactiveDates(driversInactiveDates.driver_inactive && driversInactiveDates.driver_inactive.data);
    }, [driversInactiveDates]);

    useEffect(() => {
        // let date = new Date();
        // let start_date = new Date(date.getFullYear(), 0, 1);
        // let end_date = new Date(date.getFullYear(), 11, 31);
        // let driver_id = product && product.id;

        // if(driver_id){
        //     dispatch(
        //         getDriversInactiveDateReasonAction(
        //             start_date.toISOString(),
        //             driver_id,
        //             end_date.toISOString(),
        //             res => {
        //                 setInactiveDelete(false)
        //             }
        //         )
        //     );
        // }
        let driver = product && product;
        getDriversInactiveDates(driver);
    }, [dateDescriptionDialog]);

    // useEffect(() => {
    // let date = new Date();
    // let start_date = new Date(date.getFullYear(), 0, 1);
    // let end_date = new Date(date.getFullYear(), 12, 31);
    // let driver_id = product && product.id;

    // if(driver_id){
    //     dispatch(
    //         getDriversInactiveDateReasonAction(
    //             start_date.toISOString(),
    //             driver_id,
    //             end_date.toISOString(),
    //             res => {
    //                 setInactiveDelete(false)
    //             }
    //         )
    //     );
    // }
    // let driver = product && product;
    // getDriversInactiveDates(driver);
    // }, [inactiveDates]);

    useEffect(() => {
        // let date = new Date();
        // let start_date = new Date(date.getFullYear(), 0, 1);
        // let end_date = new Date(date.getFullYear(), 12, 31);
        // let vehicle_id = product && product.id;

        // if(vehicle_id){
        //     dispatch(
        //         getDriversInactiveDateReasonAction(
        //             start_date.toISOString(),
        //             vehicle_id,
        //             end_date.toISOString(),
        //             res => {
        //                 setInactiveDelete(false)
        //             }
        //         )
        //     );
        // }
        let driver = product && product;
        getDriversInactiveDates(driver);
    }, [inactiveDelete]);

    // useEffect(() => {

    //     dispatch(
    //         vehicleCompanyList()
    //     )

    // }, []);

    const hideDialog = () => {
        var emptyProduct = {};
        setDialogDriversManagement(false);
        setProduct(emptyProduct)

    }



    const onDropDownChange = (e) => {

        if (e.target.name == "refrigerate") {
            //setFrigo(e.value)
        } else if (e.target.name == "drivers") {
            //setDriversData(e.value)
        } else if (e.target.name == "company") {
            //setCompanyDropdown(e.value)

            dispatch(
                driversVehicleList(e.target.value.id)
            )

        } else {
            // delete e.value.created_at
            // delete e.value.lastupdated
            //setSelectedGroupedCity(e.value);
        }


        onInputChange(e, e.target.name)
    }

    const vehilcleDocuments = [
        {
            doc_name: "criminal_recordDoc",
            title: "Sabıka Kaydı",
            //date_title: "Sabıka Kaydı",
            date_instance: "criminal_recordDoc",
            date_calendar_id: "basic1"
        },
        {
            doc_name: "licence_Doc",
            title: "Ehliyet",
            //date_title : "Kasko Belgesi (Geçerlilik Tarihi)",
            date_instance: "licence_Doc",
            date_calendar_id: "basic2"
        },
        {
            doc_name: "src_Doc",
            title: "SRC Belgesi",
            //date_title: "SRC Belgesi",
            date_instance: "src_Doc",
            date_calendar_id: "basic3"
        },
        {
            doc_name: "psycho_Doc",
            title: "Psikoteknik Belgesi",
            //date_title: "Muayene(Geçerlilik Tarihi)",
            date_instance: "psycho_Doc",
            date_calendar_id: "basic4"
        },
        {
            doc_name: "photo_doc",
            title: "Fotograf Ekle",
            //date_title: "Muayene(Geçerlilik Tarihi)",
            date_instance: "photo_doc",
            date_calendar_id: "basic4"
        }

    ];

    const deleteInactiveDate = () => {
        dispatch(
            deleteDriversInactiveDateReasonAction(
                deleteDate.id,
                success => {
                    if (success) {
                        setInactiveDelete(success);
                        setDeleteInactiveDateDialog(false);
                    }
                }
            )
        )
    };

    const handleSelectDate = date => {
        if (product.id) {
            let date_handler;
            if (inactiveDates && inactiveDates.length > 0) {
                inactiveDates && inactiveDates.forEach(inactive_date => {

                    let server_date = new Date(inactive_date.date).getDate();
                    let tick_date = date.value.getDate();

                    if (tick_date == server_date) date_handler = inactive_date;

                });
            }

            if (date_handler) {
                setDeleteInactiveDateDialog(true);
                setDeleteDate(date_handler);
            } else {
                setDateDescriptionDialog(true);
                setSelectedDate(date.value);
            }
        }
        // NOT_ACTIVE_DATE.push({date: date})
    };

    const formatDate = iso_date => {
        let date = new Date(iso_date);
        date.setDate(date.getDate());

        let dd = date.getDate();
        let mm = date.getMonth();
        let yy = date.getFullYear();

        let formated_date = dd + "/" + mm + "/" + yy;

        return formated_date;
    };



    const selectedInactiveDate = date => {

        // select inactive date: AS_IS
        let div;
        if (inactiveDates && inactiveDates.length > 0) {
            inactiveDates && inactiveDates.forEach(inactive_date => {

                let date_format = new Date(inactive_date.date);
                //date_format.setDate(date_format.getDate() + 1);

                let driver_id = inactive_date.driver;
                let selected = {
                    day: date_format.getDate(),  // get date day: type number
                    month: date_format.getMonth(),   // get date month: type number
                    selectable: true,
                    reason: inactive_date.reason     // get inactive date reason
                };
                // for the selected vehicle
                if (
                    selected.day == date.day &&
                    driver_id == product.id &&
                    selected.month == date.month
                ) {
                    div = <div title={selected.reason || "Nedeni bulumamaktadir"} style={{ backgroundColor: 'red', color: '#ffffff', fontWeight: 'bold', borderRadius: '50%', width: '2em', height: '2em', lineHeight: '1.6em', padding: 4 }}>{selected.day}</div>
                }
            });
        }


        if (div) return div;
        else return date.day;
    };

    const dateDescriptionHideDialog = () => {
        setDateDescriptionDialog(false);
    };

    const timeZone = date => {
        var new_date = new Date(date);
        var format = new_date.setHours(new_date.getHours() + (new_date.getTimezoneOffset() / -60));
        var formated_date = new Date(format).toISOString();
        return formated_date
    };

    const saveAndSelectDate = () => {
        var inactive_data = {
            date: timeZone(selectedDate),
            id: null,
            reason: inactiveReason,
            driver: product && product.id // selected product
        };

        // addDate(date);
        // localStorage.setItem('date', JSON.stringify(date));
        if (inactiveReason != '') {
            dispatch(
                driversInactiveDateReasonAction(
                    inactive_data,
                    res => {
                        if (res.success) {
                            setDateDescriptionDialog(false);
                            setInactiveDelete(false);
                            setInactiveReason('');
                        }
                    }
                )
            );
        } else {
            toastCallback('warn', "Error");
        }

    };

    // const hideDialog = () => {
    //     setDialogDriversManagement(false);
    // };

    const hideDeleteInactiveDialog = () => {
        setDeleteInactiveDateDialog(false);
    };


    const addRole = () => {
        setDriverRoleDialog(true);
        dispatch(
            getDriversRole()
        )
    };

    const deleteInactiveDateDialogFooter = (
        <React.Fragment>
            <Button
                label="Hayır"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteInactiveDialog}
            />
            <Button
                label="Evet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteInactiveDate}
            />
        </React.Fragment>
    );

    const DialogFooter = (
        <React.Fragment>
            {
                dateDescriptionDialog ?

                    <Button
                        label="Kaydet"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={e => saveAndSelectDate()}
                    /> :
                    <>
                        <Button
                            label="İptal"
                            icon="pi pi-times"
                            className="p-button-text"
                            onClick={hideDialog}
                        />
                        <Button
                            label="Kaydet"
                            icon="pi pi-check"
                            className="p-button-text"
                        // onClick={saveProject}
                        />
                    </>
            }
        </React.Fragment>
    );


    const driverManagementTabs = [
        {
            menuItem: 'Kullanıcı Bilgileri',
            pane: {
                key: 'tab3',
                content: (
                    <div style={{ overflow: "overlay" }}>
                        <React.Fragment>
                            <Segment>
                                <Grid columns={2} relaxed='very'>
                                    <Grid.Column>
                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Firma </label>
                                            <br />
                                            <Dropdown
                                                value={product && product.company}
                                                options={Object.keys(companyList).length > 0 ? companyList.data.data : []}
                                                name="company"
                                                onChange={onDropDownChange}
                                                //onChange={e => setSelectedGroupedCity(e.value)}
                                                optionLabel="name" />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf"> Araç </label>
                                            <br />
                                            <Dropdown
                                                value={product.vehicles && product.vehicles.length != 0 && product.vehicles[0].id || product.vehicles}
                                                options={Object.keys(vehicleList).length > 0 ? vehicleList.data.data[0].vehicles : []}
                                                name="vehicles"
                                                onChange={onDropDownChange}
                                                //optionLabel="first_name"
                                                optionLabel="vehicle_id"
                                                optionValue="id"
                                                placeholder="Araç seçiniz"
                                            //onChange={e => onInputChange(e, "driver")}
                                            />

                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Adı </label>
                                            <InputText
                                                id="first_name"
                                                defaultValue={product && product.first_name}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "first_name")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Soyadı </label>
                                            <InputText
                                                id="last_name"
                                                defaultValue={product && product.last_name}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "last_name")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>


                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Doğum Tarihi </label>
                                            <Calendar id="basic5" value={new Date(product.birthday && product.birthday || "")} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "birthday")} />
                                            {/* <Calendar id="basic5" dateFormat="dd.mm.yy" /> */}
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">TC </label>
                                            <InputText
                                                id="identity_number"
                                                defaultValue={product && product.identity_number}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "identity_number")}
                                                autoFocus
                                                maxlength="11"
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Ehliyet Geçerlilik Tarihi </label>
                                            <Calendar id="basic5" value={new Date(product.licence_doc && product.licence_doc.doc_expiration || product.licence_doc || "")} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "licence_doc")} />
                                            {/* <Calendar id="basic5" dateFormat="dd.mm.yy" /> */}
                                        </div>


                                        {/* <div className="p-field">
                                            <label htmlFor="sdfdf"> Sabıka Kaydı var mı?</label>

                                            <Dropdown
                                                value={product && product.criminalOptions}
                                                options={criminalOptions}
                                                name="criminalOptions"
                                                onChange={onDropDownChange}
                                                optionLabel="name"
                                                placeholder="Var mı? Yok mu?"
                                            //onChange={e => onInputChange(e, "frigo")}
                                            />
                                        </div> */}


                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Psiko Teknik Geçerlilik Süresi </label>
                                            <Calendar id="basic5" value={new Date(product.psycho_doc && product.psycho_doc.doc_expiration || product.psycho_doc || "")} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "psycho_doc")} />
                                            {/* <Calendar id="basic5" dateFormat="dd.mm.yy" /> */}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Src Geçerlilik Süresi </label>
                                            <Calendar id="basic5" value={new Date(product.src_doc && product.src_doc.doc_expiration || product.src_doc || "")} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "src_doc")} />
                                            {/* <Calendar id="basic5" value={new Date( product.src_doc_date  && product.src_doc_date || product.src_doc.doc_expiration )} dateFormat="dd.mm.yy" onChange={(e) => onInputChange(e, "src_doc_date")} /> */}
                                            {/* <Calendar id="basic5" dateFormat="dd.mm.yy" /> */}
                                        </div>



                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Telefon No </label>
                                            <InputText
                                                id="phone"
                                                defaultValue={product && product.phone}
                                                maxlength="11"
                                                placeholder="(555)-555-555"
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "phone")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Email Adresi </label>
                                            <InputText
                                                id="email"
                                                defaultValue={product && product.email}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "email")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf"> Adres </label>
                                            <InputText
                                                id="address_line"
                                                defaultValue={product && product.address_line}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "address_line")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="sdfdf">Ehliyet No </label>
                                            <InputText
                                                id="licence_number"
                                                defaultValue={product && product.licence_number}
                                                //defaultValue={vehicleId}
                                                // onInput={e => onInputChange(e, "plaka")}
                                                onChange={(e) => onInputChange(e, "licence_number")}
                                                autoFocus
                                                required={true}
                                                className="submitted"
                                            />
                                        </div>

                                        {vehilcleDocuments.map(vehicle => (
                                            <Upload
                                                vehicle={vehicle}
                                                withButton={false}
                                                callbackAlert={toastCallback}
                                                onDocUploaded={doc_name => onDocUploaded(doc_name)}
                                                product={product}
                                                onInputChange={onInputChange}
                                            />)
                                        )}

                                    </Grid.Column>


                                </Grid>

                                <Divider vertical>-</Divider>
                            </Segment>
                        </React.Fragment>

                    </div>
                ),
            }
        },
        {
            menuItem: 'Aktif Olmadıgı Günler',
            pane: {
                key: 'tab4', content: (
                    <div style={{ overflow: "overlay" }}>
                        <Calendar
                            inline
                            value={new Date()}
                            style={{ width: "100%" }}
                            onSelect={date => handleSelectDate(date)}
                            dateTemplate={selectedInactiveDate}
                        >
                        </Calendar>
                    </div>
                )
            },
        },
        {
            menuItem: 'Yetkiler',
            pane: {
                key: 'tab5', content: (
                    <div style={{ overflow: "overlay" }}>
                        <Button
                            label="Yetki Ekle"
                            className="p-button-warning"
                            onClick={addRole}
                        />
                    </div>
                )
            },
        }

    ];

    return (
        <>
            <Dialog
                visible={dialogDriversManagement}
                style={{ width: "550px" }}
                header={product.id ? "Kullanıcı Güncelle" : "Kullanıcı Ekle"}
                modal
                footer={productDialogFooter("drivers-mannagement-dialog")}
                onHide={hideDialog}
                blockScroll={true}
            >

                <Tab panes={driverManagementTabs} renderActiveOnly={false} />

            </Dialog>


            <Dialog
                visible={dateDescriptionDialog}
                style={{ width: "450px" }}
                onHide={dateDescriptionHideDialog}
                footer={DialogFooter}
            >
                <p>Aktif Olmadıgı Gün Nedeni</p>
                <dl>
                    <Input
                        action={{
                            color: 'teal',
                            labelPosition: 'left',
                            icon: 'calendar',
                            content: selectedDate && selectedDate.toLocaleDateString(),
                        }}
                        actionPosition='left'
                        placeholder='Aktif olmadigini nedeni...'
                        // defaultValue={ inactive && inactive.reason}
                        size="huge"
                        onChange={event => setInactiveReason(event.target.value)}
                    />

                </dl>
            </Dialog>

            {/* confirm date delete */}
            <Dialog
                visible={deleteInactiveDateDialog}
                style={{ width: "250px" }}
                header="Uyarı"
                modal
                footer={deleteInactiveDateDialogFooter}
                onHide={hideDeleteInactiveDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle p-mr-3"
                        style={{ fontSize: "2rem" }}
                    />
                    {deleteDate && (
                        <span>
                            {formatDate(deleteDate.date)} silmek istediğinize emin misiniz <b></b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <DriverRole
                driverRoleDialog={driverRoleDialog}
                setDriverRoleDialog={setDriverRoleDialog}
                selectedDriversRole={selectedDriversRole}
                setSelectedDriversRole={setSelectedDriversRole}
                product={product}
                //onInputChange={onInputChange}
            />
        </>
    )
}

export default DriverManagement