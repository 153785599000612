import TYPES from "../store/types";

export const getStatus = (type) => {
  switch (type) {
    case "ORDER_ACCEPTED":
      return "Paket kabul yapıldı";
    case "COMPLETED":
      return "Rota Tamamlandı";
    case "BRANCH_ACCEPTED":
      return "Şube kabul yapıldı";
    case "BRANCH_RETURN_ACCEPTED":
      return "Şubeye iade kabul yapıldı";
    case "ON_THE_WAY":
    case "ON_THE_WAY_TO_CUSTOMER":
    case "CUSTOMER_INFORMED_1":
    case "CUSTOMER_INFORMED_2":
    case "CUSTOMER_READINESS_RECEIVED":
      return "Dağıtımda";
    case "COURIER_DEBIT":
      return "Kurye zimmetine aldı";
    case "WILL_BE_RETURN":
      return "İade edilecek";
    case "DELIVERY_CANCELLED":
      return "Teslimat iptal edildi";
    case "CUSTOMER_INFORMED_3":
      return "Son 30 dakika";
    case "DELIVERED":
      return "Teslim edildi";
    case "PARTIALLY_DELIVERED":
      return "Kısmen teslim edildi";
    case "NOT_DELIVERED":
      return "Teslim edilemedi";
    case "DELIVERY_POSTPONED":
      return "Teslim ertelendi";
    case "IN_WAREHOUSE":
    case "DELIVERY_PLANNED":
    case "ON_BOARD":
    case "REVERT_FROM_PLAN":
      return "Depoda";
    case "ORDER_RECEIVED":
      //return "Sipariş alındı";
      return "Paket kabul bekliyor";

    case "SENT_TO_PLAN":
      return "Planlamaya gönderildi";
    default:
      return type;
  }
};

export const GONDERIM_TURLERI = [
  { value: "STANDART_SHIPPING", label: "Standart Kargo" },
  { value: "SAME_DAY_SHIPPING", label: "Aynı Gün Kargo" },
];

export const loadingActive = (dispatch, status) => {
  if (status === true) {
    dispatch({
      type: TYPES.LOADING_CHANGE,
      payload: true,
    });
  } else {
    dispatch({
      type: TYPES.LOADING_CHANGE,
      payload: false,
    });
  }
};

export const ARAC_TIPLERI = {
  "Frigo Kapalı Kasa": {
    icon: "images/customerDashboard/frigo.svg",
    color: "warning",
  },
  Motorsiklet: {
    icon: "images/customerDashboard/motorcycle.svg",
    color: "success",
  },
  Panelvan: {
    icon: "images/customerDashboard/panelvan.svg",
    color: "info",
  },
  "Standart Kapalı Kasa": {
    icon: "images/customerDashboard/standart.svg",
    color: "error",
  },
};
