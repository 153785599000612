import TYPES from "../types";

const initialState = {
  inactive: '',
  driver_inactive: '',
  payment_extra: ''
};

export const inactiveDateReasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.INACTIVE_DATE:
      return { ...state, inactive: [...state.inactive.data, action.payload.data[0]]}
    case TYPES.GET_INACTIVE_DATE:
      return {...state, inactive: action.payload}
    case TYPES.DELETE_INACTIVE_DATE:
      return { ...state, inactive: state.inactive.data.filter(item => item.id != action.payload)}
    case TYPES.DRIVERS_INACTIVE_DATE:
      return { ...state, driver_inactive: [...state.driver_inactive.data, action.payload.data[0]]}
    case TYPES.GET_DRIVERS_INACTIVE_DATE:
      return {...state, driver_inactive: action.payload}
    case TYPES.DELETE_INACTIVE_DATE:
      return { ...state, driver_inactive: state.driver_inactive.data.filter(item => item.id != action.payload)}
    case TYPES.PAYMENT_EXTRA_DATE:
      return {...state, payment_extra: [...state.payment_extra.data, action.payload.data[0]]} 
    case TYPES.GET_PAYMENT_EXTRA_DATE:
      return {...state, payment_extra: action.payload}
    default:
      return state
  }
};
