import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { saveSgkinfo, getSgkinfo } from "../../store/actions/sgkAction";

const Sgk = (props) => {

    const { id, toastCallback } = props;
    const dispatch = useDispatch();

    const emptySgkObject = {
        "id": "",
        "username": "",
        "company_code": "",
        "system_password": "",
        "company_password": "",
        "company_reg_no": "",
        "company": id // Company Id
    }

    const [sgkData, setSgkData] = useState({
        "id": "",
        "username": "",
        "company_code": "",
        "system_password": "",
        "company_password": "",
        "company_reg_no": "",
        "company": id // Company Id
    });


    

    //const [edit, setEdit] = useState(!sgkData ? true : false);
    const [edit, setEdit] = useState(true);
    const [editActive, setEditActive] = useState({});


    


    const saveSgk = () => {
        //console.log("sgk data :", sgkData);
        if (sgkData.username == "" || sgkData.company_code == "" || sgkData.system_password == "" || sgkData.company_password == "" || sgkData.company_reg_no == "") {
            toastCallback("warn", "Boş alanlar var");
            return
        }

        if(edit == true){
            return
        }
        
        dispatch(
            saveSgkinfo(sgkData, toastCallback, setSgkData, setEditActive)
        )
    }

    const editSgk = () => {
        if (edit == true) {
            setEdit(false);
            setEditActive(sgkData)
            setSgkData(emptySgkObject)
        } else {
            setEdit(true);
            setSgkData(editActive)
        }
    }

    useEffect(() => {
        dispatch(getSgkinfo(id, setSgkData, emptySgkObject, setEdit));
    }, [])

    return (
        <div style={{ overflow: "overlay" }}>
            <div style={{ "display": "flex", "justifyContent": "end" }}>
                <Button icon="pi pi-pencil" className={`p-button-rounded p-button-${(edit == true) ? "secondary" : "success"} p-mr-2`} onClick={() => editSgk()} />
                {/* style={{"width": "80px", "border": "none", "transition": "none"}} */}
            </div>
            <div>
                <div className="paymentContent">
                    <span className="pc-span-fontw">Kullancı adı</span>
                    <InputText
                        value={sgkData && sgkData.username}
                        onChange={(e) => setSgkData({ ...sgkData, username: e.target.value })}
                        disabled={edit}
                    />
                </div>
                <div className="paymentContent">
                    <span className="pc-span-fontw">İşyeri kodu</span>
                    <InputText
                        value={sgkData && sgkData.company_code}
                        onChange={(e) => setSgkData({ ...sgkData, company_code: e.target.value })}
                        disabled={edit}
                    />
                </div>
                <div className="paymentContent">
                    <span className="pc-span-fontw">Sistem şifre</span>
                    <Password
                        feedback={false}
                        value={sgkData && sgkData.system_password}
                        onChange={(e) => setSgkData({ ...sgkData, system_password: e.target.value })}
                        disabled={edit}
                    />
                </div>
                <div className="paymentContent">
                    <span className="pc-span-fontw">İşyeri şifre</span>
                    <Password
                        feedback={false}
                        value={sgkData && sgkData.company_password}
                        onChange={(e) => setSgkData({ ...sgkData, company_password: e.target.value })}
                        disabled={edit}
                    />
                </div>
                <div className="paymentContent">
                    <span className="pc-span-fontw">İşyeri sicil no</span>
                    <InputText
                        value={sgkData && sgkData.company_reg_no}
                        onChange={(e) => setSgkData({ ...sgkData, company_reg_no: e.target.value })}
                        disabled={edit}
                    />
                </div>
                <div className="paymentContent pc-w-250">
                    {/* <Button label="Kaydet" onClick={(e) => dispatch(saveProgressPayment(countPricNumber))} /> */}
                    <Button label="Kaydet" onClick={() => saveSgk()} />
                </div>
            </div>
        </div>
    )
}

export default Sgk
