import TYPES from "../types";
import { Services } from "../../api/Services"


const server = new Services();

export const saveSgkinfo = (data, toastCallback, setSgkData, setEditActive) => {

    return async (dispatch) => {
        server.
            sgk(data).then((data) => {
                toastCallback("success");
                setSgkData(data.entity.data[0]);
                setEditActive(data.entity.data[0]);
                // dispatch({
                //   type: TYPES.LISTING,
                //   payload: data.entity
                // });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};

export const getSgkinfo = (id, setSgkData, emptySgkObject, setEdit) => {

    return async (dispatch) => {
        server.
            getSgk(id).then((data) => {
                //toastCallback("success");
                //setSgkData(data.entity.data.length > 0 ? data.entity.data[0]:emptySgkObject)
                if(data.entity.data.length > 0){
                    setSgkData(data.entity.data[0])
                }else{
                    setSgkData(emptySgkObject);
                    setEdit(false)
                }
                // dispatch({
                //   type: TYPES.LISTING,
                //   payload: data.entity
                // });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};


