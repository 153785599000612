import { Auth } from "../../api/Auth";
import { Services } from "../../api/Services";
import TYPES from "../types";
import history from "../history";

export const login = (username, password, captchaToken, callback, onError) => {
  return async (dispatch) => {
    const authServices = new Auth();
    const server = new Services();
    dispatch({
      type: TYPES.USER_LOGOUT,
      payload: "",
    });
    dispatch({
      type: TYPES.USER,
      payload: "",
    });
    authServices
      .login(username, password, captchaToken)
      .then((data) => data)
      .then((datajson) => {
        return dispatch({
          type: TYPES.TOKEN,
          payload: datajson.entity.token,
          data: datajson,
        });
      })
      .then((datas) => {
        if (authServices.isAuthenticated()){
          server
          .user()
          .then((res) => {
            dispatch({
              type: TYPES.USER,
              payload: res.entity.data[0],
            });
            return res.entity.data[0];
          })
          .then((data) => {
            callback(data);
          })
          .catch((error) => {
            console.log(error);
          });
        }

        else onError({
          error:{message: "Could not login!"}
        })
      })
      .catch((error) => {
        console.log(error);
        onError(error);
      });
  };
};

/**
 * logout function wipe data from redux (maybe need to delete token again)
 * @param {*} callback function when logout service return 200
 * @returns
 */
export const logout = (callback) => {
  return (dispatch) => {
    const server = new Auth();
    server
      .logout()
      .then(() => {
        dispatch({
          type: TYPES.USER_LOGOUT,
          payload: "",
        });
        if (callback) callback();
      })
      .then((e) => {
        dispatch({
          type: TYPES.USER,
          payload: "",
        });
      });
  };
};
