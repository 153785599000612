export const dropdownFilterChange = (e, t, cb) => {

  const selected = reverseStateofOrder(e.value, t);
  cb(selected);
};

export const reverseStateofOrder = (text, t) => {
  switch (text) {
    case t("in-warehouse"):
      return "IN_WAREHOUSE";
    case t("delivery-planned"):
      return "DELIVERY_PLANNED";
    case t("on-board"):
      return "ON_BOARD";
    case t("on-the-way-customer"):
      return "CUSTOMER";
    //return "CUSTOMER_INFORMED_3";
    case t("customer-informed-1"):
      return "CUSTOMER_INFORMED_1";
    case t("customer-informed-2"):
      return "CUSTOMER_INFORMED_2";
    case t("customer-readiness-received"):
      return "CUSTOMER_READINESS_RECEIVED";
    case t("customer-informed-3"):
      return "CUSTOMER_INFORMED_3";
    case t("delivered"):
      return "DELIVERED";
    case t("partially-delivered"):
      return "PARTIALLY_DELIVERED";
    case t("not-delivered"):
      return "NOT_DELIVERED";
    case t("delivery-postponed"):
      return "DELIVERY_POSTPONED";
    case t("courier-debit"):
      return "COURIER_DEBIT";
    case t("branch-accepted"):
      return "BRANCH_ACCEPTED";
    case t("send-to-plan"):
      return "SENT_TO_PLAN";
    case t("will-be-return"):
      return "WILL_BE_RETURN";
    case t("delivery-cancelled"):
      return "DELIVERY_CANCELLED";

    default:
      return text;
  }
};

export const getPaymentType = (paymentType, t) => {
  switch(paymentType){
    case "door_credit":
      return t("door_credit");
    case "door_cash":
      return t("door_cash");
    case "online": 
      return t("online");
      
    default:
      return paymentType;
  }
}

export const onDateChange = (e, cb) => {
  const dds = new Date(e.value);
  const fixedMonth = dds.getMonth() + 1;
  const finalDate = `${dds.getFullYear()}-${fixedMonth < 10 ? "0" + fixedMonth : fixedMonth}-${dds.getDate() < 10 ? "0" + dds.getDate() : dds.getDate()
    }`;
  cb(finalDate);
};



export const stateofOrder = (text, t) => {
  switch (text) {
    case "IN_WAREHOUSE":
      return t("in-warehouse");
    case "DELIVERY_PLANNED":
      //return t("delivery-planned");
      return t("in-warehouse");
    case "ON_BOARD":
      return t("on-board");
    case "ON_THE_WAY_TO_CUSTOMER":
      return t("on-the-way-customer");
    case "CUSTOMER_INFORMED_1":
      //return t("customer-informed-1");
      return t("on-the-way-customer");
    case "DELIVERY_CANCELLED":
      //return t("customer-informed-1");
      return t("delivery_cancelled");
    case "CUSTOMER_INFORMED_2":
      //return t("customer-informed-2");
      return t("on-the-way-customer");
    case "CUSTOMER_READINESS_RECEIVED":
      return t("customer-readiness-received");
    case "CUSTOMER_INFORMED_3":
      //return t("customer-informed-3");
      return t("on-the-way-customer");
    case "DELIVERED":
      return t("delivered");
    case "PARTIALLY_DELIVERED":
      //return t("partially-delivered");
      return t("delivered");
    case "NOT_DELIVERED":
      return t("not-delivered");
    case "DELIVERY_POSTPONED":
      return t("delivery-postponed");
    case "SENT_TO_PLAN":
      return "PLANLAMAYA GÖNDERİLDİ";

    default:
      return text;
  }
};
