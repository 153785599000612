import TYPES from "../types";

const initialState = {};

export const jobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_JOBS_LIST_ALL:
            return { ...state, data: action.payload.data }
           break;
        case TYPES.UPDATE_JOB:
            return {
                ...state, 
                data: state.data.map(job =>  job.id == action.payload.id ? action.payload : job) 
            }
           break;
        default:
            return state
    }
};
