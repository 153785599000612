import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Table } from 'semantic-ui-react';
import { Button } from "primereact/button";
import { delayVisitMultiple } from "../../store/actions/liveTrackingAction";

const PostponeVisit = (props) => {
    const dispatch = useDispatch();
    const { dialogPostponeVisit, setDialogPostponeVisit, toastCallback, selectedProducts } = props;

    const [delayDate, setDelayDate] = useState(null);

    const hideDialogPostpone = () => {
        setDialogPostponeVisit(false);
    };

    const postponeVisitSave = () => {
        //date and data check
        if(!delayDate) {
            toastCallback("warn", "Tarih alanı boş geçilemez");
            return
        }
        if(!selectedProducts) {
            toastCallback("warn", "Kayıt yok");
            return
        }

        //show undelivered orders
        let postponeData = selectedProducts.filter(d => d.delivery_status == "NOT_DELIVERED");
        dispatch(delayVisitMultiple(delayDate, postponeData, toastCallback, setDialogPostponeVisit));
    };



    const postponeVisitDialogFooter = (

        <React.Fragment>
            {/* <Button
            label="İptal"
            icon="pi pi-times"
            className="p-button-text"
            //onClick={postponeVisitDialogVisible}
          /> */}
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => postponeVisitSave()}
            />
        </React.Fragment>

    );

    const dateStyle = {
        background: "#f9fafb",
        padding: "8px 9px 8px 4px",
        /* border-radius: 3px; */
        border: "1px solid rgba(34,36,38,.15)",
        boxShadow: "none",
        borderRadius: "0.28571429rem",
        marginBottom: "15px"
    }

    return (
        <Dialog
            header="Ziyaret Ertele"
            visible={dialogPostponeVisit}
            style={{ width: "650px" }}
            onHide={hideDialogPostpone}
            footer={postponeVisitDialogFooter}
        >
            <div style={dateStyle}>
                <span>Tarih  </span>
                <Calendar
                    id="calender"
                    style={{ marginRight: "25px", width: "185px" }}
                    value={delayDate}
                    onChange={(e) => setDelayDate(e.value)}
                    selectionMode="single"
                    dateFormat="dd.mm.yy"
                    readOnlyInput
                />
            </div>
            <div>


                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Sipariş Kodu</Table.HeaderCell>
                            <Table.HeaderCell>Tarih</Table.HeaderCell>
                            <Table.HeaderCell>Firma Adı</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            selectedProducts && selectedProducts.map(d => {
                                return d.delivery_status == "NOT_DELIVERED" && <Table.Row><Table.Cell>{d.order_code}</Table.Cell><Table.Cell>{new Date(d.delivery_date).toLocaleDateString()}</Table.Cell><Table.Cell>{d.firm_name}</Table.Cell></Table.Row>
                            })
                        }

                    </Table.Body>
                </Table>
            </div>
        </Dialog>
    )
}

export default PostponeVisit
