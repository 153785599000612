import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Table } from 'semantic-ui-react';
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { postCustomerCountyUserMultiple } from "../../store/actions/debitAction"


const DebitMultipleSelection = (props) => {
    const dispatch = useDispatch();
    const { dialogDebitMultipleSelection, setDialogDebitMultipleSelection, selectedProducts, onInputChange, product, toastCallback } = props;
    const driversList = useSelector((state) => state.driverManagementReducer);

    const hideDialogDebit = () => {
        setDialogDebitMultipleSelection(false);
    };

    const debitMultipleSelectionSave = () => {
        if(!selectedProducts) {
            toastCallback("warn", "İl/ilçe seçili değil!");
            return
          }
        const debitMultipleSelected = [...selectedProducts];
        //update selected list
        debitMultipleSelected.map(d => {
            d.user_oid = product.user_oid;
            d.customer_oid = product.customer_id
        })
        dispatch(postCustomerCountyUserMultiple(debitMultipleSelected , toastCallback, setDialogDebitMultipleSelection))
    };


    const debitDialogFooter = (

        <React.Fragment>
            {/* <Button
            label="İptal"
            icon="pi pi-times"
            className="p-button-text"
            //onClick={postponeVisitDialogVisible}
          /> */}
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => debitMultipleSelectionSave()}
            />
        </React.Fragment>

    );

    return (
        <Dialog
            header="Ziyaret Ertele"
            visible={dialogDebitMultipleSelection}
            style={{ width: "650px" }}
            onHide={hideDialogDebit}
            footer={debitDialogFooter}
        >
            <Dropdown
                style={{
                    height: "33px",
                    lineHeight: "14px",
                    marginRight: "10px",
                    width: "180px",
                }}
                value={product.user_oid}
                options={driversList.data.data}
                optionValue="id"
                optionLabel="full_name"
                //onChange={(e) => { dbtUserSelectionComboBox(e, rowData) }}
                onChange={(e) => { onInputChange(e, "user_oid") }}
                placeholder="Müşteri"
            />
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>il</Table.HeaderCell>
                        <Table.HeaderCell>ilçe</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        selectedProducts && selectedProducts.map(d => {
                            return <Table.Row><Table.Cell>{d.city}</Table.Cell><Table.Cell>{d.county}</Table.Cell></Table.Row>
                        })
                    }

                </Table.Body>
            </Table>

        </Dialog>
    )
}

export default DebitMultipleSelection
