import React from "react";
import PropTypes from "prop-types";
import {
  alpha,
  Box,
  Button,
  MenuItem,
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";
import Label from "components/CustomTable/TableLabel";
import { ARAC_TIPLERI } from "helper";
import { TextFirstLetter } from "utils";

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

Item.propTypes = {
  item: PropTypes.object,
  handleProjectsOpen: PropTypes.func,
};

export default function Item({
  item,
  handleProjectsOpen,
  handleDelete,
  toggleModal,
}) {
  const { palette } = useTheme();
  const [openMenu, setOpenMenuActions] = React.useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const color = ARAC_TIPLERI[item.aracTip]?.color || "primary";
  const color1 = palette[color].main;
  const bgColor = alpha(palette[color].main, 0.08);

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell>
        <Typography variant="caption">{item.plaka}</Typography>
      </TableCell>
      <TableCell>
        {item.aracTip ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconWrapperStyle sx={{ color: color1, backgroundColor: bgColor }}>
              <Box
                component="img"
                src={ARAC_TIPLERI[item.aracTip]?.icon}
                sx={{ width: 20, height: 20 }}
              />
            </IconWrapperStyle>
            <Typography variant="caption">{item.aracTip}</Typography>
          </Stack>
        ) : null}
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.model}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.surucu)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.name)}</Typography>
      </TableCell>
      <TableCell title={item.projeler}>
        {item.projeler.length > 0 && (
          <Button
            size="small"
            onClick={() => handleProjectsOpen(item)}
            variant="contained"
          >
            {item.projeler.length > 0 ? "Projeleri Gör" : ""}
          </Button>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.zone)}</Typography>
      </TableCell>
      <TableCell>
        <Label
          variant="ghost"
          color={item.status === "ACTIVE" ? "success" : "error"}
        >
          {item.status === "ACTIVE" ? "Aktif" : "Pasif"}
        </Label>
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => handleDelete(item.id)}
                sx={{ color: "error.main" }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
                Sil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleModal(item);
                  handleCloseMenu();
                }}
              >
                <Iconify icon={"eva:edit-fill"} />
                Düzenle
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
