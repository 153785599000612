import TYPES from '../types';

const initialState = {
    unplannedOrders: '',
};

export const getUnplannedOrders = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_UNPLANNED_ORDERS:
            return {...state, unplannedOrders: action.payload};
        default:
            return state;
    }
}