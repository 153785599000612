import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAdminOrderUnplanned } from "../../store/actions/orderAction";
import Table from "../../components/Table";
import { Auth } from "../../api/Auth";
import Layout from "components/Layout";
// import TYPES from "../../store/types/";

const AdminOrderManagement = (props) => {
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();

  const dispatch = useDispatch();

  const getOrderList = useSelector((state) => state.orderReducer);
  const new_orders = useSelector((excel_file) => excel_file.uploadExcel);
  // const getUngeocodedReducer = useSelector((state) => state.ungeocodedReducer);
  //const path = props.location.pathname.split("/")[1];  url path
  //console.log(getOrderList);

  useEffect(() => {
    dispatch(getAdminOrderUnplanned());
  }, []);

  useEffect(() => {
    if (new_orders && new_orders.excel_data) {
      if (!getOrderList.data) {
        getOrderList.data = [];
      }
      new_orders.excel_data.forEach((order) => {
        getOrderList.data.push(order);
      });
      // dispatch({
      //   type: TYPES.GET_ORDER_UNPLANNED,
      //   payload: new_orders.excel_data
      // })
    }
  }, [new_orders]);

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  return (
    <Layout
      {...props}
      heading="Sipariş Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "Sipariş Yönetimi" }]}
    >
      <Table
        products={getOrderList.data} //filter(p => p.sent_to_plan == false)
        unplannedOrderButtonShow={false}
        type="admin-order-management"
        //differentPage={path == "geocode" ? "OrderManangementAdminGeocode": null}
      />
    </Layout>
  );
};
export default AdminOrderManagement;
