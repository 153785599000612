import React from "react";
import Item from "./item";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Iconify from "components/Iconify";
import TableNoData from "components/CustomTable/TableNoData";

// Table Head Data
const tableHeadData = [
  { key: "kurye", title: "Kurye" },
  { key: "sevkTarih", title: "Sevk Tarihi" },
  { key: "rotaSaat", title: "Rota Baş. Saati" },
  { key: "aracBagliSube", title: "Araç Bağlı Şube" },
  { key: "plaka", title: "Plaka" },
  { key: "gonderi", title: "Sipariş" },
  { key: "paket", title: "Paket" },
  { key: "teslim", title: "Teslim" },
  { key: "teslimEdilmeyen", title: "Teslim Edilmeyen" },
  { key: "nokta", title: "Nokta" },
  { key: "deci", title: "Desi" },
  // { key: "teslimPercent", title: "Teslim %" },
  // { key: "miktar", title: "Miktar %" },
  { key: "actions", title: "İşlemler" },
];

const List = ({ data }) => {
  const [showAll, setShowAll] = React.useState(false);
  const dataToShow = showAll ? data : data.slice(0, 5);

  return (
    <Card>
      <CardHeader title="Gönderiler" sx={{ mb: 3 }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadData.map((t) => (
                <TableCell key={t.key}>{t.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.length > 0 ? (
              dataToShow.map((row, index) => <Item key={index} item={row} />)
            ) : (
              <TableNoData colSpan={12} isNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {data.length > 5 && (
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="inherit"
            endIcon={
              showAll ? (
                <Iconify icon="bx:bx-chevron-down" />
              ) : (
                <Iconify icon="bx:bx-chevron-right" />
              )
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Daha az göster" : "Tümünü göster"}
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default List;
