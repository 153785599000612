import { Ref } from "semantic-ui-react";
import { Services } from "../../api/Services";
import TYPES from "../types";

export const uploadExcelAction = (file, warehouseOid, date,callback, onError, modalGeocode, alertGeocode) => {
  return async (dispatch) => {
    const server = new Services();
    server
      .uploadExcel(file, warehouseOid, date)
      .then(res => {
        dispatch({
          type: TYPES.UPLOAD_EXCEL,
          payload: res.entity.data,
        });

        /* Excel yükledikten hemen sonra geocode ekranının açılmısını sağlar */
        /*let uncoordinatedPoints = res.entity.data.filter(d => d.address.latitude === 0 && d.address.longitude === 0)

        if (uncoordinatedPoints.length > 0) {
          modalGeocode(true);
          alertGeocode("warn", "Koordinatsız Noktalar Bulunmakta!");

          let unCoordata = {};
          unCoordata["data"] = uncoordinatedPoints;

          dispatch({
            type: TYPES.GET_UNGEOCODED,
            payload: unCoordata
          });

        }*/


        callback(res.entity.success)
      })
      // .then(data => {
      //   dispatch({
      //     type: TYPES.UPDATE_TABLE_ORDER,
      //     payload: data.entity.data
      //   })
      // })
      .catch(error => {
        onError(error);
        console.log(error);
      });
  };
};
