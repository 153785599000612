import TYPES from "../types";
import { Services } from "../../api/Services";

const server = new Services();

// vehicles inactives dates
export const inactiveDateReasonAction = (inactive_data, callback) => {
  return async (dispatch) => {
    server
      .saveInactiveDate(inactive_data)
      .then(res => {
        dispatch({
          type: TYPES.INACTIVE_DATE,
          payload: res.entity,
        });
        callback(res.entity);
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  }
};

export const getInactiveDateReasonAction = (start_date, vehicle_id, end_date, callback) => {

  return async (dispatch) => {
    server
      .getInactiveDate(start_date, vehicle_id, end_date)
      .then(res => {
        dispatch({
          type: TYPES.GET_INACTIVE_DATE,
          payload: res.entity,
        });
        callback();
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const deleteInactiveDateReasonAction = ( inactive_date_id, callback )=> {

  return async (dispatch) => {
    server
      .deleteInactiveDateReason(inactive_date_id)
      .then(res => {
        dispatch({
          type: TYPES.DELETE_INACTIVE_DATE,
          paylaod: inactive_date_id
        });
        callback(true);
      })
      .catch(error => { console.log(error.message)});
  }
  
};


// drivers inactive dates
export const driversInactiveDateReasonAction = (inactive_data, callback) => {
  return async (dispatch) => {
    server
      .saveDriversInactiveDate(inactive_data)
      .then(res => {
        dispatch({
          type: TYPES.DRIVERS_INACTIVE_DATE,
          payload: res.entity,
        });
        callback(res.entity);
      })
      .catch((error) => {
        console.log(error);
        //onError();
      });
  }
};

export const getDriversInactiveDateReasonAction = (start_date, vehicle_id, end_date, callback) => {

  return async (dispatch) => {
    server
      .getDriversInactiveDate(start_date, vehicle_id, end_date)
      .then(res => {
        dispatch({
          type: TYPES.GET_DRIVERS_INACTIVE_DATE,
          payload: res.entity,
        });
        callback();
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const deleteDriversInactiveDateReasonAction = ( inactive_date_id, callback ) => {
  return async (dispatch) => {
    server
      .deleteDriversInactiveDateReason(inactive_date_id)
      .then(res => {
        dispatch({
          type: TYPES.DELETE_DRIVERS_INACTIVE_DATE,
          paylaod: inactive_date_id
        });
        callback(true);
      })
      .catch(error => { console.log(error.message)});
  }
  
};

export const paymentExtraDateAction = (inactive_data, callback) => {
  return async (dispatch) => {
    server
      .savePaymentExtraDate(inactive_data)
      .then(res => {
        console.log(res);
        dispatch({
          type: TYPES.PAYMENT_EXTRA_DATE,
          payload: res.entity,
        });
        callback(res.entity);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const getPaymentExtraDateAction = (start_date, vehicle_id, end_date, callback) => {

  return async (dispatch) => {
    server
      .getPaymentExtraDate(start_date, vehicle_id, end_date)
      .then(res => {
        dispatch({
          type: TYPES.GET_PAYMENT_EXTRA_DATE,
          payload: res.entity,
        });
        callback();
      })
      .catch(error => {
        console.log(error);
      });
  }
};
