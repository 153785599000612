import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Typography, Box } from "@mui/material";
// utils
import { fShortenNumber } from "utils/formatNumber";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2, 2, 2, 3),
}));

// ----------------------------------------------------------------------

BookingWidgetSummary.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

export default function BookingWidgetSummary({
  title,
  total,
  icon,
  onClick,
  sx,
}) {
  return (
    <RootStyle onClick={onClick} sx={sx}>
      <div>
        <Typography variant="h2">{fShortenNumber(total)}</Typography>
        <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
          {title}
        </Typography>
      </div>
      <Box sx={{ width: 120, height: 130, lineHeight: 0 }}>{icon}</Box>
    </RootStyle>
  );
}
