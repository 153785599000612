import "./App.css";
import RootRouter from "./router";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { persistor } from "./store";
import history from "./store/history.js";
import { PersistGate } from "redux-persist/es/integration/react";
import { SnackbarProvider } from "notistack";
import ThemeProvider from "theme";
import { Spinner } from "../src/screens/Spinner/Spinner";

// import WithStylesContext from "react-with-styles/lib/WithStylesContext";

function App() {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Spinner />
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <SnackbarProvider
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={1500}
            >
              <RootRouter />
            </SnackbarProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
