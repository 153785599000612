import TYPES from "../types";

const initialState = { data: "" };

export const liveTrackingHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_DELIVERY_HISTORY:
            return { ...state, data: action.payload.data }

        default:
            return state
    }
};
