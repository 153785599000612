import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";
import Table from "../../components/Table";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Container } from "@mui/material";
import Topbar from "components/Topbar";

const Debit = (props) => {
  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();
  // const dispatch = useDispatch();
  const navBarWidth = 280;
  const isMobile = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  //   var test = [
  //       {"customer": "AA", "county": "çekmeköy" },
  //       {"customer": "BB", "county": "sancaktepe" },
  //       {"customer": "CC", "county": "sarıgazi" }
  //   ]

  return (
    <>
      {!isMobile && <Topbar marginLeft={`${navBarWidth}px`} />}
      <Container
        maxWidth={false}
        sx={{ display: isMobile ? "block" : "flex", paddingTop: "60px" }}
      >
        <NavBar navBarWidth={navBarWidth} isMobile={isMobile} />
        <Box width={1} mt={2} ml={isMobile ? 0 : `${navBarWidth + 20}px`}>
          <Box sx={{ pb: 10 }}>
            <Table
              //products={test}
              type="debit"
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Debit;
