import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, Typography } from "@mui/material";
import _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

export default function MultipleSelect({
  label,
  defaultValue = [],
  options = [],
  handleChange,
  id,
  placeholder,
  name,
}) {
  const newOptions = _.uniqBy(options, function (e) {
    return e.key;
  });

  return (
    <Select
      id={id}
      multiple
      value={defaultValue}
      onChange={({ target: { value } }) => handleChange(value, name)}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <Typography sx={{ opacity: 0.5, fontSize: 14 }}>
              {placeholder}
            </Typography>
          );
        }

        return selected.map((i) => i.value).join(", ");
      }}
      MenuProps={MenuProps}
    >
      {newOptions.map((item, i) => {
        return (
          <MenuItem key={`${item.key}-${i}-multiselect`} value={item}>
            <Checkbox checked={defaultValue.indexOf(item) > -1} />
            <ListItemText primary={item.value} />
          </MenuItem>
        );
      })}
    </Select>
  );
}
