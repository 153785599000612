import React, { useEffect } from "react";
import { Auth } from "../../api/Auth";
import NavBar from "../../components/Navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Container } from "@mui/material";
import Topbar from "components/Topbar";

const Reports = (props) => {
  let auth = new Auth();
  let isAuthUser = auth.isAuthenticated();
  const navBarWidth = 280;
  const isMobile = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  return (
    <>
      {!isMobile && <Topbar marginLeft={`${navBarWidth}px`} />}
      <Container maxWidth={false} sx={{ display: isMobile ? "block" : "flex" }}>
        <NavBar navBarWidth={navBarWidth} isMobile={isMobile} />
        <Box width={1} mt={2} ml={isMobile ? 0 : `${navBarWidth + 20}px`}>
          <Box sx={{ pb: 10 }}>Reports</Box>
        </Box>
      </Container>
    </>
  );
};

Reports.whyDidYouRender = false;

export default Reports;
