import { Cookies } from 'react-cookie';
import { Services } from './Services.js';
// Object.defineProperty(exports, "__esModule", { value: true });

var services = new Services();

export function Auth() {
    const cookie = new Cookies();
    var _this = this;

	this.login = (username, password, grecaptcha) => {
        return new Promise(async (resolve, reject) => {
          await services.login(username, password, grecaptcha)
                        .then((response) =>{
                            _this.setAuthorizationCookie(response.entity.token);
                            //access token issue
                            //wait for token save

                            // <!-- Global site tag (gtag.js) - Google Analytics -->
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { window.dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('set', { 'user_id': username });
                            gtag('config', 'G-B9BC62GB6W');

                            setTimeout(() => {
                                if (_this.hasAuthorizationCookie()) return resolve(response);
                                else return reject(response);
                            }, 500);

                            //resolve(response);
                        })
                        .catch((error) => {
                           reject(error);
                        })
       })
    }

    this.logout = () => {
        return new Promise(async (resolve, reject) => {
           return await services.logout()
                           .then(() => {
                               _this.removeAuthorizationCookie();
                               resolve();
                           })
                           .catch(() => {
                               _this.removeAuthorizationCookie();
                               resolve();
                           })
        })
    }

    this.isAuthenticated = () => {
        return _this.hasAuthorizationCookie();
    }

    this.hasAuthorizationCookie = () => {
        let hasToken = cookie.get("access_token");
        return !!hasToken;
    }
    
    this.setAuthorizationCookie = (val) => {
        if (_this.hasAuthorizationCookie()) _this.removeAuthorizationCookie();
        cookie.set("access_token", val, {path: "/"});
    }

    this.removeAuthorizationCookie = () => {
        cookie.remove("access_token", {path: "/"});
    }

    this.getLoginToken = () =>{
        return cookie.get("access_token");
    }
};

// if (typeof module === "object" && module.exports) {
//     module['exports'] = Auths;
// };

// if (typeof Module === 'undefined')
//     Module = {};
// return Auths(Module);
// export default Auths;

