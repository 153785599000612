import React from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import List from "./List";
import Detail from "./Detail";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Services } from "api/Services";

const listFilterData = (data) => {
  return data.map((item) => {
    return {
      id: item.id,
      created_at: item.created_at,
      name: item.name,
      code: item.code,
      address: item.address?.address || "",
      city: item.address?.city || "",
      county: item.address?.county || "",
      first_name: item.address?.first_name || "",
      last_name: item.address?.last_name || "",
      gsm_number: item.address?.gsm_number || "",
      username: item.contact?.username || "",
      //
      take_photo_on_delivery: item.take_photo_on_delivery,
      use_barcode_on_delivery: item.use_barcode_on_delivery,
      use_order_code_for_outlet: item.use_order_code_for_outlet,
      use_receiver_info_for_outlet: item.use_receiver_info_for_outlet,
      use_shipment_code_on_delivery: item.use_shipment_code_on_delivery,
      //
      detailData: item,
    };
  });
};

const server = new Services();

ProjectDefinition.propTypes = {
  style: PropTypes.array,
};

export default function ProjectDefinition(props) {
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [detail, setDetail] = React.useState(null);

  function handleDetailItemEdit(item) {
    setDetail({ detail: item, isEdit: true });
  }

  function handleDetailItemNew() {
    setDetail({ detail: null, isEdit: false });
  }

  function handleCloseDetail() {
    setDetail(null);
  }

  async function getList() {
    setLoading(true);
    try {
      const res = await server.getList();
      setTableData(listFilterData(res.entity.data));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleDetailItemDelete(id) {
    const index = tableData.findIndex((i) => i.id === id);
    if (index > -1) {
      try {
        await server.delCustomer(id);
        const newData = [...tableData];
        newData.splice(index, 1);
        setTableData(newData);
      } catch (error) {}
    }
  }

  React.useEffect(() => {
    getList();
  }, []);

  return (
    <Layout
      {...props}
      heading="Proje Yönetimi"
      links={[{ name: "Genel", href: "/" }, { name: "Proje Yönetimi" }]}
      action={
        <Button
          startIcon={<Add />}
          variant="contained"
          onClick={handleDetailItemNew}
        >
          Yeni Ekle
        </Button>
      }
    >
      <List
        handleDetailItemEdit={handleDetailItemEdit}
        handleDetailItemDelete={handleDetailItemDelete}
        loading={loading}
        tableData={tableData}
      />
      {detail && (
        <Detail
          item={detail}
          getList={getList}
          handleCloseDetail={handleCloseDetail}
        />
      )}
    </Layout>
  );
}
