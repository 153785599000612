import TYPES from "../types";

const initialState = {
  data:''
};

export const driverManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_DRIVERS_LIST_ALL:
      return { ...state, data: action.payload.data }
    case TYPES.DRIVERS_ADD:
      //return { ...state, data: [...state.data.data, action.payload.data[0]] }
      return { ...state, data: [...state.data, action.payload.data[0]] }
    case TYPES.DELETE_DRIVER:
      return { data: state.data.data ?
        state.data.data.filter(driver => driver.id != action.payload.data[0].id) :
        state.data.filter(content => content.id != action.payload.data[0].id)
    }
    case TYPES.DRIVER_STATUS:
      return {
        data: state.data.data ? 
        state.data.data.map(content => content.id == action.payload.data[0].id? action.payload.data[0] :content) :
        state.data.map(content => content.id == action.payload.data[0].id? action.payload.data[0] :content)
       }  
      case TYPES.DRIVERS_UPDATE:
        return {
          data: state.data.data ? 
          state.data.data.map(content => content.id == action.payload.data[0].id? action.payload.data[0] :content) :
          state.data.map(content => content.id == action.payload.data[0].id? action.payload.data[0] :content)
         }
      case TYPES.GET_DRIVERS_WITH_DATE:
        return {
          data: action.payload.data
        }
    default:
      return state
  }
};
