import React from 'react'
import { Icon } from 'semantic-ui-react';
import { Calendar } from 'primereact/calendar';

// file handle component: GLOBAL_FILE_INPUT
export const FilesInput = (props) => {
    const { 
        onFileUploaded,
        onSuccess,
        product,
        onInputChange,
        vehicle
    }  = props;

    var docUploaded = onSuccess;

    const handleFileUpload = event => {
        var file = event.target.files;
        onFileUploaded(file);
    };

    return (
        <div>
            {/* <div>
             <label htmlFor="sdfdf"> {vehicle && vehicle.date_title}</label>
             <Calendar 
                 id={vehicle.date_calendar_id} 
                 dateFormat="dd.mm.yy" 
                 //value = {new Date(product.inception ? product.inception.doc_expiration || product.inception : "-" )}
                 onChange={e => onInputChange(e, vehicle.date_instance)} />
            </div> */}
            <div style={{ cursor: "pointer" }}>
            {/* <label htmlFor="sdfdf"> {vehicle && vehicle.title}</label> */}
              <input 
                 className="FileInput" 
                 type="file"
                 disabled={docUploaded}
                 onChange = {event => handleFileUpload(event)}
              />
              {/* <span>Upload Files</span> */}
           </div>
        </div>
    )
}
