import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "@material-ui/core";
import TYPES from "../../store/types/index";
import { Button } from "primereact/button";
import { assignWarehouseSave } from "../../store/actions/branchAction";

const DriverRole = (props) => {
  const dispatch = useDispatch();
  const {
    driverRoleDialog,
    setDriverRoleDialog,
    selectedDriversRole,
    setSelectedDriversRole,
    product,
  } = props;
  const driversRole = useSelector((state) => state.driverRoleReducer);

  // useEffect(() => {
  //   console.log("driversRole :", driversRole)
  // }, [driversRole])

  const hideDialogDriverRole = () => {
    setDriverRoleDialog(false);
  };

  const saveDriversRole = () => {
    setDriverRoleDialog(false);
  };

  const driverRoleDialogFooter = (
    <React.Fragment>
      <Button
        label="Kaydet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={(e) => saveDriversRole()}
      />
    </React.Fragment>
  );

  const selectedRole = (e) => {
    //console.log(e.target.id);
    let duplicateData =
      selectedDriversRole && selectedDriversRole.find((d) => d == e.target.id);
    if (duplicateData) {
      let currentSelectedBranch = selectedDriversRole.filter(
        (c) => c != duplicateData
      );
      //console.log("y :", y);
      setSelectedDriversRole([...currentSelectedBranch]);
    } else {
      //selectedDriversRole ? setSelectedDriversRole([...selectedDriversRole, e.target.id]) : setSelectedDriversRole([e.target.id])
      //setSelectedDriversRole([...selectedDriversRole,e.target.id])

      if (
        e.target.id == "BRANCH_RETURN_ACCEPT_USER" ||
        e.target.id == "BRANCH_ACCEPT_USER"
      ) {
        //remove
        //ORDER_ACCEPT_USER, BRANCH_USER

        selectedDriversRole.indexOf("ORDER_ACCEPT_USER") != -1 &&
          selectedDriversRole.splice(
            selectedDriversRole.indexOf("ORDER_ACCEPT_USER"),
            1
          );
        selectedDriversRole.indexOf("BRANCH_USER") != -1 &&
          selectedDriversRole.splice(
            selectedDriversRole.indexOf("BRANCH_USER"),
            1
          );
      } else if (
        e.target.id == "ORDER_ACCEPT_USER" ||
        e.target.id == "BRANCH_USER"
      ) {
        //remove
        //BRANCH_RETURN_ACCEPT_USER, BRANCH_ACCEPT_USER

        selectedDriversRole.indexOf("BRANCH_RETURN_ACCEPT_USER") != -1 &&
          selectedDriversRole.splice(
            selectedDriversRole.indexOf("BRANCH_RETURN_ACCEPT_USER"),
            1
          );
        selectedDriversRole.indexOf("BRANCH_ACCEPT_USER") != -1 &&
          selectedDriversRole.splice(
            selectedDriversRole.indexOf("BRANCH_ACCEPT_USER"),
            1
          );
      }

      selectedDriversRole
        ? setSelectedDriversRole([...selectedDriversRole, e.target.id])
        : setSelectedDriversRole([e.target.id]);
    }
  };

  useEffect(() => {
    //console.log("driversRole :", selectedDriversRole)
    //role assigmnet
    product.role = selectedDriversRole;
  }, [selectedDriversRole]);

  return (
    <Dialog
      header="Yetki Atama"
      visible={driverRoleDialog}
      style={{ width: "1000px" }}
      onHide={hideDialogDriverRole}
      footer={driverRoleDialogFooter}
    >
      {driversRole.drivers &&
        driversRole.drivers.map((element) => {
          console.log(element);
          return (
            <table>
              <tr>
                {/* <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchAssigment(e)} /> </td> */}
                <td>
                  <Checkbox
                    id={element.code}
                    checked={
                      selectedDriversRole &&
                      selectedDriversRole.includes(element.code)
                    }
                    onChange={(e) => selectedRole(e)}
                  />{" "}
                </td>
                <td>{element.name}</td>
              </tr>
            </table>
          );
        })}
    </Dialog>
  );
};

export default DriverRole;
