import TYPES from '../types';

const initialState = {
    data: '',
};

export const activeWarehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_ACTIVE_WAREHOUSE:
            return {...state, data: action.payload.data};
        default:
            return state;
    }
}