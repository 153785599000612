import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown, Radio } from "semantic-ui-react";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import { setTableSelectedData } from "../../store/actions";
import "../DistributionReport/distribution.css";
import data from "./mockdata";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";

const VehicleStatusReport = (props) => {
  const dropdownlist = [
    {
      key: "1",
      text: "BRINGO",
      value: "BRINGO",
    },
    {
      key: "2",
      text: "ABC TAŞERON",
      value: "ABC TAŞERON",
    },
  ];
  const dispatch = useDispatch();
  const [products, setProducts] = useState(data);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const isMounted = useRef(false);
  const table = useRef(null);
  const [date, setDate] = useState(new Date());
  const [customer, setCustomer] = useState(dropdownlist[0].text);
  const tableData = useSelector((state) => state.tableReducer);

  var auth = new Auth();
  var isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  const renderTick = (data, type) => {
    if (data.status == type) {
      return <i className={"pi pi-check"}></i>;
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={data.orders}>
        <Column field="ilce" header="Araç"></Column>
        <Column
          body={(data) => renderTick(data, "active")}
          field="active"
          header="Aktif Araç"
        ></Column>
        <Column
          body={(data) => renderTick(data, "passive")}
          field="passive"
          header="Pasif Araç"
        ></Column>
        <Column
          body={(data) => renderTick(data, "maintenance")}
          field="maintenance"
          header="Bakımda"
        ></Column>
        <Column
          body={(data) => renderTick(data, "vehicle")}
          field="vehicle"
          header="Dağıtıcı"
        ></Column>
        <Column
          body={(data) => renderTick(data, "completedDoc")}
          field="completedDoc"
          header="Belgesi Tamamlanan"
        ></Column>
        <Column
          body={(data) => renderTick(data, "uncumpletedDoc")}
          field="uncumpletedDoc"
          header="Belgesi Tamamlanmayan"
        ></Column>
        <Column></Column>
      </DataTable>
    );
  };

  const header = <div className="table-header-container"></div>;

  useEffect(() => {
    setExpandedRows({ [tableData.cityId]: true });
  }, []);

  const onCheckHandler = (selected) => {
    if (
      expandedRows &&
      expandedRows.hasOwnProperty(selected.id) &&
      expandedRows[selected.id] == true
    ) {
      setExpandedRows({ [selected.id]: null });
      tableData.city = "";
      tableData.cityId = "";
      dispatch(setTableSelectedData(tableData));
    } else {
      setExpandedRows({ [selected.id]: true });
      tableData.city = selected.İl;
      tableData.cityId = selected.id;
      dispatch(setTableSelectedData(tableData));
    }
  };

  return (
    <>
      <NavBar />
      <div className="datatable-rowexpansion-demo">
        <Toast ref={toast} />
        <div className="filter">
          <span className="customerText">Müşteri </span>
          <Dropdown
            className="dropdownCustomer"
            placeholder={"HEPSİ"}
            search
            selection
            options={dropdownlist}
            onChange={(event, data) => setCustomer(data.value)}
          />
          <Calendar
            id="range"
            style={{ height: "36px", marginRight: "10px" }}
            value={date}
            onChange={(e) => setDate(e.value)}
            selectionMode="range"
            readOnlyInput
          />
          <i style={{ fontSize: "20px" }} className={"pi pi-calendar"}></i>
        </div>
        <div className={"links"}>
          <a href="">{customer}</a>
          <span>&gt;</span>
          <a href="">Türkiye</a>
        </div>
        <div className="card">
          <DataTable
            className={"tt"}
            ref={table}
            value={products}
            expandedRows={expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
          >
            <Column
              body={(e) => (
                <Radio
                  checked={
                    tableData.city && tableData.city == e.İl
                      ? true
                      : expandedRows &&
                        expandedRows.hasOwnProperty(e.id) &&
                        expandedRows[e.id] == true
                  }
                  onClick={() => onCheckHandler(e)}
                />
              )}
              expander
              style={{ width: "3em" }}
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="İl"
              header="İl"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="active"
              header="Aktif Araç Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="passive"
              header="Pasif Araç Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="maintenance"
              header="Bakımda Olan Araç Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="vehicle"
              header="Dağıtıcı Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="completedDoc"
              header="Belgesi Tamamlanan Dağıtıcı Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              field="uncumpletedDoc"
              header="Belgesi Tamamlanmayan Dağıtıcı Sayısı"
              sortable
            />
            <Column
              style={{ textAlign: "center" }}
              headerStyle={{ textAlign: "center" }}
              body={(data) => {
                const ratingColor =
                  data.rating <= 5
                    ? " pi-angle-down iconDown"
                    : " pi-angle-up iconUp";
                return (
                  <div className={"rating"}>
                    <div className={"ratingNumber"}>{data.rating}</div>
                    <i className={`pi ${ratingColor}`}></i>
                  </div>
                );
              }}
              field="rating"
              header="Ortalama Müşteri Puanı"
              sortable
            />
          </DataTable>
        </div>
      </div>
    </>
  );
};

VehicleStatusReport.whyDidYouRender = false;

export default VehicleStatusReport;
