// @mui
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@mui/material";
//
import EmptyContent from "./EmptyContent";

TableNoData.propTypes = {
  colSpan: PropTypes.number,
  isNotFound: PropTypes.bool,
};

export default function TableNoData({ colSpan, isNotFound }) {
  return isNotFound ? (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ border: 0 }}>
        <EmptyContent
          title="Hiç veri bulunamadı"
          sx={{
            "& span.MuiBox-root": { height: 160 },
          }}
        />
      </TableCell>
    </TableRow>
  ) : null;
}
