import TYPES from "../types";

const initialState = {};

export const vehicleZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_VEHICLE_ZONE_LIST_ALL:
      return { ...state, data: action.payload }

    default:
      return state
  }
};
