import { Services } from '../../api/Services';
import types from '../types';
import { loadingActive } from "helper";

const getServices = new Services()

export const getReports = (startDate, endDate, customerId) => {
    return (dispatch) => {
        loadingActive(dispatch, true);
        getServices.getReportsGeneral(startDate, endDate, customerId)
            .then(data => {
                dispatch({
                    type: types.GET_REPORTS,
                    payload: data.entity
                })
                loadingActive(dispatch, false);
            })
            // .then(datanew => console.log('testing', datanew))
    }
}

export const getReportsDelivery = (startDate, endDate, customerId, callback) => {
    // console.log(startDate.substring(0, startDate.length - 1), endDate.substring(0, endDate.length - 1))
    return (dispatch) => {
        loadingActive(dispatch, true);
        getServices.getReportsDelivery(startDate, endDate, customerId)
            .then((data) => {
                dispatch({
                    type: types.GET_REPORTS_DELIVERY,
                    payload: data.entity
                });
                callback(data.entity);
                loadingActive(dispatch, false);
            })
            // .then(datanew => console.log('testing', datanew))
    }
}

export const getReportsProduct = (startDate, endDate, customerId) => {
    return (dispatch) => {
        loadingActive(dispatch, true);
        getServices.getReportsProduct(startDate, endDate, customerId)
            .then((data) => {
                dispatch({
                    type: types.GET_PRODUCT_REPORTS,
                    payload: data.entity
                });
                // callback(data.entity);
                loadingActive(dispatch, false);
            })
            // .then(datanew => console.log('testing', datanew))
    }
}
