import React from "react";
import PropTypes from "prop-types";
import Item from "./item";
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Iconify from "components/Iconify";
import TableNoData from "components/CustomTable/TableNoData";

CityList.propTypes = {
  data: PropTypes.array,
};

export default function CityList({ data }) {
  // Table Head Data
  const tableHeadData = [
    { key: "il", title: "İl" },
    { key: "gonderiSayisi", title: "Sipariş Sayısı" },
    { key: "yogunluk", title: "Yoğunluk" },
  ];

  const [showAll, setShowAll] = React.useState(false);

  const dataToShow = showAll ? data : data.slice(0, 5);

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadData.map((t) => (
                <TableCell key={t.key}>{t.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.length > 0 ? (
              dataToShow.map((row, index) => <Item key={index} item={row} />)
            ) : (
              <TableNoData isNotFound colSpan={3} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {data.length > 5 && (
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="inherit"
            endIcon={
              showAll ? (
                <Iconify icon="bx:bx-chevron-down" />
              ) : (
                <Iconify icon="bx:bx-chevron-right" />
              )
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Daha az göster" : "Tümünü göster"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
