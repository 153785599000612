import TYPES from "../types";

const initialState = {
  token: '',
  user: 'deneme'
};

export const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.TEST:
      return {...state, token: action.payload}

    default:
      return state
  }
};
