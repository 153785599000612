import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from "primereact/dialog";
import { Checkbox } from "@material-ui/core";
import TYPES from "../../store/types/index";
import { Button } from "primereact/button";
import { assignWarehouseSave } from "../../store/actions/branchAction";

const VehicleAddBranch = (props) => {
    const dispatch = useDispatch();
    const { vehicleAddBranchDialog, setVehicleAddBranchDialog, selectedBranch, setSelectedBranch, product } = props;

    const vehicleBranchList = useSelector(state => state.vehicleBranchReducer);

    // useEffect(() => {
    //     console.log("vehicleBranchList :", vehicleBranchList)
    // }, [vehicleBranchList])


    const hideDialogVehicleAddBranch = () => {
        setVehicleAddBranchDialog(false)
    };


    const saveVehicleAddBranch = () => {
        setVehicleAddBranchDialog(false)
    };



    const vehicleAddBranchFooter = (

        <React.Fragment>
            <Button
                label="Kaydet"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveVehicleAddBranch()}
            />
        </React.Fragment>

    );



    const selectedBranchCheckbox = (e) => {
        //console.log(e.target.id);
        let duplicateData = selectedBranch && selectedBranch.find(d => d == e.target.id);
        if (duplicateData) {
            let currentSelectedBranch = selectedBranch.filter(c => c != duplicateData);
            //console.log("y :", y);
            setSelectedBranch([...currentSelectedBranch])
        } else {
            selectedBranch ? setSelectedBranch([...selectedBranch, e.target.id]) : setSelectedBranch([e.target.id])
        }
    };

    useEffect(() => {
        //console.log("vehicleBranch :", selectedBranch)
        //branch assigmnet
        product.branch=selectedBranch;
    }, [selectedBranch])


    return (
        <Dialog
            header="Şube Atama"
            visible={vehicleAddBranchDialog}
            style={{ width: "1000px" }}
            onHide={hideDialogVehicleAddBranch}
            footer={vehicleAddBranchFooter}
        >
            {
                vehicleBranchList.data && vehicleBranchList.data.map((element) => {
                    return (
                        <table>
                            <tr>
                                <td><Checkbox id={element.id} checked={selectedBranch && selectedBranch.includes(element.id)} onChange={e => selectedBranchCheckbox(e)} /> </td>
                                <td>{element.name}</td>
                            </tr>
                        </table>
                    )
                })
            }
        </Dialog>
    )
}

export default VehicleAddBranch
