import React from "react";
import PropTypes from "prop-types";
import { Box, Card, Stack, Typography } from "@mui/material";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { LoadingButton } from "@mui/lab";
import { Refresh, Search } from "@mui/icons-material";
import CustomSwitch from "components/CustomSwitch";

Filter.propTypes = {
  style: PropTypes.array,
};

export default function Filter(props) {
  const { options, setFilters, filters, setGetFiltersData } = props;

  if (!options) return null;

  const handleSwitchChange = (e) => {
    setFilters({ ...filters, active: e.target.checked });
  };

  const onChangeInput = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilterReset = () => {
    setFilters({
      branchId: "",
      companyId: "",
      propertyType: "",
      vehicleTypeId: "",
      active: "",
      vehicleName: "",
    });
    setGetFiltersData(true);
  };

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Filtre
      </Typography>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <CustomSelect
          label="Aracın Bağlı Olduğu Cariler"
          placeholder="Seçiniz"
          data={options?.companies}
          name="companyId"
          value={filters.companyId || ""}
          onChange={onChangeInput}
        />
        <CustomSelect
          label="Aracın Bağlı Olduğu Şubeler"
          placeholder="Seçiniz"
          data={options?.branches}
          name="branchId"
          value={filters.branchId || ""}
          onChange={onChangeInput}
        />
        {/* <CustomSelect
          label="Kiralık / Özmal Durum"
          placeholder="Seçiniz"
          data={options.property_types}
          name="propertyType"
          value={filters.propertyType || ""}
          onChange={onChangeInput}
        /> */}
        <CustomSelect
          label="Araç Tipi"
          placeholder="Seçiniz"
          data={options.vehicle_types}
          name="vehicleTypeId"
          value={filters.vehicleTypeId || ""}
          onChange={onChangeInput}
        />
        <CustomInput
          label="Araç Plaka"
          name="vehicleName"
          placeholder="Araç plakası giriniz"
          value={filters.vehicleName}
          onChange={onChangeInput}
        />
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Box sx={{ mb: 1 }}>
            <CustomSwitch
              checked={filters.active}
              onChange={handleSwitchChange}
              name="active"
              label="Aktif"
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              color="secondary"
              variant="contained"
              onClick={() => setGetFiltersData(true)}
              endIcon={<Search />}
              sx={{ py: "9px!important", minWidth: 100 }}
            >
              Filtrele
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={handleFilterReset}
            >
              <Refresh />
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
