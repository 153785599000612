import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import Item from "./item";
import Filter from "./Filter";
import {
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  vehicleBracnhList,
  vehicleProjectList,
  vehicleZoneList,
} from "store/actions/vehicleManagementAction";

const listFilterData = (data) => {
  return data.map((item) => {
    return {
      detailData: item,
      // Custom render item
      id: item.id,
      lastUpdated: item.lastupdated,
      status: item.status,
      name: item.company?.name || "",
      plaka: item.vehicle_id,
      projeler: item.projects,
      zone: item.branches.map((b) => b.name).join(" / "),
      model: item.vehicle_model?.name || "",
      aracTip: item.vehicle_type?.name || "",
      surucu: item.drivers[0]?.name || "",
    };
  });
};

const TableList = ({
  data,
  options,
  setFilters,
  vehicleFilter,
  setGetFiltersData,
  loading,
  handleDelete,
  toggleModal,
}) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState(listFilterData(data));
  const [filterSearch, filterSearchOnChange] = React.useState("");
  const [openProjects, setOpenProjects] = React.useState(null);

  React.useEffect(() => {
    dispatch(vehicleZoneList());
    dispatch(vehicleBracnhList());
    dispatch(vehicleProjectList());
  }, []);

  React.useEffect(() => {
    setTableData(listFilterData(data));
  }, [data]);

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "lastUpdated", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadData = [
    { key: "plaka", title: "Plaka", onSort },
    { key: "aracTip", title: "Tip", onSort },
    { key: "model", title: "Model", onSort },
    { key: "surucu", title: "Sürücü", onSort },
    { key: "name", title: "Firma", onSort },
    { key: "projeler", title: "Projeler", onSort },
    { key: "zone", title: "Bölge", onSort },
    { key: "status", title: "Durumu", onSort },
    { key: "filter", title: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: ["status", "name", "plaka", "projeler", "zone", "model"],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData = tableDataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const vehicleProjectData = useSelector(
    (state) => state.vehicleProjectReducer
  );

  const getItemProjectNames = (projects) => {
    if (!vehicleProjectData?.data?.data) {
      return [];
    }

    const vehicleProjects = vehicleProjectData.data.data;
    if (vehicleProjects && projects && projects.length > 0) {
      return vehicleProjects
        .map((d) => projects.includes(d.project_id) == true && d.name)
        .filter(Boolean)
        .map((p) => p);
    }
    return [];
  };

  const handleProjectsClose = () => {
    setOpenProjects(null);
  };
  const handleProjectsOpen = (projects) => {
    setOpenProjects(projects);
  };

  return (
    <Stack spacing={2}>
      <Filter
        options={options}
        setFilters={setFilters}
        filters={vehicleFilter}
        setGetFiltersData={setGetFiltersData}
      />
      <CustomTable
        {...{
          loading,
          // Filter
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => {
            return (
              <Item
                key={item.id}
                item={{ ...item, projeler: getItemProjectNames(item.projeler) }}
                handleProjectsOpen={handleProjectsOpen}
                handleDelete={handleDelete}
                toggleModal={() =>
                  toggleModal({ type: "update", item: item.detailData })
                }
              />
            );
          }),
        }}
      />
      {openProjects && (
        <Dialog onClose={handleProjectsClose} open>
          <DialogTitle sx={{ textAlign: "center" }}>
            {openProjects.name}
          </DialogTitle>
          <List sx={{ minWidth: "320px" }} dense>
            {openProjects.projeler.map((project, index) => (
              <ListItem key={index} button>
                <ListItemText primary={project} />
              </ListItem>
            ))}
          </List>
        </Dialog>
      )}
    </Stack>
  );
};

export default TableList;
