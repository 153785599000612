// @mui
import { Stack, Button, Typography } from "@mui/material";
// assets
import { DocIllustration } from "assets";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useSelector((s) => s.userReducer);

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: "center",
        display: "block",
      }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Merhaba, {user?.first_name}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Yardıma mı ihtiyaçın var?
          <br /> Lütfen dokümanımıza göz at.
        </Typography>
      </div>

      <Button href="/" target="_blank" rel="noopener" variant="contained">
        Dokümanı İncele
      </Button>
    </Stack>
  );
}
