const data = [
    {
      id: "1000",
      code: "f230fh0g3",
      İl: "ISTANBUL",
      description: "Product Description",
      image: "bamboo-watch.jpg",
      active: 3,
      passive: 2,
      maintenance: 1,
      deliveried: 200,
      package: 250,
      vehicle: 1,
      activeHours:"150 saat",
      visited : 250,
      completedDoc: 1,
      uncumpletedDoc: 1,
      category: "Accessories",
      unsuccessful: 25,
      Performance:250,
      company:"XYZ LTD ŞTİ",
      inventoryStatus: "INSTOCK",
      fee: "40000 TL",
      rating: 8.2,
      orders: [
        {
          id: "1000",
          rating: 8.2,
          date: "2020-09-13",
          packages: 50,
          unsuccessful: 10,
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          inspectionDoc: "31.09.2021",
          maintenence: "31.09.2021",
          vehicleStatus: "active",
          plate: "34ABC123",
          status: "active",
          doc: "data",
          docDate: "31.12.2021",
        },
        {
          id: "1001",
          rating: 8.2,
          date: "2020-05-14",
          packages: 100,
          unsuccessful: 10,
          plate: "34AU012",
          status: "passive",
          vehicleStatus: "active",
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          inspectionDoc: "31.09.2021",
          maintenence: "31.09.2021",
          insurance: "31.12.2020",
          doc: "data"

        },
        {
          id: "1002",
          rating: 8.2,
          date: "2019-01-04",
          packages: 25,
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          inspectionDoc: "31.09.2021",
          maintenence: "31.09.2021",
          vehicleStatus: "maintenance",
          unsuccessful: 2,
          plate: "34GB356",
          status: "maintenance",
          doc: "data"

        },
        {
          id: "1003",
          rating: 8.2,
          date: "2020-09-13",
          packages: 25,
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          maintenence: "31.09.2021",
          inspectionDoc: "31.09.2021",
          vehicleStatus: "passive",
          unsuccessful: 3,
          plate: "34ABC41",
          status: "vehicle",
          doc: "data"

        },
      ],
    },
    {
      id: "1001",
      code: "nvklal433",
      İl: "BURSA",
      description: "Product Description",
      image: "black-watch.jpg",
      activeHours:"150 saat",
      active: 3,
      passive: 2,
      maintenance: 1,
      deliveried: 200,
      fee: "40000 TL",
      vehicle: 1,
      completedDoc: 1,
      Performance:250,
      uncumpletedDoc: 1,
      category: "Accessories",
      unsuccessful: 25,
      visited : 250,
      package: 250,
      inventoryStatus: "INSTOCK",
      company:"XYZ LTD ŞTİ",
      rating: 7.3,
      orders: [
        {
          id: "2000",
          rating: 7.3,
          date: "2020-05-14",
          packages: 100,
          unsuccessful: 20,
          plate: "16ABC123",
          status: "completedDoc",
          docDate: "31.12.2021",
          maintenence: "31.09.2021",
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          vehicleStatus: "passive",
          inspectionDoc: "31.09.2021",
          doc: "data"

        },
        {
          id: "2001",
          rating: 7.3,
          date: "2020-02-28",
          packages: 100,
          unsuccessful: 5,
          plate: "16gb36",
          status: "uncumpletedDoc",
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          maintenence: "31.09.2021",
          inspectionDoc: "31.09.2021",
          vehicleStatus: "active",
          doc: "data"

        },
      ],
    },
    {
      id: "1002",
      code: "zz21cz3c1",
      İl: "ANKARA",
      description: "Product Description",
      image: "blue-band.jpg",
      company:"XYZ LTD ŞTİ",
      active: 3,
      passive: 2,
      maintenance: 1,
      fee: "40000 TL",
      vehicle: 1,
      completedDoc: 1,
      uncumpletedDoc: 1,
      activeHours:"150 saat",
      deliveried: 200,
      category: "Fitness",
      Performance:250,
      unsuccessful: 25,
      visited : 250,
      package: 250,
      inventoryStatus: "LOWSTOCK",
      rating: 5.0,
      orders: [
        {
          id: "3000",
          rating: 5.0,
          date: "2020-07-05",
          packages: 100,
          unsuccessful: 20,
          plate: "06JJ123",
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          maintenence: "31.09.2021",
          insurance: "31.12.2020",
          vehicleStatus: "maintenance",
          inspectionDoc: "31.09.2021",
          status: "active",
          doc: "data"

        },
        {
          id: "3001",
          rating: 5.0,
          date: "2020-02-06",
          packages: 100,
          unsuccessful: 5,
          plate: "06ABC123",
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          maintenence: "31.09.2021",
          insurance: "31.12.2020",
          inspectionDoc: "31.09.2021",
          vehicleStatus: "passive",
          status: "active",
          doc: "data"

        },
      ],
    },
    {
      id: "1003",
      code: "244wgerg2",
      İl: "IZMIR",
      description: "Product Description",
      image: "blue-t-shirt.jpg",
      active: 3,
      passive: 2,
      maintenance: 1,
      company:"XYZ LTD ŞTİ",
      vehicle: 1,
      deliveried: 200,
      completedDoc: 1,
      uncumpletedDoc: 1,
      visited : 250,
      Performance:250,
      package: 250,
      category: "Clothing",
      unsuccessful: 25,
      fee: "40000 TL",
      activeHours:"150 saat",
      inventoryStatus: "INSTOCK",
      rating: 4.3,
      orders: [{
        id: "4000",
        rating: 4.3,
        date: "2020-09-05",
        docDate: "31.12.2021",
        trafficDoc: "31.12.2021",
        insurance: "31.12.2020",
        maintenence: "31.09.2021",
        inspectionDoc: "31.09.2021",
        packages: 100,
        unsuccessful: 20,
        plate: "35ABC123",
        status: "active",
        vehicleStatus: "active",
        doc: "data"

    },
      {
        id: "4001",
        rating: 4.3,
        date: "2019-04-16",
        packages: 100,
        unsuccessful: 5,
        plate: "35GB56",
        docDate: "31.12.2021",
        trafficDoc: "31.12.2021",
        insurance: "31.12.2020",
        maintenence: "31.09.2021",
        inspectionDoc: "31.09.2021",
        status: "active",
        vehicleStatus: "passive",

        doc: "data"

    },],
    },
    {
      id: "1004",
      code: "f2843ju98",
      İl: "ANTALYA",
      description: "Product Description",
      image: "bracelet.jpg",
      active: 3,
      passive: 2,
      maintenance: 1,
      vehicle: 1,
      completedDoc: 1,
      company:"XYZ LTD ŞTİ",
      uncumpletedDoc: 1,
      activeHours:"150 saat",
      Performance:250,
      category: "Accessories",
      visited : 250,
      deliveried: 200,
      package: 250,
      fee: "40000 TL",
      unsuccessful: 25,
      inventoryStatus: "INSTOCK",
      rating: 8.2,
      orders: [
        {
          id: "5000",
          rating: 8.2,
          date: "2020-09-05",
          packages: 100,
          unsuccessful: 20,
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          inspectionDoc: "31.09.2021",
          maintenence: "31.09.2021",
          plate: "07ABC123",
          vehicleStatus: "passive",

          status: "active",
          doc: "data"

        },
        {
          id: "5001",
          rating: 8.2,
          date: "2019-04-16",
          packages: 100,
          unsuccessful: 5,
          docDate: "31.12.2021",
          trafficDoc: "31.12.2021",
          insurance: "31.12.2020",
          inspectionDoc: "31.09.2021",
          maintenence: "31.09.2021",
          plate: "07GB56",
          status: "active",
          vehicleStatus: "passive",

          doc: "data"

        },
      ],
    },

  ];
  export default data