import PropTypes from "prop-types";
import qs from "query-string";
// @mui
import { Stack, Typography, Box, CircularProgress } from "@mui/material";
// utils
import { fShortenNumber } from "utils/formatNumber";
// components
import Iconify from "components/Iconify";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

InvoiceAnalytic.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  percent: PropTypes.number,
  price: PropTypes.number,
  total: PropTypes.number,
  routeKey: PropTypes.string,
};

export default function InvoiceAnalytic({
  title,
  total,
  icon,
  color,
  percent,
  routeKey,
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 1, minWidth: 200 }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ position: "relative" }}>
        <Iconify
          icon={icon}
          sx={{ color, width: 24, height: 24, position: "absolute" }}
        />

        <CircularProgress
          variant="determinate"
          value={percent}
          size={56}
          thickness={4}
          sx={{ color, opacity: 0.48 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={56}
          thickness={4}
          sx={{
            color: "grey.50016",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.48,
          }}
        />
      </Stack>

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography variant="h6">{title}</Typography>

        <Typography variant="subtitle2">
          {fShortenNumber(total)}{" "}
          <Box component="span" sx={{ color: "text.secondary", typography: "body2" }}>
            adet
          </Box>
        </Typography>

        {routeKey == "all" ? null : (
          <Link
            to={{
              pathname: "/distribution/detail",
              search: qs.stringify({ routeKey }),
            }}
          >
            <Typography variant="subtitle2" sx={{ color }}>
              <Stack direction="row" alignItems="center">
                Detayı incele <ChevronRight fontSize="inherit" sx={{ color }} />
              </Stack>
            </Typography>
          </Link>
        )}
      </Stack>
    </Stack>
  );
}
