import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { FormControlLabel, Switch } from "@mui/material";

SwitchField.propTypes = {
  label: PropTypes.string,
};

export default function SwitchField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <FormControlLabel
      label={label}
      control={
        <Switch label={label} {...field} {...props} checked={field.value} />
      }
    />
  );
}
