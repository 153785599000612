import TYPES from "../types";

const initialState = {
    status: false
};

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.LOADING_CHANGE:
            return { ...state, status: action.payload }
            break;
        default:
            return state
    }
};
