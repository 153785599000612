import _ from "lodash";

export const getLengthByKey = (data, key) => {
  if (key == "all") {
    return data.length;
  }
  const totalLength = data.reduce((total, item) => {
    return item[key] > 0 ? total + 1 : total;
  }, 0);

  return totalLength;
};

export function applySortFilter({
  tableData,
  // Tabs
  tabsActive,
  // Filter
  comparator,
  filterOption,
  filterSearch,
  filterSearchKeys,
}) {
  // Tabs
  if (tabsActive && tabsActive !== "all") {
    tableData = tableData.filter((item) => item[tabsActive]);
  }

  // Order By
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  // Filter
  if (filterOption !== "Tümü") {
    tableData = tableData.filter((item) => item.durum === filterOption);
  }

  // Filter Search
  const convertString = (text) => {
    if (text !== "") {
      return `${text}`
        .toUpperCase()
        .replace(/İ/g, "I")
        .replace(/Ğ/g, "G")
        .replace(/Ü/g, "U")
        .replace(/Ş/g, "S")
        .replace(/Ö/g, "O")
        .replace(/Ç/g, "C");
    }
  };
  if (filterSearch !== "") {
    const textArray = filterSearch.split(" ");
    let wholeData = [];
    for (const key of filterSearchKeys) {
      if (key.includes(".")) {
        console.log("HERE");
      } else {
        tableData.map((row) => {
          for (const i in textArray) {
            const element = textArray[i];
            if (convertString(row[key])?.includes(convertString(element))) {
              wholeData = [...wholeData, row];
            }
          }
        });
      }
    }
    // wholeData = _.uniqBy(wholeData, filterSearchKeys[0]);
    return wholeData;
  }

  return tableData;
}
