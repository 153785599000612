import TYPES from "../types";
import { Services } from "../../api/Services"


const server = new Services();

export const saveProgressPayment = (companyId, data, toastCallback) => {

    return async (dispatch) => {
        server.
            progressPayment(companyId, data).then((data) => {
                toastCallback("success")
                // dispatch({
                //   type: TYPES.LISTING,
                //   payload: data.entity
                // });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};


export const saveProgressPaymentVehicle = (vehicleId, data, toastCallback) => {

    return async (dispatch) => {
        server.
            progressPaymentVehicle(vehicleId, data).then((data) => {
                toastCallback("success")
                // dispatch({
                //   type: TYPES.LISTING,
                //   payload: data.entity
                // });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};



export const getProgressPayment = (id, setCountPrice) => {

    return async (dispatch) => {
        server.
            getCurrentProgressPayment(id).then((data) => {
                let temporaryData = [...data.entity.data];
                if (temporaryData[0].count_calculators == null) temporaryData[0].count_calculators = []; // empty array
                if (temporaryData[0].deci_calculators == null) temporaryData[0].deci_calculators = []; // empty array
                setCountPrice(temporaryData[0])

                //setCountPrice(data.entity.data[0])
                //   dispatch({
                //     type: TYPES.LISTING,
                //     payload: data.entity
                //   });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};



export const getProgressPaymentVehicle = (id, setCountPrice) => {

    return async (dispatch) => {
        server.
            getCurrentProgressPaymentVehicle(id).then((data) => {
                let temporaryData = [...data.entity.data];
                if (temporaryData[0].count_calculators == null) temporaryData[0].count_calculators = []; // empty array
                if (temporaryData[0].deci_calculators == null) temporaryData[0].deci_calculators = []; // empty array
                setCountPrice(temporaryData[0])

                //setCountPrice(data.entity.data[0])
                //   dispatch({
                //     type: TYPES.LISTING,
                //     payload: data.entity
                //   });
            })
            .catch((error) => {
                console.log(error);
            });
    }
};