import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
// import { createBrowserHistory } from 'history';
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import reducers from "./reducers";
import history from "./history";
import { persistStore, persistReducer } from "redux-persist";
import logger from "redux-logger";

// const history =  createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const thunkMiddleware = thunk;
const routersMiddleware = routerMiddleware(history);
const middlewares = [thunkMiddleware, routersMiddleware];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  connectRouter(history)(persistedReducer),
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
export let persistor = persistStore(store);

export default store;
